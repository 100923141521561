'use strict';

const ConfigurationModule = require('./Module');
const CodingScopeConfiguration = require('./CodingScope');

/**
 * Módulo de configuración: Medical Coding
 */
class MedicalCodingConfiguration extends ConfigurationModule {
    /**
     * Constructor de la clase
     *
     * @param {Object} params Objeto de definición del módulo
     */
    constructor(params) {
        super(params);

        this.scopes = Array.isArray(params.scopes) ? params.scopes.map(scopeDefinition => {
            return new CodingScopeConfiguration(scopeDefinition);
        }) : [];
    }

    /**
     * Obtiene la lista de scopes definidos
     */
    getScopes() {
        return this.scopes;
    }
}

module.exports = MedicalCodingConfiguration;
