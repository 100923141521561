'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedQueryAction
 *
 * @property {string} func Nombre de la función: createQuery
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - el ID del campo
 *                         - la configuración de la query
 */

/**
 * Clase de gestión de una regla de tipo Añadir una nota de discrepancia
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class QueryAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.QUERY;

        this.definition.query = Object.assign({
            text: '',
            autoClose: false,
            requiredState: null,
            alert: false,
        }, definition.query || {});
    }

    /**
     * Get text
     *
     * @return {String} Text
     */
    getText() {
        return this.definition.query.text;
    }

    /**
     * Should close itself automatically when conditions would not pass
     *
     * @return {Boolean} Auto close
     */
    shouldAutoClose() {
        return !!this.definition.query.autoClose;
    }

    /**
     * Should open only if involved forms have the specified approval state
     *
     * @return {string} State ID
     */
    shouldOpenIfFormsHaveState() {
        return this.definition.query.requiredState;
    }

    /**
     * Should alert user when a query is going to be created
     *
     * @return {Boolean} Alert
     */
    shouldAlertUser() {
        return !!this.definition.query.alert;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @return {ExportedQueryAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        action.func = 'createQuery';

        return action;
    }
}

module.exports = QueryAction;
