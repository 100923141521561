'use strict';

const _ = require('lodash');

/**
 * Clase que gestiona el estado de completado de los elementos del CRD
 *
 * Emplea los flags de FormFields para añadir la propiedad **completed**
 *
 * @property {integer[]} _excludedElementIds Lista de elementos que no deben intervenir en el cálculo del completado
 *                                           Por ejemplo para no tener en cuenta los campos no accesibles por perfil
 *
 * @memberOf Record
 */
class Completion {
    /**
     * @param  {FormFields} formFields Instancia
     */
    constructor(formFields) {
        this.formFields = formFields;
        this.record = this.formFields.getRecord();
        this.crf = this.record.getCRF();
        this.completion = {};

        this._excludedElementIds = [];
    }

    /**
     * Resetea la información de validación en este registro, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     */
    reset() {
        _.each(_.keys(this.completion), key => {
            _.unset(this.completion, key);
        });
    }

    /**
     * Establece la lista de campos que no intervienen en el cálculo del grado de completado
     *
     * @param  {Field[]} elementIds Lista de instancias de campo en el CRF
     */
    excludeElements(elementIds) {
        this._excludedElementIds = elementIds;
    }

    /**
     * Devuelve el estado de rellenado de un elemento del CRD (campo, form, lista, sección, etc.)
     *
     * Si no se especifica un elemento, lo hace sobre el registro global
     *
     * @param  {Structure.Container}  element            El elemento en el CRF
     * @param  {ListIndices}          listIndices        Lista de índices de listas que lo contienen
     * @param  {object}               requiredFieldsInfo Información de campos que deben rellenarse acorde al CRF
     *
     * @return {object}                                  Estado de rellenado del elemento
     */
    getCompletion(element, listIndices) {
        if (!element || element.isRoot()) {
            return this.getRecordCompletion();
        }

        if (element.isList()) {
            return this.getListCompletion(element, listIndices);
        }

        if (element.isSection()) {
            return this.getSectionCompletion(element, listIndices);
        }

        if (element.isForm()) {
            return this.getFormCompletion(element, listIndices);
        }

        if (element.isField()) {
            return this.getFieldCompletion(element, listIndices);
        }
    }

    /**
     * Recalcula la información de completado del elemento especificado
     *
     * @param  {Structure.Container} element     El elemento en el CRF
     * @param  {ListIndices}         listIndices Lista de índices de listas que lo contienen
     *
     * @return {object}                           Nuevo estado de rellenado del elemento
     */
    reload(element, listIndices) {
        this.clear(element, listIndices);

        return this.getCompletion(element, listIndices);
    }

    /**
     * Recalcula la información de completado de un ítem concreto de una lista
     *
     * @param  {Structure.List} list        La instancia de lista
     * @param  {ListIndices}    listIndices Índices de las listas a las que pertenece el ítem
     * @param  {Number}         itemId      ID interno único del ítem
     *
     * @return {object}                     Nuevo estado de rellenado del ítem
     */
    reloadListItem(list, listIndices, itemId) {
        this.clearListItem(list, listIndices, itemId);

        return this.getListItemCompletion(list, listIndices, itemId);
    }

    /**
     * Limpia la información de completado de un elemento concreto del cuaderno
     *
     * @param  {Structure.Container} element     El elemento en el CRF
     * @param  {ListIndices}         listIndices Lista de índices de listas que lo contienen
     */
    clear(element, listIndices) {
        element = element || this.crf;
        delete this.completion[this.getElementKey(element, listIndices)];

        if (element.isList()) {
            const listId = element.getId();
            const listData = this.record.getList(listId, listIndices);
            listData.forEach((listItem, index) => {
                const itemIndices = {
                    ...listIndices,
                    [listId]: index + 1,
                };
                this.clearListItem(element, itemIndices, listItem.__id);
            });
        } else {
            element.getChildren().forEach(child => this.clear(child, listIndices));
        }
    }

    /**
     * Limpia la información de completado de un ítem de lista
     *
     * @param  {Structure.List} list        Instancia de la lista en la definición
     * @param  {ListIndices}    listIndices Índices de listas a las que pertenece el ítem
     * @param  {Number}         itemId      ID interno único del ítem
     */
    clearListItem(list, listIndices, itemId) {
        delete this.completion[this.getElementKey(list, listIndices, itemId)];

        list.getChildren().forEach(child => this.clear(child, listIndices));
    }

    /**
     * Determina si el campo es requerido según su definición e información contextual (habilitado y visible)
     * Los campos que no son visibles no se consideran obligatorios
     * Los campos que no son editables tampoco pueden ser nunca obligatorios
     *
     * @param  {Container}   field       Instancia del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}                 TRUE si el campo está rellenado
     *
     * @private
     */
    isFieldRequired(field, listIndices) {
        // Es requerido por definición
        if (!field.isRequired()) {
            return false;
        }

        const fieldId = field.getId();

        // No está en la lista de excepciones
        if (this._excludedElementIds.includes(fieldId)) {
            return false;
        }

        // Y es accesible de acuerdo al estado en el registro (visible y editable)
        return this.formFields.isFieldAvailable(fieldId, listIndices);
    }

    /**
     * Determina el estado de relleno de un campo de un formulario, y lo establece en FormFields, en su campo
     * correspondiente
     *
     * @param  {Structure.Field}  field    Instancia del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {object}   Estado de rellenado del campo
     */
    getFieldCompletion(field, listIndices) {
        const key = this.getElementKey(field, listIndices);
        if (typeof this.completion[key] !== 'undefined') {
            return this.completion[key];
        }

        let fieldCompletion = false;
        const fieldId = field.getId();

        if (this.isFieldRequired(field, listIndices)) {
            fieldCompletion = !this.record.isFieldValueEmpty(fieldId, listIndices);
        }

        this.formFields.setCompleted(fieldId, fieldCompletion, listIndices);

        // lo guardamos en nuestro objeto interno, indexado por el UID del campo (unido por .)
        this.completion[key] = fieldCompletion;

        return fieldCompletion;
    }

    /**
     * Determina el estado de relleno de un formulario, recorriendo los campos que lo conforman y consultando
     * el estado de rellenado de cada uno
     *
     * @param  {Structure.Form}  form        Instancia del formulario en el CRF
     * @param  {ListIndices}     listIndices Índices de las listas a las que pertenece el formulario
     *
     * @return {object}                      Estado de rellenado del formulario
     */
    getFormCompletion(form, listIndices) {
        const key = this.getElementKey(form, listIndices);
        if (typeof this.completion[key] !== 'undefined') {
            return this.completion[key];
        }

        // calcula los campos requeridos en tiempo real, mirando individualmente su estado de visibilidad, etc.
        const requiredFields = form.getRequiredFields().filter(field => this.isFieldRequired(field, listIndices));

        const formCompletion = {
            required: requiredFields.length,
            completed: 0,
            pending: requiredFields.length,
        };

        requiredFields.forEach(field => {
            if (this.getFieldCompletion(field, listIndices)) {
                formCompletion.completed++;
                formCompletion.pending--;
            }
        });

        this.completion[key] = formCompletion;

        return formCompletion;
    }

    /**
     * Determina el estado de relleno de una sección, recorriendo los hijos directos que lo conforman y consultando
     * el estado de rellenado de cada uno
     *
     * @param  {Structure.Section}  section     Instancia de la sección en el CRF
     * @param  {ListIndices}        listIndices Índices de las listas a las que pertenece la sección
     *
     * @return {object}                         Estado de rellenado de la sección
     */
    getSectionCompletion(section, listIndices) {
        const key = this.getElementKey(section, listIndices);
        if (typeof this.completion[key] === 'undefined') {
            this.completion[key] = this.getSectionsContainerCompletion(section, listIndices);
        }

        return this.completion[key];
    }

    /**
     * Determina el estado de relleno de un contenedor de secciones genérico, a partir del estado de sus hijos
     *
     * @param  {Structure.SectionsContainer} container   Instancia del contenedor
     * @param  {ListIndices}                 listIndices Índices de las listas a las que pertenece
     *
     * @return {object}                                  Estado de rellenado del elemento
     *
     * @private
     */
    getSectionsContainerCompletion(container, listIndices) {
        return container.getChildren().reduce((acc, child) => {
            const childCompletion = this.getCompletion(child, listIndices);

            ['required', 'completed', 'pending'].forEach(resultKey => {
                acc[resultKey] += childCompletion[resultKey];
            });

            return acc;
        }, {
            required: 0,
            completed: 0,
            pending: 0,
        });
    }

    /**
     * Determina el estado de relleno de una lista, recorriendo los elementos registrados en los datos y consultando el
     * estado de rellenado de cada uno
     *
     * @param  {Structure.List} list        Instancia de la lista en el CRF
     * @param  {ListIndices}    listIndices Índices de las listas a las que pertenece la lista
     *
     * @return {object}                     Estado de rellenado de la lista
     */
    getListCompletion(list, listIndices) {
        const key = this.getElementKey(list, listIndices);
        if (typeof this.completion[key] !== 'undefined') {
            return this.completion[key];
        }

        const listId = list.getId();
        const listData = this.record.getList(listId, listIndices) || [];

        this.completion[key] = listData.reduce((acc, itemData, index) => {
            const itemIndices = {
                ...listIndices,
                [listId]: index + 1, // listIndices empieza en 1 para el primer elemento
            };

            const itemCompletion = this.getListItemCompletion(list, itemIndices, itemData.__id);
            ['required', 'completed', 'pending'].forEach(resultKey => {
                acc[resultKey] += itemCompletion[resultKey];
            });

            return acc;
        }, {
            required: 0,
            completed: 0,
            pending: 0,
        });

        return this.completion[key];
    }

    /**
     * Determina el estado de relleno de un ítem de una lista, recorriendo los hijos directos que lo conforman y
     * consultando el estado de rellenado de cada uno
     *
     * Para determinar el ítem, en listIndices ya viene como clave el ID de la propia lista, y el índice empezando en
     * 1 del ítem a estudiar.
     *
     * @param  {Structure.List}  list        Instancia de la lista en el CRF
     * @param  {ListIndices}     listIndices Índices de las listas a las que pertenece la lista
     * @param  {Number}          itemId      ID interno del ítem
     *
     * @return {object}                      Estado de rellenado del ítem de lista
     */
    getListItemCompletion(list, listIndices, itemId) {
        const key = this.getElementKey(list, listIndices, itemId);
        if (typeof this.completion[key] === 'undefined') {
            this.completion[key] = this.getSectionsContainerCompletion(list, listIndices);
        }

        return this.completion[key];
    }

    /**
     * Determina el estado de relleno global del registro
     *
     * @return {object} Estado de rellenado del registro
     */
    getRecordCompletion() {
        if (typeof this.completion.root === 'undefined') {
            this.completion.root = this.getSectionsContainerCompletion(this.crf);
        }

        return this.completion.root;
    }

    /**
     * Obtiene la lista de campos que pertenecen a la estructura del elemento seleccionado que se consideran
     * obligatorios según el estado actual del registro
     *
     * @see RecordCompletion.isFieldRequired
     *
     * @param  {Number}            elementId   ID único del elemento contenedor de campos
     * @param  {ListIndices}       listIndices Índices de las listas a las que pertenece el elemento en el registro
     *
     * @return {Structure.Field[]}             Lista de objetos de tipo Field
     */
    getRequiredFields(elementId, listIndices) {
        const element = this.crf.getElement(elementId, listIndices);
        if (element !== null) {
            let fields = element.getFields();

            return _.map(fields, field => this.isFieldRequired(field, listIndices));
        } else {
            return [];
        }
    }

    /**
     * Obtiene la clave interna de un elemento para indexar en el objeto de completado. Es el UID del elemento unido
     * por puntos, para tener un acceso directo de primer nivel
     *
     * @param  {Structure.Container} element     La instancia de elemento
     * @param  {ListIndices}         listIndices Índices de las listas que contienen el elemento
     * @param  {Number}              [itemId]    ID del ítem de lista, si es que fuera necesario
     *
     * @return {string}                          La clave calculada
     *
     * @private
     */
    getElementKey(element, listIndices, itemId) {
        if (element.isRoot()) {
            return 'root';
        }

        const elementId = element.getId();
        const uid = this.record.getElementUID(elementId, listIndices);
        if (!uid) {
            // anotamos el elemento como desconocido para temas de depuración si hiciera falta
            return `unk-${element.getId()}${JSON.stringify(listIndices)}`;
        }

        if (typeof itemId !== 'undefined') {
            uid.push(itemId);
        }

        return uid.join('.');
    }
}

module.exports = Completion;
