'use strict';

const _ = require('lodash');

const Condition = require('./Condition');

/**
 * Posibles valores de agrupación de condiciones: todas o alguna
 *
 * @readonly
 *
 * @enum {string}
 */
const Operator = {
    ALL: 'and',
    ANY: 'or',
};

/**
 * Definición en la base de datos de la lista de condiciones
 * @typedef {object} ConditionsListDefinition
 *
 * @property {Operator}                                     operator Operador que agrupa las condiciones que componen la
 *                                                                   lista
 * @property {ConditionsListDefinition|ConditionDefinition} list     Lista de condiciones o agrupaciones
 */

/**
 * Objeto de exportación de la lista de condiciones
 * @typedef {ConditionsListDefinition} ExportedConditionList
 */

/**
 * Objeto que contiene la información de una lista de condiciones de regla y métodos para manejarla
 *
 * @property {ConditionsListDefinition}                             definition El objeto original almacenado en la base
 *                                                                             de datos
 * @property {array.<Structure.ConditionsList|Structure.Condition>} conditions Instancias de las condiciones contenidas
 *                                                                             en la lista, que a su vez pueden ser
 *                                                                             listas de condiciones
 * @property {Structure.ConditionsList|Structure.Rule}              parent     Lista de condiciones o regla en la que
 *                                                                             está contenida la lista
 *
 * @memberOf Structure
 */
class ConditionsList {
    /**
     * Constructor de la clase, instancia recursivamente las condiciones y agrupaciones de condiciones que contiene
     *
     * @param  {ConditionsListDefinition} definition Objeto de definición almacenado en la base de datos
     */
    constructor(definition) {
        this.definition = definition;

        this.conditions = _.map(definition.list, (childDefinition) => {
            if (_.isUndefined(childDefinition.list)) {
                return this.instantiateCondition(childDefinition);
            }

            return this.instantiateConditionsList(childDefinition);
        });

        // Lista de condiciones que contiene esta lista de manera recursiva
        this.parent = null;
    }

    /**
     * Enum: valores de agrupación de condiciones
     */
    static get Operator() {
        return Operator;
    }

    /**
     * Añade una condición a la lista a partir de su configuración
     *
     * @param  {ConditionDefinition} conditionDefinition Definición de la condición
     *
     * @return {Structure.Condition}                     La nueva condición instanciada
     */
    instantiateCondition(conditionDefinition = {}) {
        const condition = new Condition(conditionDefinition);
        condition.setList(this);

        return condition;
    }

    /**
     * Añade una lista de condiciones a la lista a partir de su configuración
     *
     * @param  {ConditionsListDefinition} listDefinition Definición de la lista
     *
     * @return {Structure.ConditionsList}                Objeto creado
     */
    instantiateConditionsList(listDefinition = {}) {
        const conditionsList = new ConditionsList(listDefinition);
        conditionsList.setParent(this);

        return conditionsList;
    }

    /**
     * Obtiene la definición original de las condiciones de la regla en formato de objeto editable
     *
     * @return {object} El objeto de definición de las condiciones
     */
    get() {
        return this.definition;
    }

    /**
     * Establece el elemento padre que incluye esta lista de condiciones, puede ser la regla u otra lista
     *
     * @param {Structure.Rule|Structure.ConditionsList} parent Objeto padre
     */
    setParent(parent) {
        this.parent = parent;
    }

    /**
     * Devuelve el elemento padre de esta lista
     *
     * @return {Structure.Rule|Structure.ConditionsList} parent Objeto padre
     */
    getParent() {
        return this.parent;
    }

    /**
     * Obtiene el objeto de regla que contiene esta lista de condiciones
     *
     * @return {Structure.Rule} La instancia de Rule
     */
    getRule() {
        if (this.parent instanceof ConditionsList) {
            return this.parent.getRule();
        }

        return this.parent;
    }

    /**
     * Obtiene los elementos hijos de la lista
     *
     * @return {array.<Structure.ConditionsList|Structure.Condition>} Lista de hijos
     */
    getConditions() {
        return this.conditions;
    }

    /**
     * Obtiene la referencia al objeto global de CRD
     *
     * @return {Structure.CRF} Objeto de gestión del CRD
     */
    getCRF() {
        // cuando el último parent sea la regla ya se obtiene el objeto
        return this.parent.getCRF();
    }

    /**
     * Obtiene el modo de agrupación de las condiciones de la lista
     *
     * @return {Operator} Identificador del modo de agrupación
     */
    getConnector() {
        return this.definition.operator;
    }

    /**
     * Obtiene los campos que se incluyen en alguna de las condiciones
     *
     * @return {Structure.Field[]} Lista de campos
     */
    getFields() {
        const fields = _.flatten(_.map(this.conditions, _.method('getFields')));

        return _.uniqBy(fields, _.method('getId'));
    }

    /**
     * Obtiene las listas que se incluyen en alguna de las condiciones
     *
     * @return {Structure.List[]} Lista de listas
     */
    getLists() {
        const lists = _.flatten(_.map(this.conditions, _.method('getLists')));

        return _.uniqBy(lists, _.method('getId'));
    }

    /**
     * Obtiene el objeto de exportación para usar en la aplicación de reglas
     *
     * @return {ExportedConditionList} Lista exportada
     */
    export() {
        return this.definition;
    }

    /**
     * Añade una condición a la lista. Si no se especifica el objeto de condición se crea una vacía, con configuración
     * por defecto
     *
     * @param {Structure.Condition} condition El objeto de condición
     * @param {Number}              position  La posición de inserción, si no se especifica va al final
     *
     * @return {Structure.Condition} La nueva condición instanciada
     */
    addCondition(condition = null, position = null) {
        if (!condition) {
            condition = this.instantiateCondition();
        }
        condition.setList(this);

        if (position === null) {
            position = this.conditions.length;
        }

        this.conditions.splice(position, 0, condition);
        this.definition.list.splice(position, 0, condition.get());

        return condition;
    }

    /**
     * Añade una lista de condiciones a la estructura de objetos
     *
     * @param {Structure.ConditionsList} list     La lista a añadir, si no existe se crea una por defecto
     * @param {Number}                   position La posición de inserción, si no se especifica va al final
     *
     * @return {Structure.ConditionsList}         La nueva lista instanciada
     */
    addList(list = null, position = null) {
        if (!list) {
            const defaultDefinition = {
                operator: Operator.ALL,
                list: [],
            };

            list = this.instantiateConditionsList(defaultDefinition);
        }

        if (position === null) {
            position = this.conditions.length;
        }

        this.conditions.splice(position, 0, list);
        this.definition.list.splice(position, 0, list.get());

        return list;
    }

    /**
     * Elimina una instancia de la estructura de condiciones
     *
     * @param  {Structure.Condition|Structure.ConditionsList} child El elemento a eliminar
     *
     * @return {boolean}                                            Si se ha eliminado
     */
    removeChild(child) {
        const index = _.findIndex(this.conditions, child);

        if (index > -1) {
            this.conditions.splice(index, 1);
            this.definition.list.splice(index, 1);

            return true;
        }

        return false;
    }

    /**
     * Determina si alguna de las condiciones de la lista incluye al elemento del CRF seleccionado
     *
     * @param  {Number}  elementId ID único del elemento
     *
     * @return {boolean}           Si alguna condición incluye al elemento
     */
    hasElement(elementId) {
        return _.some(this.conditions, _.method('hasElement', elementId));
    }

    /**
     * Sustituye una condición de la lista
     *
     * @param  {Structure.Condition} currentCondition La condición actual a sustituir
     * @param  {Structure.Condition} newCondition     La condición a poner en lugar de la actual
     *
     * @return {Structure.Condition}                  La condición que queda en la posición tras reemplazar
     */
    replaceCondition(currentCondition, newCondition) {
        const conditionsArray = this.getConditions();

        const conditionIndex = _.findIndex(conditionsArray, currentCondition);
        if (conditionIndex === -1) {
            return currentCondition;
        }

        conditionsArray.splice(conditionIndex, 1, newCondition);
        this.definition.list.splice(conditionIndex, 1, newCondition.get());

        return newCondition;
    }

    /**
     * Obtiene la ruta relativa dentro de la estructura de reglas teniendo en cuenta listas antecesoras
     *
     * @return {integer[]} Array de posiciones relativas
     *
     * @example
     * // returns null
     * conditionList.setParent(rulesInstance);
     * conditionList.getPath();
     *
     * @example
     * // returns [2]
     * list1.setParent(rulesInstance); // list1.getConditions().length === 2
     * list2.setParent(list1);
     * list2.getPath();
     */
    getPath() {
        if (this.parent instanceof ConditionsList) {
            const positionInParent = _.findIndex(this.parent.getConditions(), this);

            return (this.parent.getPath() || []).concat(positionInParent);
        }

        return null;
    }

    /**
     * Determina si en la lista de condiciones alguna incluye una comparación con un valor de aleatorización
     *
     * @return {boolean} Si alguna de las condiciones implica la aleatorización del registro
     */
    hasRandomization() {
        return _.some(this.getConditions(), _.method('hasRandomization'));
    }

    /**
     * Obtiene la lista de condiciones individuales (que no son listas anidadas) y las deja en un único nivel
     *
     * @return {Structure.Condition[]} Lista de condiciones
     */
    flattenConditions() {
        return _.reduce(this.conditions, (acc, child) => {
            return acc.concat(child instanceof ConditionsList ? child.flattenConditions() : child);
        }, []);
    }

    /**
     * Obtiene las condiciones de la regla en las que interviene alguno de los IDs de elementos seleccionados
     *
     * @param  {integer[]}             elementIds Lista de IDs a buscar
     *
     * @return {Structure.Condition[]}            Lista de condiciones individuales
     */
    getByElementIds(elementIds) {
        const conditions = this.flattenConditions();

        return _.filter(conditions, condition => {
            const commonIds = _(condition.getFields())
                .concat(condition.getLists())
                .map(_.method('getId'))
                .uniqBy()
                .intersection(elementIds)
                .value();

            return !_.isEmpty(commonIds);
        });
    }

    /**
     * Obtiene los identificadores de campos del registro que intervienen en las condiciones de esta lista
     *
     * @return {RecordFields[]} Lista de identificadores de campos
     */
    getRecordFields() {
        return _.reduce(this.flattenConditions(), (recordFields, condition) => {
            // Sólo aparece en el lado izquierdo de la condición
            const lhs = condition.getLhs();
            if (lhs && lhs.isRecordField()) {
                recordFields.push(lhs.getField());
            }

            return recordFields;
        }, []);
    }
}

module.exports = ConditionsList;
