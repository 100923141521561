'use strict';

const _ = require('lodash');

const ConfigurationModule = require('./Module');
const State = require('./State');

/**
 * Módulo de configuración: Gestión de estados
 *
 * @property {State[]} states Lista fija de estados configurables
 */
class StatesConfiguration extends ConfigurationModule {
    /**
     * Constructor de la clase, inicializa los estados configurables
     *
     * @param  {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super(definition);

        _.defaults(this.definition, {
            enabled: false,
            lock: {
                enabled: false,
                sign: false,
                form: {
                    enabled: false,
                    sign: false,
                },
            },
            states: [
                { id: 's1', enabled: false },
                { id: 's2', enabled: false },
                { id: 's3', enabled: false },
                { id: 's4', enabled: false },
                { id: 's5', enabled: false },
            ],
        });

        this._states = this.definition.states.map(state => State.instance(state));
    }

    /**
     * Obtiene la lista de estados de aprobación
     *
     * @return {State[]} Lista de objetos de gestión de estado
     *
     */
    getStates() {
        return this._states;
    }

    /**
     * Obtiene la definición de un estado a partir de su ID
     *
     * @param  {string} stateId ID del estado
     *
     * @return {State}          El estado encontrado
     */
    getState(stateId) {
        return this.getStates().find(state => state.getId() === stateId) || null;
    }

    /**
     * Determina si el estado solicitado se encuentra en la configuración
     *
     * @param  {string}  stateId ID del estado
     *
     * @return {boolean}         Si existe en la lista de estados
     */
    hasState(stateId) {
        return this.getState(stateId) !== null;
    }

    /**
     * Determina si tiene habilitado algún estado a nivel de cuaderno
     * Nota: en la resolución del módulo ya están filtrados los estados con isEnabled() a falso
     *
     * @return {boolean} Si alguno de los estados está habilitado
     */
    hasRecordStates() {
        return this.getStates().length > 0;
    }

    /**
     * Obtiene la lista de estados que están definidos también para la gestión de formularios
     *
     * @return {State[]} Lista de estados
     */
    getFormStates() {
        return this.getStates().filter(state => state.hasFormApproval());
    }

    /**
     * Determina si tiene habilitada la gestión de estados de formularios
     *
     * @return {boolean} Si tiene habilitada cualquiera de las opciones de estado de formularios
     */
    hasFormStates() {
        return this.getStates().some(state => state.hasFormApproval());
    }

    /**
     * Determina si la aplicación tiene definido soporte para bloquear registros
     * Dependiente de la opción superior de gestión de estados
     *
     * @return {boolean} Valor definido
     */
    hasRecordLock() {
        return this.isEnabled() && !!_.get(this.definition, 'lock.enabled');
    }

    /**
     * Determina si la aplicación tiene definida la necesidad de firmar el bloqueo de registros
     * Dependiente de la opción superior de bloqueo de registros
     *
     * @return {boolean} Valor definido
     */
    hasRecordSignOnLock() {
        return this.hasRecordLock() && !!_.get(this.definition, 'lock.sign');
    }

    /**
     * Determina si la aplicación tiene definido soporte para bloquear formularios
     * Dependiente de la opción superior de bloqueo de registros
     *
     * @return {boolean} Valor definido
     */
    hasFormLock() {
        return this.hasRecordLock() && !!_.get(this.definition, 'lock.form.enabled');
    }

    /**
     * Determina si la aplicación tiene definida la necesidad de firmar el bloqueo de formularios
     * Dependiente de la opción superior de bloqueo de formulario
     *
     * @return {boolean} Valor definido
     */
    hasFormSignOnLock() {
        return this.hasFormLock() && !!_.get(this.definition, 'lock.form.sign');
    }
}

module.exports = StatesConfiguration;
module.exports.State = State;
