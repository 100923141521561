'use strict';

const Expression = require('./Expression');

/**
 * Campos disponibles
 * @enum {string}
 * @readOnly
 */
const Fields = {
    RANDOMIZATION: 'randomization',
};

/**
 * Clase de gestión de una expresión que evalúa un campo del registro de datos
 *
 * @property {Fields} fieldName Identificador del campo
 *
 * @memberOf Criteria
 */
class RecordFieldExpression extends Expression {
    /**
     * Constructor de la clase: inicializa el nombre de la propiedad
     *
     * @param {Fields} fieldName Nombre del campo
     */
    constructor(fieldName) {
        super();

        this.fieldName = fieldName;
    }

    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @return {string} Tipo de expresión: "record"
     */
    getType() {
        return 'record';
    }

    /**
     * Constantes de nombre de campo
     */
    static get Fields() {
        return Fields;
    }

    /**
     * Genera una representación de la expresión apta para exportar o depurar
     *
     * @return {string} Información de los datos de la expresión en una cadena
     *
     * @example
     * // returns 'record:randomization'
     * const expr = new recordFieldExpression('randomization');
     * expr.toString();
     */
    toString() {
        return `record:${this.fieldName}`;
    }

    /**
     * Obtiene el nombre del campo que se quiere evaluar
     *
     * @return {Fields} El nombre de campo definido
     */
    getField() {
        return this.fieldName;
    }
}

module.exports = RecordFieldExpression;
