'use strict';

/**
 * Definición de la selección de perfiles
 *
 * @typedef {object} ProfileSelectorDefinition
 *
 * @property {Operation} operation Operación sobre la lista de perfiles: allow/deny
 * @property {integer[]} profiles  IDs de los perfiles afectados por la operación
 */

/**
 * Operaciones a ejecutar sobre la lista de perfiles
 *
 * @readonly
 *
 * @enum {string}
 */
const Operation = {
    ALLOW: 'allow',
    DENY: 'deny',
};

/**
 * Selector genérico de perfiles para gestionar propiedades de los controladores
 * Por ejemplo, el acceso y la edición de los datos
 *
 * @property {ProfileSelectorDefinition} definition Objeto de definición
 */
class ProfilesSelector {
    /**
     * Constructor de la clase: inyecta el objeto de definición
     *
     * @param  {ProfilesSelectorDefinition} definition Definición configurada
     */
    constructor(definition = {}) {
        this.definition = {
            operation: definition.operation || Operation.ALLOW,
            profiles: definition.profiles || [],
        };
    }

    /**
     * Determina si el perfil indicado configurado para permitir una acción
     *
     * @param  {Number}  profileId ID del perfil
     *
     * @return {boolean}           Si se permite según la configuración
     */
    allows(profileId) {
        profileId = parseInt(profileId, 10); // normalizar el valor si fuera un string

        const operation = this.getOperation();
        const profilesList = this.getProfiles();

        if (operation === Operation.ALLOW) {
            // Una lista vacía quiere decir "permitir todos"
            return profilesList.length === 0 || profilesList.includes(profileId);
        }

        if (this.definition.operation === Operation.DENY) {
            // Una lista vacía quiere decir "denegar todos"
            return profilesList.length > 0 && !profilesList.includes(profileId);
        }

        // Y si no es un valor conocido...
        return false;
    }

    /**
     * Determina si el perfil indicado configurado para denegar una acción
     *
     * @param  {Number}  profileId ID del perfil
     *
     * @return {boolean}           Si se deniega según la configuración
     */
    denies(profileId) {
        if (Object.values(Operation).includes(this.getOperation())) {
            return !this.allows(profileId);
        }

        return false;
    }

    /**
     * Obtiene el valor de la operación que se ejecuta sobre la lista de perfiles
     *
     * @return {Operation} El valor configurado
     */
    getOperation() {
        return this.definition.operation;
    }

    /**
     * Obtiene la lista de perfiles configurados
     *
     * @return {integer[]} Lista de IDs de perfil
     */
    getProfiles() {
        return this.definition.profiles || [];
    }
}

module.exports = ProfilesSelector;

module.exports.instance = (...args) => new ProfilesSelector(...args);
