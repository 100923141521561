'use strict';

const TYPE_REGULAR = 'regular';
const TYPE_FINAL = 'final';

/**
 * @class
 */
class RecordStatus {
    /**
     * Instance
     *
     * @param {object} definition Definition
     *
     * @return {RecordStatus}     Instance
     *
     * @static
     */
    static instance(definition = {}) {
        return new this(definition);
    }
    /**
     * Constructor
     *
     * @param {object} status            Definition
     * @param {number} status.id         Unique ID
     * @param {string} status.type       Type: (regular|final)
     * @param {string} status.label      Label
     * @param {object} status.conditions Conditions
     */
    constructor({ id, type = TYPE_REGULAR, label = '', conditions = {} }) {
        this.id = id;
        this.type = [TYPE_REGULAR, TYPE_FINAL].includes(type) ? type : TYPE_REGULAR;
        this.label = label;
        this.conditions = conditions;
    }
}

module.exports = RecordStatus;
