'use strict';

const GenericSide = require('./Side/Side');
const FieldSide = require('./Side/Field');
const ValueSide = require('./Side/Value');
const ListSide = require('./Side/List');
const RecordSide = require('./Side/Record');

/**
 * Constructor de lados de condición de regla
 */
class ConditionSideFactory {
    /**
     * Instancia el objeto de gestión del lado de la condición
     *
     * @param  {object}        definition      Configuración del lado
     * @param  {Condition}     parentCondition Condición que incluye este lado
     *
     * @return {ConditionSide}                 El lado creado
     */
    static create(definition, parentCondition) {
        const side = this._instantiateSide(definition);
        side.setCondition(parentCondition);

        return side;
    }

    /**
     * Instancia del objeto de gestión del lado de condición
     *
     * @param  {object}        definition Definición del lado
     *
     * @return {ConditionSide}            Instancia del lado
     */
    static _instantiateSide(definition) {
        switch (definition.type) {
            case 'field':
                return new FieldSide(definition);
            case 'value':
                return new ValueSide(definition);
            case 'list':
                return new ListSide(definition);
            case 'record':
                return new RecordSide(definition);
            case 'user':
                // GARU-4218 el tipo de condición "Dato de usuario" está obsoleto, pero aún pueden quedar referencias en
                // el editor. Se crea un objeto general de lado para tener los datos originales disponibles
                return new GenericSide(definition);
        }

        throw new Error(`Tipo de lado no reconocido: ${definition.type}`);
    }
}

module.exports = ConditionSideFactory;
