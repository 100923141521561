'use strict';

/**
 * Tipos de lado de condición
 * @enum {string}
 * @readOnly
 */
const SideTypes = {
    FIELD: 'field',
    LIST: 'list',
    RECORD: 'record',
    USER_FIELD: 'user',
    VALUE: 'value',
};

/**
 * Tipos de dato que controla la condición
 * Las colecciones (conjunto de respuestas, centros, aleatorización...) tienen un tipo independiente: no es lo mismo el
 * centro ID 1 que el conjunto ID 1
 * @enum {string}
 * @readOnly
 */
const DataTypes = {
    BOOLEAN: 'boolean',
    DATE: 'date',
    NUMBER: 'number',
    OPTIONS_GROUP: 'options-group',
    STRING: 'string',
    ARRAY: 'array',
    RECORD_RANDOMIZATION: 'record-randomization',
    RECORD_SITE: 'record-site',
    RECORD_VERSION: 'record-version',
};

/**
 * Clase de gestión de un lado de condición de reglas
 */
class ConditionSide {
    /**
     * Constantes de tipo de dato
     */
    static get DataTypes() {
        return DataTypes;
    }

    /**
     * Constantes de tipo de lado
     */
    static get SideTypes() {
        return SideTypes;
    }

    constructor(definition) {
        this.definition = definition;

        // Condición padre que contiene el lado
        this.condition = null;
    }

    setCondition(condition) {
        this.condition = condition;
    }

    /**
     * Obtiene el objeto de condición que incluye esta expresión
     *
     * @return {Condition} Definición de la condición
     */
    getCondition() {
        return this.condition;
    }

    /**
     * Obtiene la definición original de la expresión en forma de objeto editable
     *
     * @return {object} El objeto de definición
     */
    get() {
        return this.definition;
    }

    getType() {
        return this.definition.type;
    }

    /**
     * ¿Es una referencia a un campo del CRF?
     *
     * @return {boolean} Si el atributo de tipo corresponde
     */
    isField() {
        return this.definition.type === SideTypes.FIELD;
    }

    /**
     * ¿Es un valor constante?
     *
     * @return {boolean} Si el atributo de tipo corresponde
     */
    isConstantValue() {
        return this.definition.type === SideTypes.VALUE;
    }

    /**
     * ¿Es una referencia a una lista del CRF?
     *
     * @return {boolean} Si el atributo de tipo corresponde
     */
    isList() {
        return this.definition.type === SideTypes.LIST;
    }

    /**
     * ¿Es un dato del registro que no se encuentre en la estructura de campos?
     *
     * @return {boolean} Si el atributo de tipo corresponde
     */
    isRecordField() {
        return this.definition.type === SideTypes.RECORD;
    }

    getCRF() {
        return this.condition.getCRF();
    }

    /**
     * Obtiene el objeto de regla que contiene esta expresión
     *
     * @return {Structure.Rule} La instancia de Rule
     */
    getRule() {
        return this.condition.getRule();
    }

    /**
     * Obtiene el tipo de dato que controla este lado de la condición
     *
     * @return {DataTypes} Identificador del tipo
     */
    getDataType() {
        return null;
    }

    /**
     * Determina si el lado de la condición admite modificadores de valor
     *
     * @return {boolean} Por defecto no los admite, se debe sobrecargar el método para devolver otro resultado
     */
    canModifyValue() {
        return false;
    }
}

module.exports = ConditionSide;
