'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedHideChildren
 *
 * @property {string} func Nombre de la función: hideChildren
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - el ID del campo
 */

/**
 * Clase de gestión de una regla de tipo Ocultar hijos
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class HideChildren extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.HIDE_CHILDREN;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        let action = super.export();
        action.func = 'hideChildren';

        return action;
    }
}

module.exports = HideChildren;
