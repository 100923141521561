'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: Notificaciones
 */
class NotificationsConfiguration extends ConfigurationModule {}

module.exports = NotificationsConfiguration;
