'use strict';

const _ = require('lodash');
const moment = require('moment');

/**
 * Suma un valor a un dato numérico o de fecha (instancia de Date)
 *
 * @param {number|Date} value  Valor al que sumar
 * @param {Number}      amount Magnitud a sumar, si el dato es una fecha es el número de días
 *
 * @return {mixed} El valor modificado, o si no es de un tipo válido se devuelve el valor original
 *
 * @example
 * // returns 25
 * operator.add(15, 10);
 *
 * @example
 * // returns 'number'
 * operator.add('number', 10);
 */
function add(value, amount) {
    if (_.isFinite(value)) {
        return value + amount;
    }

    if (_.isDate(value)) {
        return moment(value).add(amount, 'days').toDate();
    }

    return value;
}

/**
 * Resta un valor a un dato numérico o de fecha (instancia de Date)
 *
 * @param {number|Date} value  Valor al que restar
 * @param {Number}      amount Magnitud a restar, si el dato es una fecha es el número de días
 *
 * @return {mixed} El valor modificado, o si no es de un tipo válido se devuelve el valor original
 *
 * @example
 * // returns 5
 * operator.sub(15, 10);
 *
 * @example
 * // returns 'number'
 * operator.sub('number', 10);
 */
function sub(value, amount) {
    if (_.isFinite(value)) {
        return value - amount;
    }

    if (_.isDate(value)) {
        return moment(value).subtract(amount, 'days').toDate();
    }

    return value;
}

/**
 * Suma un número de días a la fecha especificada
 *
 * @param {string|Date} value      Valor de fecha, o representación de la fecha
 * @param {Number}      amount     Número de días a sumar
 * @param {string}      dateFormat Formato de entrada y salida de la nueva fecha, si la entrada es una cadena
 *
 * @return {mixed} La nueva fecha, la representación como cadena o bien el valor original si no se puede transformar
 *
 * @example
 * // returns '2006-09-12'
 * operator.addDays('2006-09-07', 5)
 *
 * @example
 * // returns '12/09/2006'
 * operator.addDays('07/09/2006', 5, 'DD/MM/YYYY')
 *
 * @example
 * // returns 43'
 * operator.addDays(43, 5)
 */
function addDays(value, amount, dateFormat = 'YYYY-MM-DD') {
    if (_.isDate(value)) {
        return moment(value).add(amount, 'days').toDate();
    }

    const momentObject = moment(value, dateFormat);
    if (!momentObject.isValid()) {
        return value;
    }

    return momentObject.add(amount, 'days').format(dateFormat);
}

/**
 * Resta un número de días a la fecha especificada
 *
 * @param {string|Date} value      Valor de fecha, o representación de la fecha
 * @param {Number}      amount     Número de días a restar
 * @param {string}      dateFormat Formato de entrada y salida de la nueva fecha, si la entrada es una cadena
 *
 * @return {mixed} La nueva fecha, la representación como cadena o bien el valor original si no se puede transformar
 *
 * @example
 * // returns '2006-09-02'
 * operator.subtractDays('2006-09-07', 5)
 *
 * @example
 * // returns '02/09/2006'
 * operator.subtractDays('07/09/2006', 5, 'DD/MM/YYYY')
 *
 * @example
 * // returns 43'
 * operator.subtractDays(43, 5)
 */
function subtractDays(value, amount, dateFormat = 'YYYY-MM-DD') {
    if (_.isDate(value)) {
        return moment(value).subtract(amount, 'days').toDate();
    }

    const momentObject = moment(value, dateFormat);
    if (!momentObject.isValid()) {
        return value;
    }

    return momentObject.subtract(amount, 'days').format(dateFormat);
}

module.exports = {
    add: add,
    sub: sub,
    addDays: addDays,
    subtractDays: subtractDays,
};
