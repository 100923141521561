'use strict';

const _ = require('lodash');

const CrfElementConditionSide = require('./CrfElement');

/**
 * Tipos de acción sobre la lista
 * @enum {string}
 * @readOnly
 */
const Actions = {
    FUNCTION: 'function',
    INDEX: 'index',
};

/**
 * Tipos de función sobre la lista
 * @enum {string}
 * @readOnly
 */
const Functions = {
    COUNT: 'count',
};

/**
 * Tipo de expresión: Operación sobre una lista del CRF
 *
 * @property {Structure.List} target Objeto de lista de destino
 *
 * @extends Structure.CrfElementSide
 */
class ListConditionSide extends CrfElementConditionSide {
    /**
     * Constantes de tipo de acción
     */
    static get Actions() {
        return Actions;
    }

    /**
     * Constantes de tipo de función
     */
    static get Functions() {
        return Functions;
    }

    /**
     * Obtiene el identificador de la acción que se ejecuta sobre la lista
     *
     * @return {Actions} Texto identificativo de la acción
     */
    getAction() {
        return _.get(this.definition, 'action', null);
    }

    /**
     * Obtiene el identificador de la función que se ejecuta sobre la lista
     *
     * @return {Functions} Texto identificativo de la función
     */
    getFunction() {
        return _.get(this.definition, 'function', null);
    }

    /**
     * @inheritDoc
     *
     * @throws {Error} Si alguno de los identificadores no se reconoce. Se trata más bien de una medida de control para
     *                 desarrollo, para actualizar este método si se añaden tipos de acción o de función
     */
    getDataType() {
        const list = this.getTarget();
        if (!list) {
            return null;
        }

        const action = this.getAction();
        if (!action) {
            return null;
        }

        if (_.values(Actions).indexOf(action) === -1) {
            throw new Error(`Tipo de acción sobre lista desconocida: "${action}"`);
        }

        if (action === Actions.INDEX) {
            return ListConditionSide.DataTypes.NUMBER;
        }

        // action === Actions.FUNCTION
        const listFunction = this.getFunction();
        if (!listFunction) {
            return null;
        }

        if (_.values(Functions).indexOf(listFunction) === -1) {
            throw new Error(`Tipo de función sobre lista desconocida: "${listFunction}"`);
        }

        if (listFunction === Functions.COUNT) {
            return ListConditionSide.DataTypes.NUMBER;
        }

        throw new Error(`Tipo de dato para función no especificado: "${listFunction}"`);
    }

    /**
     * Determina si el lado de la condición admite modificadores de valor
     *
     * @return {boolean} Verdadero si el destinatario definido es una lista válida
     *
     * @override
     */
    canModifyValue() {
        const target = this.getTarget();

        // Esto es así porque sabemos que todas las operaciones posibles con listas actualmente devuelven un número
        return target !== null && target.isList();
    }
}

module.exports = ListConditionSide;
