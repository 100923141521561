'use strict';

const _ = require('lodash');

const FieldsContainer = require('./FieldsContainer');

/**
 * @typedef {object} FormDefinition
 */

/**
 * Clase de gestión de un formulario del CRF
 * @extends FieldsContainer
 * @property {FormDefinition} definition Definición específica del formulario
 * @memberOf Structure
 */
class Form extends FieldsContainer {
    /**
     * Constructor a partir de la definición del formulario
     *
     * @param  {FormDefinition} definition Objeto de definición
     */
    constructor(definition) {
        super(definition);
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['epro', 'eproReminders']);
    }

    /**
     * Determina si el objeto es de tipo formulario
     * @return {boolean} Verdadero siempre
     */
    isForm() {
        return true;
    }

    /**
     * Is ePRO
     *
     * @return {Boolean} Indica si es un formulario de ePRO
     */
    isEpro() {
        return !!this.definition.epro;
    }

    /**
     * Obtiene la lista de campos que pertenecen al formulario
     * @return {Field[]} Lista completa de campos, incluyendo subvariables
     */
    getFields() {
        return this.getDescendants();
    }

    /**
     * Obtiene la lista de valores por defecto de los campos que componen el formulario
     * @return {object.<string, mixed>} Mapa indexado por ID de campo con el valor por defecto de cada uno de ellos
     * @example
     * // returns {'3#1': 15, '3#2': undefined, '3#3': 'Sí'}
     * let form = new Form();
     * form.setChildren([
     *     new Field({id: '3#1', default: 15}),
     *     new Field({id: '3#2'}),
     *     new Field({id: '3#3', default: 'Sí'}),
     * ]);
     * form.getDefaultValues();
     */
    getDefaultValues() {
        let fields = this.getFields();

        return _.fromPairs(fields.map(function getFieldData(field) {
            return [field.getId(), field.getDefaultValue()];
        }));
    }
    /**
     * Devuelve los campos que son de obligado cumplimiento en este formulario
     *
     * @return {Array<Structure.Field>}  Lista de campos obligatorios
     */
    getRequiredFields() {
        return _.filter(this.getFields(), _.method('isRequired'));
    }
    /**
     * Determina si alguno de los campos que componen el formulario es requerido
     *
     * @return {boolean} Si alguno de los campos es requerido
     */
    hasRequiredFields() {
        return _.some(this.getFields(), _.method('isRequired'));
    }

    /**
     * Obtiene los objetos de definición de recordatorio
     *
     * @returns {Object[]} Definición de recordatorios ePRO
     */
    getEproReminders() {
        return this.isEpro() ? this.definition.eproReminders : [];
    }
}

module.exports = Form;
