'use strict';

const { has, setWith, get } = require('lodash');
const debug = require('debug')('sharecrf:core');

const MetadataItem = require('./Metadata/Item');
const EventEmitter = require('../EventEmitter');

/**
 * Información sobre el metadato en el objeto, como su valor y el momento en el que se establece.
 *
 *  Si está activada la firma, además del valor guardará información sobre el autor.
 *
 * @typedef {object} MetadataFlag
 *
 * @property {mixed}          value    El valor del metadato
 * @property {Date}           date     Momento en el que se ha modificado (si procede)
 * @property {MetadataAuthor} [author] Información del usuario que lo ha modificado (si procede)
 */

/**
 * Información sobre el autor de un cambio en un metadato (el firmante)
 *
 * @typedef {object} MetadataAuthor
 *
 * @property {Number}  id     ID del usuario en BD
 * @property {string}  email  Email del usuario tal y como estaba en el momento de la acción
 */

/**
 * Objeto de flags de un elemento
 *
 * @typedef {object} ElementMetadata
 *
 * @property {MetadataFlag} [locked] Información de bloqueo de formularios
 * @property {MetadataFlag} [s1]     Información del primer estado configurable
 * @property {MetadataFlag} [s2]     Información del segundo estado configurable
 * @property {MetadataFlag} [s3]     Información del tercer estado configurable
 * @property {MetadataFlag} [s4]     Información del cuarto estado configurable
 * @property {MetadataFlag} [s5]     Información del quinto estado configurable
 */

/**
 * Objeto de gestión de los metadatos del registro/paciente
 *
 * El objeto interno de metadatos está indexado por el UID de cada campo del registro/paciente como string,
 * separado por puntos
 *
 * @memberOf Record
 */
class RecordMetadata {
    /**
     * @param {Configuration} config Configuración de la información que lleva el metadata
     * @param {Record.Data}   record Instancia de Record asociada
     */
    constructor(config, record) {
        this.states = config.getStates();
        this.record = record;

        // Lista de flags disponibles dependientes de la configuración
        this._availableGlobalFlags = [];
        this._availableFormFlags = [];

        if (this.states.isEnabled()) { // GARU-4345 Solo si el módulo está activado
            if (this.states.hasRecordLock()) {
                this._availableGlobalFlags.push('locked');
            }

            if (this.states.hasFormLock()) {
                this._availableFormFlags.push('locked');
            }

            this.states.getStates().forEach(state => {
                const stateId = state.getId();

                this._availableGlobalFlags.push(stateId);

                if (state.hasFormApproval()) {
                    this._availableFormFlags.push(stateId);
                }
            });
        }

        // Objeto interno de metadatos. Se va a encargar solamente de los metadatos de elementos del CRD
        this.metadata = {};

        // Para tratar los metadatos globales usaremos otro objeto equivalente
        this._globalMetadata = {};

        this.events = EventEmitter.instance();
    }

    /**
     * Resetea la información de datos en este registro, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     *
     * @param {object} obj Objeto de evaluación. Se puede llamar recursivamente a reset() partiendo desde la raíz y
     *                     recorriendo los metadatos de las listas que pueda haber
     */
    reset(obj = null) {
        const crf = this.record.getCRF();

        // Si no se especifica un subobjeto se entiende que es el objeto de metadatos completo, incluyendo los globales
        const isGlobal = obj === null;
        if (isGlobal) {
            obj = this.metadata;
        }

        for (const elementId in obj) {
            const item = obj[elementId];

            if (crf.hasList(elementId)) {
                // Si el ID corresponde a una lista, se debe recorrer para limpiar los datos internos
                for (const key in item) {
                    // De manera general, si la clave es numérica se considera un ID de elemento
                    if (/^\d+$/.test(key)) {
                        this.reset(item[key]);
                    } else {
                        delete item[key];
                    }
                }
                // Y no se le prepara un objeto de metadatos ya que no lo necesita
            } else {
                for (const key in item) {
                    delete item[key];
                }
            }
        }

        if (isGlobal) {
            for (const key in this._globalMetadata) {
                delete this._globalMetadata[key];
            }
        }
    }

    /**
     * Carga los metadatos de registro en BD (solo la parte de metadata)
     *
     * @param  {object}  jsonMetadata Metadatos JSON obtenidos de la fila de BD (columna metadata)
     * @param  {boolean} flatten      TRUE para hacer un flatten de los datos que se van a cargar. Es el caso por
     *                                ejemplo cuando vienen de la API pública, donde conservan la estructura de
     *                                secciones, forms, etc. del CRF
     *
     * @return {boolean}              TRUE si se cargó todo con éxito
     */
    load(jsonMetadata = {}, flatten = false) {
        if (flatten) {
            debug('Flatten metadata on load %O', jsonMetadata);
            jsonMetadata = this.flatten(jsonMetadata);
            debug('Flattened metadata %O', jsonMetadata);
        }

        this.metadata = JSON.parse(JSON.stringify(jsonMetadata));

        // metadatos globales van a otro objeto
        if (jsonMetadata.global !== undefined) {
            this._globalMetadata = jsonMetadata.global;
            delete this.metadata.global;
        }

        this.events.emit('load', this.metadata, this._globalMetadata);

        return true;
    }

    /**
     * Devuelve el objeto de metadatos
     *
     * @return {object} Metadatos
     */
    getMetadata() {
        return this.metadata;
    }

    /**
     * Obtiene la referencia al objeto de CRF interno
     *
     * @return {Structure.CRF} El objeto de CRF
     */
    getCRF() {
        return this.record.getCRF();
    }

    /**
     * Obtiene la configuración de estados de aprobación
     *
     * @return {StatesConfiguration} La configuración de estados
     */
    getStatesConfiguration() {
        return this.states;
    }

    /**
     * Exporta una copia de los metadatos actuales
     *
     * @return {object} Copia de los metadatos
     */
    export() {
        const result = JSON.parse(JSON.stringify(this.metadata));
        // Se incluyen los metadatos globales en el mismo objeto exportado bajo la clave 'global'
        result.global = JSON.parse(JSON.stringify(this._globalMetadata));

        return result;
    }

    /**
     * Método auxiliar para obtener un metadato
     *
     * @param  {integer[]}       elementUId UID del elemento en el CRF, NULL si queremos el raíz del registro/paciente
     * @param  {string}          flagName   Nombre del flag a consultar, NULL para todos
     *
     * @return {ElementMetadata}            Información de metadatos
     *
     * @private
     */
    _getMetadata(elementUId, flagName) {
        if (!elementUId) { // TODO: STATES ¿y si quiero un metadata específico global?
            return this._globalMetadata;
        }

        if (!has(this.metadata, elementUId)) {
            setWith(this.metadata, elementUId, {}, Object);
        }
        const formMetadata = get(this.metadata, elementUId);

        if (flagName) {
            // TODO: STATES Probar cuando no exista formMetadata[flagName]
            // Devuelve el metadato concreto correspondiente a flagName
            return formMetadata[flagName];
        }

        // Devuelve el metadato completo del formulario, no se ha especificado un flagName concreto
        return formMetadata;
    }

    /**
     * Obtiene el objeto de información de un metadato del formulario identificado por la clave.
     * El resultado de la función es una referencia al objeto en la estructura de los metadatos.
     *
     * @param  {Number}          formId      ID del formulario en el CRF
     * @param  {ListIndices}     listIndices Información de items dentro de listas
     * @param  {string}          metadataKey La clave del metadato a buscar
     *
     * @return {ElementMetadata}             Información sobre el metadato
     *
     * @private
     */
    _getFormMetadata(formId, listIndices, metadataKey) {
        const formUid = this.record.getElementUID(formId, listIndices);

        return formUid ? this._getMetadata(formUid, metadataKey) : null;
    }

    /**
     * Devuelve los datos de un estado del formulario especificado
     *
     * @param  {string}       stateId     Identificador del estado
     * @param  {Number}       formId      ID del formulario en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {MetadataFlag}             Información sobre el estado del formulario
     */
    getFormState(stateId, formId, listIndices) {
        return this._getFormMetadata(formId, listIndices, stateId);
    }

    /**
     * Obtiene información sobre todos los estados asociados a un formulario concreto
     *
     * @param  {Number}      formId      ID del formulario en el CRF
     * @param  {ListIndices} listIndices Información de ítemes dentro de listas
     *
     * @return {object}                  Información de estados indexados por clave
     */
    getFormStates(formId, listIndices) {
        const formMetadata = this._getFormMetadata(formId, listIndices);
        const formStates = {};

        for (const key in formMetadata) {
            if (key !== 'locked' && this._availableFormFlags.includes(key)) {
                formStates[key] = formMetadata[key];
            }
        }

        return formStates;
    }

    /**
     * Obtiene la información de bloqueo de un formulario
     *
     * @param  {Number}       formId      ID del formulario en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {MetadataFlag}             Información de bloqueo
     */
    getFormLock(formId, listIndices) {
        return this._getFormMetadata(formId, listIndices, 'locked');
    }

    /**
     * Obtiene el valor de un flag a nivel de registro/paciente (global)
     *
     * @param  {string}       flagName Nombre del flag a obtener (locked, s1-s5)
     *
     * @return {MetadataFlag}          Metadatos globales
     */
    _getGlobalFlag(flagName) {
        if (this._globalMetadata[flagName] === undefined) {
            return null;
        }

        return this._globalMetadata[flagName];
    }

    /**
     * Determina si el registro tiene habilitado el estado solicitado
     *
     * @param  {string}  stateId Identificador del estado a comprobar (locked, s1-s5)
     *
     * @return {boolean}         Si está con valor true en el objeto de metadatos globales
     */
    hasRecordState(stateId) {
        if (this._availableGlobalFlags.indexOf(stateId) === -1) {
            return false;
        }

        const recordMetadata = this._getMetadata();

        return !!recordMetadata[stateId] && !!recordMetadata[stateId].value;
    }

    /**
     * Establece información sobre un flag de formulario
     *
     * @param {integer[]}    elementUId UID del formulario
     * @param {string}       flagName   Nombre del flag
     * @param {MetadataItem} flagItem   Objeto de información
     *
     * @private
     */
    _setFlag(elementUId, flagName, flagItem) {
        if (!(flagItem instanceof MetadataItem)) {
            throw new Error('flagItem must be an instance of MetadataItem');
        }

        const metadataInfo = this._getMetadata(elementUId);

        metadataInfo[flagName] = flagItem.get();
    }

    /**
     * Establece información sobre un flag global
     *
     * @param {string}       flagName Nombre del flag
     * @param {MetadataItem} flagItem Objeto de información
     */
    setRecordFlag(flagName, flagItem) {
        if (this._availableGlobalFlags.indexOf(flagName) === -1) {
            throw new Error(`Invalid flag name "${flagName}"`);
        }

        this._setFlag(null, flagName, flagItem);
    }

    /**
     * Establece información sobre un flag de formulario
     *
     * @param {integer[]}    formUid  UID del formulario
     * @param {string}       flagName Nombre del flag
     * @param {MetadataItem} flagItem Objeto de información
     */
    setFormFlag(formUid, flagName, flagItem) {
        if (this._availableFormFlags.indexOf(flagName) === -1) {
            throw new Error(`Invalid flag name "${flagName}"`);
        }

        this._setFlag(formUid, flagName, flagItem);
    }

    /**
     * Devuelve los datos de un estado global del registro
     *
     * @param  {string}       stateId Identificador del estado
     *
     * @return {MetadataFlag}         Estado de bloqueo
     */
    getGlobalState(stateId) {
        return this._getGlobalFlag(stateId);
    }

    /**
     * Obtiene información sobre los estados actuales globales del registro
     *
     * @return {object} Lista de metadatos indexados por la clave del estado
     */
    getGlobalStates() {
        const recordMetadata = this._getMetadata();
        const globalStates = {};

        for (const key in recordMetadata) {
            if (key !== 'locked' && this._availableGlobalFlags.includes(key)) {
                globalStates[key] = recordMetadata[key];
            }
        }

        return globalStates;
    }

    /**
     * Devuelve el estado global de bloqueado del registro
     *
     * @return {MetadataFlag} Estado de bloqueo
     */
    getGlobalLock() {
        return this._getGlobalFlag('locked');
    }

    /**
     * Determina si el registro está bloqueado de forma global
     *
     * @return {boolean} TRUE si el registro está bloqueado
     */
    isRecordLocked() {
        if (!this.states.hasRecordLock()) {
            return false;
        }

        const lock = this.getGlobalLock();
        if (lock) {
            return !!lock.value;
        }

        return false;
    }

    /**
     * Determina si el formulario del CRF especificado está bloqueado
     *
     * @param  {Number}      formId      ID del formulario en la estructura
     * @param  {ListIndices} listIndices Índices de las listas que contienen al formulario
     *
     * @return {boolean}                 TRUE si el formulario está bloqueado
     */
    isFormLocked(formId, listIndices) {
        if (!this.states.hasFormLock()) {
            return false;
        }

        const lock = this.getFormLock(formId, listIndices);

        return lock ? !!lock.value : false;
    }

    /**
     * Determina si el formulario indicado tiene habilitado el estado solicitado
     *
     * @param  {string}      stateId     Identificador del estado a comprobar
     * @param  {Number}      formId      ID del formulario en la estructura
     * @param  {ListIndices} listIndices Índices de las listas que contienen al formulario
     *
     * @return {boolean}                 Si está con valor true en el objeto de metadatos del formulario
     */
    hasFormState(stateId, formId, listIndices) {
        if (this._availableFormFlags.indexOf(stateId) === -1) {
            return false;
        }

        const formState = this.getFormState(stateId, formId, listIndices);

        return formState ? !!formState.value : false;
    }
}

module.exports = RecordMetadata;

module.exports.Item = MetadataItem;
