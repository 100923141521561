'use strict';

const _ = require('lodash');

const ExpressionFactory = require('./ExpressionFactory');

const Types = require('./ConditionTypes');

/**
 * Configuración de definición de la condición, con un formato conocido
 * @typedef {object} ConditionConfig
 * @property {Types}  comparison Tipo de operación
 * @property {object} lhs        Operando izquierdo
 * @property {object} rhs        Operando derecho
 */

/**
 * Clase de definición de una condición individual como parte de un Criteria general
 *
 * @property {string}                   type Tipo de operación que relaciona las expresiones contenidas
 * @property {Criteria.Expression}      lhs Lado izquierdo de la condición
 * @property {Criteria.Expression|null} lhs Lado derecho de la condición, si procede según el tipo de operación
 *
 * @memberOf Criteria
 */
class Condition {
    /**
     * Constructor de la clase a partir del tipo de condición
     *
     * @param  {string} type     Tipo de condición a instanciar
     * @param {Boolean} [active] Indica que contiene campos inactivos
     */
    constructor(type, active = true) {
        this.setType(type);
        this.lhs = null;
        this.rhs = null;
        this.active = active;
    }

    /**
     * Constructor estático a partir de un objeto de configuración
     *
     * @param  {ConditionConfig} config Objeto de definición de la condición
     *
     * @return {Criteria.Condition} La condición creada
     */
    static createFromConfig(config) {
        let condition;
        if (config.inactive) {
            condition = new Condition(Types.ALWAYS_FALSE, false);
        }
        else {
            condition = new Condition(config.comparison);

            condition.setLhs(ExpressionFactory.createFromConfig(config.lhs));
            if (!_.isUndefined(config.rhs)) {
                condition.setRhs(ExpressionFactory.createFromConfig(config.rhs));
            }
        }

        return condition;
    }

    /**
     * Representa el objeto en un formato apto para exportar o depurar
     *
     * @return {string} Representación donde se indica la operación y la representación de los operandos
     *
     * @example
     * // returns 'condition:neq[field:233,value:1]';
     * const condition = new Condition({
     *     lhs: { type: 'field', id: 233 },
     *     rhs: { type: 'value', value: 1 },
     *     comparison: 'neq',
     * });
     * condition.toString();
     */
    toString() {
        return this.active
            ? `condition:${this.type}[${_.compact([this.lhs, this.rhs].map(_.method('toString')))}]`
            : 'condition:false';
    }

    /**
     * Establece el tipo de operación (añade la propiedad "type" al objeto)
     *
     * @param {string} type Identificador del tipo de operación
     *
     * @return {Criteria.Condition} La propia condición
     *
     * @throws {Error} si no se reconoce el identificador
     */
    setType(type) {
        if (!type) {
            throw new Error('El tipo de condición no puede estar vacío');
        }
        const knownTypes = _.values(Types);

        const key = _.toLower(type);
        if (knownTypes.indexOf(key) === -1) {
            throw new Error(`Tipo de condición no conocido: ${key}`);
        }

        this.type = key;

        return this;
    }

    /**
     * Instancia la parte izquierda de la expresión a partir del objeto de definición
     *
     * @param {object} lhs Definición del operando
     *
     * @return {Criteria.Condition} La propia condición
     */
    setLhs(lhs) {
        this.lhs = lhs;

        return this;
    }

    /**
     * Instancia la parte derecha de la expresión a partir del objeto de definición. En caso de no existir la definición
     * el valor queda como nulo
     *
     * @param {object|undefined} rhs Definición del operando, si existe
     *
     * @return {Criteria.Condition} La propia condición
     */
    setRhs(rhs) {
        this.rhs = rhs;

        return this;
    }

    /**
     * Obtiene el operando izquierdo
     *
     * @return {Criteria.Expression} Objeto de expresión de la condición
     */
    getLhs() {
        return this.lhs;
    }

    /**
     * Obtiene el operando derecho
     *
     * @return {Criteria.Expression|null} Objeto de expresión de la condición, o nulo si no procede
     */
    getRhs() {
        return this.rhs;
    }

    /**
     * Obtiene el tipo definido de operación
     *
     * @return {Types} Identificador del tipo de operación
     */
    getType() {
        return this.type;
    }

    /**
     * Obtiene una referencia al constructor de expresiones
     *
     * @return {Criteria.ExpressionFactory} El objeto generador de expresiones
     */
    expr() {
        return ExpressionFactory;
    }

    /**
     * Determina si alguna de las expresiones que componen la condición es de tipo Valor de campo para el campo
     * especificado
     *
     * @param  {Number}  fieldId ID único del campo en el CRD
     *
     * @return {boolean}         Si alguna de las expresiones es de valor del campo
     */
    hasField(fieldId) {
        return Condition.sideHasField(this.getLhs(), fieldId) || Condition.sideHasField(this.getRhs(), fieldId);
    }

    /**
     * Side has field
     *
     * @param {Criteria.Expression} side Condition side
     * @param {string} fieldId Field ID
     *
     * @returns {boolean} If the side has the field
     *
     * @protected
     */
    static sideHasField(side, fieldId) {
        return !!(side && side.getType() === 'field' && side.getFieldId() === fieldId);
    }

    /**
     * Determina si alguna de las expresiones que componen la condición afecta a una lista del CRD
     * especificada
     *
     * @param  {Number}  listId ID único de la lista en el CRD
     *
     * @return {boolean}        Si alguna de las expresiones afecta a la lista
     */
    hasList(listId) {
        return _.some(_.compact([this.getLhs(), this.getRhs()]), side => {
            return _.isFunction(side.getListId) && side.getListId() === listId;
        });
    }
}

module.exports = Condition;
