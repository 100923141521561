'use strict';

const ConfigurationModule = require('./Module');
const RecordStatus = require('./RecordStatus');

/**
 * Módulo de configuración: variables encriptadas
 */
class RecordStatusModule extends ConfigurationModule {
    /**
     * Constructor de la clase, inicializa los estados del paciente
     *
     * @param  {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super(definition);

        this.statusList = (this.definition.statuses || []).map(statusDefinition => RecordStatus.instance(statusDefinition));
    }

    /**
     * Get status list length
     *
     * @return {number} Number of status
     */
    countStatus() {
        return this.statusList.length;
    }

    /**
     * Get status by index
     *
     * @param {number} index  Index
     *
     * @return {RecordStatus} Status
     */
    getStatus(index) {
        return this.statusList.length > index ? this.statusList[index] : null;
    }

    /**
     * Find status
     *
     * @param {number} statusId ID
     *
     * @return {RecordStatus}   Status
     */
    findStatus(statusId) {
        return this.statusList.find(candidate => candidate.id === statusId) || null;
    }
}

module.exports = RecordStatusModule;
