'use strict';

const SectionsContainer = require('./SectionsContainer');

/**
 * Clase de gestión de una sección del CRF
 * @extends SectionsContainer
 * @memberOf Structure
 */
class Section extends SectionsContainer {
    /**
     * Determina si el objeto es de tipo sección
     * @return {boolean} Verdadero siempre
     */
    isSection() {
        return true;
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['scheduledVisit']);
    }

    /**
     * Obtiene la configuración de la visita programada de la sección
     *
     * @returns {Object} Objeto de definición de visita programada
     */
    getScheduledVisit() {
        return typeof this.definition.scheduledVisit !== 'undefined' && !!this.definition.scheduledVisit.enabled
            ? this.definition.scheduledVisit
            : null;
    }

    /**
     * Obtiene la configuración de notificaciones asociadas a la visita programada de la sección
     *
     * @returns {Object[]} Objetos de definición de notificación
     */
    getVisitNotifications() {
        const scheduledVisit = this.getScheduledVisit();

        return scheduledVisit !== null && Array.isArray(scheduledVisit.notifications)
            ? scheduledVisit.notifications.filter(notification => !!notification.enabled)
            : [];
    }

    /**
     *
     * @param {number} id Notification ID
     *
     * @returns {Object} Notification definition
     */
    getVisitNotification(id) {
        return this.getVisitNotifications().find(notification => notification.id === id) || null;
    }
}

module.exports = Section;
