'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: Grupos de centros
 */
class GroupsConfiguration extends ConfigurationModule {

}

module.exports = GroupsConfiguration;
