'use strict';

const _ = require('lodash');
const moment = require('moment');

const { UNKNOWN_VALUE, MISSING_VALUE } = require('../../Record/constants');

/**
 * Compara dos valores en una expresión, para determinar si son iguales
 *
 * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
 * @param  {mixed} valueRight      Valor a la derecha de la comparación
 *
 * @return {boolean}               Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function equal(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    if (_.isArray(valueLeft) && _.isArray(valueRight)) {
        const arrayLeft = Array.from(valueLeft);
        const arrayRight = Array.from(valueRight);

        arrayLeft.sort();
        arrayRight.sort();

        return _.isEqual(arrayLeft, arrayRight);
    }

    return _.isEqual(valueLeft, valueRight);
}
/**
 * Compara dos fechas en una expresión, para determinar si son iguales
 *
 * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
 * @param  {mixed} valueRight      Valor a la derecha de la comparación
 *
 * @return {boolean}               Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function dateEqual(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight) || isPartialDate(valueLeft) || isPartialDate(valueRight)) {
        return false;
    }

    return _.isEqual(valueLeft, valueRight);
}

/**
 * Compara dos valores en una expresión, para determinar si son distintos
 *
 * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
 * @param  {mixed} valueRight      Valor a la derecha de la comparación
 *
 * @return {boolean}               Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function notEqual(valueLeft, valueRight) {
    if (isEmpty(valueLeft) && isEmpty(valueRight)) {
        return false;
    }

    return !equal(valueLeft, valueRight);
}
/**
 * Compara dos fechas en una expresión, para determinar si son distintos
 *
 * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
 * @param  {mixed} valueRight      Valor a la derecha de la comparación
 *
 * @return {boolean}               Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function dateNotEqual(valueLeft, valueRight) {
    if ((isEmpty(valueLeft) && !isEmpty(valueRight))
        || (!isEmpty(valueLeft) && isEmpty(valueRight))
    ) {
        return true;
    }

    const leftDateObject = createDateObject(valueLeft);
    const rightDateObject = createDateObject(valueRight);

    if (!leftDateObject.isValid() || !rightDateObject.isValid()) {
        return false;
    }

    const leftDateComponents = valueLeft.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
    const rightDateComponents = valueRight.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

    return leftDateComponents[0] !== rightDateComponents[0]
        || leftDateComponents[1] !== rightDateComponents[1]
        || leftDateComponents[2] !== rightDateComponents[2]
    ;
}

/**
 * Determina si el valor se considera vacío, es decir que no tiene valor o tiene un valor vacío
 *
 * GARU-4689 La implementación se copia de sharecrf-back-lib/packages/audit-log/diff/utils.js@isEmptyValue para tomar
 * como "vacío" el valor "false" de un campo de tipo checkbox desmarcado.
 *
 * @GARU-4864 El valor desconocido se considera vacío para las reglas
 *
 * @param  {mixed}   value Valor a comprobar
 *
 * @return {boolean}       Resultado de la comprobación
 */
function empty(value) {
    return isUnknown(value) ||
        isMissing(value) ||
        _.isNil(value) ||
        value === '' ||
        value === false ||
        _.isNaN(value) ||
        (_.isArray(value) && _.isEmpty(value)) ||
        (_.isPlainObject(value) && Object.keys(value).length === 0);
}

/**
 * Determina si el valor se considera no vacío, es decir que tiene valor y éste no está vacío
 *
 * @param  {mixed}   value Valor a comprobar
 *
 * @return {boolean}       Resultado de la comprobación
 */
function notEmpty(value) {
    return !empty(value);
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo es mayor o igual que el derecho
 *
 * @param  {mixed}   valueLeft  Valor a la izquierda de la comparación
 * @param  {mixed}   valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function greaterThanOrEqual(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    return notEmpty(valueLeft) &&
        notEmpty(valueRight) &&
        isNotUnknown(valueLeft) &&
        isNotUnknown(valueRight) &&
        _.gte(valueLeft, valueRight);
}
/**
 *
 * @param {string} valueLeft  Valor a la izquierda de la comparación
 * @param {string} valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}          La fecha izquierda es posterior o igual a la fecha de la derecha
 */
function dateGreaterThanOrEqual(valueLeft, valueRight) {
    const leftDateObject = createDateObject(valueLeft);
    const rightDateObject = createDateObject(valueRight);

    if (!leftDateObject.isValid() || !rightDateObject.isValid()) {
        return false;
    }

    const leftDateComponents = valueLeft.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
    const rightDateComponents = valueRight.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

    if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
        if (leftDateComponents[0] > rightDateComponents[0]) {
            return true;
        }
        if (leftDateComponents[0] < rightDateComponents[0]) {
            return false;
        }

        if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
            if (leftDateComponents[1] > rightDateComponents[1]) {
                return true;
            }
            if (leftDateComponents[1] < rightDateComponents[1]) {
                return false;
            }

            if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                if (leftDateComponents[2] >= rightDateComponents[2]) {
                    return true;
                }
            }
        }
    }

    return false;
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo es estrictamente mayor que el derecho
 *
 * @param  {mixed}   valueLeft  Valor a la izquierda de la comparación
 * @param  {mixed}   valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 */
function greaterThan(valueLeft, valueRight) {
    return notEmpty(valueLeft) &&
        notEmpty(valueRight) &&
        isNotUnknown(valueLeft) &&
        isNotUnknown(valueRight) &&
        _.gt(valueLeft, valueRight);
}
/**
 *
 * @param {string} valueLeft  Valor a la izquierda de la comparación
 * @param {string} valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}          La fecha izquierda es posterior o igual a la fecha de la derecha
 */
function dateGreaterThan(valueLeft, valueRight) {
    const leftDateObject = createDateObject(valueLeft);
    const rightDateObject = createDateObject(valueRight);

    if (!leftDateObject.isValid() || !rightDateObject.isValid()) {
        return false;
    }

    const leftDateComponents = valueLeft.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
    const rightDateComponents = valueRight.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

    if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
        if (leftDateComponents[0] > rightDateComponents[0]) {
            return true;
        }
        if (leftDateComponents[0] < rightDateComponents[0]) {
            return false;
        }

        if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
            if (leftDateComponents[1] > rightDateComponents[1]) {
                return true;
            }
            if (leftDateComponents[1] < rightDateComponents[1]) {
                return false;
            }

            if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                if (leftDateComponents[2] > rightDateComponents[2]) {
                    return true;
                }
            }
        }
    }

    return false;
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo es estrictamente menor que el derecho
 *
 * @param  {mixed}   valueLeft  Valor a la izquierda de la comparación
 * @param  {mixed}   valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 */
function lessThan(valueLeft, valueRight) {
    return notEmpty(valueLeft) &&
        notEmpty(valueRight) &&
        isNotUnknown(valueLeft) &&
        isNotUnknown(valueRight) &&
        _.lt(valueLeft, valueRight);
}
/**
 *
 * @param {string} valueLeft  Valor a la izquierda de la comparación
 * @param {string} valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}          La fecha izquierda es posterior o igual a la fecha de la derecha
 */
function dateLessThan(valueLeft, valueRight) {
    const leftDateObject = createDateObject(valueLeft);
    const rightDateObject = createDateObject(valueRight);

    if (!leftDateObject.isValid() || !rightDateObject.isValid()) {
        return false;
    }

    const leftDateComponents = valueLeft.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
    const rightDateComponents = valueRight.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

    if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
        if (leftDateComponents[0] < rightDateComponents[0]) {
            return true;
        }
        if (leftDateComponents[0] > rightDateComponents[0]) {
            return false;
        }

        if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
            if (leftDateComponents[1] < rightDateComponents[1]) {
                return true;
            }
            if (leftDateComponents[1] > rightDateComponents[1]) {
                return false;
            }

            if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                if (leftDateComponents[2] < rightDateComponents[2]) {
                    return true;
                }
            }
        }
    }

    return false;
}
/**
 * Compara dos valores en una expresión, para determinar si el izquierdo es menor o igual que el derecho
 *
 * @param  {mixed}   valueLeft  Valor a la izquierda de la comparación
 * @param  {mixed}   valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function lessThanOrEqual(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    return notEmpty(valueLeft) &&
        notEmpty(valueRight) &&
        isNotUnknown(valueLeft) &&
        isNotUnknown(valueRight) &&
        _.lte(valueLeft, valueRight);
}
/**
 *
 * @param {string} valueLeft  Valor a la izquierda de la comparación
 * @param {string} valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}          La fecha izquierda es posterior o igual a la fecha de la derecha
 */
function dateLessThanOrEqual(valueLeft, valueRight) {
    const leftDateObject = createDateObject(valueLeft);
    const rightDateObject = createDateObject(valueRight);

    if (!leftDateObject.isValid() || !rightDateObject.isValid()) {
        return false;
    }

    const leftDateComponents = valueLeft.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
    const rightDateComponents = valueRight.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

    if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
        if (leftDateComponents[0] < rightDateComponents[0]) {
            return true;
        }
        if (leftDateComponents[0] > rightDateComponents[0]) {
            return false;
        }

        if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
            if (leftDateComponents[1] < rightDateComponents[1]) {
                return true;
            }
            if (leftDateComponents[1] > rightDateComponents[1]) {
                return false;
            }

            if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                if (leftDateComponents[2] <= rightDateComponents[2]) {
                    return true;
                }
            }
        }
    }

    return false;
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo contiene al derecho
 *
 * @param  {string} valueLeft  Valor a la izquierda de la comparación
 * @param  {string} valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}           Resultado de la comparación
 *
 * @todo   Documentar los parámetros de estas funciones
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function contains(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    return isNotUnknown(valueLeft) && isNotUnknown(valueRight) && _.includes(valueLeft, valueRight);
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo no contiene al derecho
 *
 * @param  {string}  valueLeft  Valor a la izquierda de la comparación
 * @param  {string}  valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 *
 * @todo   Documentar los parámetros de estas funciones
 * @todo   Si el valor es nulo, ¿debería devolver true?
 */
function notContains(valueLeft, valueRight) {
    if (isEmpty(valueLeft) && isEmpty(valueRight)) {
        return false;
    }

    return !contains(valueLeft, valueRight);
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo comienza por el derecho
 *
 * @param  {string}  valueLeft  Valor a la izquierda de la comparación
 * @param  {string}  valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 *
 * @todo Documentar los parámetros de estas funciones
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function startsWith(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    return isNotUnknown(valueLeft) && isNotUnknown(valueRight) && _.startsWith(valueLeft, valueRight);
}

/**
 * Compara dos valores en una expresión, para determinar si el izquierdo finaliza por el derecho
 *
 * @param  {string}  valueLeft  Valor a la izquierda de la comparación
 * @param  {string}  valueRight Valor a la derecha de la comparación
 *
 * @return {boolean}            Resultado de la comparación
 *
 * @todo Documentar los parámetros de estas funciones
 *
 * @GARU-4864 Dos valores desconocidos se consideran equivalentes con respecto a las reglas
 */
function endsWith(valueLeft, valueRight) {
    if (isEmpty(valueLeft) || isEmpty(valueRight)) {
        return false;
    }

    return isNotUnknown(valueLeft) && isNotUnknown(valueRight) && _.endsWith(valueLeft, valueRight);
}

/**
 * Create date object
 *
 * @param {String} value Date value with format 'YYYY-MM-DD
 *
 * @returns {Moment}     Moment instance
 */
function createDateObject(value) {
    let date;

    if (!value) {
        date = moment.invalid();
    }
    else if (`${value}`.indexOf('x') > -1) {
        const matches = /^(.+)-(.+)-(.+)$/.exec(`${value}`.trim());
        if (matches) {
            const internalFormat = ['YYYY', 'MM', 'DD']
                .map((item, index) => matches[index + 1] === 'x' ? '[x]' : item)
                .join('-');

            date = moment(value, internalFormat, true);
        } else {
            date = moment.invalid();
        }
    }
    else {
        date = moment(value, 'YYYY-MM-DD', true);
    }

    return date;
}

/**
 * IS partial date
 *
 * @param {String} value Date value with format 'YYYY-MM-DD
 *
 * @returns {Boolean}    TRUE if date is valid but is not complete
 */
function isPartialDate(value) {
    const date = createDateObject(value);

    return !!(value && date && date.isValid() && `${value}`.indexOf('x') > -1);
}

/**
 * IS complete date
 *
 * @param {String} value Date value with format 'YYYY-MM-DD
 *
 * @returns {Boolean}    TRUE if date is valid and complete
 */
function isCompleteDate(value) {
    const date = createDateObject(value);

    return !!(value && date && date.isValid() && `${value}`.indexOf('x') === -1);
}

/**
 * Is unknown value
 *
 * @param  {mixed}   value Any field value
 *
 * @return {boolean}       TRUE if the value is unknown
 */
const isUnknown = value => {
    return value === UNKNOWN_VALUE;
};

/**
 * Is missing value
 *
 * @param  {mixed}   value Any field value
 *
 * @return {boolean}       TRUE if the value is missing
 */
const isMissing = value => {
    return value === MISSING_VALUE;
};

/**
 * Is not unknown value
 *
 * @param  {mixed}   value Any field value
 *
 * @return {boolean}       TRUE if the value is not unknown
 */
const isNotUnknown = value => {
    return !isUnknown(value);
};

/**
 * Is empty value
 *
 * @param  {mixed}   value Any field value
 *
 * @return {boolean}       TRUE if the value is empty
 */
const isEmpty = value => {
    switch (String(value)) {
        case '':
        case 'undefined':
        case 'null':
        case UNKNOWN_VALUE:
        case MISSING_VALUE:
            return true;

        default:
            return false;
    }
};

/**
 * It returns false
 *
 * GARU-5032 - Every condition with a removed field is always false
 *
 * @return {Boolean} False
 */
const returnFalse = () => false;

module.exports = {
    equal,
    notEqual,
    greaterThanOrEqual,
    greaterThan,
    lessThan,
    lessThanOrEqual,
    empty,
    notEmpty,
    contains,
    notContains,
    startsWith,
    endsWith,
    isCompleteDate,
    isPartialDate,
    isUnknown,
    isNotUnknown,
    returnFalse,
    dateEqual,
    dateNotEqual,
    dateGreaterThanOrEqual,
    dateGreaterThan,
    dateLessThan,
    dateLessThanOrEqual,
};
