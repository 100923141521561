'use strict';

/**
 * Tipos de operación binaria
 *
 * @enum {string}
 *
 * @readOnly
 */
const BinaryOperators = {
    ADD: '+',
    DIVIDE: '/',
    MODULO: '%',
    MULTIPLY: '*',
    SUBTRACT: '-',
};

/**
 * Tipos de operación unaria
 *
 * @enum {string}
 *
 * @readOnly
 */
const UnaryOperators = {
    MINUS: '-',
    PLUS: '+',
};

module.exports = {
    UNARY: UnaryOperators,
    BINARY: BinaryOperators,
};
