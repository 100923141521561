'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core');
const { UNKNOWN_VALUE, MISSING_VALUE } = require('./constants');
const Field = require('../Structure/Field');


/**
 * Objeto de gestión de los datos de la entidad
 *
 * @memberOf Record
 */
class Data {
    /**
     * @param  {CRF} crf        Instancia de un CRF
     */
    constructor(crf) {
        this.crf = crf;
        this.data = {};
    }

    /**
     * GARU-7958 Modo de generación de IDs de elementos de listas
     *
     * @return {boolean} TRUE en modo antiguo, FALSE en modo secuencial
     *
     * @todo Eliminar este método cuando se migre el estudio icd01
     */
    get legacyListIds() {
        return !!this.data.__legacy_list_ids;
    }

    /**
     * Carga los datos de registro en BD (solo la parte de data)
     *
     * @param  {Object} jsonData  Datos en JSON de la columna data de una fila de BD
     * @param  {boolean} truncate   TRUE si se toman los datos de jsonData como completos, de modo que para los
     *                              arrays, los items sobrantes en currentData que no vengan en jsonData se eliminan
     *
     * @return {Record.Data}          Instancia propia
     */
    load(jsonData = {}, truncate = false) {
        debug('Load new record data %O on %O', jsonData, this.data);
        this._mergeData(this.data, jsonData, truncate);
        // Rellena los __id que falten
        this._fillEmptyListItemsId(this.data, {});
        // Elimina cualquier referencia a items de listas eliminados
        _.unset(this.data, '__deleted_items');
        debug('Merged data %O', this.data);

        return this;
    }

    /**
     * Aplica una función a los elementos de las listas que se encuentran en el objeto de datos
     *
     * @param {object}      data        Objeto de datos
     * @param {ListIndices} listIndices Índices de las listas asociadas
     * @param {Array}       scopeUidParts UID del elemento padre de la lista
     */
    _fillEmptyListItemsId(data, listIndices, scopeUidParts = []) {
        _.each(data, (value, id) => {
            const element = this.crf.getElement(id);
            if (element !== null && element.isList()) {
                const indices = { ...listIndices };
                const listUidParts = [...scopeUidParts, id];
                _.each(this.getList(id, listIndices, false), (item, index) => {
                    indices[id] = index + 1;
                    const listUid = listUidParts.join('.');
                    if (typeof item.__id === 'undefined') {
                        item.__id = this.generateListItemId(element.getId(), indices);
                    }
                    else if (!this.legacyListIds) {
                        this.data.__list_counters = this.data.__list_counters || {}; // eslint-disable-line camelcase
                        if (!this.data.__list_counters[listUid] || this.data.__list_counters[listUid] < item.__id) {
                            this.data.__list_counters[listUid] = item.__id;
                        }
                    }
                    this._fillEmptyListItemsId(item, indices, [...listUidParts, item.__id]);
                });
            }
        });
    }
    /**
     * Actualiza los datos actuales ya cargados con un nuevo subconjunto
     *
     * Una vez hecho el merge, recorre los items de las listas para asegurar que tengan un __id
     *
     * @param  {object} currentData Datos en JSON a actualizar
     * @param  {object} jsonData    Nuevos datos en JSON
     * @param  {boolean} truncate   TRUE si se toman los datos de jsonData como completos, de modo que para los
     *                              arrays, los items sobrantes en currentData que no vengan en jsonData se eliminan
     *
     * @return {object}             El objeto currentData modificado
     *
     * @private
     */
    _mergeData(currentData, jsonData, truncate = false) {
        // Si jsonData es un array significa que hemos entrado recursivamente en el _mergeData de una lista
        const insideList = Array.isArray(jsonData);

        // 1. Recorremos las claves de primer nivel que no tienen un array como valor. Las listas van aparte
        _.each(jsonData, (value, key) => {
            if (key === '__list_counters') {
                Object.keys(value).forEach(listKey => { // List ID or list UID, depending on data.__legacy_list_ids
                    currentData.__list_counters = currentData.__list_counters || {}; // eslint-disable-line camelcase
                    if (!currentData.__list_counters[listKey] || currentData.__list_counters[listKey] < value[listKey]) {
                        currentData.__list_counters[listKey] = value[listKey];
                    }
                });
            }
            else if (typeof currentData[key] === 'undefined') {
                _.set(currentData, key, value);
            }
            else if (_.isObject(value)) {
                // Puede ser un campo de valor múltiple, lo comprobamos ahora
                const isField = !insideList && this.crf.hasField(key);
                if (isField) {
                    _.set(currentData, key, value);
                } else {
                    // tanto si es un objeto como un array (los arrays son objetos), se mezclan sus elementos con una
                    // llamada recursiva a este mismo método
                    this._mergeData(currentData[key], value, truncate);

                    if (Array.isArray(value) && truncate) {
                        // se eliminan los ítems que no vengan si así lo indica el parámetro 'truncate'
                        const maxIndex = value.length - 1;
                        _.remove(currentData[key], (dataValue, index) => index > maxIndex);
                    }
                }
            }
            else {
                _.set(currentData, key, value);
            }
        });

        return currentData;
    }

    /**
     * Resetea la información de datos en este registro, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     *
     * @return {Record.Data}          Instancia propia
     */
    reset() {
        _.each(_.keys(this.data), key => {
            _.unset(this.data, key);
        });
        _.unset(this.data, '__deleted_items');

        return this;
    }
    /**
     * Devuelve la instancia de CRF correspondiente a este registro de datos
     *
     * @return {Structure.CRF} Instancia de CRF
     */
    getCRF() {
        return this.crf;
    }
    /**
     * Exporta una copia de los datos actuales
     *
     * @return {object} Copia de los datos
     */
    export() {
        return JSON.parse(JSON.stringify(this.data));
    }

    /**
     * Devuelve el objeto de datos, conservando siempre la misma referencia, de modo que aunque se modifiquen
     * los datos, la referencia en memoria a este objeto sea la misma
     *
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {object} Datos del registro (parcial dentro de listas si hay listIndices)
     */
    getData(listIndices = {}) {
        const lists = this.crf.getOrderedLists(listIndices);
        const pathLodash = [];

        // En lists hay objetos con la forma {id, index}
        _.each(lists, list => {
            pathLodash.push(list.id, list.index - 1);
        });

        return _.isEmpty(pathLodash) ? this.data : _.get(this.data, pathLodash);
    }

    /**
     * Crea un nuevo item en la lista
     *
     * @param  {Number}  listId     ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece esta lista
     *
     * @return {Object}            Datos del nuevo elemento creado
     */
    createListItem(listId, listIndices = {}) {
        const listData = this.getList(listId, listIndices);
        if (!listData) {
            return false;
        }

        // New unique ID for the new element
        const elementId = this.generateListItemId(listId, listIndices);

        const elementData = {
            __id: elementId,
        };

        // Add new item to list
        listData.push(elementData);

        return elementData;
    }

    /**
     * Elimina un elemento de la lista
     *
     * @param  {Number}  listId     ID de la lista en el CRF
     * @param {Number}  itemIndex   Índice en la lista del ítem a borrar, empezando en 1
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece esta lista
     *
     * @return {array|boolean}      TRUE si se elimina con éxito el ítem
     */
    deleteListItem(listId, itemIndex, listIndices = {}) {
        if (itemIndex < 1) {
            throw new Error('itemIndex must start in 1');
        }
        const listSection = this.getList(listId, listIndices);
        if (!listSection) {
            return false;
        }

        // Elimina el elemento de la lista
        const deleted = _.first(listSection.splice(itemIndex - 1, 1));

        // Si es un elemento preexistente, que ya tiene __id
        if (deleted && deleted.__id) {
            if (typeof this.data.__deleted_items === 'undefined') {
                this.data.__deleted_items = []; // eslint-disable-line camelcase
            }
            // marcamos el UID del elemento eliminado
            // primero obtenemos el UID de la lista, teniendo en cuenta listIndices
            const itemUId = this.getElementUID(listId, listIndices);
            // añadimos finalmente el __id del item eliminado
            itemUId.push(deleted.__id);
            this.data.__deleted_items.push(itemUId);

            return itemUId;
        }

        return true;
    }

    /**
     * Elimina un elemento de una lista especificado por su UID
     *
     * @param  {array|string} itemUId  UID del item a eliminar
     *
     * @return {boolean}      Resultado de la operación
     */
    deleteListItemByUId(itemUId) {
        if (!itemUId) {
            return false;
        }
        let uid = JSON.parse(JSON.stringify(itemUId));
        if (!Array.isArray(uid)) {
            uid = _.split(uid, '.');
        }
        const itemId = parseInt(uid.pop(), 10);
        const listId = _.last(uid);
        const listIndices = this.getListIndicesByUID(uid);
        const list = this.getList(listId, listIndices);

        if (!list || isNaN(itemId)) {
            return false;
        }

        // Elimina el elemento de la lista
        _.remove(list, { __id: itemId });

        return true;
    }

    /**
     * Determina si en la lista existe un item en la posición indicada
     *
     * @param {Number}      listId       ID de la lista en el CRF
     * @param {Number}      itemIndex    Índice del ítem (empezando en 1)
     * @param {ListIndices} listIndices  Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}   TRUE si existe el ítem indicado
     */
    listItemExists(listId, itemIndex, listIndices) {
        const __id = this.getListElementId(listId, itemIndex, listIndices);

        return __id !== null;
    }

    /**
     * Devuelve el path de un elemento en los datos, empleando los ID internos en el CRF de cada componente.
     * Si el elemento está incluido en listas, además de ID
     * del propio elemento (que ocupará siempre la posición final en el array devuelto), tendrá antes una serie
     * de ID que vendrán de 2 en 2 (con el ID de la lista, y el índice que ocupa en ella)
     * @example
     *
     * [4] - Campo con ID 4 en el CRF que se encuentra en el nivel raíz (no pertenece a ninguna lista)
     * [3, 2, 8] - Campo con ID 8 en el CRF, que está contenido en la lista con ID 3; el índice que ocupa en esa lista
     *             es el 2 (comenzando por 0)
     *
     * @param {Number}  elementId ID del elemento en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {Array}  Path tipo lodash dentro de los datos para obtener la ruta hasta el elemento
     */
    getElementPath(elementId, listIndices = {}) {
        const elementDefinition = this.crf.getElement(elementId);
        if (!elementDefinition) {
            return null;
        }

        const elementPath = [elementId];

        const parentLists = elementDefinition.getParentLists();
        parentLists.forEach(listDefinition => {
            const listId = listDefinition.getId();

            // Transformamos el índice de la lista a índice de array (numérico) y comenzando por 0
            // Puede ser null. Ej: condición de regla con campo en último elemento de lista, cuando la lista está vacía
            const listIndex = _.toNumber(listIndices && listIndices[listId] || 0) - 1;

            // Se añade en la penúltima posición, justo antes del ID de destino, ya que los padres vienen ordenados de
            // manera descendiente
            elementPath.splice(-1, 0, listId, listIndex);

            return true;
        });

        // En caso de estar dentro de una lista, se comprueba que el elemento exista (sin el último componente que corresponde al campo y puede no existir en data)
        const itemExists = elementPath.length === 1 || _.get(this.data, elementPath.slice(0, -1), false);

        return itemExists ? elementPath : null;
    }

    /**
     * Devuelve el path completo de un elemento en los datos, empleando los nombres (no los ID) en el CRF de cada
     * componente. Tiene en cuenta la estructura del CRF completa, deshaciendo el flatten de los datos en su caso,
     * colocando cada componente bajo su padre en la estructura.
     *
     * El nº de índice de cada item de lista es la posición natural que ocupa, pero empezando en 1
     *
     * Útil entre otras cosas para llamar a la API mediante el SDK, ya que se deben identificar los elementos mediante
     * sus identificadores como string en el CRF actual, no el flatten.
     *
     * Importante: en la estructura de datos, no se anidan las variables y subvariables. Todo campo en un formulario,
     * independientemente de su indentación, pertenece de forma directa al formulario.
     *
     * @example
     *
     * ['datos_basales', 'sexo']
     * ['visitas', 2, 'datos_visita', 'pruebas', 'tas']
     *
     * @param  {Number}  elementId ID del elemento en el CRF
     * @param  {ListIndices} listIndices  Índices de las listas a las que pertenece el campo
     * @param  {string} nameFunction  Nombre de la función que se aplicará sobre cada Container para extraer la
     *                                información legible. Por defecto es 'getName', pero se puede usar otros como
     *                                'getLabel', etc.
     * @param {boolean} skipSubvariables TRUE si no queremos incluir en el path variables padre de subvariables
     *
     * @return {Array}  Path completo del elemento
     */
    getElementFullNamedPath(elementId, listIndices = {}, nameFunction = 'getName', skipSubvariables = true) {
        const element = this.crf.getElement(elementId);
        if (!element) {
            return null;
        }

        // const namedListIndices = this.crf.getNamedListIndices(listIndices);
        const resolvedPath = [element[nameFunction]()];

        // Obtenemos la lista de antecesores de este elemento en el CRF, hasta el raíz
        let parentList = element.getParents();
        // Eliminamos los que sean fields si procede
        if (skipSubvariables) {
            parentList = parentList.filter(parent => !parent.isField());
        }
        parentList.reverse();
        // La recorremos en orden inverso, desde el elemento que estamos hasta el raíz
        // Cada vez que encontremos una lista, insertamos a continuación en el path
        // el índice que ocupa este elemento (empiezan en 1)
        const itemExists = _.every(parentList, parent => {
            if (parent.isList()) {
                const parentIndex = listIndices[parent.getId()];
                if (typeof parentIndex === 'undefined') {
                    // No se ha definido el índice para la lista
                    return false;
                }
                // TODO comprobar la longitud de la lista en los datos?
                resolvedPath.push(parentIndex);
                resolvedPath.push(parent[nameFunction]());
            } else if (!parent.isRoot()) {
                // añadimos el nombre sin más al path
                resolvedPath.push(parent[nameFunction]());
            }

            return true;
        });

        // ordenamos inversamente, ya que hemos recorrido desde el elemento hasta el raíz
        resolvedPath.reverse();

        return itemExists ? resolvedPath : null;
    }
    /**
     * Devuelve el UID del elemento indicado mediante su path, que es un array de los componentes con el
     * identificador alfanumérico en el CRF de cada uno
     *
     * Si hay listas, estará el identificador de la lista seguido del índice puro de array (empezando en 0)
     * En ese caso se buscará en los datos el ítem en ese índice, y si existe, usar su __id para el UID
     *
     * @example
     * ['screening', 'visitas', 0, 'datos_visita', 'fecha_visita']
     * a
     * [11, 5, 12]
     * Suponiendo que 11 es el ID de la lista 'visitas' en el CRF, 5 el '__id' de la primera visita,
     * y 12 el ID de la variable 'fecha_visita'
     *
     * @param  {string|array} elementPath Path como array, o string separado por '.' o '/'
     *
     * @return {Array}             UID del elemento
     */
    getElementUIdByNamedPath(elementPath) {
        let components = elementPath;
        if (!Array.isArray(components)) {
            components = components.split(/[./]/);
        }
        const uid = [];
        const listIndices = {};
        let i, ii, comp, element;
        for (i = 0, ii = components.length; i < ii; i++) {
            comp = components[i];
            element = this.crf.getElementByName(comp);
            if (element) {
                if (element.isList()) {
                    const itemIndex = components[i + 1];
                    const listData = this.getList(element.getId(), listIndices);
                    const __id = typeof listData[itemIndex] !== 'undefined' ? listData[itemIndex].__id : null;
                    if (__id) {
                        uid.push(element.getId());
                        uid.push(__id);
                        listIndices[element.getId()] = itemIndex + 1;
                        i++;
                    }
                }
            }
        }
        // comp tendrá el elemento final
        element = this.crf.getElementByName(comp);
        uid.push(element.getId());

        return uid;
    }

    /**
     * Devuelve el path completo de un elemento en los datos, empleando los ID en el CRF de cada
     * componente. Tiene en cuenta la estructura del CRF completa, deshaciendo el flatten de los datos en su caso,
     * colocando cada componente bajo su padre en la estructura.
     *
     * @example
     *
     * ['Datos Basales', 'Sexo']
     * ['Visitas no programadas', 2, 'Datos de la visita', 'Pruebas', 'TAS']
     *
     * @param  {Number}  elementId ID del elemento en el CRF
     * @param  {ListIndices} listIndices  Índices de las listas a las que pertenece el campo
     *
     * @return {Array}  Path completo del elemento con los labels de cada componente
     */
    getElementFullIdPath(elementId, listIndices = {}) {
        return this.getElementFullNamedPath(elementId, listIndices, 'getId');
    }

    /**
     * Returns the full path of an item in the data, using the labels (not the IDS) in the CRF for each component.
     * It takes into consideration the complete CRD structure, undoing the flattening of the data if necessary and
     * placing each component under its parent in the structure.
     * When the item is inside a list, the ID of the item in the list is included in the path.
     *
     * @example
     *
     * ['Datos Basales', 'Sexo']
     * ['Visitas no programadas', 2, 'Datos de la visita', 'Pruebas', 'TAS']
     *
     * @param  {string} elementUId UID del elemento en el CRF
     *
     * @return {Array|null} Complete path of the element with the labels of each component, or NULL if the element
     *                      does not exist in the CRF structure
     */
    getElementFullLabeledPathByUID(elementUId) {
        const resolvedPath = [];
        const uidParts = Array.isArray(elementUId) ? elementUId : String(elementUId).split('.');

        for (let index = 0; index < uidParts.length; index++) {
            const elementId = uidParts[index];
            const element = this.crf.getElement(elementId);
            if (!element) {
                return null;
            }

            Array.prototype.push.apply(resolvedPath, element.getFullLabelFromList());

            if (element.isList() && uidParts[index + 1]) {
                resolvedPath.push(uidParts[index + 1]);
                index++;
            }
        }

        return resolvedPath;
    }

    /**
     * Devuelve un objeto ListIndices a partir de la información del UID del elemento
     *
     * @param  {array|string|integer} uid UID del elemento
     *
     * @return {ListIndices}     Objeto con los ID de las listas y su índice correspondiente
     *                           NULL si el elemento no existe, o alguno de los ítems de las listas intermedias
     */
    getListIndicesByUID(uid) {
        if (!Array.isArray(uid)) {
            uid = _.split(uid, '.');
        }

        return _.chain(uid)
            .chunk(2)
            .reduce((acc, fragment) => {
                // acc será NULL si ya hemos encontrado un error
                if (typeof acc !== 'undefined' && fragment.length == 2) {
                    // se trata de un ID de lista seguido de un índice
                    // buscamos el índice del item que se corresponde con el __id correspondiente
                    const listId = parseInt(fragment[0], 10);
                    const list = this.getList(listId, JSON.parse(JSON.stringify(acc)));
                    const itemId = parseInt(fragment[1], 10);
                    const itemIndex = _.findIndex(list, { __id: itemId });
                    debug('getListIndicesByUID %d[%O]: %O', listId, itemId, itemIndex);
                    if (itemIndex === -1) {
                        // el ítem de esta lista no existe en los datos actuales
                        debug('Item %d[%o] does not exist', listId, itemId);

                        return undefined;
                    }
                    acc[listId] = itemIndex + 1; // en listIndices los índices empiezan siempre en 1
                }

                return acc;
            }, {})
            .value();
    }

    /**
     * Obtiene un UID para el elemento, con formato array de path de lodash, para operaciones como _.get
     * En las listas, transforma el índice n dentro del array en el __id correspondiente
     * dentro de los datos de la entidad
     *
     * @param  {Number}      elementId   ID del elemento en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {Array<integer>}  Lista de componentes del path, con los ID de cada elemento, y si es una lista
     *                           el siguiente tendrá el índice del item
     *
     *                           Devuelve NULL si el elemento no existe en los datos
     *
     * @example
     * // returns [2, 16, 3]
     * record.load({
     *     1: 'value',
     *     2: [{
     *         __id: 12,
     *         3: 'value',
     *     }, {
     *         __id: 16,
     *         3: 'value',
     *     }],
     * });
     * record.getElementUID(3, {2: 2});
     */
    getElementUID(elementId, listIndices = {}) {
        const dataPath = this.getElementPath(elementId, listIndices);
        if (!dataPath) {
            return null;
        }
        // A partir de aquí hemos asegurado que el elemento al menos existe en la estructura de CRF
        const uid = _.chain(dataPath)
            .chunk(2)
            .map(fragment => {
                if (fragment.length == 2) {
                    // se trata de un ID de lista seguido de un índice (comenzando por 0 - viene de getElementPath)
                    // transformamos el índice en el __id del elemento correspondiente
                    const listId = fragment[0];
                    const index = parseInt(fragment[1], 10);
                    // getListElementId toma índices comenzando en 1
                    const __id = this.getListElementId(listId, index + 1, listIndices);
                    if (__id === null) {
                        // si este ítem no existe actualmente en los datos
                        return null;
                    }

                    return [listId, __id];
                }

                // no hay lista, se trata del ID del elemento final
                return fragment;
            })
            .flatten()
            .value();

        return _.every(uid) ? uid : null;
    }

    /**
     * Devuelve el ID del elemento en el CRF, obviando cualquier información de listas que venga en el UID
     *
     * @param  {string|array|integer} elementUId UID del campo en los datos
     *
     * @return {Number}             ID del campo en el CRF
     */
    getElementId(elementUId) {
        if (!Array.isArray(elementUId)) {
            elementUId = _.split(elementUId, '.');
        }
        if (elementUId.length % 2 === 0) {
            // si el nº de componentes es par, el UID se corresponde con un item de una lista
            // en este caso, no hay ID del elemento concreto. Devolver el ID de la lista.
            return _.nth(elementUId, -2);
        }

        return _.last(elementUId);
    }

    /**
     * Obtiene un array de los elementos de una lista en los datos. Si la lista no existe aún en los datos,
     * crea un array vacío y devuelve la referencia.
     *
     * @param  {Number}      listId      ID único numérico de la lista en la definición del CRF
     * @param  {ListIndices} listIndices Índices de las listas que a su vez contienen la lista
     * @param  {boolean}     [create]    TRUE para crear la lista vacía si no existe (por defecto)
     *
     * @return {Array<object>}           Array de elementos de la lista, NULL si no existe en el CRF
     */
    getList(listId, listIndices = {}, create = true) {
        // Validar que efectivamente el ID corresponde a una lista
        if (!this.crf.hasList(listId)) {
            return null;
        }

        const listPath = this.getElementPath(listId, listIndices);
        if (listPath !== null) {
            if (create && !_.has(this.data, listPath)) {
                _.set(this.data, listPath, []);
            }

            return _.get(this.data, listPath, []);
        }

        return null;
    }

    /**
     * Obtiene la longitud (número de elementos) de una lista en los datos
     *
     * @param  {Number}      listId      ID único numérico de la lista en la definición
     * @param  {ListIndices} listIndices Índices de las listas que a su vez contienen la lista
     *
     * @return {Number}                  Número de elementos en la lista (0 si no existe)
     */
    getListLength(listId, listIndices = {}) {
        const list = this.getList(listId, listIndices);

        return Array.isArray(list) ? list.length : 0;
    }

    /**
     * @private
     */
    generateListItemId(listId, listIndices) {
        if (typeof this.data.__list_counters === 'undefined') {
            this.data.__list_counters = {}; // eslint-disable-line camelcase
        }

        if (this.legacyListIds) {
            if (typeof this.data.__list_counters[listId] === 'undefined') {
                const list = this.getList(listId, listIndices);
                if (list === null) {
                    return null;
                }

                // Para inicializar el valor del contador para esta lista se obtiene el máximo ID de los elementos actuales. Si la lista estuviera vacía
                // o si ninguno de los elementos tuviera ID, se toma el valor 0. El primer elemento apartir de ahora tendrá ID = 1
                // Es posible que esto no sea necesario y que fuera suficiente tomar el valor 0 directamente, pero se mantiene esta lógica por razones
                // históricas (antes de corregir GARU-7917 se tomaba la longitud de la lista y se desconoce por qué). No debería haber ningún caso en que
                // aquí llegaran elementos con ID y no estuviera definido el contador.
                this.data.__list_counters[listId] = Math.max.apply(null, [0, ...list.map(item => item.__id || 0)]);
            }

            // Increment counter for this list
            this.data.__list_counters[listId]++;

            return this.data.__list_counters[listId];
        }

        const uid = this.getElementUID(listId, listIndices);
        if (!uid || !uid.length) {
            return null;
        }
        const key = uid.join('.');

        return this.getNextListCounter(key);
    }

    /**
     * Get next list sequence number
     *
     * This does not check if the UID belongs to a list
     *
     * @param {string} uid List UID
     *
     * @returns {number} Sequence number
     */
    getNextListCounter(uid) {
        this.data.__list_counters = this.data.__list_counters || {}; // eslint-disable-line camelcase
        if (typeof this.data.__list_counters[uid] === 'undefined') {
            this.data.__list_counters[uid] = 0;
        }

        // Increment counter for this list
        this.data.__list_counters[uid]++;

        return this.data.__list_counters[uid];
    }

    /**
      * Obtiene el __id interno de un item en una lista
      *
      * @param  {Number}  listId      ID de la lista en el CRF
      * @param  {Number}  itemIndex   Índice del ítem en la lista, empezando en 1
      * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
      *
      * @return {Number}              __id interno del ítem, o NULL si no existe eĺ ítem en la lista
      */
    getListElementId(listId, itemIndex, listIndices = {}) {
        const data = this.getListElement(listId, itemIndex, listIndices);
        if (data === null) {
            // si la lista no existe en los datos
            return null;
        }

        return _.get(data, '__id', null);
    }

    /**
     * Devuelve el índice de la lista (empezando en 1) correspondiente al elemento
     * indicado por su __id interno
     *
      * @param  {Number}  listId     ID de la lista en el CRF
      * @param  {Number}  itemId     ID interno __id del ítem en la lista
      * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
     *
     * @return  {Number}             Índice en la lista de ese ítem, empezando en 1. NULL si no existe el ítem
     */
    getListItemIndex(listId, itemId, listIndices = {}) {
        const data = this.getList(listId, listIndices);
        if (data === null) {
            // si la lista no existe en los datos
            debug('No existe la lista', listId);

            return null;
        }
        const arrayIndex = _.findIndex(data, { __id: parseInt(itemId, 10) });

        return arrayIndex !== -1 ? arrayIndex + 1 : null;
    }

    /**
      * Obtiene el objeto de datos completo de un item en una lista
      *
      * @param  {Number}  listId      ID de la lista en el CRF
      * @param  {Number}  itemIndex   Índice del ítem en la lista, empezando en 1
      * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
      *
      * @return {object}             Datos completos del ítem, o NULL si no existe eĺ ítem en la lista
      */
    getListElement(listId, itemIndex, listIndices = {}) {
        const list = this.getList(listId, listIndices);

        return _.get(list, itemIndex - 1, null);
    }

    /**
     * Obtiene el valor legible de un campo de este registro
     *
     * @param  {Number}  fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
     *
     * @return {string}             Valor legible del campo, NULL si no existe
     */
    getReadableFieldValue(fieldId, listIndices) {
        const value = this.getFieldValue(fieldId, listIndices);
        const field = this.crf.getField(fieldId);

        return field ? field.getReadableValue(value) : null;
    }

    /**
     * Obtiene el valor legible de un campo de este registro
     *
     * @param  {string|array} fieldUId   UID del campo en los datos
     *
     * @return {string}             Valor legible del campo
     */
    getReadableFieldValueByUID(fieldUId) {
        const fieldId = this.getElementId(fieldUId);
        const listIndices = this.getListIndicesByUID(fieldUId);

        return this.getReadableFieldValue(fieldId, listIndices);
    }

    /**
     * Obtiene el valor de un campo concreto en los datos. Si está contenido en alguna lista, es necesario
     * pasarle los índices para que pueda resolverlo correctamente
     *
     * @param  {Number}  fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
     *
     * @return {mixed}    Valor del campo
     */
    getFieldValue(fieldId, listIndices = {}) {
        const fieldExists = this.crf.hasField(fieldId);
        if (!fieldExists) {
            return undefined;
        }

        const elementPath = this.getElementPath(fieldId, listIndices);
        if (elementPath === null) {
            return undefined;
        }

        return _.get(this.data, elementPath);
    }

    /**
     * Obtiene el valor de un campo concreto en los datos. Si está contenido en alguna lista, es necesario
     * pasarle los índices para que pueda resolverlo correctamente
     *
     * @param  {string} fieldUId     UID del campo en los datos
     *
     * @return {mixed}    Valor del campo
     */
    getFieldValueByUId(fieldUId) {
        const fieldId = this.getElementId(fieldUId);
        const listIndices = this.getListIndicesByUID(fieldUId);

        return this.getFieldValue(fieldId, listIndices);
    }

    /**
     * Determina de forma unívoca si el valor de este campo se considera vacío o no relleno
     *
     * @param  {Number}  fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece a su vez esta lista
     *
     * @return {boolean}             TRUE si el campo está vacío
     */
    isFieldValueEmpty(fieldId, listIndices) {
        let isEmpty = true;

        if (this.crf.hasField(fieldId)) {
            const value = this.getFieldValue(fieldId, listIndices);

            isEmpty = Data.isValueEmpty(value);
        }

        // Por defecto, aunque incluso no exista el campo en el CRF, se considera que está vacío
        return isEmpty;
    }

    /**
     * Determina si el valor se considera no relleno, que es equivalente a decir "vacío" al referirse a un campo del CRD
     *
     * @param  {mixed}   value Valor del campo
     *
     * @return {Boolean}       TRUE sie el valor del campo se considera vacío o no relleno
     */
    static isValueEmpty(value) {
        if ([UNKNOWN_VALUE, MISSING_VALUE].includes(value)) {
            // Si el valor es desconocido o está perdido, sí está relleno, el campo no se considera vacío
            return false;
        }

        if (_.isObject(value) || _.isString(value)) {
            return _.isEmpty(value);
        }

        return _.isNil(value) || _.isNaN(value) || value === false;
    }

    /**
     * Determina si el valor se debe encriptar
     *
     * Los valores vacíos, desconocidos o datos perdidos no se encriptan en base de datos
     *
     * @param {*} value Valor
     *
     * @return {boolean} Si el dato debe ser encriptado antes de guardarlo en BDD
     */
    static isValueEncryptable(value) {
        if (value === UNKNOWN_VALUE || value === MISSING_VALUE) {
            return false;
        }

        return typeof value !== 'undefined'
            && value !== null
            && !_.isNaN(value)
            && value !== false
            && value !== ''
            && (!Array.isArray(value) || value.length > 0)
            && (Object(value) !== value || Object.keys(value).length > 0);
    }

    /**
     * Establece el valor para un campo
     *
     * @param {Number}      fieldId     ID único numérico del campo en la estructura
     * @param {mixed}       fieldValue  Valor a establecer
     * @param {ListIndices} listIndices Índices de las listas que incluyen al campo
     *
     * @return {mixed}                  El valor establecido, o undefined la combinación <fieldId, listIndices> no
     *                                  existe en los datos
     */
    setFieldValue(fieldId, fieldValue, listIndices = {}) {
        const field = this.crf.getField(fieldId);
        if (!field) {
            return undefined;
        }

        const fieldPath = this.getElementPath(fieldId, listIndices);
        if (fieldPath === null) {
            return undefined;
        }

        // esta comprobación debería mirar el tipo de campo, pero de momento el tipo de un campo de hora es 'string'
        const newValue = field.getFormControl() === Field.FormControl.TIME
            ? Data.normalizeInternalTimeValue(fieldValue)
            : fieldValue;


        const currentValue = _.get(this.data, fieldPath);
        let changed = false;
        if (!Data.isEqual(currentValue, newValue)) {
            _.set(this.data, fieldPath, newValue);
            changed = true;
        }

        return changed;
    }

    /**
     * Normalizr internal time value
     *
     * @param {*} value Raw value
     *
     * @returns {string} Internal value
     */
    static normalizeInternalTimeValue(value = '') {
        let internalValue;
        if (!value) {
            internalValue = '';
        }
        else if (/^\d{2}:\d{2}:\d{2}$/.test(`${value}`)) {
            const [, timevalue] = /^(\d{2}:\d{2}):\d{2}$/.exec(value);
            internalValue = timevalue;
        }
        else {
            internalValue = value;
        }

        return internalValue;
    }

    /**
     * Is empty
     *
     * @param {mixed} value Value
     *
     * @returns {Boolean}   TRUE if value is empty
     *
     * @static
     * @private
     */
    static isEmpty(value) {
        return typeof value === 'undefined'
            || value === null
            || value === ''
            || value === false
            || (Array.isArray(value) && !value.length)
            || (Object(value) === value && !Object.keys(value).length);
    }

    /**
     * Is equal
     *
     * @param {*} valueA Value A
     * @param {*} valueB Value B
     *
     * @returns {Boolean} TRUE if both values are equal
     *
     * @static
     * @private
     */
    static isEqual(valueA, valueB) {
        let equal = false;
        if (Data.isEmpty(valueA) && Data.isEmpty(valueB)) {
            equal = true;
        } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
            // Si el valor de la acción es un array, la comparación con !== siempre será true, ya que siempre serán dos
            // referencias distintas. No es tan directo.
            // Con la segunda parte se ignora el orden de los arrays: con [1, 2, 3] y [3, 2, 1], hasChanged es FALSE
            equal = _.isEqual(valueA, valueB)
                || (_.difference(valueA, valueB).length === 0 && _.difference(valueB, valueA).length === 0);
        } else {
            equal = valueA === valueB;
        }

        return equal;
    }

    /**
     * Obtiene el valor de reseteo de un campo. Lo que hace es devolver el valor vacío equivalente al tipo de campo.
     *
     * La idea es que este valor vacío siempre pise en los datos al valor actual. Si fuese undefined, no valdría, ya que
     * un merge con los datos deja el valor anterior siempre.
     *
     * @param  {Number}  fieldId ID del campo en el CRF
     *
     * @return {mixed}           Valor vacío según el tipo de campo
     */
    getResetValue(fieldId) {
        const fieldDefinition = this.crf.getField(fieldId);
        // GARU-4876 Si el campo no es una variable de datos, por ejemplo raw, no puede tener valor nunca
        if (!fieldDefinition || fieldDefinition.getFormControl() === 'raw') {
            return undefined;
        }

        switch (fieldDefinition.getFieldType()) {
            case 'number':
                return null;
            case 'boolean':
                return false;
            case 'string':
                return '';
            case 'array':
                return [];
        }

        return undefined;
    }

    /**
     * Establece el valor de un campo sobreescribiéndolo siempre con el valor por defecto tal y como está
     * definido en el CRF
     *
     * @param {Number}  fieldId     ID del campo en el CRF
     * @param  {object} listIndices Índices de las listas a las que pertenece el campo
     *
     * @returns {mixed}             El valor establecido, o undefined la combinación <fieldId, listIndices> no existe
     *                              en los datos
     *
     * @see setFieldValue
     */
    setFieldDefaultValue(fieldId, listIndices = {}) {
        const fieldDefinition = this.crf.getField(fieldId);
        if (!fieldDefinition) {
            return;
        }

        const value = fieldDefinition.getDefaultValue();
        const result = this.setFieldValue(fieldId, value, listIndices);

        return typeof result === 'undefined' ? undefined : value;
    }

    /**
     *
     * @param {Number} fieldId     ID del campo
     * @param {Object} listIndices Índices de las listas a las que pertenece el campo
     *
     * @returns {mixed}            -
     */
    setFieldDescendantsDefaultValues(fieldId, listIndices = {}) {
        const fieldDefinition = this.crf.getField(fieldId);
        if (!fieldDefinition) {
            return;
        }

        return fieldDefinition.getDescendants().forEach(descendant => {
            return this.setFieldDefaultValue(descendant.getId(), listIndices);
        });
    }

    /**
     * Recopila todos los valores de índices de listas disponibles en la entidad para el elemento especificado
     *
     * @param {Number}  elementId El ID único del elemento en la estructura
     *
     * @return {ListIndices[]} Lista de listas de índices especificadas por {<id de lista>: <índice numérico>}
     */
    getElementAllListIndices(elementId) {
        const elementDefinition = this.crf.getElement(elementId);
        if (!elementDefinition) {
            return undefined;
        }

        const parentList = elementDefinition.getParentList();
        if (parentList === null) {
            // El elemento no pertenece a una lista
            return undefined;
        }

        return this.getAllListIndices(parentList.getId());
    }

    /**
     * Obtiene la lista completa de objetos ListIndices asociados a una lista, incluyendo los índices que hay dentro de
     * la propia lista
     *
     * @param  {Number}  listId ID único de la lista
     *
     * @return {ListIndices[]}  Índices calculados
     *
     * @example
     * // returns [{1: 1, 2: 1}, {1: 2, 2: 1}, {1: 2, 2: 2}]
     * const crf = new CRF({sections: [{
     *     id: 1,
     *     type: 'list',
     *     sections: [{
     *         id: 2,
     *         type: 'list',
     *     }],
     * }]});
     * const recordData = new RecordData(crf);
     * recordData.load({
     *     1: [{
     *         __id: 1,
     *         2: [{
     *             __id: 1,
     *         }],
     *     }, {
     *         __id: 2,
     *         2: [{
     *             __id: 1,
     *         }, {
     *             __id: 2,
     *         }],
     *     }],
     * });
     * recordData.getAllListIndices(listId)
     * entity.getElementAllListIndices(elementId);
     */
    getAllListIndices(listId) {
        const listDefinition = this.crf.getList(listId);
        if (!listDefinition) {
            return undefined;
        }

        const parentList = listDefinition.getParentList();
        // Aquí se prepara un contexto de ejecución a partir del padre
        // Si no hay lista padre será un objeto vacío al que añadir los índices propios
        const parentIndices = _.isNull(parentList) ? [{}] : this.getAllListIndices(parentList.getId());

        // Ahora se usa flatten porque cada elemento de parentList genera n nuevos, y los queremos al mismo nivel
        return _.flatten(_.map(parentIndices, currentIndices => {
            const itemsCount = this.getListLength(listId, currentIndices);

            return _.times(itemsCount, itemIndex => {
                const localIndices = JSON.parse(JSON.stringify(currentIndices));

                return _.set(localIndices, listId, itemIndex + 1);
            });
        }));
    }

    /**
     * Obtiene la lista de valores que tiene una variable en cada elemento de lista a los que pertenece. El resultado es
     * un array con tantos niveles como listas anidadas haya.
     * Si el campo no está definido en algún índice, para ese elemento vale undefined
     * @todo Ver bien cómo se puede hacer esto, se usa para hacer un watch de la lista completa de valores de un campo
     *       en reglas que involucran listas
     * @param  {string} fieldId ID del campo, único en la definición
     * @return {mixed[]}        Lista de valores
     */
    getFieldAllValues(fieldId) {
        const allIndices = this.getElementAllListIndices(fieldId);
        if (_.isEmpty(allIndices)) {
            return undefined;
        }

        const allValues = [];

        allIndices.forEach(listIndices => {
            const path = _.values(listIndices).map(listIndex => {
                return listIndex - 1;
            });

            _.set(allValues, path, this.getFieldValue(fieldId, listIndices));
        });

        return allValues;
    }

    /**
     * Obtiene la lista de tamaños (número de elementos) que tiene una lista en cada elemento de las listas a las que
     * pertenece. El resultado es un array con tantos niveles como listas anidadas haya.
     * Si la lista no está definida en algún índice, para ese elemento vale undefined
     *
     * @param  {Number}  listId ID único de la lista
     *
     * @return {integer[]}      Lista de propiedades "length"
     *
     * @todo Revisar la función porque no funciona si no hay listas padres, ver si se usa y dónde
    */
    getListAllLengths(listId) {
        const allIndices = this.getElementAllListIndices(listId);
        if (_.isEmpty(allIndices)) {
            return undefined;
        }

        const allLengths = [];

        allIndices.forEach(listIndices => {
            const path = _.values(listIndices).map(listIndex => {
                return listIndex - 1;
            });

            _.set(allLengths, path, this.getListLength(listId, listIndices));
        });

        return allIndices;
    }

    /**
     * Determina si una sección, formulario o lista existe en este registro.
     * Por ejemplo, si está contenida en una lista, verifica primero que existe el item de la lista.
     *
     * @param  {Number}  elementId    ID único del elemento en el CRF
     * @param  {ListIndices}  listIndices  Índices de las listas a las que pertenece el elemento
     *
     * @return {boolean}    TRUE si la sección existe
     */
    hasElement(elementId, listIndices) {
        return this.getElementPath(elementId, listIndices) !== null;
    }

    /**
     * Determina si un elemento del CRD:
     * 1 - Existe en los datos. Solo lo revisa en el caso de los ítems de listas, y se reduce a comprobar si en la
     *     lista que contiene el elemento, ese ítem todavía existe (no se ha eliminado). No mira realmente el propio
     *     valor de la variable.
     * 2 - Si está en listas, mira el path de listas ancestro de la variable, y comprueba contra el CRF actual que
     *     continúa siendo válido (detecta por ejemplo si el campo se ha movido a otra lista, o si la propia lista
     *     contendora se ha movido bajo otro elemento)
     *
     * @param  {string}  elementUid   UID del elemento en el CRF
     *
     * @return {boolean} TRUE si existe en los datos y es válido
     */
    isElementValid(elementUid) {
        const uid = Array.isArray(elementUid) ? elementUid.concat([]) : _.split(elementUid, '.');
        const listIndices = this.getListIndicesByUID(uid);
        const parentLists = [];

        return _.chunk(uid, 2).every(([elementId, itemId]) => {
            if (typeof itemId !== 'undefined') {
                const data = this.getList(elementId, listIndices, false); // false para que no cree el elemento
                parentLists.push(parseInt(elementId, 10));

                // la lista existe en los datos, y el item __id también existe en esa lista
                return data !== null && _.findIndex(data, { __id: parseInt(itemId, 10) }) !== -1;
            }

            const element = this.crf.getElement(elementId);

            return element !== null && _.isEqual(element.getParentLists().map(list => list.getId()), parentLists);
        });
    }
}

module.exports = Data;
