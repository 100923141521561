'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:rules:filter');

const { ENCRYPTED_VALUE, MISSING_VALUE } = require('../constants');

const RuleEvents = require('./Events');

/**
 * @memberOf Record.Rules
 */
class FilterListener {
    /**
     * Constructor, establece la referencia al objeto de ejecución de reglas e inicializa las propiedades de la clase
     *
     * @param  {Record.Rules} rules Instancia de reglas en ejecución
     */
    constructor(rules) {
        this.rules = rules;
        this.filtersData = {};
    }

    /**
     * Al iniciar la ejecución de reglas se limpia el objeto de información
     *
     * @param {exportedRule[]} rulesList Lista de reglas de la ejecución
     */
    onPreExecute(rulesList) {
        if (_.isEmpty(this.filtersData)) {
            // No hay nada que limpiar
            return;
        }

        const filterTargets = [];

        rulesList.forEach(exportedRule => {
            exportedRule.actions.forEach(exportedAction => {
                if (exportedAction.definition.type === 'filter' &&
                    filterTargets.indexOf(exportedAction.definition.target) === -1
                ) {
                    filterTargets.push(exportedAction.definition.target);
                }
            });
        });

        if (filterTargets.length === 0) {
            return;
        }

        this.filtersData = _.pickBy(this.filtersData, (value, key) => {
            const fieldId = parseInt(key.split('_').pop());

            return filterTargets.indexOf(fieldId) === -1;
        });
    }

    /**
     * Comprueba que hay datos de filtro para el campo especificado, creando datos iniciales si no existen
     *
     * @param  {Number}      fieldId     ID único numérico del campo
     * @param  {ListIndices} listIndices Índices de las listas que contienen el campo
     * @param  {boolean}     cleanUp     Si se deben limpiar los valores filtrados tras la ejecución
     *
     * @return {object}                  Referencia al objeto de información de filtro del campo
     */
    getFieldFilterData(fieldId, listIndices, cleanUp) {
        const key = this.rules.getRecord().getElementUID(fieldId, listIndices).join('_');

        if (this.filtersData[key] === undefined) {
            this.filtersData[key] = {
                filter: {
                    active: false,
                    options: [],
                },
                unfilter: {
                    active: false,
                    options: [],
                },
                listIndices: listIndices,
                cleanUp: cleanUp,
            };
        }

        return this.filtersData[key];
    }

    /**
     * Con cada aplicación de filtro de opciones se acumulan las opciones que habilita el filtro
     *
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {ListIndices}    listIndices    Índices de las listas en el contexto de la regla
     */
    onFilterOptions(exportedAction, listIndices) {
        const fieldId = exportedAction.definition.target;
        const availableOptions = exportedAction.definition.options;

        debug('filter field options: field %d %o, options %o', fieldId, listIndices, availableOptions);
        const filterData = this.getFieldFilterData(fieldId, listIndices, exportedAction.definition.cleanUp).filter;

        filterData.active = true;
        filterData.options = _.uniq(filterData.options.concat(availableOptions));
    }

    /**
     * Al desactivar un filtro se marca el campo en el objeto de datos para al menos tener la identificación, y al final
     * detectar todos los campos, se hayan filtrado o no
     *
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {ListIndices}    listIndices    Índices de las listas en el contexto de la regla
     */
    onUnfilterOptions(exportedAction, listIndices) {
        const fieldId = exportedAction.definition.target;
        const availableOptions = exportedAction.definition.options;

        const unfilterData = this.getFieldFilterData(fieldId, listIndices, exportedAction.definition.cleanUp).unfilter;

        unfilterData.active = true;
        unfilterData.options = _.uniq(unfilterData.options.concat(availableOptions));
    }

    /**
     * Al finalizar la ejecución se comprueban los datos de filtros y se establecen las opciones disponibles en los
     * campos que han sido afectados
     *
     * @param {RuleDefinition[]} rulesList    Lista de reglas ejecutadas
     * @param {Filters}          rulesFilters Filtros de ejecución de reglas
     */
    onPostExecute(rulesList, rulesFilters) {
        _.each(this.filtersData, (fieldResult, fieldKey) => {
            const fieldUid = fieldKey.split('_');
            const fieldId = _.last(fieldUid);

            debug('set availableOptions for field %s: %o', fieldKey, fieldResult);

            const field = this.rules.getRecord().getCRF().getField(fieldId);
            const optionsGroup = field.getOptions();

            if (!optionsGroup) {
                return;
            }

            let availableOptions = optionsGroup.getOptionList();
            // Si se ha ejecutado alguna regla de filtro se tienen en cuenta las opciones definidas en la regla
            if (fieldResult.filter.active) {
                availableOptions = availableOptions.filter(option => {
                    return fieldResult.filter.options.indexOf(option.value) > -1;
                });

                if (fieldResult.cleanUp) {
                    this.cleanUpField(fieldId, fieldResult, rulesFilters);
                }
            }

            this.rules.formFields.setAvailableOptions(fieldId, availableOptions, fieldResult.listIndices);
        });
    }

    /**
     * Limpia del valor del campo la opción u opciones que no está/n disponible/s
     *
     * @param {Number}  fieldId      ID del campo en la estructura
     * @param {object}  fieldResult  Información calculada relativa al filtro
     * @param {Filters} rulesFilters Filtros de ejecución de reglas, para ser trasladados a una nueva ejecución
     */
    cleanUpField(fieldId, fieldResult, rulesFilters) {
        const validOptions = fieldResult.filter.options;
        const fieldValue = this.rules.getRecord().getFieldValue(fieldId, fieldResult.listIndices);

        // GARU-7703 Special values should not be cleaned up
        if ([ENCRYPTED_VALUE, MISSING_VALUE].includes(fieldValue)) {
            return;
        }

        if (Array.isArray(fieldValue)) {
            const newValue = fieldValue.filter(option => validOptions.includes(option));

            if (newValue.length < fieldValue.length) {
                const setValueActionDefinition = {
                    definition: {
                        target: fieldId,
                        value: newValue,
                    },
                };

                this.rules.setValue(fieldResult.listIndices, setValueActionDefinition, rulesFilters);
            }
        } else if (validOptions.indexOf(fieldValue) === -1) {
            const clearValueActionDefinition = {
                definition: {
                    target: fieldId,
                },
            };

            this.rules.clearValue(fieldResult.listIndices, clearValueActionDefinition, rulesFilters);
        }
    }

    /**
     * Registro del listener
     *
     * @param  {Record.Rules}                rules Instancia de reglas en ejecución
     *
     * @return {Record.Rules.FilterListener}       El objeto registrado
     */
    static register(rules) {
        const listener = new FilterListener(rules);

        rules.addEventListener(RuleEvents.ON_PRE_EXECUTE, _.bind(listener.onPreExecute, listener));
        rules.addEventListener(RuleEvents.ON_FILTER_OPTIONS, _.bind(listener.onFilterOptions, listener));
        rules.addEventListener(RuleEvents.ON_UNFILTER_OPTIONS, _.bind(listener.onUnfilterOptions, listener));
        rules.addEventListener(RuleEvents.ON_POST_EXECUTE, _.bind(listener.onPostExecute, listener));

        return listener;
    }
}

module.exports = FilterListener;
