'use strict';

const conditions = require('./conditions');

const SIMPLE_DATETIME_RE = /^\d{4}-\d{2}-\d{2} \d{2}:(\d{2})(?::(\d{2}))?$/;

/**
 * @typedef {import('@inetsys/sharecrf-core').CRFField} CoreField
 */

/**
 * Takes the value of a field and returns a normalized value for the field type
 *
 * @param {CoreField} field Field instance to normalize the value for
 * @param {any}       value Value to normalize
 *
 * @returns {any} Normalized value
 */
function normalizeFieldValue(field, value) {
    let normalizedValue = value;

    // GARU-5333 Consideramos como valores booleanos solamente true y false, de este modo una regla que compruebe el
    // valor "desmarcado" sobre un campo sin inicializar (undefined) lo evaluará como cierto
    if (field.getFieldType() === 'boolean') {
        normalizedValue = !!value;
    }
    else if (
        // GARU-7349 Rules-wise all the datetime values include the seconds part
        field.getFormControl() === 'datetimepicker'
        && !conditions.empty(value)
        && typeof value === 'string'
    ) {
        const reResult = SIMPLE_DATETIME_RE.exec(value);

        if (reResult) {
            const minutes = reResult[1];
            const seconds = reResult[2];

            if (typeof minutes !== 'undefined' && typeof seconds === 'undefined') {
                normalizedValue = `${value}:00`;
            }
        }
    }

    return normalizedValue;
}

module.exports = { normalizeFieldValue };