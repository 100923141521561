'use strict';

const _ = require('lodash');

const Configuration = require('./Configuration');
const CRF = require('./Structure/CRF');
const Customization = require('./Customization');
const FieldOptions = require('./Structure/FieldOptions');
const Rules = require('./Structure/Rules/Rules');
const ElementsCache = require('./ElementsCache');

/**
 * Clase de gestión de un eCRD completo con instancias de todos sus componentes
 *
 * @property {Structure.CRF}            crf           Instancia del CRF
 * @property {Structure.FieldOptions[]} options       Instancias de los conjuntos de respuestas
 * @property {Structure.Rules}          rules         Instancia de las reglas
 * @property {Configuration}            configuration Instancia de la configuración
 * @property {Customization}            customization Instancia de la personalización
 */
class Ecrd {
    /**
     * Constructor de la clase: se encarga de instanciar todos los componentes del eCRD
     *
     * @param  {object} definition Objeto de definición del eCRD, típicamente el registro leído de la base de datos
     */
    constructor(definition) {
        _.defaults(definition, {
            options: [],
            structure: {},
            rules: [],
            configuration: {},
            customization: {},
            version: {},
            cache: {},
        });

        this.definition = JSON.parse(JSON.stringify(definition));

        this.instantiateComponents();
    }

    /**
     * Instancia los componentes que forman el eCRD
     */
    instantiateComponents() {
        this.options = this.definition.options.map(groupDefinition => {
            return new FieldOptions(groupDefinition);
        });
        this.crf = new CRF(this.definition.structure, this.options);
        this.rules = new Rules(this.crf, this.definition.rules);
        this.configuration = new Configuration(this.definition.configuration);
        this.customization = new Customization(this.definition.customization);

        this.cache = new ElementsCache(this.definition.cache, this.crf);

        this.crf.setEcrd(this);
    }

    /**
     * Obtiene el objeto de definición completo
     *
     * @return {object} El objeto de definición
     */
    get() {
        return this.definition;
    }

    /**
     * Obtiene el ID del registro de eCRD
     *
     * @return {Number}  ID numérico
     */
    getId() {
        return this.definition.id;
    }

    /**
     * Obtiene las versiones de los módulos implicados en la resolución del eCRD
     *
     * @return {Object} Diccionario de versiones de módulos
     */
    getVersion() {
        return this.definition.version;
    }

    /**
     * Obtiene el nombre asignado para la versión del eCRD
     *
     * @return {string} El nombre de la versión de eCRD
     */
    getName() {
        return this.definition.name;
    }

    /**
     * Obtiene la descripción de la versión del eCRD
     *
     * @return {string} Texto de descripción
     */
    getDescription() {
        return this.definition.description;
    }

    /**
     * Obtiene la instancia del CRF
     *
     * @return {Structure.CRF} El objeto de CRF
     */
    getCRF() {
        return this.crf;
    }

    /**
     * Obtiene las instancias de conjuntos de respuestas
     *
     * @return {Structure.FieldOptions[]} Los objetos de conjunto de respuestas
     */
    getOptions() {
        return this.options;
    }

    /**
     * Obtiene la instancia de las reglas
     *
     * @return {Structure.Rules} El objeto de reglas
     */
    getRules() {
        return this.rules;
    }

    /**
     * Obtiene la instancia de la configuración
     *
     * @return {Configuration} El objeto de configuración
     */
    getConfiguration() {
        return this.configuration;
    }

    /**
     * Obtiene la instancia de la personalización
     *
     * @return {Customization} El objeto de personalización
     */
    getCustomization() {
        return this.customization;
    }

    /**
     * Obtiene la instancia de caché de elementos
     *
     * @return {ElementsCache} Instancia de caché
     */
    getCache() {
        return this.cache;
    }

    /**
     * Obtiene un objeto de datos con la definición de cada componente que forma el eCRD
     *
     * @return {object} Objeto de definición completo
     */
    export() {
        return {
            options: this.options.map(group => group.get()),
            crf: this.crf.get(),
            rules: this.rules.get(),
            configuration: this.configuration.get(),
            customization: this.customization.get(),
            cache: this.getCache().get(),
            version: this.getVersion(),
        };
    }
}

module.exports = Ecrd;
