'use strict';

const ConfigurationModule = require('./Module');

/**
 * Mensajes predefinidos para crear o responder queries
 *
 * @typedef {object} QueryMessages
 *
 * @property {string[]} create Mensajes predefinidos para crear queries
 * @property {string[]} reply  Mensajes predefinidos para responder queries
 * @property {string[]} close  Mensajes predefinidos para cerrar queries
 */

/**
 * Módulo de configuración: Notas de discrepancia
 */
class QueriesConfiguration extends ConfigurationModule {
    /**
     * Obtiene la dirección de correo definida para recibir una copia de las queries generadas en el estudio
     *
     * @return {string|null} Dirección especificada, o null si no se ha definido o no se han activado las queries
     *
     * @example
     * // returns 'admin@email.com'
     * const configuration = new QueriesConfiguration({enabled: true, email: 'admin@email.com'});
     * configuration.getEmail();
     */
    getEmail() {
        if (!this.isEnabled()) {
            return null;
        }

        return this.definition.email || null;
    }

    /**
     * Obtiene los mensajes preconfigurados al crear, responder o cerrar una query
     *
     * @return {QueryMessages} Mensajes predefinidos
     *
     * @example
     * // returns {create: ['msg1'], reply: ['msg2'], close: ['msg3']}
     * const configuration = new QueriesConfiguration({enabled: true, fixedMessages: {
     *     create: ['msg1'],
     *     reply: ['msg2'],
     *     close: ['msg3'],
     * }});
     * configuration.getFixedMessages();
     */
    getFixedMessages() {
        if (!this.isEnabled() || !this.definition.fixedMessages) {
            return {};
        }

        return this.definition.fixedMessages;
    }

    /**
     * Determina si se debe mostrar el número de queries abiertas en el listado de pacientes
     * Dependiente de la definición de queries
     *
     * @return {boolean} Valor definido
     *
     * @example
     * // returns true
     * const configuration = new QueriesConfiguration({enabled: true, showInRecordsList: true});
     * configuration.mustShowInRecordsList();
     */
    mustShowInRecordsList() {
        return this.isEnabled() && !!this.definition.showInRecordsList;
    }
}

module.exports = QueriesConfiguration;
