'use strict';

/**
 * @namespace Record
 */
module.exports = Object.freeze({
    UNKNOWN_VALUE: '#UNKNOWN#',
    ENCRYPTED_VALUE: '#ENCRYPTED#',
    MISSING_VALUE: '#MISSING#',
});
