'use strict';

const CoreBase = require('../CoreBase');

/**
 * Módulo genérico de configuraciñón del CRD
 *
 * @property {object} definition Objeto de definición con, al menos, la clave "enabled"
 */
class ConfigurationModule extends CoreBase {
    /**
     * Constructor estático
     *
     * @param  {...any} args Argumentos de entrada
     *
     * @return {this}        Instancia creada
     */
    static instance(...args) {
        return new this(...args);
    }

    /**
     * Constructor de la clase, guarda el objeto interno de definición del módulo
     *
     * @param  {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super({
            enabled: false,
            ...definition,
        });
    }

    /**
     * Determina si el módulo está habilitado: está habilitado si tiene la propiedad "enabled" a TRUE
     *
     * @return {boolean} Si está habilitado
     */
    isEnabled() {
        return !!this.definition.enabled;
    }
}

module.exports = ConfigurationModule;
