'use strict';

const Action = require('./Action');

/**
 * Clase de gestión de una regla de tipo Activar elemento ePRO
 *
 * @extends {Action}
 *
 * @memberOf Structure
 */
class EnableEproAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.ENABLE_EPRO;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        action.func = 'enableEpro';

        return action;
    }
}

module.exports = EnableEproAction;
