'use strict';

const Expression = require('./Expression');

/**
 * Tipos de operación
 * @enum {string}
 * @readOnly
 */
const Operators = { //eslint-disable-line no-unused-vars
    ADD: 'add',
    SUBTRACT: 'sub',
};

/**
 * @typedef {object} Modifier
 *
 * @property {Operators}           operator Tipo de operación
 * @property {Criteria.Expression} amount   Expresión para resolver la magnitud de la operación
 */

/**
 * Clase de gestión de una expresión cuyo resultado se puede modificar para obtener un resultado más complejo
 *
 * @abstract
 *
 * @property {Modifier} modifier Definición del valor de modificación
 *
 * @memberOf Criteria
 */
class ModifiableExpression extends Expression {
    /**
     * Constructor de la clase: inicializa el modificador
     */
    constructor() {
        super();

        this.modifier = null;
    }

    /**
     * Establece un modificador que se aplicará al valor resuelto de la propiedad
     *
     * @param  {Operators}           operation Identificador de la operación: add/sub
     * @param  {Criteria.Expression} amount    Expresión para resolver la magnitud de la operación
     *
     * @return {Criteria.ModifiableExpression} El propio objeto de expresión para poder encadenar
     */
    modify(operation, amount) {
        this.modifier = {
            operation: operation,
            amount: amount,
        };

        return this;
    }

    /**
     * Obtiene el modificador sobre el valor resuelto del campo
     *
     * @return {object|null} Modificador definido, o nulo si no tiene
     *
     * @example
     * // returns {operation: 'add', amount: '[value:2]'}
     * const expr = new ModifiableExpression();
     * expr.modify('add', new ValueExpression(2)).getModifier();
     */
    getModifier() {
        return this.modifier;
    }

    /**
     * Obtiene una representación de cadena de la parte del modificador de la expresión
     *
     * @return {string} Representación del modificador
     * @example
     * // returns ''
     * const expr = new ModifiableExpression();
     * expr.toString();
     *
     * @example
     * // returns '-value:5'
     * const expr = new ModifiableExpression({modifier: {operation: 'sub', amount: 5}});
     * expr.toString();
     */
    toString() {
        if (!this.modifier) {
            return '';
        }

        const sign = this.modifier.operation === 'add' ? '+' : '-';

        return `${sign}${this.modifier.amount}`;
    }
}

module.exports = ModifiableExpression;
