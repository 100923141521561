'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedFilterAction
 *
 * @property {string}   func  Nombre de la función: filter
 * @property {Array}    args  Argumentos de entrada a la función:
 *                            - el ID del campo
 *                            - la lista de opciones disponibles
 * @property {string[]} scope Los ámbitos de ejecución de la acción
 */

/**
 * Clase de gestión de una regla de tipo Filtrar opciones de valor disponibles
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class FilterAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.FILTER;
        this.definition.options = definition.options || [];
    }

    /**
     * Includes option value
     *
     * @param  {String}  optionValue Option value
     *
     * @return {Boolean}             TRUE if option is included in this action
     */
    includesOptionValue(optionValue) {
        return this.definition.options.indexOf(optionValue) > -1;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @return {ExportedFilterAction} Objeto de exportación de la acción
     */
    export() {
        let action = super.export();
        action.func = 'filterOptions';

        return action;
    }
}

module.exports = FilterAction;
