'use strict';

const moment = require('moment');

const Field = require('../../../Structure/Field');
const FieldValidation = require('./Field');

/**
 * Validador de campos de tipo lista de ficheros
 *
 * @memberOf Record
 */
class FieldDateValidation extends FieldValidation {
    /**
     * Valida que el dato sea una fecha correcta especificada en el formato correcto
     *
     * @param  {string}  value Representación de la fecha
     *
     * @return {boolean}       Si el valor representa una fecha
     * @protected
     */
    static validateDateIsValid(value) {
        return value && (moment(`${value}`, Field.FieldFormats.DATE_ISO8601, true)).isValid();
    }
    /**
     * Valida que el dato sea una fecha correcta especificada en el formato correcto
     *
     * @param  {string}  value Representación de la fecha
     *
     * @return {boolean}       Si el valor representa una fecha
     * @protected
     */
    static validatePartialDateIsValid(value) {
        const dateComponents = `${value}`.split('-');
        if (dateComponents.length > 3) {
            return false;
        }

        const dateFormat = `${dateComponents[0] === 'x' ? '[x]' : 'YYYY'}-${dateComponents[1] === 'x' ? '[x]' : 'MM'}-${dateComponents[2] === 'x' ? '[x]' : 'DD'}`;

        return value && (moment(`${value}`, dateFormat, true)).isValid();
    }

    /**
     * Build moment object
     *
     * @param {String} value Date value with format 'YYYY-MM-DD'. Partial dates are allowed.
     *
     * @returns {Moment}     Moment instance
     *
     * @static
     * @private
     */
    static buildMomentObject(value) {
        let date;

        if (!value) {
            date = moment();
        } else if (value.indexOf('x') > -1) {
            const matches = /^(.+)-(.+)-(.+)$/.exec(`${value}`.trim());
            if (matches) {
                const internalFormat = ['YYYY', 'MM', 'DD']
                    .map((item, index) => matches[index + 1] === 'x' ? '[x]' : item)
                    .join('-');

                date = moment(value, internalFormat, true);
            } else {
                date = moment.invalid();
            }
        } else {
            date = moment(value, 'YYYY-MM-DD', true);
        }

        return date;
    }

    /**
     * Validate past date
     *
     * @param {String} value Field value
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintPastDate(value) {
        const date = FieldDateValidation.buildMomentObject(value);
        const today = moment().hour(0).minutes(0).seconds(0);

        return value && date.isValid() ? date.unix() < today.unix() : true;
    }
    /**
     * Validate future date
     *
     * @param {String} value Field value
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintFutureDate(value) {
        const date = FieldDateValidation.buildMomentObject(value);
        const today = moment().hour(23).minutes(59).seconds(59);

        return value && date.isValid() ? date.unix() > today.unix() : true;
    }
    /**
     * Validate date after
     *
     * Note: 'limit' date is invalid
     *
     * @param {String} value Field value
     * @param {String} limit Lower limit
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintDateAfter(value, limit) {
        const leftDateObject = FieldDateValidation.buildMomentObject(value);
        const rightDateObject = FieldDateValidation.buildMomentObject(limit);

        if (!value || !leftDateObject.isValid() || !limit || !rightDateObject.isValid()) {
            return true;
        }

        const leftDateComponents = value.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
        const rightDateComponents = limit.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

        if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
            if (leftDateComponents[0] > rightDateComponents[0]) {
                return true;
            }
            if (leftDateComponents[0] < rightDateComponents[0]) {
                return false;
            }

            if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
                if (leftDateComponents[1] > rightDateComponents[1]) {
                    return true;
                }
                if (leftDateComponents[1] < rightDateComponents[1]) {
                    return false;
                }

                if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                    if (leftDateComponents[2] > rightDateComponents[2]) {
                        return true;
                    }

                    return false;
                }
            }
        }

        return true;
    }
    /**
     * Validate date before
     *
     * Note: 'limit' date is valid
     *
     * @param {String} value Field value
     * @param {String} limit Upper limit
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintDateBefore(value, limit) {
        const leftDateObject = FieldDateValidation.buildMomentObject(value);
        const rightDateObject = FieldDateValidation.buildMomentObject(limit);

        if (!value || !leftDateObject.isValid() || !limit || !rightDateObject.isValid()) {
            return true;
        }

        const leftDateComponents = value.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
        const rightDateComponents = limit.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

        if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
            if (leftDateComponents[0] < rightDateComponents[0]) {
                return true;
            }
            if (leftDateComponents[0] > rightDateComponents[0]) {
                return false;
            }

            if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
                if (leftDateComponents[1] < rightDateComponents[1]) {
                    return true;
                }
                if (leftDateComponents[1] > rightDateComponents[1]) {
                    return false;
                }

                if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                    if (leftDateComponents[2] <= rightDateComponents[2]) { // "limit" date is valid
                        return true;
                    }

                    return false;
                }
            }
        }

        return true;
    }
    /**
     * Validate date from today
     *
     * @param {String} value Field value
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintFromToday(value) {
        const date = FieldDateValidation.buildMomentObject(value);
        const today = moment().hour(0).minutes(0).seconds(0);

        return value && date.isValid() ? date.unix() >= today.unix() : true;
    }
    /**
     * Validate date until today
     *
     * @param {String} value Field value
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintUntilToday(value) {
        const date = FieldDateValidation.buildMomentObject(value);
        const today = moment().format('YYYY-MM-DD');

        if (!value || !date.isValid()) {
            return true;
        }

        const leftDateComponents = value.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));
        const rightDateComponents = today.split('-', 3).map(part => part === 'x' ? 'x' : parseInt(part));

        if (leftDateComponents[0] !== 'x' && rightDateComponents[0] !== 'x') {
            if (leftDateComponents[0] < rightDateComponents[0]) {
                return true;
            }
            if (leftDateComponents[0] > rightDateComponents[0]) {
                return false;
            }

            if (leftDateComponents[1] !== 'x' && rightDateComponents[1] !== 'x') {
                if (leftDateComponents[1] < rightDateComponents[1]) {
                    return true;
                }
                if (leftDateComponents[1] > rightDateComponents[1]) {
                    return false;
                }

                if (leftDateComponents[2] !== 'x' && rightDateComponents[2] !== 'x') {
                    if (leftDateComponents[2] <= rightDateComponents[2]) {
                        return true;
                    }

                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Field allows partial date
     *
     * @returns {Boolean} TRUE if field allow partial dates
     */
    fieldAllowsPartialDate() {
        return this.field.getFormControl() === Field.FormControl.DATECOMPONENTS;
    }

    /**
     * @inheritDoc
     */
    _validateFieldType(fieldValue) {
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        return this.fieldAllowsPartialDate()
            ? FieldDateValidation.validatePartialDateIsValid(fieldValue)
            : FieldDateValidation.validateDateIsValid(fieldValue);
    }

    /**
     * @inheritDoc
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        let valid = false;

        switch (true) {
            case !this._validateFieldType(fieldValue):
                valid = true;
                break;

            // deprecated
            case constraintName === 'past-date':
                valid = FieldDateValidation.validateConstraintPastDate(fieldValue);
                break;

            // deprecated
            case constraintName === 'future-date':
                valid = FieldDateValidation.validateConstraintFutureDate(fieldValue);
                break;

            case constraintName === 'after':
                valid = FieldDateValidation.validateConstraintDateAfter(fieldValue, constraintDefinition);
                break;

            case constraintName === 'before':
                valid = FieldDateValidation.validateConstraintDateBefore(fieldValue, constraintDefinition);
                break;

            // deprecated
            case constraintName === 'from-today':
                valid = FieldDateValidation.validateConstraintFromToday(fieldValue);
                break;

            case constraintName === 'until-today':
                valid = FieldDateValidation.validateConstraintUntilToday(fieldValue);
                break;
        }

        return valid;
    }
}

module.exports = FieldDateValidation;
