'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:criteria:executor');

const Validation = require('../../Record/Validation');
const ConditionTypes = require('../ConditionTypes');

const conditions = require('./conditions');
const operator = require('./operator');

const { FieldType, FormControl } = require('../../Structure/Field');

const { normalizeFieldValue } = require('./normalize-value');

/**
 * Clase que se encarga de ejecutar unos criterios contra un objeto de datos de entidad
 *
 * @property {Record.Base}       recordBase Instancia del registro completo
 * @property {Record.Rules}      rules      Instancia de la ejecución de reglas
 * @property {Record.FormFields} formFields Instancia de flags de campos y secciones
 * @property {Record.Data}       record     Objeto de datos
 * @property {Record.Validation} validation Validador del registro
 *
 * @memberOf Criteria
 */
class CriteriaExecutor {
    /**
     * Instance
     *
     * @param {Record.Base} recordBase Instancia del registro completo
     *
     * @return {CriteriaExecutor}      Instancia
     *
     * @static
     */
    static instance(recordBase) {
        return new this(recordBase);
    }

    /**
     * Constructor de la clase: inicializa el objeto de datos
     *
     * @param {Record.Base} recordBase Instancia del registro completo
     */
    constructor(recordBase) {
        this.recordBase = recordBase;
        this.rules = recordBase.getRules();
        this.formFields = recordBase.getFormFields();
        this.record = recordBase.getData();
        this.validation = recordBase.getValidation();
    }

    /**
     * Obtiene el objeto validador de datos del cuaderno
     *
     * @return {Record.Validation} El validador de datos
     */
    getValidation() {
        if (_.isNil(this.validation)) {
            this.validation = new Validation(this.rules);
        }

        return this.validation;
    }

    /**
     * Evalúa un criterio contra el objeto de datos interno de la clase
     *
     * @param  {Criteria.Criteria} criteria Objeto Criteria
     * @param  {ListIndices}       context  Los índices del contexto de ejecución
     *
     * @return {boolean} Si cumple o no los criterios
     */
    execute(criteria, context) {
        return this.evaluateCriteria(criteria, context);
    }

    /**
     * Evalúa un criterio contra el objeto de datos interno de la clase, evaluando individualmente los elementos que lo
     * componen para obtener el resultado final
     *
     * @param  {Criteria.Criteria} criteria Objeto Criteria
     * @param  {ListIndices}       context  Los índices del contexto de ejecución
     *
     * @return {boolean} Si cumple o no los criterios
     *
     * @private
     */
    evaluateCriteria(criteria, context) {
        const lodashMethod = criteria.isTypeAnd() ? 'every' : 'some';

        return _[lodashMethod](criteria.getComparisons(), child => {
            if (child.getComparisons) {
                return this.evaluateCriteria(child, context);
            }

            return this.evaluateCondition(child, context);
        });
    }

    /**
     * Evalúa una condición individual a partir de su tipo y sus operandos
     *
     * @param  {Criteria.Condition} condition El objeto de condición
     * @param  {ListIndices}        context   Los índices del contexto de ejecución
     *
     * @return {boolean} Si se cumple o no la condición
     *
     * @private
     */
    evaluateCondition(condition, context) {
        const sidesDefinition = _.compact([
            condition.getLhs(),
            condition.getRhs(),
        ]);

        const sides = sidesDefinition.map(side => {
            const { mode, value, type } = this.evaluateExpression(side, context);

            return {
                valid: this.validateExpression(side, context, value),
                mode,
                value,
                type,
            };
        });

        // Esto estará definido en caso de que el lado izquierdo de la condición sea un campo del CRD. Si hubiera otro
        // campo en el lado derecho debe ser del mismo tipo.
        // Se utiliza para afinar las funciones de comparación, para diferenciar los casos específicos de cada tipo (de
        // momento solo sirve para los casos más complejos en las comparaciones de fechas con valores parciales)
        const valueType = Array.isArray(sides) && sides.length > 0 ? sides[0].type : null;

        const validExpressions = _.every(sides, 'valid');
        if (!validExpressions) {
            return false;
        }

        const sideValues = _.map(sides, 'value');
        const conditionsMethod = this.getConditionsMethod(condition.getType(), valueType);

        const result = conditions[conditionsMethod].apply(null, sideValues);
        debug('condición', conditionsMethod, sideValues, result);

        return result;
    }

    /**
     * Evalúa una expresión cualquiera que se encuentra dentro de una condición de comparación
     *
     * @param  {Criteria.Expression} expression El objeto de expresión
     * @param  {ListIndices}         context    Los índices del contexto de ejecución
     *
     * @return {mixed} El valor evaluado, según la expresión
     *
     * @private
     */
    evaluateExpression(expression, context) {
        const mode = expression.getType();
        const data = { mode };

        switch (mode) {
            case 'field':
                // eslint-disable-next-line no-case-declarations
                const { value, type } = this.evaluateField(expression, context);
                data.value = value;
                data.type = type;
                break;

            case 'value':
                data.value = expression.getValue();
                break;

            case 'count':
                data.value = this.evaluateListLength(expression, context);
                break;

            case 'index':
                data.value = this.evaluateListIndex(expression, context);
                break;

            case 'record':
                data.value = this._evaluateRecordData(expression);
                break;

            default:
                throw new Error(`Tipo de expresión desconocido: ${mode}`);
        }

        return data;
    }

    /**
     * Obtiene los índices numéricos válidos del contexto de ejecución según la expresión
     *
     * @param  {Number}      targetId       ID del elemento de destino, se necesita para resolver listas secuencialmente
     * @param  {ListIndices} listIndices    Índices configurados, con posibles expresiones "first", "current", "last"...
     * @param  {ListIndices} contextIndices Índices numéricos del contexto
     *
     * @return {ListIndices}                Índices numéricos
     *
     * @private
     *
     * @example
     * executor._getExecutionIndices()
     */
    _getExecutionIndices(targetId, listIndices, contextIndices) {
        const element = this.record.getCRF().getElement(targetId);
        if (!element) {
            return null;
        }

        const executionIndices = {};

        const validIndices = _.every(element.getParentLists(), list => {
            const listId = list.getId();
            // se evalúa el número de elementos con los índices ya resueltos
            const listLength = this.record.getListLength(listId, executionIndices);

            const currentIndex = contextIndices[listId];
            if (_.has(listIndices, listId)) {
                switch (listIndices[listId]) {
                    case 'first':
                        executionIndices[listId] = listLength > 0 ? 1 : null;
                        break;
                    case 'previous':
                        executionIndices[listId] = currentIndex > 1 ? currentIndex - 1 : null;
                        break;
                    case 'current':
                        executionIndices[listId] = currentIndex;
                        break;
                    case 'next':
                        executionIndices[listId] = listLength - currentIndex > 0 ? currentIndex + 1 : null;
                        break;
                    case 'last':
                        executionIndices[listId] = listLength > 0 ? listLength : null;
                        break;
                    default:
                        // Y si es un valor numérico se traslada tal y como está definido
                        executionIndices[listId] = listIndices[listId];
                }

            } else {
                // Si no se sobreescribe se usa el valor del contexto
                executionIndices[listId] = currentIndex;
            }

            return executionIndices[listId] !== null;
        });

        debug('execution indices: indices %o, context %o, result %o', listIndices, contextIndices, executionIndices);

        return validIndices ? executionIndices : null;
    }

    /**
     * Evalúa una expresión de tipo "campo del CRD"
     *
     * @param  {Criteria.FieldExpression} expression Objeto de expresión
     * @param  {ListIndices}              context    Los índices del contexto de ejecución
     *
     * @return {object}                              value: El valor actual del campo en el registro de datos
     *                                               type: El tipo interno del valor del campo
     *
     * @private
     */
    evaluateField(expression, context) {
        const data = {
            value: undefined,
        };

        const fieldId = expression.getFieldId();

        const executionIndices = this._getExecutionIndices(fieldId, expression.getListIndices(), context);
        if (executionIndices === null) {
            // GARU-4276 Si los índices no son correctos tiene sentido pensar que el valor no está definido
            return data;
        }

        const fieldInstance = this.record.getCRF().getField(fieldId);
        data.type = fieldInstance.getFieldType();
        if (data.type === FieldType.STRING) {
            const formControl = fieldInstance.getFormControl();
            if ([FormControl.DATEPICKER, FormControl.DATECOMPONENTS].includes(formControl)) {
                data.type = FieldType.DATE;
            }
            else if (formControl === FormControl.TIME) {
                data.type = FieldType.TIME;
            }
            else if (formControl === FormControl.DATETIMEPICKER) {
                data.type = FieldType.DATETIME;
            }
        }

        let fieldValue = normalizeFieldValue(fieldInstance, this.record.getFieldValue(fieldId, executionIndices));

        debug('campo', { id: fieldId, value: fieldValue, indices: executionIndices });

        const modifier = expression.getModifier();
        if (modifier) {
            fieldValue = this.applyModifier(modifier, fieldValue, fieldInstance);
        }

        data.value = fieldValue;

        return data;
    }

    /**
     * Evalúa una expresión de tipo "número de elementos de lista del CRD"
     *
     * @param  {Criteria.ListLengthExpression} expression Objeto de expresión
     * @param  {ListIndices}                   context    Los índices del contexto de ejecución
     *
     * @return {Number}  El valor actual de los elementos de la lista en el registro de datos
     *
     * @private
     */
    evaluateListLength(expression, context) {
        const listId = expression.getListId();

        const executionIndices = this._getExecutionIndices(listId, expression.getListIndices(), context);
        if (executionIndices === null) { // Índices no válidos siempre evalúa a falso
            return false;
        }

        const listLength = this.record.getListLength(listId, executionIndices);

        debug('lista', { id: listId, length: listLength, indices: executionIndices });

        const modifier = expression.getModifier();
        if (!modifier) {
            return listLength;
        }

        return this.applyModifier(modifier, listLength);
    }

    /**
     * Evalúa una expresión de tipo "Índice de la lista en el contexto actual"
     *
     * @param  {Criteria.ListIndexExpression} expression Objeto de expresión
     * @param  {ListIndices}                  context    Los índices del contexto de ejecución
     *
     * @return {Number}                       El índice de la lista especificada en el contexto, si existe
     *
     * @private
     */
    evaluateListIndex(expression, context) {
        const listId = expression.getListId();
        const indexInContext = _.get(context, listId);

        debug('índice', { id: listId, contexto: context });

        const modifier = expression.getModifier();
        if (!modifier) {
            return indexInContext;
        }

        return this.applyModifier(modifier, indexInContext);
    }

    /**
     * Evalúa una expresión de tipo "Valor de campo del registro"
     *
     * @param  {Criteria.RecordFieldExpression} expression Objeto de expresión
     *
     * @return {mixed}                                     El valor del campo en el registro
     *
     * @private
     */
    _evaluateRecordData(expression) {
        const field = expression.getField();

        if (field === 'randomization') {
            return this.recordBase.getRandomizationBranchId();
        }

        if (field === 'version') {
            // El identificador "version" quiere decir "Versión del eCRD con el que se creó el registro"
            return this.recordBase.getCreationEcrdId();
        }

        if (field === 'siteId') {
            return this.recordBase.getSiteId();
        }

        if (field === 'siteCode') {
            const site = this.recordBase.getSite();

            return site ? site.code : null;
        }

        debug('unknown record field, evaluating expression "%s" to null', expression);

        return null;
    }

    /**
     * Aplica un modificador a un valor previamente resuelto
     *
     * @param  {object}          modifier Definición del modificador
     * @param  {mixed}           value    Valor a modificar
     * @param  {Structure.Field} [field]  Campo en el CRF
     *
     * @return {mixed} Valor modificado
     *
     * @private
     */
    applyModifier(modifier, value, field = null) {
        debug('applyModifier', modifier, value);

        let operation;

        if (field && field.getFormControl() === 'datepicker') {
            operation = modifier.operation === 'add' ? 'addDays' : 'subtractDays';
        } else {
            operation = modifier.operation;
        }

        const { value: amount } = this.evaluateExpression(modifier.amount);

        return operator[operation](value, amount);
    }

    /**
     * Obtiene el nombre del método de @see Criteria.conditions que se va a usar para evaluar una condición individual
     *
     * @param  {string} type Tipo de comparación según la definición
     * @param  {string} fieldType Tipo de los campos implicados en la condición
     *
     * @return {string} Nombre del método
     *
     * @throws {Error} si no se reconoce el tipo de entrada
     *
     * @private
     */
    getConditionsMethod(type, fieldType) {
        const dateTypes = {
            [ConditionTypes.EQUALS]: 'dateEqual',
            [ConditionTypes.NOT_EQUALS]: 'dateNotEqual',
            [ConditionTypes.GREATER_THAN]: 'dateGreaterThan',
            [ConditionTypes.GREATER_THAN_OR_EQUALS]: 'dateGreaterThanOrEqual',
            [ConditionTypes.LESS_THAN]: 'dateLessThan',
            [ConditionTypes.LESS_THAN_OR_EQUALS]: 'dateLessThanOrEqual',
        };
        const types = {
            [ConditionTypes.CONTAINS]: 'contains',
            [ConditionTypes.EMPTY]: 'empty',
            [ConditionTypes.ENDS_WITH]: 'endsWith',
            [ConditionTypes.EQUALS]: 'equal',
            [ConditionTypes.GREATER_THAN]: 'greaterThan',
            [ConditionTypes.GREATER_THAN_OR_EQUALS]: 'greaterThanOrEqual',
            [ConditionTypes.LESS_THAN]: 'lessThan',
            [ConditionTypes.LESS_THAN_OR_EQUALS]: 'lessThanOrEqual',
            [ConditionTypes.NOT_CONTAINS]: 'notContains',
            [ConditionTypes.NOT_EMPTY]: 'notEmpty',
            [ConditionTypes.NOT_EQUALS]: 'notEqual',
            [ConditionTypes.STARTS_WITH]: 'startsWith',
            [ConditionTypes.IS_COMPLETE_DATE]: 'isCompleteDate',
            [ConditionTypes.IS_PARTIAL_DATE]: 'isPartialDate',
            [ConditionTypes.UNKNOWN]: 'isUnknown',
            [ConditionTypes.NOT_UNKNOWN]: 'isNotUnknown',
            // Tipo automático cuando se resuelve una condición con un campo inactivo
            [ConditionTypes.ALWAYS_FALSE]: 'returnFalse',
        };

        const method = fieldType === 'date'
            ? dateTypes[type] || types[type]
            : types[type];

        if (!method) {
            throw new Error(`Tipo de comparación desconocido: ${type}`);
        }

        return method;
    }

    /**
     * Valida el valor de una expresión con respecto al tipo esperado. Si la expresión es de valor del CRD, se mirará
     * que coincida con el tipo definido
     *
     * @param  {Criteria.Expression} expression Objeto de expresión evaluable
     * @param  {ListIndices}         context    Contexto actual de ejecución
     * @param  {mixed}               value      El valor que se quiere verificar
     *
     * @return {boolean}            Si el valor se considera válido
     */
    validateExpression(expression, context, value) {
        // si no se especifica lo contrario el valor es válido
        let isValid = true;

        const type = expression.getType();

        if (type === 'count') {
            // El número de elementos tiene que ser al menos 0: value ∈ ℕ ∪ {0}
            isValid = _.isInteger(value) && value >= 0;
        } else if (type === 'index') {
            // El índice de la lista tiene que ser un número natural : value ∈ ℕ
            isValid = _.isInteger(value) && value > 0;
        } else if (type === 'field') {
            const fieldId = expression.getFieldId();

            const executionIndices = this._getExecutionIndices(fieldId, expression.getListIndices(), context);
            isValid = this.getValidation().validateFieldType(fieldId, executionIndices);
        }

        if (!isValid) {
            debug('tipo de dato inválido', expression.toString(), value);
        }

        return isValid;
    }
}

module.exports = CriteriaExecutor;
