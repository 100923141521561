'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedSetValueAction
 *
 * @property {string} func Nombre de la función: setValue/setDefaultValue/setFormula
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - si el modo es valor por defecto / limpiar valor:
 *                             - el ID del campo
 *                         - si el modo es valor fijo:
 *                             - el ID del campo
 *                             - el valor fijo
 *                             - la propiedad extra "cleanup" si no se cumplen las condiciones
 *                         - si el modo es fórmula:
 *                             - el ID del campo
 *                             - la expresión de la fórmula a evaluar
 *                             - la propiedad extra "cleanup" si no se cumplen las condiciones
 */

const MODE = {
    DEFAULT: 'default',
    VALUE: 'value',
    FORMULA: 'formula',
    CLEAR: 'clear',
};

/**
 * Clase de gestión de una regla de tipo Establecer un valor en una variable
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class SetValueAction extends Action {
    /**
     * Defined modes
     *
     * @type {Object}
     */
    static get MODE() {
        return MODE;
    }

    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.SET_VALUE;
    }

    /**
     * Get mode
     *
     * @return {String} Mode
     */
    getMode() {
        return this.definition.mode || MODE.DEFAULT;
    }

    /**
     * Get value
     *
     * @return {mixed} Value
     */
    getValue() {
        return this.definition.value;
    }

    /**
     * Determina si el modo de valor es de fórmula
     *
     * @return {boolean} Si está definido como modo fórmula
     */
    hasFormula() {
        return this.getMode() === MODE.FORMULA;
    }

    /**
     * Determina si el elemento del CRF seleccionado participa en el resultado de la fórmula
     *
     * @param  {Number}  elementId ID único del elemento
     *
     * @return {boolean}           Si se incluye en la fórmula alguna expresión para recoger los datos del elemento
     */
    hasElementInFormula(elementId) {
        // La única opción posible actualmente es el valor de un campo
        // Si contiene field(elementId) o field(elementId, ...)
        const regex = new RegExp(`field\\(${elementId}(?:\\)|,)`);

        return regex.test(this.definition.value);
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @return {ExportedSetValueAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        switch (this.getMode()) {
            // TODO: GARU-5149 Una vez que se hayan migrado los cuadernos actuales no debe quedar ninguna regla con
            // esta acción y se puede eliminar el fragmento
            case MODE.DEFAULT:
                action.func = 'setDefaultValue';
                break;

            case MODE.CLEAR:
                action.func = 'clearValue';
                break;

            case MODE.VALUE:
                action.func = 'setValue';
                break;

            case MODE.FORMULA:
                action.func = 'setFormula';
                break;
        }

        return action;
    }
}

module.exports = SetValueAction;
