'use strict';

const FieldBaseValidator = require('./Field');

const FieldFileValidator = require('./File');
const FieldTimeValidation = require('./Time');
const FieldDateValidation = require('./Date');
const FieldNumberValidation = require('./Number');
const FieldDateTimeValidation = require('./DateTime');

const Field = require('../../../Structure/Field');

module.exports = {
    /**
     * Instancia un validador de campo según el tipo de dato
     *
     * @param  {Record.Rules}           recordRules   Instancia de ejecución de reglas con referencias a los datos del
     *                                                cuaderno
     * @param  {Number}                 fieldId       ID numérico del campo en el CRF
     * @param  {Configuration}          configuration Instancia de configuración del cuaderno
     * @param  {Action[]}                errorRulesActions Acciones de reglas de error
     *
     * @return {Record.FieldValidation}               Instancia de validador de campo
     */
    instance: (recordRules, fieldId, configuration, errorRulesActions) => {
        const field = recordRules.getRecord().getCRF().getField(fieldId);

        let classConstructor = FieldBaseValidator;
        if (field) {
            switch (field.getFormControl()) {
                case Field.FormControl.FILES:
                    classConstructor = FieldFileValidator;
                    break;

                case Field.FormControl.TIME:
                    classConstructor = FieldTimeValidation;
                    break;

                case Field.FormControl.DATEPICKER:
                case Field.FormControl.DATECOMPONENTS:
                    classConstructor = FieldDateValidation;
                    break;

                case Field.FormControl.DATETIMEPICKER:
                    classConstructor = FieldDateTimeValidation;
                    break;

                case Field.FormControl.VISUAL_ANALOGUE_SCALE:
                    classConstructor = FieldNumberValidation;
                    break;
            }
        }

        return classConstructor.instance(recordRules, field, configuration, errorRulesActions);
    },
};
