'use strict';

const _ = require('lodash');

const Condition = require('./Condition');

/**
 * Configuración de definición del criterio, con un formato conocido
 * @typedef {object} CriteriaConfig
 * @property {string}   operator Tipo de agrupación: and/or
 * @property {object[]} list     Lista de definición de los criterios anidados o condiciones que componen el criterio
 */

/**
 * Clase para albergar criterios para filtros o condiciones sobre los datos. Consta de una lista de condiciones que a su
 * vez pueden ser instancias anidadas de Criteria, agrupadas por criterio de cumplir todas (and) o cumplir alguna (or).
 * De uso en las condiciones de reglas automáticas
 *
 * @property {string}                                      type        Tipo de agrupación de las condiciones (and/or)
 * @property {Array<Criteria.Criteria|Criteria.Condition>} comparisons Lista de condiciones o criterios anidados
 *
 * @memberOf Criteria
 */
class Criteria {
    /**
     * Constructor de la clase, inicializa el tipo de objeto con una lista vacía de elementos
     *
     * @param {string} type Tipo de agrupación: and/or
     */
    constructor(type = 'and') {
        this.type = type;
        this.comparisons = [];
    }

    /**
     * Genera un objeto de Criteria a partir de una definición
     *
     * @param  {CriteriaConfig} config Configuración del criterio en un formato conocido
     *
     * @return {Criteria.Criteria} El criterio creado
     */
    static createFromConfig(config) {
        const criteria = new Criteria();

        criteria.setType(config.operator);
        config.list.forEach(definition => {
            if (!_.isUndefined(definition.list)) {
                criteria.addCriteria(Criteria.createFromConfig(definition));
            } else {
                criteria.addCondition(Condition.createFromConfig(definition));
            }
        });

        return criteria;
    }

    /**
     * Representación del criterio para exportar o con propósito de depuración
     *
     * @return {string} Representación del contenido del criterio
     *
     * @example
     * // returns 'criteria:or[condition:neq[field:233,value:1]]';
     * const criteria = new Criteria({
     *     list: [{
     *         lhs: { type: 'field', id: 233 },
     *         rhs: { type: 'value', value: 1 },
     *         comparison: 'neq',
     *     }],
     *     operator: 'or',
     * });
     * criteria.toString();
     */
    toString() {
        return `criteria:${this.type}[${this.comparisons.map(_.method('toString'))}]`;
    }

    /**
     * Establece el tipo de agrupación de las condiciones del criterio
     *
     * @param {string} type Tipo de agrupación: and/or
     *
     * @return {Criteria.Criteria} La instancia de Criteria
     *
     * @throws {Error} Si el identificador del tipo no está reconocido
     */
    setType(type) {
        const typeKey = _.toLower(type);
        if (['and', 'or'].indexOf(typeKey) === -1) {
            throw new Error(`Tipo de composición no conocido: ${typeKey}`);
        }

        this.type = typeKey;

        return this;
    }

    /**
     * Añade un criterio anidado a la lista de condiciones del criterio principal
     *
     * @param {Criteria.Criteria} criteria Criterio a añadir
     *
     * @return {Criteria.Criteria} El criterio modificado
     */
    addCriteria(criteria) {
        this.comparisons.push(criteria);

        return this;
    }

    /**
     * Añade una comparación a la lista de condiciones del criterio principal
     *
     * @param {Criteria.Condition} condition Condición a añadir
     *
     * @return {Criteria.Criteria} El criterio modificado
     */
    addCondition(condition) {
        this.comparisons.push(condition);

        return this;
    }

    /**
     * Determina si el modo de agrupación de las condiciones es "cumplir todas" (valor "and")
     *
     * @return {boolean} Si el valor de la definición es "and"
     */
    isTypeAnd() {
        return this.type === 'and';
    }

    /**
     * Determina si el modo de agrupación de las condiciones es "cumplir alguna" (valor "or")
     *
     * @return {boolean} Si el valor de la definición es "or"
     */
    isTypeOr() {
        return this.type === 'or';
    }

    /**
     * Obtiene la lista de condiciones o criterios que incluye el criterio
     *
     * @return {Array<Criteria.Criteria|Criteria.Condition>} Lista de comparaciones o criterios anidados
     */
    getComparisons() {
        return this.comparisons;
    }

    /**
     * Determina si alguna de las condiciones del criterio incluye una expresión de tipo Valor de campo para el campo
     * especificado
     *
     * @param  {Number}   fieldId ID único del campo en el CRD
     *
     * @return {boolean}          Si alguna de las condiciones incluye el valor del campo
     */
    hasField(fieldId) {
        return _.some(this.getComparisons(), _.method('hasField', fieldId));
    }

    /**
     * Determina si alguna de las condiciones del criterio incluye una expresión que afecta a la lista especificada
     *
     * @param  {Number}   listId ID único de la lista en el CRD
     *
     * @return {boolean}         Si alguna de las condiciones incluye la lista
     */
    hasList(listId) {
        return _.some(this.getComparisons(), _.method('hasList', listId));
    }
}

module.exports = Criteria;
