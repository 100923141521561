'use strict';

/**
 * Clase genérica de expresión dentro de una condición, de la que extienden las implementaciones concretas
 *
 * @memberOf Criteria
 */
class Expression {
    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @abstract
     *
     * @return {string} Tipo de expresión
     *
     * @throws {Error} si no se ha sobrecargado el método en la implementación final
     */
    getType() {
        throw new Error('Se debe sobrecargar el método getType()');
    }
}

module.exports = Expression;
