'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: variables encriptadas
 */
class EncryptedFieldsConfiguration extends ConfigurationModule {
}

module.exports = EncryptedFieldsConfiguration;
