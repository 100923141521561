'use strict';

const BlockRandomizationAction = require('./BlockRandomization');
const DisableAction = require('./Disable');
const ErrorAction = require('./Error');
const FilterAction = require('./Filter');
const HideAction = require('./Hide');
const HideChildrenAction = require('./HideChildren');
const MessageAction = require('./Message');
const QueryAction = require('./Query');
const SetValueAction = require('./SetValue');
const NotificationAction = require('./Notification');
const EnableEproAction = require('./EnableEpro');

/**
 * @constructs Structure.Action
 */
class ActionFactory {
    /**
     * A partir de un objeto de definición instancia la clase correspondiente para gestionar una acción
     *
     * @param  {object}           definition Definición de la acción
     *
     * @return {Structure.Action}            Acción creada
     *
     * @throws {Error} Si el identificador del tipo de acción no se reconoce
     */
    static instantiateAction(definition) {
        switch (definition.type) {
            case 'blockRandomization':
                return BlockRandomizationAction.instance(definition);
            case 'disable':
                return new DisableAction(definition);
            case 'error':
                return new ErrorAction(definition);
            case 'filter':
                return new FilterAction(definition);
            case 'hide':
                return new HideAction(definition);
            case 'hideChildren':
                return new HideChildrenAction(definition);
            case 'message':
                return new MessageAction(definition);
            case 'notification':
                return NotificationAction.instance(definition);
            case 'query':
                return new QueryAction(definition);
            case 'setValue':
                return new SetValueAction(definition);
            case 'enableEpro':
            default:
                return new EnableEproAction(definition);
        }

        throw new Error(`Tipo de acción de regla desconocido: "${definition.type}"`);
    }
}

module.exports = ActionFactory;
