'use strict';

const ModifiableExpression = require('./ModifiableExpression');

/**
 * Clase de gestión de una expresión para obtener el valor de una propiedad presente en el CRD
 *
 * @extends Criteria.Expression
 *
 * @property {object} listIndices Índices de las listas que pueden contener al objetivo de la expresión
 *
 * @abstract
 *
 * @memberOf Criteria
 */
class RecordDataExpression extends ModifiableExpression {
    /**
     * Constructor de la clase: inicializa la lista de índices de listas
     */
    constructor() {
        super();

        this.listIndices = {};
    }

    /**
     * Añade un índice de lista necesario para recoger el valor de una propiedad que pertenece a dicha lista
     *
     * @param  {string}  listName  Nombre único de la lista según está definido en el CRF
     * @param  {Number}  listIndex Índice de la lista en la estructura de datos (empezando por 0)
     *
     * @return {Criteria.RecordDataExpression} El propio objeto de expresión para poder encadenar
     */
    list(listName, listIndex) {
        this.listIndices[listName] = listIndex;

        return this;
    }

    /**
     * Obtiene la lista de índices que incluyen al campo en la estructura de datos
     *
     * @return {object|null} Objeto de índices de listas, o nulo si no hay
     *
     * @example
     * // returns {list1: 1, list2: 2}
     * const expr = new RecordDataExpression();
     * expr.list('list1', 1).list('list2', 2).getListIndices();
     */
    getListIndices() {
        return this.listIndices;
    }
}

module.exports = RecordDataExpression;
