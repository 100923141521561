'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedHideAction
 *
 * @property {string} func Nombre de la función: hideField/hideSection
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - el ID del campo
 */

/**
 * Clase de gestión de una regla de tipo Ocultar elemento
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class HideAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.HIDE;
    }

    /**
     * Should reset value on hiding
     *
     * @return {Boolean} TRUE if field value should be reset when field is hidden
     */
    shouldResetValueOnHiding() {
        return this.getTarget() && !!this.definition.resetValue;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedHideAction} Objeto de exportación de la acción
     */
    export() {
        let action = super.export();
        action.func = 'hideElement';

        return action;
    }
}

module.exports = HideAction;
