'use strict';

const _ = require('lodash');

const SectionsContainer = require('./SectionsContainer');
const List = require('./List');
const Section = require('./Section');
const Form = require('./Form');
const Field = require('./Field');

const debug = require('debug')('sharecrf:core');

/**
 * Información de índices de un item concreto dentro de una lista
 *
 * Cuando nos queremos referir a una variable que está dentro de una lista, además del propio ID de la variable,
 * debemos pasar información acerca de cada una de las listas anidadas donde está contenida, y para cada una de ellas,
 * el índice que ocupa el item
 *
 * Por ejemplo, si estamos en una variable dentro de la lista Tratamientos, que a su vez está dentro de la lista
 * Visitas, la manera de indicar los índices es un objeto con cada clave igual al ID de la lista a la que nos
 * referimos, y por cada uno el índice (comenzando por 1) que ocupa en la lista 'visitas' y en 'tratamiento'
 *
 * @example
 * {
 *    4: 1,
 *    7: 2,
 * }
 *
 * @typedef {object} ListIndices
 */

/**
 * Información de índices de un item concreto dentro de una lista. Contiene la misma información que un objeto
 * ListIndices, pero indexando por el nombre de la lista en el CRF en lugar de por el ID
 *
 * @example
 * {
 *    'visitas': 1,
 *    'tratamientos': 2,
 * }
 *
 * @typedef {object} NamedListIndices
 */

/**
 * Propiedades configurables que afectan al funcionamiento del CRF
 *
 * @typedef {object} CRFOptions
 *
 * @property {boolean} cache Si se habilita, usa una caché interna que almacena los objetos contenedores que se han
 *                           solicitado para tener un acceso instantáneo en sucesivas consultas, ya que la operación de
 *                           recorrido del árbol es bastante costosa
 */

/**
 * Objeto que contiene la estructura del CRF y expone la API del objeto global de entidad CRF
 * @extends Structure.SectionsContainer
 * @memberOf Structure
 */
class CRF extends SectionsContainer {
    /**
     * Constructor de la clase: a partir de un objeto Javascript instancia el CRF con su lista de secciones
     * @param {object}     jsonDefinition Definición del CRF
     * @param {CRFOptions} options        Opciones de funcionamiento del CRF
     */
    constructor(jsonDefinition = {}, options = {}) {
        _.merge(jsonDefinition, {
            creationFields: [],
            listFields: [],
        });

        _.defaults(options, {
            cache: true,
        });
        super(jsonDefinition);

        this.options = options;

        this.setChildren(this.instantiateContainers(this.definition.sections));

        this.creationFields = null;
        this.creationForm = null;
        this.listFields = null;

        // Contador interno de IDs de elementos
        this.idCounter = null;

        this.ecrd = null;
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['creationFields', 'listFields']);
    }

    /**
     * Establece el elemento padre de tipo eCRD que contiene este CRF
     *
     * @param {Ecrd} ecrd Instancia del eCRD
     *
     * @return {CRF} El CRF actualizado
     */
    setEcrd(ecrd) {
        this.ecrd = ecrd;

        return this;
    }

    /**
     * Obtiene la referencia al objeto contenedor de eCRD
     *
     * @return {Ecrd} El eCRD
     */
    getEcrd() {
        return this.ecrd;
    }

    /**
     * @inheritDoc
     */
    isRoot() {
        return true;
    }

    /**
     * Inicializa el contador interno de IDs para las siguientes operaciones
     *
     * @param  {Number}  initialValue Valor del cual partir
     */
    initIdCounter(initialValue) {
        this.idCounter = initialValue;
    }

    /**
     * Obtiene el ID más alto de todos los elementos que pertenecen al CRF
     *
     * @return {Number}  Valor del ID más alto
     */
    getMaxElementId() {
        const elementIds = _(this.flattenSections()).concat(this.getFields()).map(element => element.getId()).value();

        return _.max(elementIds);
    }

    /**
     * A partir de una lista de definiciones instancia los objetos contenedores correspondientes a cada una de ellas
     * Aplica recursividad para instanciar las secciones internas
     * Los contenedores instanciados acaban en la lista de hijos del contenedor que llama a la función
     * @param  {object[]} definitionsList Lista de objetos de definición de contenedores
     * @return {Container[]}              Lista de contenedores instanciados
     */
    instantiateContainers(definitionsList) {
        let self = this;

        return _.map(definitionsList, function instantiateElement(elementDefinition) {
            let element;

            switch (elementDefinition.type) {
                case 'list':
                    element = self.instantiateList(elementDefinition);
                    break;

                case 'section':
                    element = self.instantiateSection(elementDefinition);
                    break;

                case 'form':
                    element = self.instantiateForm(elementDefinition);
                    break;

                default:
                    throw new Error(`Tipo de contenedor no reconocido: "${elementDefinition.type}"`);
            }

            if (self.getOption('cache')) {
                self.elementsById[element.getId()] = element;
                self.elementsByName[element.getName()] = element; // BUG
            }

            return element;
        });
    }

    /**
     * Instancia un contenedor de tipo sección a partir de un objeto de definición
     *
     * @param  {object} sectionDefinition Definición de la sección
     *
     * @return {Structure.Section}        Sección instanciada
     */
    instantiateSection(sectionDefinition) {
        const section = new Section(sectionDefinition);
        section.setChildren(this.instantiateContainers(sectionDefinition.sections));

        return section;
    }

    /**
     * Instancia un contenedor de tipo lista a partir de un objeto de definición
     *
     * @param  {object} listDefinition Definición de la lista
     *
     * @return {Structure.List}        Lista instanciada
     */
    instantiateList(listDefinition) {
        const list = new List(listDefinition);
        list.setChildren(this.instantiateContainers(listDefinition.sections));

        return list;
    }

    /**
     * Instancia un contenedor de tipo formulario a partir de un objeto de definición
     *
     * @param  {object} formDefinition Definición del formulario
     *
     * @return {Structure.Form}        Formulario instanciado
     */
    instantiateForm(formDefinition) {
        const form = new Form(formDefinition);

        form.setChildren(this.instantiateFields(form, formDefinition.fields));

        return form;
    }

    /**
     * Instancia la lista de campos de un formulario a partir de su definición, aplicando recursividad para las
     * subvariables propias de cada uno de los campos
     *
     * @param  {Structure.FieldsContainer} parentElement Formulario o campo que actúa de padre de los campos
     * @param  {object[]}                 fieldsList     Lista de definiciones de campo
     *
     * @return {Field[]}                                 Lista de objetos de tipo campo
     *
     * @private
     */
    instantiateFields(parentElement, fieldsList) {
        return _.map(fieldsList, fieldDefinition => {
            return this.instantiateField(parentElement, fieldDefinition);
        });
    }

    /**
     * Instancia un campo a partir de su definición
     *
     * @param  {Structure.FieldsContainer} parentElement   Formulario o campo que actúa de padre del campo
     * @param  {object}                    fieldDefinition Objeto de definición
     *
     * @return {Structure.Field}                           Instancia de Field creada
     *
     * @private
     */
    instantiateField(parentElement, fieldDefinition) {
        const field = new Field(fieldDefinition);
        field.setChildren(this.instantiateFields(field, fieldDefinition.fields));

        if (field.getFormControl() === 'grid') {
            field.setCells(fieldDefinition.cells.map(row => {
                return row.map(cell => {
                    if (!cell) {
                        return null;
                    }

                    const cellField = this.instantiateField(parentElement, cell);
                    cellField.setGrid(field).setParent(parentElement);

                    return cellField;
                });
            }));
        }

        if (this.getOption('cache')) {
            this.elementsById[field.getId()] = field;
            this.elementsByName[field.getName()] = field;
        }

        return field;
    }

    /**
     * Obtiene el valor de la ruta para el elemento raíz del CRF
     * @return {Array} Lista vacía, ya que no tiene nombre ni padre
     */
    getPath() {
        return [];
    }

    /**
     * @inheritdoc
     */
    getFullLabel() {
        return [];
    }

    /**
     * Obtiene el valor de la ruta desde la lista más próxima
     * @return {Array} Lista vacía, ya que no tiene nombre ni padre
     */
    getPathFromList() {
        return [];
    }

    /**
     * Obtiene la lista de campos que se incluyen en el formulario de creación
     *
     * @param {boolean} reload Flag para recargar los datos
     *
     * @return {Structure.Field[]} Instancias de campo a partir de los IDs almacenados
     */
    getCreationFields(reload = false) {
        if (reload || this.creationFields === null) {
            const fieldsIds = _.get(this.definition, 'creationFields', []);

            this.creationFields = _.reduce(fieldsIds, (currentList, fieldId) => {
                const field = this.getField(fieldId);
                if (field && field.getParentList() === null) {
                    currentList.push(field);
                }

                return currentList;
            }, []);
        }

        return this.creationFields;
    }

    /**
     * Obtiene la lista de campos que se incluyen en el listado general de registros
     *
     * @param {boolean} reload Flag para recargar los datos
     *
     * @return {Structure.Field[]} Instancias de campo
     */
    getListableFields(reload = false) {
        if (reload || this.listFields === null) {
            const fieldsIds = _.get(this.definition, 'listFields', []);

            this.listFields = _.reduce(fieldsIds, (currentList, fieldId) => {
                const field = this.getField(fieldId);
                if (field && field.getParentList() === null) {
                    currentList.push(field);
                }

                return currentList;
            }, []);
        }

        return this.listFields;
    }

    /**
     * Devuelve la lista de campos de primer nivel, es decir, los que no están incluidos en ninguna lista
     *
     * @return {Structure.Field[]} Lista de objetos de campo
     */
    getRootFields() {
        const rootForms = this.getRootForms();

        return _.flatten(rootForms.map(form => form.getFields()));
    }

    /**
     * Devuelve la lista de formularios de primer nivel, es decir, los que no están incluidos en ninguna lista
     *
     * @return {Structure.Form[]} Lista de objetos de formulario
     */
    getRootForms() {
        return this.getForms().filter(form => form.getParentList() === null);
    }

    /**
     * Devuelve la lista de secciones de primer nivel, es decir, las que no están incluidas en ninguna lista
     *
     * @return {Structure.Section[]} Lista de objetos de sección
     */
    getRootSections() {
        return this.getSections().filter(section => {
            return section.getParentList() === null;
        });
    }

    /**
     * Devuelve la lista de listas de primer nivel, es decir, las que no están incluidas en ninguna lista
     *
     * @return {Structure.List[]} Lista de objetos de lista
     */
    getRootLists() {
        return this.getLists().filter(list => {
            return list.getParentList() === null;
        });
    }

    /**
     * Obtiene la referencia al objeto global de CRF
     *
     * @return {Structure.CRF} El propio objeto de CRF
     */
    getCRF() {
        return this;
    }

    /**
     * Devuelve un objeto ListIndices a partir de los datos especificados, un objeto en el que cada clave es el
     * ID de la lista en el CRF, y el valor el índice. Principalmente, está diseñado para traducir la información desde
     * $stateParams de AngularJS al objeto ListIndices que se necesita para gestionar los datos
     *
     * Los índices en $stateParams empiezan en 0, y en el Core los listIndices empiezan en 1
     *
     * @param  {object} stateParams Objeto $stateParams de AngularJS
     *
     * @return {ListIndices}        Objeto con los ID de las listas y su índice correspondiente
     *
     * @todo  Validar la existencia de cada componente de stateParams al indexar result
     */
    getListIndices(stateParams) {
        return _.transform(stateParams, (result, index, key) => {
            // si se corresponde con una lista del CRF
            let list = this.getListByName(key);
            if (list !== null) {
                result[list.getId()] = parseInt(index, 10) + 1;
            }
        }, {});
    }

    /**
     * Operación contraria a getListIndices, a partir de los índices de las listas indexados por el ID de cada una,
     * devuelve un objeto NamedListIndices, un objeto en el que cada clave es el nombre de la lista en el CRF, y el
     * valor el índice (empezando en 0). Principalmente, está diseñado para transformar de nuevo un objeto ListIndices
     * a un objeto $stateParams de AngularJS
     *
     * @param   {ListIndices}      listIndices Objeto con los nombres de las listas y su índice correspondiente
     *
     * @return  {NamedListIndices} stateParams Objeto $stateParams de AngularJS
     */
    getNamedListIndices(listIndices) {
        return _.transform(listIndices, (result, index, key) => {
            result[this.getList(key).getName()] = index - 1;
        }, {});
    }

    /**
     * Devuelve el array de listas e índices en un mapa recorrible que garantiza que al hacer un each,
     * irá por orden de cada lista, primero la lista padre y luego la anidada.
     *
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {object[]}                Lista de objetos {id, index} recorrible con orden garantizado
     */
    getOrderedLists(listIndices = {}) {
        const listIds = Object.keys(listIndices).map(Number);
        const orderedLists = [];

        const maxRetries = 20;
        let retry = 0;

        while (listIds.length > 0 && retry < maxRetries) {
            retry++;

            const sampleId = listIds.splice(0, 1)[0];
            const list = this.getList(sampleId);

            if (!list) {
                continue;
            }

            const parentLists = list.getParentLists();
            const parentsInIndices = _.some(parentLists, parent => {
                return listIds.indexOf(parent.getId()) > -1;
            });

            if (parentsInIndices) {
                // si alguno de los padres sigue en la lista quiere decir que va antes al ordenar, se vuelve a
                // introducir el ID actual para ser evaluado al final, después de los padres
                listIds.push(sampleId);
            } else {
                orderedLists.push({
                    id: sampleId,
                    index: listIndices[sampleId],
                });
            }
        }

        // Esto sólo pasaría si una lista es padre de la otra y a la vez la otra padre de la una, jamás debería entrar
        if (retry === maxRetries) {
            throw new Error('locked lists');
        }

        return orderedLists;
    }

    /**
     * Devuelve el ID del elemento que corresponde con el UID que se le pasa. Normalmente será el último
     * componente del UID (los anteriores serán los items de las listas a las que pertenecen)
     *
     * Hay un caso especial que es el UID de un item de una lista. Entonces el número de componentes del UID es par,
     * y los dos últimos son ['ID_LISTA', index]
     *
     * @param  {array|string|integer} uid  UID de elemento
     *
     * @return {Number}      ID de elemento que corresponde a ese UID
     */
    getElementIdByUID(uid) {
        if (!_.isArray(uid)) {
            uid = _.split(uid, '.');
        }
        let elementId;
        if (uid.length == 0) {
            return null;
        } else if (uid.length % 2 == 0) {
            // si el nº de componentes es par, el elemento es el penúltimo
            elementId = uid[uid.length - 2];
        } else {
            elementId = uid[uid.length - 1];
        }

        return elementId;
    }

    /**
     * Devuelve el elemento que corresponde con el UID en los datos que se le pasan. Normalmente será el último
     * componente del UID (los anteriores serán los items de las listas a las que pertenecen)
     *
     * Hay un caso especial que es el UID de un item de una lista. Entonces el número de componentes del UID es par,
     * y los dos últimos son ['ID_LISTA', index]
     *
     * @param  {array|string|integer} uid  UID de elemento
     *
     * @return {Container}     Instancia del elemento en el CRF
     */
    getElementByUID(uid) {
        const elementId = this.getElementIdByUID(uid);

        return this.getElement(elementId);
    }

    /**
     * Incrementa el contador interno de IDs de asignación a elementos
     *
     * @return {Number}  El nuevo valor del contador
     */
    incrementIdCounter() {
        if (_.isNull(this.idCounter)) {
            throw new Error('Se debe proporcionar un valor inicial al contador de IDs');
        }

        return ++this.idCounter;
    }

    /**
     * Devuelve el path teórico en los datos que llevaría una variable especificada con el path completo que se le pasa,
     * que incluye las secciones y formularios donde está incluida. Por ejemplo, como vienen en las llamadas de la API,
     * para referirse a una variable.
     *
     * Útil para cosas como un deflatten de los datos o metadatos. Al estar aquí en la estructura, no comprueba
     * en ningún caso si el ítem de una lista, por ejemplo, existe en los datos. Simplemente lo tomará como un
     * índice en los datos (que empiezan en 1) y lo convertirá a un índice puro de array
     *
     * El path es de tipo lodash, en formato de string, y las listas se indexan entre corchetes. Se hace así para que
     * no sea ambiguo, ya que sin más información, p.ej. "data.8.1", ese 1 no se sabe si es un índice del array,
     * o el ID del siguiente hijo
     *
     * @example
     * ['screening', 'visitas', 1, 'datos_visita', 'fecha_visita']
     * a
     * "11[0].12"
     *
     * @param  {string|array} elementFullPath Path completo como array, o string separado por '.' o '/'
     *
     * @return {string}             Path de tipo lodash unívoco
     *
     * @todo  Determinar muy bien el itemindex, si empieza en 1 o en 0 cuando se pone en el $filter del oData
     */
    getElementFlattenedPath(elementFullPath) {
        const flattenedPath = [];

        const components = Array.isArray(elementFullPath) ? [].concat(elementFullPath) : elementFullPath.split(/[./]/);

        let currentElement = this;
        do {
            const currentComponent = components.shift();

            const elementChildren = currentElement.isForm() ? currentElement.getFields() : currentElement.getChildren();
            const childElementFound = elementChildren.find(child => child.getName() === currentComponent);

            if (childElementFound) {
                if (childElementFound.isList()) {
                    const itemIndex = components.shift();
                    flattenedPath.push(`${childElementFound.getId()}[${itemIndex - 1}]`);
                }
                else if (childElementFound.isField()) {
                    flattenedPath.push(`${childElementFound.getId()}`);
                }

                currentElement = childElementFound;
            }
            else {
                // Error: element not found
                break;
            }
        }
        while (components.length);

        return flattenedPath.join('.');
    }

    /**
     * Obtiene el valor de la opción de configuración especificada
     *
     * @param  {string} key Clave de opción
     *
     * @return {mixed}      El valor definido, undefined si no existe
     */
    getOption(key) {
        return _.get(this.options, key);
    }

    /**
     * @inheritDoc
     */
    getRules() {
        return this.getEcrd().getRules().rules.filter(rule => rule.hasActionTarget(0));
    }

    /**
     * Get scheduled vistis
     *
     * @return {Array} List of visits
     */
    getScheduledVisits() {
        return this.getSections().filter(section => section.isScheduledVisit());
    }

    /**
     * Get visit
     *
     * @param {number} id Section ID
     *
     * @return {Section} Section
     */
    getScheduledVisit(id) {
        const element = this.getElement(id);

        return element && element.isSection() && element.isScheduledVisit()
            ? element
            : null;
    }

    /**
     * Get visit notification
     *
     * @param {number} id Notification ID
     *
     * @returns {object}  Notification
     */
    getScheduledVisitNotification(id) {
        const visits = this.getScheduledVisits();

        let notification = null;
        for (let index = 0; index < visits.length; index++) {
            const visit = visits[index];

            const foundNotification = visit.getVisitNotification(id);
            if (foundNotification) {
                notification = {
                    ...foundNotification,
                    sectionId: visit.getId(),
                };
                break;
            }
        }

        return notification;
    }
}

module.exports = CRF;
