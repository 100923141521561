'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: Documentos
 */
class DocumentsConfiguration extends ConfigurationModule {

}

module.exports = DocumentsConfiguration;
