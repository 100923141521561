'use strict';

const FieldValidation = require('./Field');

/**
 * Validador de campos de tipo lista de ficheros
 *
 * @memberOf Record
 */
class FieldFileValidation extends FieldValidation {
    /**
     * @inheritDoc
     */
    _validateFieldType(fieldValue) {
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        return Array.isArray(fieldValue) && fieldValue.every(fileInfo => {
            // Que sea un objeto completo de datos, con todas las propiedades requeridas
            return ['id', 'mimetype', 'filename', 'filesize'].every(key => key in fileInfo);
        });
    }

    /**
     * @inheritDoc
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        if (!Array.isArray(fieldValue) || !this._validateFieldType(fieldValue)) {
            return true;
        }

        // Aquí ya sabemos que el valor es de array y todos sus elementos contienen las claves necesarias

        if (constraintName === 'maxFiles') {
            return fieldValue.length <= constraintDefinition;
        }

        if (constraintName === 'maxSize') {
            return fieldValue.every(fileInfo => {
                return typeof fileInfo.filesize === 'number' && fileInfo.filesize <= constraintDefinition;
            });
        }

        // Clave desconocida
        return false;
    }
}

module.exports = FieldFileValidation;
