'use strict';

const Action = require('./Action');

/**
 * Regla de tipo Envío de notificación
 */
class NotificationAction extends Action {
    /**
     * Constructor estático
     *
     * @param  {...object}          args Argumentos del constructor
     *
     * @return {NotificationAction}      Instancia creada
     */
    static instance(...args) {
        return new this(...args);
    }

    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.NOTIFICATION;
    }

    /**
     * Obtiene los canales destino de la notificación
     *
     * @return {string[]} Lista de canales
     */
    getChannels() {
        return this.definition.channels || [];
    }

    /**
     * Obtiene el texto con el título de la notificación
     *
     * @return {string} El título definido
     */
    getSubject() {
        return this.definition.subject || '';
    }

    /**
     * Obtiene el texto con el cuerpo del mensaje de la notificación
     *
     * @return {string} El cuerpo definido
     */
    getBody() {
        return this.definition.body || '';
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        action.func = 'sendNotification';

        return action;
    }
}

module.exports = NotificationAction;
