'use strict';

const _ = require('lodash');

const RecordData = require('./Data');
const RecordMetadata = require('./Metadata');
const FormFields = require('./FormFields');
const RecordRules = require('./Rules');
const RecordValidation = require('./Validation');

/**
 * Objeto de gestión de los datos básicos de un registro (UID, creador, sitio, etc.)
 *
 * @property {RecordData}     _data       Datos del registro (valores de las variables)
 * @property {RecordMetadata} _metadata   Metadatos del registro (gestión de estados)
 * @property {FormFields}     _formFields Objeto de flags de elementos del registro
 * @property {RecordRules}    _rules      Objeto de ejecución de reglas
 * @property {RecordValidation} _validation Objeto de validación de los datos del registro
 *
 * @memberOf Record
 */
class Base {
    /**
     * Instance
     *
     * @param {Ecrd}   ecrd   Instancia del eCRD completo
     * @param {object} dbData Datos de la fila en BD del registro
     *
     * @return {Base}         Objeto de datos junto con los componentes internos
     *
     * @static
     */
    static instance(ecrd, dbData) {
        const recordBase = new this(ecrd);
        dbData && recordBase.load(dbData);

        return recordBase;
    }

    /**
     * Constructor, inicializa el objeto de datos junto con los componentes internos
     *
     * @param {Ecrd} ecrd Instancia del eCRD completo
     */
    constructor(ecrd) {
        this.baseData = {};

        this._data = new RecordData(ecrd.getCRF());
        this._metadata = new RecordMetadata(ecrd.getConfiguration(), this._data);
        this._formFields = new FormFields(this._data, this._metadata);
        this._rules = new RecordRules(this);
        this._rules.setRules(ecrd.getRules().export());
        this._validation = new RecordValidation(this._rules, ecrd.getConfiguration());
    }

    /**
     * Carga los datos de registro en BD (excluyendo data y metadata)
     *
     * @param {Object}  dbData       Datos de la fila en BD del registro
     * @param {Boolean} truncateData Indica si se deben truncar los datos de listas eliminadas del registro
     *
     * @return {boolean}       TRUE si se cargó todo con éxito
     */
    load(dbData, truncateData = false) {
        const { data, metadata, ...cleanData } = dbData; // eslint-disable-line no-unused-vars
        Object.assign(this.baseData, cleanData);

        this._data.load(dbData.data, truncateData);
        this._metadata.load(dbData.metadata);
        this._formFields.load();

        return true;
    }
    /**
     * Resetea la información de datos en este registro, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     */
    reset() {
        Object.keys(this.baseData).forEach(key => {
            _.unset(this.baseData, key);
        });

        this._data.reset();
        this._metadata.reset();

        // GARU-4946 Es necesario recargar el servicio de flags porque se tiene en cuenta para calcular queries sobre
        // campos obligatorios (con el flag "required")
        this._formFields.reset();
    }
    /**
     * Obtiene la información completa de base del registro (version, uid, etc.)
     *
     * @return {object} Datos básicos del registro
     */
    get() {
        return this.baseData;
    }

    /**
     * Devuelve el ID del registro
     *
     * @return {Number}  ID del registro
     */
    getId() {
        return this.baseData.id;
    }

    /**
     * Devuelve el UID del registro
     *
     * @return {string} UID del registro
     */
    getUId() {
        return this.baseData.uid;
    }

    /**
     * Devuelve el estado del paciente
     *
     * @return {Number}  Estado
     */
    getStatus() {
        return this.baseData.status;
    }

    /**
     * Devuelve la versión del registro
     *
     * @return {Number}  Versión del registro
     */
    getVersion() {
        return this.baseData.version;
    }

    /**
     * Devuelve el nivel de acceso de este registro conforme a los criterios de la API
     *
     * El valor devuelto es uno de 'mine', 'group' o 'other', para indicar respectivamente que el registro
     * pertenece a uno de nuestros centros, a alguno de los centros de nuestro grupo, o a otros.
     *
     * @return {string}  Clasificación del registro según a quién pertenece: 'mine', 'group' o 'other'
     *
     * @deprecated Revisar si se sigue usando, eliminar en caso de que no
     */
    getAccessLevel() {
        return this.baseData.accessLevel;
    }

    /**
     * Devuelve el identificador del centro al que pertenece este registro/paciente
     *
     * @return {Number}  ID del centro en BD
     */
    getSiteId() {
        return this.baseData.siteId;
    }

    /**
     * Devuelve los datos del centro al que pertenece el registro
     * Para tener datos se ha debido resolver la relación con centros en algún momento. De otra manera es undefined
     *
     * @return {object} Datos de centro
     */
    getSite() {
        return _.get(this.baseData, 'site');
    }

    /**
     * Establece los datos del centro del registro
     *
     * @param  {object} siteData Datos del centro
     *
     * @return {Base}            El registro base actualizado
     */
    setSite(siteData) {
        _.set(this.baseData, 'site', siteData);

        return this;
    }

    /**
     * Devuelve la fecha de creación del registro
     *
     * @return {Date} Fecha de creación
     */
    getCreationDate() {
        return this.baseData.createdAt;
    }

    /**
     * Devuelve la fecha de última actualización del registro
     *
     * @return {Date} Fecha de actualización
     */
    getUpdateDate() {
        return this.baseData.updatedAt;
    }

    /**
     * Exporta una copia de los datos actuales
     *
     * @return {object} Copia de los datos
     */
    export() {
        return JSON.parse(JSON.stringify(this.baseData));
    }

    /**
     * Obtiene el objeto de datos del registro
     *
     * @return {RecordData} El objeto de datos del registro
     */
    getData() {
        return this._data;
    }

    /**
     * Obtiene el objeto de metadatos del registro
     *
     * @return {RecordData} El objeto de metadatos del registro
     */
    getMetadata() {
        return this._metadata;
    }

    /**
     * Obtiene el objeto de flags de elementos
     *
     * @return {RecordData} El objeto de flags de elementos
     */
    getFormFields() {
        return this._formFields;
    }

    /**
     * Obtiene el objeto de ejecución de reglas
     *
     * @return {RecordRules} El objeto de ejecución de reglas
     */
    getRules() {
        return this._rules;
    }

    /**
     * Obtiene el objeto de validación de los datos del registro
     *
     * @return {RecordData} El objeto de validación de los datos del registro
     */
    getValidation() {
        return this._validation;
    }

    /**
     * Obtiene el objeto de aleatorización asociado a este registro
     * Si no se encuentra devuelve undefined, útil para distinguir de la relación NULL que pueda haber
     *
     * @return {object} Datos de la aleatorización
     */
    getRandomization() {
        return _.get(this.baseData, ['randomization']);
    }

    /**
     * Establece el valor de aleatorización del registro
     *
     * @param  {object}     randomization Datos de aleatorización
     *
     * @return {RecordBase}               El registro base modificado
     */
    setRandomization(randomization) {
        this.baseData.randomization = randomization;

        return this;
    }

    /**
     * Obtiene el ID del brazo de aleatorización asignado a este registro
     *
     * @return {Number}  Valor de ID del brazo, null si no está aleatorizado
     */
    getRandomizationBranchId() {
        return _.get(this.baseData, ['randomization', 'branch', 'id'], null);
    }

    /**
     * Establece el valor de ID de la publicación vigente en el momento de la creación
     *
     * @param  {Number}  releaseId ID de publicación
     * @return {Base}              El objeto actualizado
     */
    setCreationReleaseId(releaseId) {
        this.baseData.releaseId = releaseId;

        return this;
    }

    /**
     * Establece el valor de ID de la publicación vigente en el momento de la actualización
     *
     * @param  {Number}  releaseId ID de publicación
     * @return {Base}              El objeto actualizado
     */
    setUpdateReleaseId(releaseId) {
        this.baseData.updateReleaseId = releaseId;

        return this;
    }

    /**
     * Obtiene el valor de ID de la publicación vigente en el momento de la creación
     *
     * @return {Number}  El ID de publicación
     */
    getCreationReleaseId() {
        return this.baseData.releaseId;
    }

    /**
     * Obtiene el valor de ID de la publicación vigente en el momento de la (última) actualización
     *
     * @return {Number}  El ID de publicación
     */
    getUpdateReleaseId() {
        return this.baseData.updateReleaseId;
    }

    /**
     * Obtiene los datos de la publicación vigente cuando se creó el registro
     *
     * @return {object} Datos de la publicación
     */
    getCreationRelease() {
        return this.baseData.creationRelease;
    }

    /**
     * Obtiene los datos de la publicación vigente cuando se actualizó por última vez el registro
     *
     * @return {object} Datos de la publicación
     */
    getUpdateRelease() {
        return this.baseData.updateRelease;
    }

    /**
     * Obtiene el ID del eCRD vigente cuando se creó el registro
     *
     * @return {number} ID del eCRD
     */
    getCreationEcrdId() {
        return _.get(this.getCreationRelease(), 'ecrdId');
    }

    /**
     * Obtiene el ID del eCRD vigente cuando se actualizó por última vez el registro
     *
     * @return {number} ID del eCRD
     */
    getUpdateEcrdId() {
        return _.get(this.getUpdateRelease(), 'ecrdId');
    }
}

module.exports = Base;
