'use strict';

const _ = require('lodash');

const RecordDataExpression = require('./RecordData');

/**
 * Clase de gestión de una expresión para obtener el valor del número de elementos de una lista del CRD
 *
 * @extends Criteria.RecordDataExpression
 *
 * @property {Number}      listId      Identificador único de la lista en la estructura
 * @property {object|null} modifier    Modificador sobre el valor del tamaño de lista
 * @property {object|null} listIndices Índices de las listas que pueden contener a su vez a la lista objetivo
 *
 * @memberOf Criteria
 */
class ListLengthExpression extends RecordDataExpression {
    /**
     * Constructor de la clase: inicializa el ID de la lista
     *
     * @param {Number}  listId ID numérico único de la lista en el CRF
     */
    constructor(listId) {
        super();

        this.listId = _.toNumber(listId);
    }

    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @return {string} Tipo de expresión: "count"
     */
    getType() {
        return 'count';
    }

    /**
     * Genera una representación de la expresión apta para exportar o depurar
     *
     * @return {string} Información de los datos de la expresión en una cadena
     *
     * @example
     * // returns 'count:17'
     * const expr = new ListLengthExpression({id: 17});
     * expr.toString();
     *
     * @example
     * // returns 'count:17-5'
     * const expr = new ListLengthExpression({id: 17, modifier: 'sub', mod_value: 5});
     * expr.toString();
     *
     * @todo Incluir las listas en la representación
     */
    toString() {
        let asString = `count:${this.listId}`;

        if (this.modifier) {
            asString += this.modifier.operation === 'add' ? '+' : '-';
            asString += this.modifier.amount;
        }

        return asString;
    }

    /**
     * Obtiene el ID de la lista de referencia
     *
     * @return {Number}  Identificador único de la lista
     *
     * @example
     * // returns 17
     * const expr = new ListLengthExpression({id: 17});
     * expr.getListId();
     */
    getListId() {
        return this.listId;
    }
}

module.exports = ListLengthExpression;
