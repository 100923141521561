'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:rules');

const Criteria = require('../../src/Criteria/Criteria');
const CriteriaExecutor = require('../../src/Criteria/Executor/Executor');
const FormulaExecutor = require('../../src/Formula/Executor');
const RuleEvents = require('../../src/Record/Rules/Events');
const FilterListener = require('../../src/Record/Rules/FilterListener');

const Conditions = require('../Criteria/Executor/conditions');

/**
 * Definición de las reglas que sirve de entrada a la clase de ejecución
 *
 * @typedef {object} RuleDefinition
 *
 * @property {string}  func       Nombre del método de Rules que ejecuta la acción de la regla
 * @property {Array<mixed>} args       Lista de argumentos de entrada a func
 * @property {object}  conditions Definición de las condiciones a partir de la cual se instancia un objeto de Criteria
 */

/**
 * Filtros que se pueden aplicar a la ejecución de las reglas
 *
 * @typedef {object} Filters
 *
 * @property {string}  [scope]      Ámbito de ejecución: create/update
 * @property {string}  [actionType] Tipo de acción: sólo se ejecutan las acciones con el tipo seleccionado
 * @property {Number}  [targetForm] ID del formulario objetivo: sólo se ejecutan las reglas sobre datos del formulario
 */

/**
 * Tipos de filtro
 * @enum {string}
 * @readOnly
 */
const Filters = {
    SCOPE: 'scope',
    ACTION_TYPE: 'actionType',
    TARGET_FORM: 'targetForm',
};

/**
 * Clase encargada de ejecutar las reglas
 *
 * @property {Record.Base}       recordBase     Instancia del objeto completo de Record
 * @property {Record.FormFields} formFields     Instancia del objeto global de FormFields
 * @property {Criteria.Executor} executor       Objeto para ejecutar los criterios de las condiciones
 * @property {ExportedRule[]}    definitions    Lista de objetos de definición de regla
 * @property {object}            eventListeners Funciones registradas para escuchar eventos en el flujo de ejecución
 * @property {Number}            maxRecursion   Nivel máximo de recursividad aceptado (reglas que lanzan otras reglas)
 *                                              Por defecto 10 niveles
 * @property {integer[]}         executionStack Pila de IDs de reglas ejecutadas de manera recursiva
 * @property {object}            eventsData     Datos persistentes durante la ejecución de reglas para ser tratados en
 *                                              eventos. Es un truco para las acciones de tipo "filter", donde se van
 *                                              acumulando valores para actualizar los flags tras aplicar las reglas
 * @property {object}            indexedRules   Listas de IDs de reglas indexadas por alguno de los criterios definidos
 *                                              en Filters
 *
 * @memberOf Record
 */
class Rules {
    /**
     * Constructor de la clase: a partir de un objeto de flags, que contiene el objeto de datos y a su vez este la
     * definición del CRD, inicializa los componentes internos que se usarán en la ejecucíon de las reglas
     *
     * @param {Record.Base} recordBase Instancia del objeto completo de Record, con referencias a los componentes
     */
    constructor(recordBase) {
        this.recordBase = recordBase;
        this.formFields = this.recordBase.getFormFields();
        this.record = this.formFields.getRecord();

        this.definitions = [];
        this.eventListeners = {};
        this.setMaxRecursion(10);
        this.executionStack = [];
        this.eventsData = {};
        this.indexedRules = {};

        // Esperamos a que la instancia de las reglas esté construida para inicializar el ejecutor, ya que si se hace
        // aquí recordBase.getRules() es undefined
        this.executor = null;
    }

    /**
     * Obtiene la referencia al objeto de datos del cuaderno
     *
     * @return {Record.Data} Objeto de datos
     */
    getRecord() {
        return this.record;
    }

    /**
     * Obtiene la referencia a la instancia de FormFields
     *
     * @return {Record.FormFields} El objeto de flags
     */
    getFormFields() {
        return this.formFields;
    }

    /**
     * Obtiene la instancia del objeto ejecutor de las condiciones, creándolo si es necesario
     *
     * @return {Criteria.Executor} El ejecutor de condiciones
     *
     * @private
     */
    _getExecutor() {
        if (_.isNil(this.executor)) {
            this.executor = this.executor = new CriteriaExecutor(this.recordBase);
        }

        return this.executor;
    }

    /**
     * Establece las definiciones de las reglas que se van a ejecutar. Obtiene la configuración de reglas de
     * la exportación proviente por ejemplo de Structure.Rules.export()
     *
     * @param {ExportedRule[]} rulesConfig Lista de definiciones de regla
     */
    setRules(rulesConfig) {
        this.definitions = _.map(rulesConfig, ruleConfig => {
            const configCopy = JSON.parse(JSON.stringify(ruleConfig));

            configCopy.criteria = Criteria.createFromConfig(configCopy.conditions);
            delete configCopy.conditions;

            return configCopy;
        });

        this.setUpListeners();
    }

    /**
     * Añade una lista de IDs de reglas indexadas por un criterio de filtro
     *
     * @param {Filters} filterName Identificador del índice
     * @param {object}  indexed    [description]
     */
    setIndexedRules(filterName, indexed) {
        if (!_.includes(Filters, filterName)) {
            debug('se añade una lista de reglas indexadas sobre un índice no conocido: %s', filterName);
        }

        this.indexedRules[filterName] = indexed;
    }

    /**
     * Establece el nivel de recursividad aceptado para la ejecución de reglas. El valor 0 deshabilita la comprobación
     * de recursividad, aunque no se recomienda hacerlo
     *
     * @param {Number}  level Nivel definido
     *
     * @return {Rules} El objeto de ejecución de reglas actualizado
     */
    setMaxRecursion(level = 10) {
        this.maxRecursion = level;

        return this;
    }

    /**
     * Inicializa los listeners de las reglas de filtro de valores
     */
    setUpListeners() {
        FilterListener.register(this);
    }

    /**
     * Registra una función para ejecutar cuando ocurra el evento especificado
     *
     * @param {string}   eventName Nombre del evento
     * @param {function} callback  Función a ejecutar cuando se lanza el evento
     *
     * @return {string} Identificador del listener del evento para poder eliminarlo
     */
    addEventListener(eventName, callback) {
        if (!_.has(this.eventListeners, eventName)) {
            this.eventListeners[eventName] = {};
        }

        const listenerId = _.uniqueId('ruleListener');

        this.eventListeners[eventName][listenerId] = callback;

        return listenerId;
    }

    /**
     * Elimina un listener de evento previamente registrado
     *
     * @param  {string} listenerId Identificador del listener
     */
    removeEventListener(listenerId) {
        if (listenerId) {
            _.each(this.eventListeners, eventList => {
                delete eventList[listenerId];
            });
        }
    }

    /**
     * Lanza un evento llamando a todos los listeners registrados para dicho evento
     *
     * @param  {RuleEvents} eventName Nombre del evento
     * @param  {Array<mixed>}    args      Lista variable de argumentos asociados al evento
     */
    emitEvent(eventName, ...args) {
        debug('event: %s %o', eventName, _.keys(this.eventListeners[eventName]));
        _.each(this.eventListeners[eventName], callback => {
            callback(...args);
        });
    }

    /**
     * Ejecución de todas las reglas disponibles
     *
     * @param  {Filters} filters  Filtros de selección de reglas a ejecutar
     *
     * @return {boolean} Si se ha ejecutado alguna regla
     */
    executeAll(filters) {
        debug('Execute all rules');

        return this._executeRules(this.definitions, filters);
    }

    /**
     * Actions have field
     *
     * @param {Object[]} exportedActions Exported actions
     * @param {number} fieldId Field ID
     *
     * @returns {boolean} If field is target of any action
     */
    _actionsHaveField(exportedActions, fieldId) {
        return (exportedActions || []).some(actionConfig => {
            return actionConfig.target && actionConfig.target === fieldId;
        });
    }

    /**
     * Actions have list
     *
     * @param {Object[]} exportedActions Exported actions
     * @param {number} listId List ID
     *
     * @returns {boolean} If list is target of any action
     */
    _actionsHaveList(exportedActions, listId) {
        return (exportedActions || []).some(actionConfig => {
            return actionConfig.targetLists && actionConfig.targetLists.includes(listId);
        });
    }

    /**
     * Ejecuta todas las reglas registradas en las que interviene alguna condición que incluye el valor del campo
     * especificado
     *
     * @param  {Number}  fieldId ID único del campo en el CRD
     * @param  {Filters} filters Filtros de selección de reglas a ejecutar
     *
     * @return {boolean} Si se ha ejecutado alguna regla
     */
    executeFieldRules(fieldId, filters) {
        debug('executeFieldRules on %d', fieldId);

        return this._executeRules(this.definitions.filter(ruleConfig => {
            return ruleConfig.criteria.hasField(fieldId) || this._actionsHaveField(ruleConfig.actions, fieldId);
        }), filters);
    }

    /**
     * Ejecuta todas las reglas registradas en las que interviene alguna condición que incluye una operación sobre la
     * lista especificada
     *
     * @param  {Number}  listId  ID único de la lista en el CRD
     * @param  {Filters} filters Filtros de selección de reglas a ejecutar
     *
     * @return {boolean} Si se ha ejecutado alguna regla
     */
    executeListRules(listId, filters) {
        debug('executeListRules on %d', listId);

        return this._executeRules(this.definitions.filter(ruleConfig => {
            return ruleConfig.criteria.hasList(listId) || this._actionsHaveList(ruleConfig.actions, listId);
        }), filters);
    }

    /**
     * Ejecuta una lista de reglas a partir de su definición
     *
     * @param  {RuleDefinition[]} rulesList Definiciones de las reglas a ejecutar
     * @param  {Filters}          filters   Filtros de selección de reglas a ejecutar
     *
     * @return {boolean} Si se ha ejecutado alguna regla
     *
     * @private
     */
    _executeRules(rulesList, filters) {
        const filteredRules = this._filterRules(rulesList, filters);

        this.emitEvent(RuleEvents.ON_PRE_EXECUTE, filteredRules, filters);

        let result = false;
        for (const ruleIndex in filteredRules) {
            const ruleConfig = filteredRules[ruleIndex];
            result = this.executeRule(ruleConfig, filters) || result;
        }

        this.emitEvent(RuleEvents.ON_POST_EXECUTE, filteredRules, filters, result);

        return result;
    }

    /**
     * Filtra la lista de reglas según una serie de criterios
     *
     * @param  {RuleDefinition[]} rulesList Definiciones de las reglas a ejecutar
     * @param  {Filters}          filters   Filtros de selección de reglas
     *
     * @return {RuleDefinition[]}           Definiciones filtradas
     */
    _filterRules(rulesList, filters = {}) {
        const filteredRules = _.reduce(filters, (result, value, key) => {
            if (!_.has(this.indexedRules, key)) {
                debug('Filtro de reglas no aplicado: %s', key);

                return result;
            }

            // Lista única de IDs por valor o valores definidos
            const idsByValue = _(this.indexedRules[key]).pick(_.castArray(value)).values().flatten().uniqBy().value();

            const filtered = _.filter(result, ruleConfig => {
                return _.includes(idsByValue, ruleConfig.id);
            });

            debug('Filtro de reglas aplicado: %s=%s, %d => %d', key, value, result.length, filtered.length);

            return filtered;
        }, rulesList);

        debug('Reglas filtradas: %d => %d', rulesList.length, filteredRules.length);

        return filteredRules;
    }

    /**
     * Ejecuta individualmente una regla:
     * 1 - calcula los contextos de ejecución de la regla
     * 2 - evalúa las condiciones para cada contexto
     * 3 - por cada resultado de la evaluación, aplica o deshace las acciones definidas
     *
     * @param  {ExportedRule} ruleConfig Objeto de definición de la regla
     * @param  {Filters}      filters    Filtros de selección de reglas a ejecutar
     *
     * @return {boolean} Verdadero si se ha ejecutado según el límite de recursividad, falso en caso contrario
     */
    executeRule(ruleConfig, filters) {
        let result = true;
        this.executionStack.push(ruleConfig.id);

        if (this.maxRecursion && this.executionStack.length > this.maxRecursion) {
            debug('max rule stack size reached %d', this.maxRecursion);
            result = false;
        } else {
            debug('rule execution stack %o', this.executionStack);

            if (ruleConfig.context) {
                // Si la regla tiene contexto de lista se ejecuta para cada combinación posible de listIndices
                _.each(this.getExecutionContexts(ruleConfig.context), listIndices => {
                    this._executeRuleWithContext(ruleConfig, filters, listIndices);
                });
            } else {
                // Si la regla no tiene contexto de lista la podemos ejecutar una única vez sin índices
                this._executeRuleWithContext(ruleConfig, filters, {});
            }
        }

        this.executionStack.pop();

        return result;
    }

    /**
     * Ejecuta una regla individual con un contexto de listas resuelto
     *
     * @param  {ExportedRule} ruleConfig  Objeto de definición de la regla
     * @param  {Filters}      filters     Filtros de selección de reglas a ejecutar
     * @param  {ListIndices}  listIndices Índices de las listas en el contexto de ejecución
     *
     * @private
     */
    _executeRuleWithContext(ruleConfig, filters, listIndices) {
        debug('Execute rule %s', ruleConfig.id);
        const meetsConditions = this._getExecutor().execute(ruleConfig.criteria, listIndices);
        const method = meetsConditions ? 'doAction' : 'undoAction';

        _.each(this.filterActions(ruleConfig.actions, filters, meetsConditions), exportedAction => {
            this[method](exportedAction, filters, listIndices);
        });
    }

    /**
     * Devuelve la lista de acciones filtrada según los criterios especificados
     *
     * @param  {Array<ExportedAction>} actions  Lista de acciones proveniente de la exportación de la regla
     * @param  {Filters}               filters  Filtros de selección de reglas a ejecutar
     * @param  {boolean}               meetsConditions  Indica si se han cumplido las condiciones
     *
     * @return {Array}                          Lista de acciones filtradas
     *
     * @private
     */
    filterActions(actions, filters = {}, meetsConditions) {
        return _.filter(actions, exportedAction => {
            // 1. Tiene filtro de tipo de acción
            if (_.has(filters, 'actionType') && !_.includes(_.castArray(filters.actionType), exportedAction.definition.type)) {
                return false;
            }
            // 2. Tiene filtro de formulario objetivo
            if (_.has(filters, 'targetForm') && filters.targetForm !== exportedAction.targetForm) {
                return false;
            }
            // GARU-3464 Querys automáticas solo en tiempo de creación, que pueden cerrarse si ya no se cumplen
            // las condiciones en tiempo de actualización. En este caso, hay que ejecutar la acción, pero solo
            // el undoAction, es decir, el evento onRemoveQuery
            if (!meetsConditions && exportedAction.definition.type === 'query') {
                return true;
            }
            // 3. Tiene filtro de scope, pero la acción no está en ninguno de ellos
            if (_.has(filters, 'scope') && !_.includes(exportedAction.scope, filters.scope)) {
                return false;
            }

            return true;
        });
    }

    /**
     * Aplica una acción conocida
     *
     * @param {ExportedAction} exportedAction Configuración obtenida de la exportación de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     *
     * @return {mixed}                        Resultado de la ejecución de la acción
     */
    doAction(exportedAction, filters, context) {
        const functionName = exportedAction.func;
        debug('doAction: %s(%O) %O -(undo %o)', functionName, exportedAction, context, !!exportedAction.undo);
        this.emitEvent(RuleEvents.ON_DO_ACTION, exportedAction, context);

        if (_.isFunction(this[functionName])) {
            return this[functionName].call(this, context, exportedAction, filters);
        }
        debug('tipo de acción no implementado: %s', functionName);
    }

    /**
     * Deshace una acción conocida, llamando al método de deshacer la acción original
     *
     * @param {ExportedAction} exportedAction Configuración obtenida de la exportación de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     *
     * @return {mixed}                        Resultado de la ejecución de la acción
     */
    undoAction(exportedAction, filters, context) {
        const undoExportedAction = JSON.parse(JSON.stringify(exportedAction));
        undoExportedAction.func = `undo${_.upperFirst(exportedAction.func)}`;
        undoExportedAction.undo = true;

        return this.doAction(undoExportedAction, filters, context);
    }

    /**
     * Obtiene una lista de contextos de ejecución: cada contexto de ejecución es un objeto de @see {@link ListIndices}
     * Los contextos representan todas las combinaciones posibles de índices para una lista, incluyendo los propios y
     * los de las listas de nivel superior.
     *
     * @param  {Number}  contextListId ID único numérico de la lista de destino
     *
     * @return {ListIndices[]}         La lista de contextos expresada como objetos de índices de listas
     *
     * @example
     * // returns [undefined]
     * Rules.getExecutionContexts(1); // crf.getElement(1).getParentList() === null
     *
     * @example
     * // returns [{11: 2}]
     * Rules.getExecutionContexts(2, {11: 2}); // crf.getElement(2).getParentList() !== null
     *
     * @example
     * // returns [{11: 1}, {11: 2}]
     * Rules.getExecutionContexts(2); // crf.getElement(2).getParentList() !== null
     *
     * @private
     */
    getExecutionContexts(contextListId) {
        if (!contextListId) {
            return null;
        }

        return this.record.getAllListIndices(contextListId);
    }

    /**
     * Acción: deshabilitar un campo
     * Establece el valor del flag "disabledByRule" a TRUE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags del campo actualizado
     */
    disableField(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const result = this.formFields.setDisabledByRule(fieldId, true, context);

        this.emitEvent(RuleEvents.ON_DISABLE_FIELD, exportedAction, context);

        return result;
    }

    /**
     * Acción: Deshacer deshabilitado de un campo (habilitar campo)
     * Establece el valor del flag "disabledByRule" a FALSE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}               Objeto de flags actualizado
     */
    undoDisableField(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const result = this.formFields.setDisabledByRule(fieldId, false, context);

        this.emitEvent(RuleEvents.ON_ENABLE_FIELD, exportedAction, context);

        return result;
    }

    /**
     * Acción: mostrar un mensaje asociado al campo
     * Establece el valor del flag "infoMessage" + messageId a TRUE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags actualizado
     */
    showFieldMessage(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const messageId = exportedAction.definition.id;

        const result = this.formFields.setInfoMessage(messageId, fieldId, true, context);
        this.emitEvent(RuleEvents.ON_SHOW_FIELD_MESSAGE, exportedAction, context);

        return result;
    }

    /**
     * Acción: deshacer mostrar un mensaje asociado al campo: ocultar el mensaje
     * Establece el valor del flag "infoMessage" + messageId a FALSE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags actualizado
     */
    undoShowFieldMessage(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const messageId = exportedAction.definition.id;

        const result = this.formFields.setInfoMessage(messageId, fieldId, false, context);
        this.emitEvent(RuleEvents.ON_HIDE_FIELD_MESSAGE, exportedAction, context);

        return result;
    }

    /**
     * Acción: mostrar un error asociado al campo
     * Establece el valor del flag "errorMessage" + messageId a TRUE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags actualizado
     */
    showFieldError(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const messageId = exportedAction.definition.id;

        const result = this.formFields.setErrorMessage(messageId, fieldId, true, context);
        this.emitEvent(RuleEvents.ON_SHOW_FIELD_ERROR, exportedAction, context);

        return result;
    }

    /**
     * Acción: deshacer mostrar un error asociado al campo: ocultar el mensaje
     * Establece el valor del flag "errorMessage" + messageId a FALSE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags actualizado
     */
    undoShowFieldError(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const messageId = exportedAction.definition.id;

        const result = this.formFields.setErrorMessage(messageId, fieldId, false, context);
        this.emitEvent(RuleEvents.ON_HIDE_FIELD_ERROR, exportedAction, context);

        return result;
    }

    /**
     * Acción: establecer el valor de un campo
     *
     * Emite un evento 'onSetValue' siempre
     * En caso de que el valor haya cambiado realmente, también emite un evento 'onSetValueChanged'. No considera
     * que ha cambiado el valor cuando el anterior y el nuevo son ambos "vacíos"
     *
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {mixed}                        El valor modificado o undefined si el campo no existe en el CRD
     *
     * @see Record.Data.setFieldValue
     *
     * @private
     */
    setValue(context, exportedAction, filters) {
        const fieldId = exportedAction.definition.target;
        // GARU-5104 Se evita copiar la referencia si el valor es un array
        const value = Array.isArray(exportedAction.definition.value)
            ? exportedAction.definition.value.slice()
            : exportedAction.definition.value;

        const currentValue = this.record.getFieldValue(fieldId, context);
        // GARU-4689 No se considera que el valor ha cambiado si antes estaba "vacío" y ahora también
        let hasChanged;
        if (Conditions.empty(currentValue) && Conditions.empty(value)) {
            hasChanged = false;
        } else if (Array.isArray(currentValue) && Array.isArray(value)) {
            // Si el valor de la acción es un array, la comparación con !== siempre será true, ya que siempre serán dos
            // referencias distintas. No es tan directo.
            // Con la segunda parte se ignora el orden de los arrays: con [1, 2, 3] y [3, 2, 1], hasChanged es FALSE
            hasChanged = !(
                _.isEqual(currentValue, value)
                || (_.difference(currentValue, value).length === 0 && _.difference(value, currentValue).length === 0)
            );
        } else {
            hasChanged = currentValue !== value;
        }

        const result = this.record.setFieldValue(fieldId, value, context);

        // Emite el evento general de onSetValue
        this.emitEvent(RuleEvents.ON_SET_VALUE, exportedAction, context);

        if (hasChanged) {
            // GARU-2924 Solamente se emite el evento si se modifica el valor
            this.emitEvent(RuleEvents.ON_SET_VALUE_CHANGED, exportedAction, context);

            // Un cambio de valor puede lanzar nuevas reglas
            this.executeFieldRules(fieldId, filters);
        }

        return typeof result === 'undefined' ? undefined : value;
    }

    /**
     * Acción: deshacer un cambio de valor. Si tiene configurada la propiedad "cleanUp" se limpia el valor de la
     * variable
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     * @param  {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {mixed}                         El valor modificado, vacío según el tipo de campo
     */
    undoSetValue(context, exportedAction, filters) {
        if (exportedAction.definition.cleanup) {
            // construimos un ExportedAction para poder llamar a la función de acción 'setValue'
            // necesita el ID del campo y el valor a establecer
            const setValueAction = {
                definition: {
                    target: exportedAction.definition.target,
                    value: this.record.getResetValue(exportedAction.definition.target),
                },
            };

            return this.setValue(context, setValueAction, filters);
        }
    }


    /**
     * Acción: establecer el valor por defecto en un campo
     *
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {mixed}                  El valor modificado
     *
     * @private
     *
     * @todo GARU-5149 Una vez que se hayan migrado los cuadernos actuales no debe quedar ninguna regla de set default
     *       (pasarían a ser set default o clear) y esta función se tiene que eliminar
     * @deprecated
     */
    setDefaultValue(context, exportedAction, filters) {
        // Es un alias de "clearValue" que usa el valor de limpieza según el tipo de campo.
        // Esta función se mantiene temporalmente porque los cuadernos con acción "setDefaultValue" hay que migrarlos
        // como parte de las tareas de GARU-5149, y mientras conviven por un instante
        return this.clearValue(context, exportedAction, filters);
    }

    /**
     * Acción: Limpiar valor del campo
     *
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {mixed}                        El valor vacío modificado
     *
     * @private
     */
    clearValue(context, exportedAction, filters) {
        const fieldId = exportedAction.definition.target;

        // obtenemos el valor de limpieza del campo
        exportedAction.definition.value = this.record.getResetValue(fieldId);

        return this.setValue(context, exportedAction, filters);
    }

    /**
     * Acción: ocultar un elemento del cuaderno
     * Establece el valor del flag "visibleByRule" a FALSE en el objeto de flags de campos
     *
     * @param {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction Objeto exportado de la acción
     * @param {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {object}                       Objeto de flags actualizado
     */
    hideElement(context, exportedAction, filters) {
        const elementId = exportedAction.definition.target;

        const element = this.record.getCRF().getElement(elementId);
        const result = this.formFields.setVisibleByRule(elementId, false, context);

        const isField = element.isField();

        const event = isField ? RuleEvents.ON_HIDE_FIELD : RuleEvents.ON_HIDE_SECTION;
        this.emitEvent(event, exportedAction, context, filters);

        this.emitEvent(event, exportedAction, context);

        if (exportedAction.definition.resetValue) {
            // GARU-4701 Si el campo no permite valores no se necesita el setValue
            // GARU-7070 Y que no se salte el proceso de data cleaning
            if (isField && element.allowsValue() && !element.ignoresDataCleaning()) {
                // Construimos un objeto temporal de tipo ExportedAction para llamar a la función de acción
                // 'clearValue', que solo necesita un target
                const defaultValueAction = {
                    definition: {
                        target: exportedAction.definition.target,
                    },
                };

                this.clearValue(context, defaultValueAction, filters);
            }

            // GARU-4271 También se limpia el valor de las subvariables, en general de todos los campos que se incluyan
            // en el árbol del elemento que se oculta
            this.clearFieldValuesByParent(element, context, filters);
        }

        return result;
    }

    /**
     * Limpia el valor de los campos que "pertenecen" al elemento solicitado
     *
     * @param {Container}   parent      El elemento que actúa de raíz del árbol
     * @param {ListIndices} listIndices Lista de índices donde resetear
     * @param {Filters}     filters     Filtros de selección de reglas a ejecutar
     *
     * @private
     */
    clearFieldValuesByParent(parent, listIndices, filters) {
        // Cálculo de los campos que tienen que cambiar el valor
        const fields = [];
        // Cálculo de las listas que se incluyen en este objeto
        const lists = [];

        let currentItem = null;
        let elements = parent.getChildren().slice();

        while (elements.length > 0) {
            currentItem = elements.shift();
            const isList = currentItem.isList();

            if (currentItem.isField()) {
                fields.push(currentItem);
            } else if (isList) {
                lists.push(currentItem);
            }

            if (!isList) {
                elements = currentItem.getChildren().concat(elements);
            }
        }

        // Si el elemento de raíz es una lista hay que recorrer sus índices
        const contexts = parent.isList() ? this.getExecutionContexts(parent.getId()).filter(ruleContext => {
            // Solamente aquellos contextos que sean hijos del de ejecución de la regla
            return Object.keys(listIndices).every(key => listIndices[key] === ruleContext[key]);
        }) : [listIndices];

        contexts.forEach(itemContext => {
            // Los campos con su propia definición de contextos
            fields.forEach(subfield => {
                // GARU-4701 Solamente los campos que permiten tener valor
                // GARU-7070 Y que no se salte el proceso de data cleaning
                if (subfield.allowsValue() && !subfield.ignoresDataCleaning()) {
                    const subvarSetDefaultAction = {
                        definition: {
                            target: subfield.getId(),
                        },
                    };

                    this.clearValue(itemContext, subvarSetDefaultAction, filters);
                }
            });

            // Y las listas se calculan recursivamente
            lists.forEach(list => {
                this.clearFieldValuesByParent(list, itemContext, filters);
            });
        });
    }

    /**
     * Acción: mostrar un elemento del cuaderno
     * Establece el valor del flag "visibleByRule" a TRUE en el objeto de flags de campos
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags actualizado
     */
    undoHideElement(context, exportedAction) {
        const elementId = exportedAction.definition.target;

        const element = this.record.getCRF().getElement(elementId);
        const result = this.formFields.setVisibleByRule(elementId, true, context);

        const event = element.isField() ? RuleEvents.ON_SHOW_FIELD : RuleEvents.ON_SHOW_SECTION;
        this.emitEvent(event, exportedAction, context);

        return result;
    }

    /**
     * Acción: asignar el resultado de una fórmula al valor de un campo
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     * @param  {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {mixed}                         El valor asignado al campo
     */
    setFormula(context, exportedAction, filters) {
        const fieldId = exportedAction.definition.target;
        const formula = exportedAction.definition.value;

        const executor = new FormulaExecutor(this.record, context);
        const value = executor.execute(formula);

        // construimos un ExportedAction para poder llamar a la función de acción 'setValue'
        // necesita el ID del campo y el valor a establecer
        const setValueAction = {
            definition: {
                target: fieldId,
                value: value,
            },
        };

        return this.setValue(context, setValueAction, filters);
    }

    /**
     * Acción: deshacer la aplicación de una fórmula. Si tiene configurada la propiedad "cleanUp" se limpia el valor de
     * la variable
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     * @param  {Filters}        filters        Filtros de selección de reglas a ejecutar
     *
     * @return {null}                         El valor modificado (null)
     */
    undoSetFormula(context, exportedAction, filters) {
        const cleanUp = exportedAction.definition.cleanup;
        const fieldId = exportedAction.definition.target;

        if (cleanUp) {
            // construimos un ExportedAction para poder llamar a la función de acción 'setValue'
            // necesita el ID del campo y el valor a establecer
            const setValueAction = {
                definition: {
                    target: fieldId,
                    value: this.record.getResetValue(fieldId),
                },
            };

            return this.setValue(context, setValueAction, filters);
        }
    }

    /**
     * Acción: añadir una nota de discrepancia
     * Lanza el evento de creación de query
     *
     * @param {ListIndices} context    Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     */
    createQuery(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_CREATE_QUERY, exportedAction, context);
    }

    /**
     * Acción: retirar una nota de discrepancia
     * Lanza el evento de retirada de query
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @todo Identificar la query que hay que retirar
     */
    undoCreateQuery(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_REMOVE_QUERY, exportedAction, context);
    }

    /**
     * Acción: activar elemento de ePRO
     *
     * @param {ListIndices} context    Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     */
    enableEpro(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_ENABLE_EPRO_ELEMENT, exportedAction, context);
    }

    /**
     * Acción: desactivar elemento de ePRO
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     * @todo Identificar la query que hay que retirar
     */
    undoEnableEpro(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_DISABLE_EPRO_ELEMENT, exportedAction, context);
    }

    /**
     * Acción: ocultar hijos
     * Establece el valor del flag "hideChildren" a TRUE en el objeto de flags de campos, con objeto
     * de ocultar las subvariables de un campo
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}                 Objeto de flags del campo actualizado
     */
    hideChildren(context, exportedAction) {
        const fieldId = exportedAction.definition.target;
        const resetDescendants = exportedAction.definition.resetDescendants;

        const result = this.formFields.setHideChildren(fieldId, true, context);

        this.emitEvent(RuleEvents.ON_HIDE_CHILDREN, exportedAction, context);

        if (resetDescendants) {
            const crf = this.record.getCRF();
            const fieldInstance = crf.getField(fieldId);
            if (!fieldInstance) {
                return;
            }

            // recorre las subvariables y llama a la acción de reglas de resetear el valor
            fieldInstance.getDescendants().forEach(subfield => {
                // construimos un objeto temporal de tipo ExportedAction para llamar a la función de acción
                // 'clearValue', que solo necesita un target
                // GARU-4701 Lógicamente si es una variable que permite valores
                // GARU-7070 Y que no se salte el proceso de data cleaning
                if (subfield.allowsValue() && !subfield.ignoresDataCleaning()) {
                    const defaultValueAction = {
                        definition: {
                            target: subfield.getId(),
                        },
                    };
                    this.clearValue(context, defaultValueAction);
                }
            });
        }

        return result;
    }

    /**
     * Acción: Deshacer ocultar hijos de un campo
     * Establece el valor del flag "hideChildren" a FALSE en el objeto de flags de campos, con objeto de mostrar
     * las subvariables de un campo
     *
     * @param {ListIndices} context     Índices en el contexto de ejecución de la acción
     * @param {ExportedAction} exportedAction   Objeto exportado de la acción
     *
     * @return {object}               Objeto de flags actualizado
     */
    undoHideChildren(context, exportedAction) {
        const fieldId = exportedAction.definition.target;

        const result = this.formFields.setHideChildren(fieldId, false, context);
        this.emitEvent(RuleEvents.ON_SHOW_CHILDREN, exportedAction, context);

        return result;
    }

    /**
     * Acción: Añadir opciones de valor disponibles para un campo
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    filterOptions(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_FILTER_OPTIONS, exportedAction, context);

        return true;
    }

    /**
     * Acción: Deshacer un filtro de opciones de valor disponibles para un campo
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    undoFilterOptions(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_UNFILTER_OPTIONS, exportedAction, context);


        return true;
    }

    /**
     * Acción: Bloquear la posibilidad de aleatorizar el registro
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    blockRandomization(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_BLOCK_RANDOMIZATION, exportedAction, context);

        return true;
    }

    /**
     * Acción: Desbloquear la posibilidad de aleatorizar el registro
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    undoBlockRandomization(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_UNLOCK_RANDOMIZATION, exportedAction, context);

        return true;
    }

    /**
     * Acción: Envío de notificación
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    sendNotification(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_SEND_NOTIFICATION, exportedAction, context);

        return true;
    }

    /**
     * Acción: Cancelar una notificación
     *
     * @param  {ListIndices}    context        Índices en el contexto de ejecución de la acción
     * @param  {ExportedAction} exportedAction Objeto exportado de la acción
     *
     * @return {boolean}                       Resultado de la acción
     */
    undoSendNotification(context, exportedAction) {
        this.emitEvent(RuleEvents.ON_CANCEL_NOTIFICATION, exportedAction, context);

        return true;
    }
}

module.exports = Rules;
