'use strict';

const _ = require('lodash');

const ConfigurationModule = require('./Module');
const State = require('./State');

/**
 * Tipos de campo a los que se aplican los estados
 *
 * @enum {String}
 *
 * @readOnly
 */
const FieldsType = {
    REQUIRED: 'required',
    ALL: 'all',
};

/**
 * Gestión de un estado de aprobación individual
 * Se comporta como un módulo de configuración dentro del módulo de estados de aprobación
 */
class RevisionState extends ConfigurationModule {
    /**
     * Inicialización de los datos internos
     *
     * @param {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super(definition);

        // Configuración inicial
        _.defaults(this.definition, {
            name: '',
            icon: State.Icons.NONE,
            color: '#000000',
            fieldsType: FieldsType.REQUIRED,
            allowEditData: true,
            notifications: {
                enabled: false,
                manualEnable: { enabled: false },
                manualDisable: { enabled: false },
                automatic: { enabled: false },
            },
        });
    }

    /**
     * Obtiene el ID único del estado
     *
     * @return {String} Identificador de estado
     */
    getId() {
        return this.definition.id;
    }

    /**
     * Obtiene el nombre configurado del estado
     *
     * @return {string} El nombre del estado
     */
    getName() {
        return this.definition.name;
    }

    /**
     * Obtiene el icono configurado del estado
     *
     * @return {Icons} El nombre del estado
     */
    getIcon() {
        return this.definition.icon;
    }

    /**
     * Obtiene el color configurado del estado
     *
     * @return {string} Código hexadecimal del color
     */
    getColor() {
        return this.definition.color;
    }

    /**
     * Obtiene el tipo de campo al que se puede aplicar este estado
     *
     * @return {String} Identificador de tipo de campo: required|all
     */
    getFieldsType() {
        return this.definition.fieldsType;
    }

    /**
     * Determina si cuando el estado está habilitado se permite la edición de los datos afectados
     *
     * @return {boolean} Definición de la propiedad
     */
    allowsEdition() {
        return !!this.definition.allowEditData;
    }

    /**
     * Determina si las notificaciones al cambiar de estado están habilitadas
     *
     * @return {boolean} TRUE si están habilitadas
     */
    isNotificationEnabled() {
        return this.definition.notifications.enabled;
    }

    /**
     * Obtiene la configuración de las notificaciones asociadas al estado
     *
     * @return {Object} Definición de configuración de notificaciones
     */
    getNotifications() {
        return this.definition.notifications;
    }

    /**
     * Determina si se deben notificar un cambio de estado al establecer el estado de forma manual
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenManualEnable() {
        return this.isNotificationEnabled()
            && this.definition.notifications.manualEnable.enabled
            && Array.isArray(this.definition.notifications.manualEnable.channels)
            && this.definition.notifications.manualEnable.channels.length > 0;
    }

    /**
     * Determina si se deben notificar un cambio de estado al desactivar el estado de forma manual
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenManualDisable() {
        return this.isNotificationEnabled()
            && this.definition.notifications.manualDisable.enabled
            && Array.isArray(this.definition.notifications.manualDisable.channels)
            && this.definition.notifications.manualDisable.channels.length > 0;
    }

    /**
     * Determina si se deben notificar un cambio de estado al desactivar el estado
     * de forma automática por cambio de datos
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenAutomaticChange() {
        return this.isNotificationEnabled()
            && this.definition.notifications.automatic.enabled
            && Array.isArray(this.definition.notifications.automatic.channels)
            && this.definition.notifications.automatic.channels.length > 0;
    }
}

module.exports = RevisionState;
