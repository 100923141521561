/* eslint-disable complexity */
'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core:validation');

const Field = require('../../../Structure/Field');

const { UNKNOWN_VALUE, ENCRYPTED_VALUE, MISSING_VALUE } = require('../../constants');

/**
 * Estado de validación de un campo con detalle de los errores en caso de no ser válido
 *
 * @typedef {object} FieldValidation
 *
 * @property {boolean}  valid  Elemento válido o no
 * @property {string[]} errors Lista de identificadores de error asociados al valor del campo
 *
 * @example
 * {
 *     valid: false,
 *     errors: ['error 1', 'error 2'],
 * }
 */

/**
 * Objeto de gestión de la validación de un campo
 *
 * @property {Record.Rules}      rules      Instancia de la ejecución de reglas, para ver errores generados
 * @property {Record.FormFields} formFields Instancia de información de campos y formularios correspondiente
 * @property {Record.Data}       record     Instancia del objeto de datos del registro
 * @property {Structure.Field}   field      Referencia al objeto de definición del campo que se va a validar
 *
 * @memberOf Record
 */
class FieldValidationClass {
    /**
     * Constructor estático
     *
     * @param  {Record.Rules}    recordRules       Instancia de ejecución de reglas con referencias a los datos del cuaderno
     * @param  {Structure.Field} field             Instancia de definición del campo
     * @param  {Configuration}   configuration     Instancia de configuración del cuaderno
     * @param {Action[]}         errorRulesActions Acciones de reglas de error
     *
     * @return {Record.FieldValidation}            La instancia de validador
     */
    static instance(recordRules, field, configuration, errorRulesActions = []) {
        return new this(recordRules, field, configuration, errorRulesActions);
    }

    /**
     * Constructor de la clase, guarda la referencia del campo para futuras operaciones
     *
     * @param {Record.Rules}    recordRules   Instancia de ejecución de reglas con referencias a los datos del cuaderno
     * @param {Structure.Field} field         Referencia al objeto de definición del campo que se va a validar
     * @param {Configuration}   configuration Instancia de configuración del cuaderno
     * @param {Action[]}        errorRulesActions Acciones de reglas de error
     */
    constructor(recordRules, field, configuration, errorRulesActions = []) {
        this.rules = recordRules;
        this.formFields = recordRules.getFormFields();
        this.configuration = configuration;

        this.record = recordRules.getRecord();
        this.field = field;

        this.validators = null;

        this.errorRulesActions = errorRulesActions;
    }

    /**
     * Obtiene una plantilla para un objeto vacío de validación de un campo
     *
     * @return {FieldValidation} Nuevo objeto de validación
     *
     * @private
     */
    _getValidationStateBoilerplate() {
        return {
            valid: true,
            errors: [],
        };
    }

    /**
     * Determina si el valor del campo se considera apto para validar. Valores vacíos y especiales (encriptado,
     * desconocido) no necesitan de validaciones
     *
     * @param  {mixed}   value El valor del campo
     *
     * @return {boolean}       Si no tiene valor
     *
     * @protected
     */
    isValidable(value) {
        if (value === undefined || value === null) {
            return false;
        }

        // Un valor de cadena vacía en campos de tipo texto también se considera vacío
        if (this.field && this.field.getFieldType() === 'string' && value === '') {
            return false;
        }

        // Valores especiales: desconocido / encriptado / perdido. No se deben validar
        if (this.field && (
            (this.field.allowsUnknownValue() && value === UNKNOWN_VALUE)
                || (this.field.isEncrypted() && value === ENCRYPTED_VALUE)
                || (this.configuration.hasMissingData() && this.field.isRequired() && value === MISSING_VALUE)
        )) {
            return false;
        }

        return true;
    }

    /**
     * Obtiene la lista de validaciones asociadas al campo
     *
     * @return {object} Objeto de validaciones a ejecutar. Se compone de una clave con el identificador del error y una
     *                  función validadora, que devuelve verdadero si el dato es correcto y falso en caso contrario
     */
    getValidators() {
        if (!_.isNil(this.validators)) {
            return this.validators;
        }

        const validators = {};

        // Por tipo
        validators[`type-${this.field.getFormControl()}`] = fieldValue => {
            return this._validateFieldType(fieldValue);
        };

        // Por restricciones del valor
        const constraints = this.field.getConstraints();

        // Se hace este recorrido extraño porque una constraint se puede llamar "length" (tamaño fijo), lo que no daría
        // los valores correctos al fiarse de ese dato
        Object.keys(constraints).forEach(constraintName => {
            const constraintDefinition = constraints[constraintName];
            validators[`constraint-${constraintName}`] = fieldValue => {
                return this._validateConstraint(constraintName, fieldValue, constraintDefinition);
            };
        });

        if (this.field.isPartialDateAllowed()) {
            if (this.field.isDayRequiredInPartialDate()) {
                validators['partial-day-unknown'] = fieldValue => this._validatePartialDateComponentIsDefined('day', fieldValue);
            }
            if (this.field.isMonthRequiredInPartialDate()) {
                validators['partial-month-unknown'] = fieldValue => this._validatePartialDateComponentIsDefined('month', fieldValue);
            }
            if (this.field.isYearRequiredInPartialDate()) {
                validators['partial-year-unknown'] = fieldValue => this._validatePartialDateComponentIsDefined('year', fieldValue);
            }
        }

        // Por reglas... preguntamos los ID de las acciones de error sobre este campo
        this.errorRulesActions.forEach(action => {
            if (action.definition.target === this.field.getId()) {
                const errorFlag = `errorMessage${action.definition.id}`;
                const key = `custom-${errorFlag}`;

                validators[key] = (fieldValue, listIndices) => {
                    const errorFlags = this.formFields.getCustomErrors(this.field.getId(), listIndices) || [];

                    return !errorFlags.includes(errorFlag);
                };
            }
        });

        this.validators = validators;

        return validators;
    }

    /**
     * Valida el valor del campo para los índices especificados
     *
     * @param  {mixed}           fieldValue  Valor a validar del campo. La razón por la que no se coge directamente del
     *                                       objeto interno de RecordData es que simplemente puede no estar aún
     *                                       actualizado (por ejemplo, en una directiva de Angular)
     * @param  {ListIndices}     listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {FieldValidation}             Resultado de la validación
     */
    validate(fieldValue, listIndices = {}) {
        const validationState = this._getValidationStateBoilerplate();

        if (!this.field || !this.record.hasElement(this.field.getId(), listIndices)) {
            return validationState;
        }

        const validators = this.getValidators();
        let valid = true;
        const errors = [];
        Object.keys(validators).forEach(key => {
            const validator = validators[key];
            if (!validator(fieldValue, listIndices)) {
                errors.push(key);
                valid = false;
            }
        });

        validationState.valid = valid;
        validationState.errors = errors;

        return validationState;
    }

    /**
     * Comprueba que el valor del campo es correcto de acuerdo a su tipo
     *
     * @param  {mixed}   fieldValue Valor del campo en el registro
     *
     * @return {Boolean}            Resultado de la validación
     *
     * @protected
     *
     * @todo Este método no valida todos los tipos de dato aún
     */
    _validateFieldType(fieldValue) {
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        // En este punto ya sabemos que this.field es un Field válido
        const formControl = this.field.getFormControl();

        switch (formControl) {
            case Field.FormControl.SELECT:
            case Field.FormControl.RADIO:
                return _.isString(fieldValue) &&
                    FieldValidationClass.validateOptionExists(this.field.getOptions(), fieldValue);

            case Field.FormControl.MULTIPLE_CHECKBOX:
                return Array.isArray(fieldValue) &&
                    FieldValidationClass.validateOptionsExists(this.field.getOptions(), fieldValue);

            case Field.FormControl.NUMBER:
                return _.isFinite(fieldValue); // números excepto Infinity, -Infinity y NaN

            case Field.FormControl.CHECKBOX:
                return _.isBoolean(fieldValue);

            case Field.FormControl.INPUT:
            case Field.FormControl.TEXTAREA:
                return _.isString(fieldValue);
        }

        return true;
    }

    /**
     * Comprueba que el valor del campo corresponde a una respuesta del conjunto asociado
     *
     * @param  {Structure.FieldOptions} options Instancia del conjunto de respuestas
     * @param  {string}                 value   El valor del campo
     *
     * @return {boolean}                        Si es un código válido en el conjunto
     */
    static validateOptionExists(options, value) {
        if (_.isNil(value) || value === '') {
            return true;
        }

        if (!options) {
            return false;
        }

        const selectedOption = options.getOptionByValue(value);

        return selectedOption !== null;
    }

    /**
     * Comprueba que el todas las opciones incluidas en el valor del campo existen
     *
     * @param  {Structure.FieldOptions} options Instancia del conjunto de respuestas
     * @param  {Array<String>}          value   El valor del campo
     *
     * @return {boolean}                        Si es un valor válido
     */
    static validateOptionsExists(options, value) {
        // ¿?
        if (_.isNil(value)) {
            return true;
        }

        // ¿?
        if (!options) {
            return false;
        }

        return value.reduce((valid, optionValue) => valid && !!options.getOptionByValue(optionValue), true);
    }

    /**
     * Valida una restricción específica de valor del campo
     *
     * @param  {string}  constraintName       Código de la restricción
     * @param  {mixed}   fieldValue           Valor del campo a comprobar
     * @param  {mixed}   constraintDefinition Configuración adicional de la restricción
     *
     * @return {boolean}                      Si el valor cumple la restricción
     *
     * @todo Solamente tiene las restricciones que usan los tipos de hora y número
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        if (!this._validateFieldType(fieldValue)) {
            return true;
        }

        const fieldType = this.field.getFieldType();
        const fieldFormControl = this.field.getFormControl();

        // las validaciones sólo se tienen que evaluar sobre campos con valor, si el campo se considera vacío no puede
        // estar en estado inválido
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        if (fieldType === 'number') {
            if (constraintName === 'max') {
                return _.lte(fieldValue, constraintDefinition);
            }

            if (constraintName === 'min') {
                return _.gte(fieldValue, constraintDefinition);
            }

            if (constraintName === 'no-decimals') {
                return Math.trunc(fieldValue) === fieldValue;
            }

            if (constraintName === 'decimals') {
                const [, decimalPart] = fieldValue.toString().split('.');

                return typeof decimalPart === 'undefined' || decimalPart.length <= constraintDefinition;
            }

            return true;
        }

        // Tipos de texto, en este punto ya se han descartado los valores incorrectos y vacíos
        if (fieldType === 'string') {
            if (constraintName === 'alphanumeric') {
                return /^[a-z 0-9]*$/i.test(fieldValue);
            }

            if (constraintName === 'only-alpha') {
                return /^[a-z ]*$/i.test(fieldValue);
            }

            if (constraintName === 'no-spaces') {
                return /^[^\s]*$/.test(fieldValue);
            }

            if (constraintName === 'minlength') {
                return fieldValue.length >= constraintDefinition;
            }

            if (constraintName === 'maxlength') {
                return fieldValue.length <= constraintDefinition;
            }

            return true;
        }

        debug('Clave de restricción de valor desconocida: "%s"', constraintName);

        return true;
    }

    /**
     *
     * @param {String} componentKey One of: day, month, year
     * @param {*}      fieldValue   Field value
     *
     * @returns {Boolean}           TRUE if validation is successful
     */
    _validatePartialDateComponentIsDefined(componentKey, fieldValue) {
        const value = `${fieldValue}`.split('-');
        const key = componentKey === 'day' ? 2 : componentKey === 'month' ? 1 : 0;
        const componentValue = value[key];

        return componentValue !== 'x';
    }
}

module.exports = FieldValidationClass;
