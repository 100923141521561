'use strict';

const _ = require('lodash');

const RecordDataExpression = require('./RecordData');

/**
 * Clase de gestión de una expresión para obtener el valor del índice de una lista del CRD en el contexto de ejecución
 * de la regla
 *
 * @extends Criteria.RecordDataExpression
 *
 * @property {Number}  listId Identificador único de la lista en la estructura
 *
 * @memberOf Criteria
 */
class ListIndexExpression extends RecordDataExpression {
    /**
     * Constructor de la clase: inicializa el ID de la lista
     *
     * @param {Number}  listId ID numérico único de la lista en el CRF
     */
    constructor(listId) {
        super();

        this.listId = _.toNumber(listId);
    }

    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @return {string} Tipo de expresión: "index"
     */
    getType() {
        return 'index';
    }

    /**
     * Obtiene el ID de la lista de referencia
     *
     * @return {Number}  Identificador único de la lista
     *
     * @example
     * // returns 17
     * const expr = new ListIndexExpression({id: 17});
     * expr.getListId();
     */
    getListId() {
        return this.listId;
    }

    /**
     * Genera una representación de la expresión apta para exportar o depurar
     *
     * @return {string} Información de los datos de la expresión en una cadena
     *
     * @example
     * // returns 'index:17'
     * const expr = new ListIndexExpression({id: 17});
     * expr.toString();
     *
     * @example
     * // returns 'index:17-5'
     * const expr = new ListIndexExpression({id: 17, modifier: 'sub', mod_value: 5});
     * expr.toString();
     */
    toString() {
        return `index:${this.listId}` + super.toString();
    }
}

module.exports = ListIndexExpression;
