'use strict';

const _ = require('lodash');

const ModifiableSide = require('./Modifiable');

/**
 * Tipo de expresión: valor de un elemento del CRF
 */
class CrfElementSide extends ModifiableSide {
    /**
     * Constructor de la clase a partir de una definición
     *
     * @param  {object} definition Definición de la expresión
     */
    constructor(definition) {
        super(definition);
    }

    /**
     * Obtiene el ID del elemento
     *
     * @return {Number}  ID del elemento definido
     */
    getId() {
        return _.toNumber(this.definition.id);
    }

    /**
     * Obtiene la definición del elemento de destino
     *
     * @return {Structure.Container} El objeto de elemento
     */
    getTarget() {
        return this.getCRF().getElement(this.definition.id);
    }

    /**
     * Obtiene los índices de las listas que restringen los elementos afectados por la condición
     *
     * @return {object} Objeto de tipo ListIndices
     */
    getListIndices() {
        return _.get(this.definition, 'lists', {});
    }
}

module.exports = CrfElementSide;
