'use strict';

const _ = require('lodash');

const ConditionsList = require('./Conditions/List');
const ActionFactory = require('./Actions/Factory');

/**
 * Objeto de definición de una regla
 * @typedef {object} RuleDefinition
 *
 * @property {Number}                   id         Identificador único de la regla en la lista general
 * @property {Number}                   context    ID de la lista de contexto de la regla, o null si el contexto es
 *                                                 global
 * @property {ConditionsListDefinition} conditions Definición de las condiciones
 * @property {ActionDefinition[]}       actions    Definición de las acciones
 * @property {Structure.Rules}          parent     Lista que incluye esta regla
 */

/**
 * Formato de exportación de la regla para ser aplicada
 * @typedef {object} ExportedRule
 *
 * @property {Number}                 id         Identificador único de la regla en la lista general
 * @property {Number}                 context    ID de la lista de contexto de la regla, o null si el contexto es global
 * @property {ExportedConditionsList} conditions Condiciones exportadas
 * @property {ExportedAction[]}       actions    Acciones exportadas
 */

/**
 * Objeto que contiene la información de una regla y métodos para manejarla
 *
 * @property {RuleDefinition}           definition El objeto original almacenado en la base de datos
 * @property {Structure.ConditionsList} conditions Objeto de gestión de condiciones
 * @property {Structure.Action[]}       actions    Lista de objetos de acción que ejecuta la regla
 *
 * @memberOf Structure
 */
class Rule {
    /**
     * Constructor de la clase, instancia las condiciones y las acciones de la regla
     *
     * @param  {RuleDefinition} definition Objeto de definición de la regla
     */
    constructor(definition) {
        this.definition = definition;

        this.conditions = new ConditionsList(_.get(definition, 'conditions', {}));
        this.conditions.setParent(this);

        this.actions = _.map(definition.actions, actionDefinition => {
            const action = ActionFactory.instantiateAction(actionDefinition);
            action.setRule(this);

            return action;
        });

        this.parent = null;
    }

    /**
     * Obtiene la representación en base de datos de la regla
     *
     * @return {RuleDefinition} Definición de la regla
     */
    get() {
        return this.definition;
    }

    /**
     * Obtiene una definición de la regla con un formato conocido para su aplicación
     *
     * @return {ExportedRule} El objeto generado
     */
    export() {
        const enabledActions = this.getActions().filter(_.method('isEnabled'));

        return {
            id: this.getId(),
            context: this.getContext(),
            conditions: this.getConditions().export(),
            actions: _.map(enabledActions, _.method('export')),
        };
    }

    /**
     * Obtiene el identificador interno de la regla
     *
     * @return {Number}  ID único numérico
     */
    getId() {
        return _.get(this.definition, 'id');
    }
    /**
     * Obtiene el nombre de la regla
     *
     * @return {String} Nombre
     */
    getName() {
        return this.definition.name || '';
    }

    /**
     * Asigna la lista padre a la que pertenece esta regla
     *
     * @param {Structure.Rules} parentList Objeto global de reglas
     */
    setParent(parentList) {
        this.parent = parentList;
    }

    /**
     * Añade una acción a partir de un objeto de definición
     *
     * @param {ActionDefinition} actionDefinition Definición de la acción
     *
     * @return {Structure.Action} La acción creada
     */
    addAction(actionDefinition) {
        // Hay que asignar un ID a la acción
        const actionIds = _.map(this.actions, action => {
            return _.toNumber(_.last(_.split(action.getId(), '-')));
        });
        const maxActionId = _.max(actionIds.concat(this.actions.length));
        actionDefinition.id = `${this.getId()}-${maxActionId + 1}`;

        const action = ActionFactory.instantiateAction(actionDefinition);
        action.setRule(this);

        this.definition.actions.push(actionDefinition);
        this.actions.push(action);

        return action;
    }

    /**
     * Determina si alguna de las acciones definidas en la regla tiene como objetivo el elemento con el ID seleccionado
     *
     * @param  {Number}   targetId ID único del elemento
     *
     * @return {boolean}           Si alguna acción afecta al elemento
     */
    hasActionTarget(targetId) {
        return _.some(this.actions, action => action.hasTarget(targetId));
    }

    /**
     * Determina si alguna de las acciones definidas en la regla tiene algún objetivo con uno de los IDs seleccionados
     *
     * @param  {integer[]}  targetIds IDs de elementos
     *
     * @return {boolean}              Si alguna acción incluye alguno de los IDs
     */
    hasAnyActionTarget(targetIds) {
        return _.some(this.actions, action => action.hasAnyTarget(targetIds));
    }

    /**
     * Determina si alguna de las condiciones de la regla incluye al elemento del CRF seleccionado
     *
     * @param  {Number}  elementId ID único del elemento
     *
     * @return {boolean}           Si alguna condición incluye al elemento
     */
    hasElementInConditions(elementId) {
        return this.conditions.hasElement(elementId);
    }

    /**
     * Determina si la regla tiene alguna acción de tipo fórmula donde esté incluido el ID del elemento seleccionado
     *
     * @param  {Number}  elementId ID único del elemento
     *
     * @return {boolean}           Si alguna fórmula contiene el elemento
     */
    hasElementInFormulas(elementId) {
        return _.some(this.actions, action => {
            return action.getType() === 'setValue' && action.hasFormula() && action.hasElementInFormula(elementId);
        });
    }

    /**
     * Determina si alguna de las acciones definidas en la regla es del tipo especificado
     *
     * @param  {string}  type Identificador del tipo de acción
     *
     * @return {boolean}      Si alguna acción es del tipo
     */
    hasActionType(type) {
        return _.some(this.actions, _.method('isType', type));
    }

    /**
     * Obtiene la lista de acciones definidas en la regla
     *
     * @return {Structure.Action[]} Lista de acciones
     */
    getActions() {
        return this.actions;
    }

    /**
     * Obtiene la lista de condiciones definidas en la regla
     *
     * @return {Structure.ConditionsList} Objeto de tipo Lista de condiciones
     */
    getConditions() {
        return this.conditions;
    }

    /**
     * Obtiene la lista de condiciones individuales, es decir, excluye las listas de condiciones
     *
     * @return {Structure.Condition[]} Condiciones de la regla
     */
    getSingleConditions() {
        return this.getConditions().flattenConditions();
    }

    /**
     * Obtiene la lista de los campos que están incluidos en las condiciones de la regla
     *
     * @return {Structure.Field[]} Lista de campos
     */
    getFieldsInConditions() {
        return this.conditions.getFields();
    }

    /**
     * Obtiene la lista de los formularios que contienen los campos incluidos en las condiciones de la regla
     *
     * @return {Array<integer>} Lista de ID de formularios
     */
    getFormsInConditions() {
        const forms = _.map(this.conditions.getFields(), _.method('getForm'));

        return _.uniqBy(forms, _.method('getId'));
    }

    /**
     * Obtiene la lista de las listas que están incluidas en las condiciones de la regla
     *
     * @return {Array<integer>} Lista de ID de listas
     */
    getListsInConditions() {
        return this.conditions.getLists();
    }

    /**
     * Obtiene la lista de las listas que están implicadas en las acciones de la regla, es decir, que algún campo
     * de la acción se encuentra bajo esa lista
     *
     * @return {Array<integer>} Lista de ID de listas
     */
    getListsInActions() {
        const crf = this.getCRF();

        return _.chain(this.actions)
            .map(action => {
                if (action.definition.target) {
                    const element = crf.getElement(action.definition.target);
                    if (element) {
                        return element.getParentLists();
                    }
                }
            })
            .flatten()
            .compact()
            .value();
    }

    /**
     * Obtiene la referencia al objeto global de CRF
     *
     * @return {Structure.CRF} Objeto de gestión del estudio
     */
    getCRF() {
        if (!this.parent) {
            return null;
        }

        return this.parent.getCRF();
    }

    /**
     * Obtiene el ID de la lista de contexto de ejecución de la regla (null si es de contexto global)
     *
     * @return {Number}  el identificador numérico de la lista
     */
    getContext() {
        return this.definition.context;
    }

    /**
     * Obtiene la definición de las listas que intervienen en el contexto de la regla
     * Si la regla tiene un contexto de lista, el resultado es la propia lista junto con sus listas de nivel superior
     *
     * @return {Structure.List[]} Lista de listas de contexto
     */
    getContextLists() {
        const listId = this.getContext();
        if (!listId) {
            return [];
        }

        const crf = this.getCRF();
        if (!crf) {
            return [];
        }

        const list = crf.getList(listId);
        if (!list) {
            return [];
        }

        return list.getParentLists().concat(list);
    }

    /**
     * Obtiene una acción definida en la regla
     *
     * @param  {string}           actionId ID de la acción
     *
     * @return {Structure.Action}          La acción solicitada
     */
    getAction(actionId) {
        return _.find(this.actions, action => {
            return action.getId() === actionId;
        });
    }

    /**
     * Determina si la regla contiene la acción seleccionada
     *
     * @param  {string}  actionId ID de la acción
     *
     * @return {boolean}          Si existe en esta regla
     */
    hasAction(actionId) {
        return !_.isUndefined(this.getAction(actionId));
    }

    /**
     * Elimina la acción seleccionada
     *
     * @param  {Structure.Action} action La acción a eliminar
     *
     * @return {boolean}                 Resultado de la operación
     */
    removeAction(action) {
        const actionId = action.getId();

        const hasRemoved = _.remove(this.actions, ruleAction => {
            return ruleAction === action;
        }).length > 0;

        _.remove(this.definition.actions, {id: actionId});

        return hasRemoved;
    }

    /**
     * Obtiene los elementos del CRF que son destinatarios de acciones de la regla
     *
     * @return {Structure.Container[]} Lista de elementos
     */
    getActionTargets() {
        return _(this.getActions())
            .map(_.method('getTarget'))
            .uniqBy()
            .filter()
            .value();
    }

    /**
     * Obtiene la relación de campos que aparecen como destinatarios de las acciones de la regla
     *
     * @return {Structure.Field[]} Lista de instancias de campo
     */
    getFieldsInActions() {
        return this.getActionTargets().filter(_.method('isField'));
    }

    /**
     * Determina si la regla se puede encadenar. Esto significa que el resultado de alguna de sus acciones puede a su
     * vez desencadenar otra regla; por ejemplo, si establece el valor de un campo ese mismo campo puede entrar en las
     * condiciones de otra regla que habría que encadenar
     *
     * @return {boolean} Si alguna de las acciones de la regla tiene potencial para servir de entrada a otra regla
     */
    isChainable() {
        return _.some(this.getActions(), action => {
            // Las acciones de tipo "hideChildren" restablecen el valor de las variables que se ocultan
            return _.includes(['setValue', 'hideChildren'], action.getType());
        });
    }

    /**
     * Determina si en la regla hay condiciones de comparación con un valor de aleatorización
     *
     * @return {boolean} Si alguna de las condiciones implica la aleatorización del registro
     */
    hasRandomization() {
        return this.getConditions().hasRandomization();
    }

    /**
     * Obtiene los identificadores de campos del registro que intervienen en las condiciones de esta regla
     *
     * @return {RecordFields[]} Lista de identificadores de campos
     */
    getRecordFieldsInConditions() {
        return this.getConditions().getRecordFields();
    }

    /**
     * Determina si la regla está deshabilitada
     *
     * @return {boolean} Si está deshabilitada
     */
    isDisabled() {
        return _.get(this.definition, 'disabled');
    }

    /**
     * Determina si la regla está habilitada
     *
     * @return {boolean} Si está habilitada (no está deshabilitada)
     */
    isEnabled() {
        return !this.isDisabled();
    }

    /**
     * Marca el flag de deshabilitado
     *
     * @return {Action} El objeto de regla modificado
     */
    disable() {
        _.set(this.definition, 'disabled', true);

        return this;
    }

    /**
     * Desmarca el flag de deshabilitado
     *
     * @return {Action} El objeto de regla modificado
     */
    enable() {
        _.set(this.definition, 'disabled', false);

        return this;
    }
}

module.exports = Rule;
