'use strict';

const { pick } = require('lodash');

/**
 * Información sobre el autor de un cambio en un metadato (el firmante)
 *
 * @typedef {object} MetadataAuthor
 *
 * @property {Number}  id     ID del usuario en BD
 * @property {string}  email  Email del usuario tal y como estaba en el momento de la acción
 */

/**
 * Tipos de operación realizada
 * @enum {string}
 * @readOnly
 */
const MetadataType = {
    MANUAL: 'manual',
    AUTOMATIC: 'auto',
};

/**
 * Información asociada a un elemento cualquiera de metadatos
 *
 * @typedef {object} MetadataItemData
 *
 * @property {MetadataType}   type   Tipo de operación: automática o manual
 * @property {mixed}          value  El valor del metadato
 * @property {Date}           date   Momento en el que se ha modificado
 * @property {MetadataAuthor} author Información del usuario que lo ha modificado
 * @property {boolean}        signed Si el cambio de valor se ha validado con firma
 */

/**
 * Información asociada a un elemento cualquiera de metadatos
 *
 * @property {MetadataItemData} data Los datos informativos
 */
class MetadataItem {
    /**
     * Inicialización de los datos internos
     *
     * @param {MetadataItemData} data Datos guardados
     */
    constructor(data) {
        this.data = Object.assign({}, data);

        // Nunca puede haber una propiedad llamada "password" en este objeto
        delete this.data.password;
    }

    /**
     * Obtiene los datos asociados al ítem
     *
     * @return {MetadataItemData} Los datos guardados
     */
    get() {
        return this.data;
    }

    /**
     * Establece la propiedad de firmado
     *
     * @param  {boolean}      signed Si el cambio de valor se ha firmado
     *
     * @return {MetadataItem}        El ítem actualizado
     */
    setSigned(signed = true) {
        this.data.signed = !!signed;

        return this;
    }

    /**
     * Establece el usuario autor de la modificación
     *
     * @param  {object}      user Datos del usuario
     *
     * @return {MetadataItem}     El ítem actualizado
     */
    setAuthor(user = {}) {
        this.data.author = pick(user, 'id', 'email', 'fullname');

        return this;
    }

    /**
     * Establece el momento actual como fecha de modificación
     *
     * @return {MetadataItem} El propio ítem
     */
    addTimestamp() {
        this.data.date = new Date();

        return this;
    }

    /**
     * Establece el tipo de operación manual
     *
     * @return {MetadataItem} El ítem actualizado
     */
    setManual() {
        this.data.type = MetadataType.MANUAL;

        return this;
    }

    /**
     * Establece el tipo de operación automático
     *
     * @return {MetadataItem} El ítem actualizado
     */
    setAutomatic() {
        this.data.type = MetadataType.AUTOMATIC;

        return this;
    }

    /**
     * Establece el valor asociado al flag
     *
     * @param  {mixed}        value El valor a establecer
     *
     * @return {MetadataItem}       El ítem actualizado
     */
    setValue(value) {
        this.data.value = value;

        return this;
    }
}

module.exports = MetadataItem;
module.exports.instance = (...args) => new MetadataItem(...args);
