'use strict';

const Action = require('./Action');

/**
 * Clase de gestión de una regla de tipo Deshabilitar variable
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class DisableAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.DISABLE;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        let action = super.export();
        action.func = 'disableField';

        return action;
    }
}

module.exports = DisableAction;
