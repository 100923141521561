'use strict';

const Action = require('./Action');

/**
 * @typedef {object} ExportedMessageAction
 *
 * @property {string} func Nombre de la función: showFieldMessage
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - el ID del campo
 *                         - el ID del mensaje
 */

/**
 * Clase de gestión de una regla de tipo Mostrar un mensaje asociado a un campo
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class MessageAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.MESSAGE;
    }

    /**
     * Obtiene el texto del mensaje de error
     *
     * @return {string} El mensaje de error definido
     */
    getMessage() {
        return this.definition.message || '';
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @return {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        action.func = 'showFieldMessage';

        return action;
    }
}

module.exports = MessageAction;
