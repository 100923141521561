'use strict';

const _ = require('lodash');

/**
 * Información de una opción individual
 *
 * @typedef {optionDefinition}
 *
 * @property {Number}  id    Identificador único de cada opción
 * @property {string}  value Valor que toma el campo cuando se selecciona la opción
 * @property {string}  label Etiqueta identificativa del valor
 *
 * @memberOf Structure
 */

/**
 * @property {Number}  id    Identificador único numérico e interno del grupo de opciones
 * @property {string}  name  Nombre identificativo del grupo de opciones en el CRF
 * @property {Array<Structure.optionDefinition>} options  Lista de objetos de opción
 *
 * @memberOf Structure
 */
class FieldOptions {
    /**
     * Constructor de la clase: guarda la definición de la lista en la instancia
     *
     * @param  {object} definition   Definición de las opciones {id, name, options}
     */
    constructor(definition) {
        this.id = definition.id;
        this.name = definition.name;
        this.options = definition.options || [];
    }

    /**
     * Obtiene un objeto con la información del grupo de opciones
     *
     * @return {object}  Definición del grupo de opciones
     */
    get() {
        return {
            id: this.id,
            name: this.name,
            options: this.options,
        };
    }

    /**
     * Obtiene el identificador único del grupo de opciones
     *
     * @return {Number}  ID único numérico
     */
    getId() {
        return this.id;
    }

    /**
     * Obtiene el nombre del grupo de opciones
     *
     * @return {string} Nombre del grupo de opciones
     */
    getName() {
        return this.name;
    }

    /**
     * Obtiene la lista de opciones completa en forma de array de objetos
     *
     * @return {Array<optionDefinition>}  Lista de opciones
     */
    getOptionList() {
        return this.options;
    }

    /**
     * Obtiene la definición de una opción dado su identificador único
     *
     * @param  {Number}  optionId ID de la opción a buscar
     *
     * @return {object}      Opción encontrada, NULL en caso de no existir en la lista
     */
    getOption(optionId) {
        return _.find(this.options, {id: _.toNumber(optionId)}) || null;
    }

    /**
     * Determina si existe la respuesta con el ID escogido en el conjunto
     *
     * @param  {Number}  optionId ID de la respuesta a evaluar
     *
     * @return {boolean}          Si hay una respuesta con el ID
     */
    hasOption(optionId) {
        return this.getOption(optionId) !== null;
    }

    /**
     * Obtiene la definición de una opción dado su valor
     *
     * @param  {integer|Array<Integer>} value Valor de la opción a buscar
     *
     * @return {object|Array<Object>}         Opción encontrada, NULL en caso de no existir en la lista
     */
    getOptionByValue(value) {
        if (Array.isArray(value)) {
            const optionValues = _.map(this.options, 'value');

            // aquí se ordenan los valores de entrada y de paso nos pulimos los que no están en la lista de respuestas
            const orderedValues = _.intersection(optionValues, value);

            return _.map(orderedValues, itemValue => {
                return _.find(this.options, {value: itemValue});
            });
        }

        return _.find(this.options, {value: value}) || null;
    }

    /**
     * Obtiene la definición de una respuesta del conjunto a partir de su etiqueta
     *
     * @param  {string} label La etiqueta de respuesta a buscar
     *
     * @return {object}       Opción encontrada
     */
    getOptionByLabel(label) {
        return _.find(this.options, {label: label}) || null;
    }
    /**
     * Devuelve el índice dentro de la lista de opciones que ocupa la opción indicada
     *
     * @param  {optionDefinition} option Opción
     *
     * @return {Number}           Índice de la opción, NULL si no existe
     */
    getOptionIndex(option) {
        return _.findIndex(this.options, {id: _.toNumber(option.id)});
    }
}

module.exports = FieldOptions;
