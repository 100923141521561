'use strict';

/**
 * Clase genérica con la configuración mínima
 *
 * @property {object} definition Objeto de definición de las propiedades de la clase
 */
class CoreBase {
    /**
     * Constructor, guarda el objeto interno de definición
     *
     * @param  {object} definition Objeto de definición
     */
    constructor(definition) {
        this.definition = definition;
    }

    /**
     * Obtiene el objeto de definición
     *
     * @return {object} Definición
     */
    get() {
        return this.definition;
    }
}

module.exports = CoreBase;
