'use strict';

const Functions = require('./Functions');
const Operators = require('./Operators');
const ListIdentifiers = require('./ListIdentifiers');

/**
 * Definición de una fórmula
 *
 * @namespace Formula
 *
 * @property {string} _internalValue Valor interno, con lo que trabaja el core
 * @property {string} _readableValue Valor legible con placeholders de campos resueltos
 */
class Formula {
    /**
     * Constructor de la clase
     *
     * @param {string} formula Valor interno de la fórmula
     */
    constructor(formula) {
        this._internalValue = formula;

        // Para generar el valor legible vamos a esperar a que se solicite la primera vez
        // El valor null sirve para distinguir de una cadena vacía, que sería una fórmula válida
        this._readableValue = null;
    }

    /**
     * Lista de funciones disponibles
     */
    static get Functions() {
        return Functions;
    }

    /**
     * Lista de operadores disponibles
     */
    static get Operators() {
        return Operators;
    }

    /**
     * Lista de identificadores de índices de lista disponibles
     */
    static get ListIdentifiers() {
        return ListIdentifiers;
    }

    /**
     * Obtiene el valor interno de la fórmula
     *
     * @return {string} El valor interno
     */
    getValue() {
        return this._internalValue;
    }

    /**
     * Obtiene el valor legible de la fórmula, generándolo en caso de no existir
     *
     * @return {string} El valor interno
     */
    getReadableValue() {
        if (this._readableValue === null) {
            this._makeReadableValue();
        }

        return this._readableValue;
    }

    /**
     * Genera la representación legible de la fórmula a partir de su valor interno y la almacena para futuros usos
     *
     * @private
     */
    _makeReadableValue() {
    }

    /**
     * Representación como string de la fórmula, equivalente a getValue()
     *
     * @return {string} El valor interno
     */
    toString() {
        return this.getValue();
    }
}

module.exports = Formula;
