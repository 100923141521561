'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: Confirmación de datos perdidos
 */
class MissingDataConfiguration extends ConfigurationModule {
    /**
     * Constructor estático
     *
     * @param  {Object} params Argumentos de entrada
     */
    constructor(params) {
        const definition = {
            reasons: [],
            ...params,
        };
        super(definition);
    }

    /**
     * Obtiene los valores preconfigurados de las razones de confirmación
     *
     * @return {string[]} Lista de mensajes predefinidos
     *
     * @example
     * // returns [{ message: 'message1', extra: 'allow' }, { message: 'message2', extra: 'forbid' }]
     * const configuration = new MissingDataConfiguration({
     *     enabled: true,
     *     reasons: [
     *         { message: 'message1', extra: 'allow' },
     *         { message: 'message2', extra: 'forbid' },
     *     ],
     * });
     * configuration.getReasons();
     */
    getReasons() {
        return this.definition.reasons;
    }
}

module.exports = MissingDataConfiguration;
