'use strict';

const BaseModule = require('../CoreBase');

class CodingScopeConfiguration extends BaseModule {
    /**
     * Obtiene el ID único del Scope
     *
     * @return {String} ID de scope
     */
    getId() {
        return this.definition.id;
    }
}

module.exports = CodingScopeConfiguration;