'use strict';

/**
 * @typedef {object} FormulaDefinition
 *
 * @property {string}   name        Nombre identificativo de la función
 * @property {string}   category    Identificador de la categoría a la que pertenece
 * @property {Number}   [arity]     Número fijo de argumentos
 * @property {Number}   [minArity]  Número mínimo de argumentos que acepta
 * @property {Number}   [maxArity]  Número máximo de argumentos que acepta
 * @property {string[]} [arguments] Tipo de dato de los argumentos de entrada
 * @property {string}   [returns]   Tipo de dato devuelto por la función
 */

/**
 * Definición de una función dentro de la fórmula
 *
 * @property {FormulaDefinition} definition Objeto de definición
 *
 * @namespace Formula
 */
class FormulaFunction {
    /**
     * Inicializa la definición de la fórmula
     *
     * @param  {FormulaDefinition} definition Objeto de definición
     */
    constructor(definition) {
        this.definition = definition;
    }

    /**
     * Obtiene el nombre identificativo
     *
     * @return {string} Nombre de la función
     */
    getName() {
        return this.definition.name;
    }

    /**
     * Obtiene el número mínimo de argumentos aceptados
     *
     * @return {Number}  Número mínimo de argumentos
     */
    getMinimumArity() {
        if (this.definition.arity !== undefined) {
            return this.definition.arity;
        }

        if (this.definition.minArity !== undefined) {
            return this.definition.minArity;
        }

        return 0;
    }

    /**
     * Obtiene el número máximo de argumentos aceptados
     *
     * @return {Number}  Número máximo de argumentos
     */
    getMaximumArity() {
        if (this.definition.arity !== undefined) {
            return this.definition.arity;
        }

        if (this.definition.maxArity !== undefined) {
            return this.definition.maxArity;
        }

        return Infinity;
    }

    /**
     * Obtiene la definición del argumento con el índice especificado
     *
     * @param  {Number}  index Índice del argumento
     *
     * @return {string}        El tipo de dato del argumento
     */
    getArgument(index) {
        if (this.definition.arguments === undefined) {
            return {
                numeric: 'number',
                geometric: 'number',
                text: 'string',
            }[this.getCategory()];
        }

        if (this.definition.arguments.length > index) {
            return this.definition.arguments[index];
        }

        if (this.definition.minArity !== undefined) {
            index = index % this.definition.minArity;

            return this.definition.arguments[index];
        }

        return null;
    }

    /**
     * Obtiene el identificador del tipo de dato que devuelve la función
     *
     * @return {string} Tipo de dato
     */
    getReturnedType() {
        if (this.definition.returns === undefined) {
            return {
                numeric: 'number',
                geometric: 'number',
                text: 'string',
            }[this.getCategory()];
        }

        return this.definition.returns;
    }

    /**
     * Obtiene la categoría de funciones a la que pertenece esta función
     *
     * @return {string} Identificador de categoría
     */
    getCategory() {
        return this.definition.category;
    }
}

module.exports = FormulaFunction;
