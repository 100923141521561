'use strict';

const _ = require('lodash');

const AuditConfiguration = require('./Configuration/Audit');
const DocumentsConfiguration = require('./Configuration/Documents');
const EncryptedFieldsConfiguration = require('./Configuration/EncryptedFields');
const EproConfiguration = require('./Configuration/Epro');
const GroupsConfiguration = require('./Configuration/Groups');
const NotificationsConfiguration = require('./Configuration/Notifications');
const QueriesConfiguration = require('./Configuration/Queries');
const RandomizationConfiguration = require('./Configuration/Randomization');
const MissingDataConfiguration = require('./Configuration/MissingData');
const RecordStatusModule = require('./Configuration/RecordStatusModule');
const RevisionStatesConfiguration = require('./Configuration/RevisionStates');
const StatesConfiguration = require('./Configuration/States');
const MedicalCodingConfiguration = require('./Configuration/MedicalCoding');

/**
 * Campos del registro permitidos para aparecer en el listado principal
 *
 * @enum {string}
 *
 * @readOnly
 */
const ListableRecordFields = {
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
};

/**
 * Objeto que contiene la definición de la configuración de la aplicación, útil para hacer consultas en tiempo de
 * diseño y creación del estudio
 *
 * @property {object}                       modules                 Lista de módulos de configuración
 * @property {DocumentsConfiguration}       modules.documents       Configuración de documentos
 * @property {QueriesConfiguration}         modules.queries         Configuración de queries
 * @property {StatesConfiguration}          modules.states          Configuración de estados de aprobación
 * @property {AuditConfiguration}           modules.audit           Configuración de audit trail
 * @property {GroupsConfiguration}          modules.groups          Configuración de grupos de centros
 * @property {RandomizationConfiguration}   modules.randomization   Configuración de aleatorización
 * @property {EncryptedFieldsConfiguration} modules.encryptedFields Configuración de campos encriptados
 * @property {RevisionStatesConfiguration}  modules.RevisionStates  Configuración de estados de revisión
 * @property {MissingDataConfiguration}     modules.missingData     Configuración de confirmación de datos perdidos
 * @property {MedicalCodingConfiguration}   modules.medicalCoding   Configuración de Medical Coding
 */
class Configuration {
    /**
     * Constructor de la clase a partir de un objeto de definición
     * @param  {Object} definition Definición de la configuración tal y como se guarda en la base de datos
     */
    constructor(definition = {}) {
        _.defaults(definition, {
            audit: {},
            completionProgress: false,
            dateFormat: 'DD-MMM-YYYY',
            documents: {},
            encryptedFields: {},
            entityLabel: 'Pacientes',
            entityLabelSingular: 'Paciente',
            epro: {},
            groups: {},
            hideCompletionCounter: false,
            language: 'es',
            languages: [],
            medicalCoding: {},
            missingData: {},
            notifications: {},
            queries: {},
            queriesShowInRecordsList: true,
            randomization: {},
            revision: {},
            states: {},
            status: {},
            uidTemplate: '[[id:auto]]',
            patientsListOrderBy: 'createdAt',
            patientsListOrderDir: 'desc',
            unknown: {},
        });

        this.definition = definition;

        this.modules = {
            audit: AuditConfiguration.instance(this.definition.audit),
            documents: DocumentsConfiguration.instance(this.definition.documents),
            encryptedFields: EncryptedFieldsConfiguration.instance(this.definition.encryptedFields),
            epro: EproConfiguration.instance(this.definition.epro),
            groups: GroupsConfiguration.instance(this.definition.groups),
            missingData: MissingDataConfiguration.instance(this.definition.missingData),
            notifications: NotificationsConfiguration.instance(this.definition.notifications),
            queries: QueriesConfiguration.instance(this.definition.queries),
            randomization: RandomizationConfiguration.instance(this.definition.randomization),
            recordStatus: RecordStatusModule.instance(this.definition.status),
            revisionStates: RevisionStatesConfiguration.instance(this.definition.revision),
            states: StatesConfiguration.instance(this.definition.states),
            medicalCoding: MedicalCodingConfiguration.instance(this.definition.medicalCoding),
        };
    }

    /**
     * Obtiene el objeto de definición de la clase con propiedades editables
     *
     * @return {object} Objeto con la definición
     */
    get() {
        return this.definition;
    }

    /**
     * Constantes de nombre de campo permitidos en el listado principal
     */
    static get ListableRecordFields() {
        return ListableRecordFields;
    }

    /**
     * Obtiene el texto definido para designar las entidades/cuadernos del estudio, en plural
     * @return {string|null} Etiqueta definida
     * @example
     * // returns 'Participantes'
     * let configuration = new Configuration({entityLabel: 'Participantes'});
     * configuration.getLabelPlural();
     */
    getLabelPlural() {
        return _.get(this.definition, 'entityLabel', null);
    }

    /**
     * Obtiene el texto definido para designar las entidades/cuadernos del estudio, en singular
     * @return {string|null} Etiqueta definida
     * @example
     * // returns 'Participante'
     * let configuration = new Configuration({entityLabelSingular: 'Participante'});
     * configuration.getLabelSingular();
     */
    getLabelSingular() {
        return _.get(this.definition, 'entityLabelSingular', null);
    }

    /**
     * Obtiene el idioma principal de la aplicación, usado para generar los textos fijos
     * @return {string} Código ISO 639-1 del idioma seleccionado, por defecto español ("es")
     * @example
     * // returns 'en'
     * let configuration = new Configuration({language: 'en'});
     * configuration.getLanguage();
     */
    getLanguage() {
        return _.get(this.definition, 'language', 'es');
    }

    /**
     * Obtener el formato de fecha del estudio
     *
     * @returns {String} Formato de fecha
     */
    getDateFormat() {
        return this.definition.dateFormat;
    }

    /**
     * Determina si el template para generar el UID tiene una estrategia que implica pedir al investigador
     * un UID manual para numerar su registro en algún punto de la plantilla.
     *
     * @return {boolean} [description]
     */
    hasUidStrategyManual() {
        const template = this.getUIdTemplate();
        if (template !== null) {
            return template.indexOf('id:manual') !== -1;
        }

        return false;
    }

    /**
     * Obtiene la plantilla definida de generación de IDs automáticos
     * @todo Adjuntar @link al módulo de id-templates
     * @return {string|null} Cadena de plantilla
     * @example
     * // returns '[[id:auto:5]]'
     * let configuration = new Configuration({uidTemplate: '[[id:auto:5]]'});
     * configuration.getUIdTemplate();
     */
    getUIdTemplate() {
        return _.get(this.definition, 'uidTemplate', null);
    }

    /**
     * Obtiene el campo del sitio al que pertenece el registro que se usa de identificador en el listado de entidades
     * @return {string|null} "code" o "name", o null si no se define
     * @example
     * // returns 'code'
     * let configuration = new Configuration({listableSiteField: 'code'});
     * configuration.getSiteFieldInEntityList();
     */
    getSiteFieldInEntityList() {
        if (['code', 'name'].indexOf(this.definition.listableSiteField) > -1) {
            return this.definition.listableSiteField;
        }

        return null;
    }

    /**
     * Obtiene la lista de campos del registro definidos para aparecer en el listado principal de registros
     *
     * @return {ListableRecordFields[]} Los nombres de los campos
     */
    getListableRecordFields() {
        return _.get(this.definition, 'listableRecordFields', []);
    }

    /**
     * Get the property to sort the patients list by default
     *
     * @returns {'uid'|'createdAt'} Property to sort the patients list by default
     */
    getPatientsListOrderBy() {
        return this.definition.patientsListOrderBy;
    }

    /**
     * Get the direction to sort the patients list by default
     *
     * @returns {'asc'|'desc'} Direction to sort the patients list by default
     *
     */
    getPatientsListOrderDir() {
        return this.definition.patientsListOrderDir;
    }

    /**
     * Obtiene el objeto de configuración del módulo de documentos
     *
     * @return {DocumentsConfiguration} Objeto de configuración de documentos
     */
    getDocuments() {
        return this.modules.documents;
    }

    /**
     * Determina si la aplicación tiene definido soporte para subida y descarga de documentos
     * @return {boolean} Valor definido
     * @example
     * // returns true
     * let configuration = new Configuration({documents: {enabled: true}});
     * configuration.hasDocuments();
     */
    hasDocuments() {
        return this.getDocuments().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de queries
     *
     * @return {QueriesConfiguration} Objeto de configuración de queries
     */
    getQueries() {
        return this.modules.queries;
    }

    /**
     * Determina si se ha configurado el soporte para queries en la aplicación
     * @return {boolean} Valor definido
     * @example
     * // returns true
     * let configuration = new Configuration({queries: {enabled: true}});
     * configuration.hasQueries();
     */
    hasQueries() {
        return this.getQueries().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de estados
     *
     * @return {StatesConfiguration} Objeto de configuración de estados
     */
    getStates() {
        return this.modules.states;
    }

    /**
     * Determina si se ha configurado el soporte para gestión de estados de entidad y formularios
     * (completado, bloqueado, firmado, etc.)
     *
     * @return {boolean}  TRUE si tiene activado el Control de Estados
     *
     * @example
     * // returns true
     * let configuration = new Configuration({states: {enabled: true}});
     * configuration.hasStates();
     */
    hasStates() {
        return this.getStates().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de audit trail
     *
     * @return {AuditConfiguration} Objeto de configuración de audit trail
     */
    getAudit() {
        return this.modules.audit;
    }

    /**
     * Determina si la aplicación tiene definido soporte para audit trail
     * @return {boolean} Valor definido
     * @example
     * // returns true
     * let configuration = new Configuration({audit: {enabled: true}});
     * configuration.hasAudit();
     */
    hasAudit() {
        return this.getAudit().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de grupos de centros
     *
     * @return {GroupsConfiguration} Objeto de configuración de grupos de centros
     */
    getGroups() {
        return this.modules.groups;
    }

    /**
     * Determina si se ha configurado el soporte para grupos de centros
     * @return {boolean} Valor definido
     * @example
     * // returns true
     * let configuration = new Configuration({groups: {enabled: true}});
     * configuration.hasGroups();
     */
    hasGroups() {
        return this.getGroups().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de aleatorización
     *
     * @return {RandomizationConfiguration} Objeto de configuración de aleatorización
     */
    getRandomization() {
        return this.modules.randomization;
    }

    /**
     * Determina si tiene habilitada la gestión de aleatorización
     *
     * @return {boolean} Si tiene activado el flag "randomization.enabled"
     */
    hasRandomization() {
        return this.getRandomization().isEnabled();
    }

    /**
     * Obtiene el objeto de configuración del módulo de notificaciones
     *
     * @return {NotificationsConfiguration} Objeto de configuración de notificaciones
     */
    getNotifications() {
        return this.modules.notifications;
    }

    /**
     * Determina si tiene habilitada la gestión de notificaciones
     *
     * @return {boolean} Si tiene activado el flag "notifications.enabled"
     */
    hasNotifications() {
        return this.getNotifications().isEnabled();
    }

    /**
     * Elimina las referencias al campo del CRF especificado que se encuentren en la configuración
     *
     * @param  {Number}  fieldId El ID único del campo
     */
    removeFieldReferences(fieldId) {
        const fieldsInRandomizationCohorts = _.get(this.definition, ['randomization', 'cohorts', 'fields'], []);

        _.remove(fieldsInRandomizationCohorts, cohortFieldId => {
            return cohortFieldId === fieldId;
        });
    }

    /**
     * Obtiene el valor más alto de ID de los elementos de la configuración que tienen uno
     *
     * @return {Number}  ID más alto de elemento
     */
    getMaxId() {
        // Por ahora la única posibilidad es que sean brazos de aleatorización
        // (si están definidos, aunque no haya aleatorización activa, hay que gestionarlos)
        const randomizationBranches = this.getRandomization().getArms();
        const maxBranch = _.maxBy(randomizationBranches, 'id');

        return _.get(maxBranch, 'id', 0);
    }

    /**
     * Determina si hay cálculo de completado de campos requeridos
     *
     * @return {boolean} Valor definido
     */
    hasCompletionProgress() {
        return !!this.definition.completionProgress;
    }

    /**
     * Determina si se deben ocultar en el menú los contadores de campos requeridos
     *
     * @return {boolean} Valor definido
     */
    hidesCompletionCounter() {
        return !!this.definition.hideCompletionCounter;
    }

    /**
     * Obtiene el objeto de configuración del módulo de ePRO
     *
     * @return {EproConfiguration} Objeto de configuración de ePRO
     */
    getEpro() {
        return this.modules.epro;
    }

    /**
     * Determina si tiene habilitada la gestión de ePRO
     *
     * @return {boolean} Si tiene activado el flag "epro.enabled"
     */
    hasEpro() {
        return this.getEpro().isEnabled();
    }

    /**
     * Obtiene la configuración de idiomas del estudio
     *
     * @return {Object[]} Lista de pares [code, name] de idioma
     */
    getLanguages() {
        return this.definition.languages;
    }

    /**
     * Obtiene la instancia de configuración del módulo de campos encriptados
     *
     * @return {EncryptedFieldsConfiguration} Configuración de campos encriptados
     */
    getEncryptedFields() {
        return this.modules.encryptedFields;
    }

    /**
     * Determina si tiene habilitada la configuración de campos encriptados
     *
     * @return {boolean} Si tiene activado el flag "encryptedFields.enabled"
     */
    hasEncryptedFields() {
        return this.getEncryptedFields().isEnabled();
    }

    /**
     * Obtiene la instancia de configuración del módulo de confirmación de datos perdidos
     *
     * @return {MissingDataConfiguration} Configuración de confirmación de datos perdidos
     */
    getMissingData() {
        return this.modules.missingData;
    }

    /**
     * Determina si tiene habilitada la configuración de confirmación de datos perdidos
     *
     * @return {Boolean} Si tiene activado el flag "missingData.enabled"
     */
    hasMissingData() {
        return this.getMissingData().isEnabled();
    }

    /**
     * Obtener propiedades de campo "No disponible"
     *
     * @return {Object} Propiedades label y tooltip
     */
    getUnknown() {
        return this.definition.unknown;
    }

    /**
     * Obtiene la instancia de configuración del módulo de estados de paciente
     *
     * @return {RecordStatusModule} Configuración de estados de paciente
     */
    getRecordStatus() {
        return this.modules.recordStatus;
    }

    /**
     * Determina si tiene habilitada la configuración de campos encriptados
     *
     * @return {boolean} Si tiene activado el flag "encryptedFields.enabled"
     */
    hasRecordStatus() {
        return this.getRecordStatus().isEnabled();
    }

    /**
     * Obtiene la instancia de configuración del módulo de estados de revisión
     *
     * @return {RevisionStatesConfiguration} Configuración de estados de revisión
     */
    getRevisionStates() {
        return this.modules.revisionStates;
    }

    /**
     * Determina si tiene habilitada la configuración de estados de revisión
     *
     * @return {boolean} Si tiene activado el flag de "revisionStates.enabled"
     */
    hasRevisionStates() {
        return this.getRevisionStates().isEnabled();
    }

    /**
     * Obtiene la instancia de configuración de Medical Coding
     *
     * @return {MedicalCodingConfiguration} Configuración de Medical Coding
     */
    getMedicalCoding() {
        return this.modules.medicalCoding;
    }

    /**
     * Determina si tiene habilitada la configuración de Medical Coding
     *
     * @return {boolean} Si tiene activado el flag de "medicalCoding.enabled"
     */
    hasMedicalCoding() {
        return this.getMedicalCoding().isEnabled();
    }

    /**
     * Determina si tiene habilitada la configuración de visitas programadas
     *
     * @return {boolean} Si tiene activado el flag de "scheduledVisits.enabled"
     */
    hasScheduledVisits() {
        return !!this.definition.scheduledVisits && !!this.definition.scheduledVisits.enabled;
    }

    /**
     * Determina si tiene recordatorios definidos, de cualquiera de sus fuentes
     *
     * @return {boolean} Si tiene activado el flag de "reminders.enabled"
     */
    hasReminders() {
        return !!this.definition.reminders && !!this.definition.reminders.enabled;
    }
}

module.exports = Configuration;

module.exports.Documents = DocumentsConfiguration;
module.exports.Queries = QueriesConfiguration;
module.exports.States = StatesConfiguration;
module.exports.Audit = AuditConfiguration;
module.exports.Groups = GroupsConfiguration;
module.exports.Randomization = RandomizationConfiguration;
module.exports.EncryptedFields = EncryptedFieldsConfiguration;
module.exports.Revision = RevisionStatesConfiguration;
module.exports.MissingData = MissingDataConfiguration;
