'use strict';

const _ = require('lodash');

const Action = require('./Action');

/**
 * @typedef {object} ExportedErrorAction
 *
 * @property {string} func Nombre de la función: showFieldError
 * @property {Array}  args Argumentos de entrada a la función:
 *                         - el ID del campo
 *                         - el ID del mensaje de error
 */

/**
 * Clase de gestión de una regla de tipo Mostrar un error asociado a un campo
 *
 * @extends {Structure.Action}
 *
 * @memberOf Structure
 */
class ErrorAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.ERROR;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @return {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        let action = super.export();
        action.func = 'showFieldError';

        return action;
    }

    /**
     * Obtiene el texto del mensaje de error
     *
     * @return {string} El mensaje de error definido
     */
    getMessage() {
        return this.definition.message || '';
    }
}

module.exports = ErrorAction;
