'use strict';

const _ = require('lodash');

const RecordDataExpression = require('./RecordData');

/**
 * Clase de gestión de una expresión para obtener el valor de un campo del CRD
 *
 * @extends Criteria.RecordDataExpression
 *
 * @property {Number}      fieldId     Identificador único del campo en la estructura
 * @property {object|null} modifier    Modificador sobre el valor del campo
 * @property {object|null} listIndices Índices de las listas que pueden contener el campo
 *
 * @memberOf Criteria
 */
class FieldExpression extends RecordDataExpression {
    /**
     * Constructor de la clase: inicializa el ID del campo
     *
     * @param {Number}  fieldId ID numérico único del campo en el CRF
     */
    constructor(fieldId) {
        super();

        this.fieldId = _.toNumber(fieldId);
    }

    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @return {string} Tipo de expresión: "field"
     */
    getType() {
        return 'field';
    }

    /**
     * Genera una representación de la expresión apta para exportar o depurar
     *
     * @return {string} Información de los datos de la expresión en una cadena
     *
     * @example
     * // returns 'field:17'
     * const expr = new FieldExpression({id: 17});
     * expr.toString();
     *
     * @example
     * // returns 'field:17-5'
     * const expr = new FieldExpression({id: 17, modifier: 'sub', mod_value: 5});
     * expr.toString();
     *
     * @todo Incluir las listas en la representación
     */
    toString() {
        let asString = `field:${this.fieldId}`;

        if (this.modifier) {
            asString += this.modifier.operation === 'add' ? '+' : '-';
            asString += this.modifier.amount;
        }

        return asString;
    }

    /**
     * Obtiene el ID del campo de referencia
     *
     * @return {Number}  Identificador único del campo
     *
     * @example
     * // returns 17
     * const expr = new FieldExpression({id: 17});
     * expr.getFieldId();
     */
    getFieldId() {
        return this.fieldId;
    }
}

module.exports = FieldExpression;
