'use strict';

const { get, chunk, isEmpty } = require('lodash');
const debug = require('debug')('sharecrf:core');

const EventEmitter = require('../EventEmitter');

/**
 * Lista de opciones de un campo seleccionable
 *
 * @typedef {object} SelectOptionsList
 *
 * @property {string} id    ID de la opción
 * @property {string} value Valor de la opción
 */

/**
 * Flags de un elemento del eCRD (campo y formulario de momento)
 *
 * @typedef {object} ElementFlags
 *
 * @property {boolean}           visible              Indica si el elemento es visible actualmente
 * @property {boolean}           visibleByParent      Causado por la acción de la variable padre
 * @property {boolean}           visibleByRule        Causado por la ejecución de una regla
 * @property {boolean}           visibleByDefinition  Causado por definición de CRF
 * @property {boolean}           required             Indica si el campo es obligatorio según los datos del registro
 * @property {boolean}           completed            Indica si está completado
 * @property {boolean}           enabled              Habilitado al no estar deshabilitado por otro flag
 * @property {boolean}           disabledByConstraint Deshabilitado a causa de una restricción de CRF
 * @property {boolean}           disabledByRule       Deshabilitado por una regla automática
 * @property {boolean}           disabledByFormLock   Deshabilitado por estar bloqueado
 * @property {boolean}           disabledByPermission Deshabilitado por permisos
 * @property {SelectOptionsList} availableOptions     En un campo de tipo select, lista de opciones disponibles
 */

const _flagsBoilerplate = {
    visible: true,
};

/**
 * Clase que gestiona la información adicional al propio valor en formularios y campos.
 *
 * Por ejemplo, información acerca de visibilidad del campo, etc.
 * También incluye por ejemplo las opciones disponibles para un select.
 *
 * @memberOf Record
 */
class FormFields {
    /**
     * @param  {Record}   record   Instancia de gestión de datos de un registro individual
     * @param  {Metadata} metadata Instancia de gestión de metadatos de un registro individual
     */
    constructor(record, metadata) {
        // TODO: Sacar "record" de "metadata" y no inyectar ambos
        this._elementsFlags = {};
        this.record = record;
        this.metadata = metadata;
        this.crf = record.getCRF();

        this.events = new EventEmitter('form-fields');
    }

    /**
     * Devuelve la referencia al objeto interno que contiene la información de flags
     * Esta referencia no cambia aunque se modifiquen los flags o se cargue un nuevo registro
     *
     * @return {object} Flags de campos
     */
    getFlags() {
        return this._elementsFlags;
    }

    /**
     * Exportación de la estructura de flags: obtiene una copia de los datos internos del objeto
     *
     * @return {object} Flags de campos
     */
    export() {
        return JSON.parse(JSON.stringify(this.getFlags()));
    }

    /**
     * Devuelve la referencia al record asociado a estos form field flags
     *
     * @return {Record.Data}  Instancia de Record
     */
    getRecord() {
        return this.record;
    }

    /**
     * Limpia la información de flags. Pierde las referencias que tuviera en memoria.
     */
    reset() {
        for (const key in this._elementsFlags) {
            delete this._elementsFlags[key];
        }
    }

    /**
     * Inicializa los form field flags acorde al record asociado en el constructor
     *
     * Su utilidad principal es emplear esta clase como un singleton, por ejemplo en AngularJS como servicio,
     * sin necesidad de hacer un new cada vez
     *
     * Para evitar que se pierdan las referencias en memoria, no se hace un clean antes del load
     *
     * @return {boolean}  TRUE si se cargó todo con éxito
     */
    load() {
        debug('Start reload FormFields');

        this._getAllRecordFields().forEach(data => {
            this.initField(data.field, data.listIndices);
        });

        debug('End reload FormFields');

        return true;
    }

    /**
     * Devuelve una copia limpia de un objeto ElementsFlags de inicio, con los flags comunes con su valores
     *
     * @return {ElementFlags}  Copia de un objeto inicial de flags
     */
    _getFlagsBoilerplate() {
        return JSON.parse(JSON.stringify(_flagsBoilerplate));
    }

    /**
     * Obtiene todas la combinaciones <campo, listIndices> presentes en el objeto de datos
     *
     * @return {object[]} Lista de pares {field: listIndices}
     *
     * @private
     */
    _getAllRecordFields() {
        const allRecordFields = [];

        this.crf.getFields().forEach(field => {
            const list = field.getParentList();

            if (list) {
                // por cada una de las instancias de esa lista
                this.record.getElementAllListIndices(field.getId()).forEach(currentListIndices => {
                    allRecordFields.push({
                        field: field,
                        listIndices: currentListIndices,
                    });
                });
            } else {
                allRecordFields.push({
                    field: field,
                });
            }
        });

        return allRecordFields;
    }

    /**
     * Inicializa un campo individual, estableciendo sus flags por defecto (hidden, etc.) y las opciones en los selects.
     *
     * @param {Structure.Field} field       Instancia de Field en el CRF
     * @param {ListIndices}     listIndices Información de items dentro de listas
     */
    initField(field, listIndices) {
        const fieldId = field.getId();

        // 1. Establece el valor inicial de los flags de ocultos para las subvariables de cada campo del CRD
        const valuesForHidden = field.getValuesToHideChildren();
        if (!isEmpty(valuesForHidden)) {
            const value = this.record.getFieldValue(fieldId, listIndices);
            const hidden = valuesForHidden.indexOf(value) > -1;
            this.setHideChildren(fieldId, hidden, listIndices);
        }

        // 2. Options para selects
        const fieldOptions = field.getOptions();
        if (fieldOptions !== null) {
            // es de tipo seleccionable y tiene opciones
            this.setAvailableOptions(fieldId, fieldOptions.getOptionList(), listIndices);
        }

        // 3. Campo requerido: el valor puede modificarse si ocurre alguno de los siguientes pasos
        if (field.isRequired()) {
            this.setRequired(fieldId, true, listIndices);
        }

        // 4. Campo deshabilitado según definición
        if (field.isDisabled()) {
            this.setDisabledByConstraint(fieldId, true, listIndices);
        }

        // 5. Campo oculto según definición
        if (field.isHidden()) {
            this.setVisibleByDefinition(fieldId, false, listIndices);
        }

        // 6. Flags según metadatos y estados de aprobación
        this._setMetadataFlags(field, listIndices);
    }

    /**
     * Establece los valores de flags asociados al estado de metadatos del registro
     *
     * @param {Structure.Container} element     El elemento del CRD en cuestión
     * @param {ListIndices}         listIndices Índices de las listas a las que pertenece
     *
     * @private
     */
    _setMetadataFlags(element, listIndices) {
        const statesConfiguration = this.metadata.getStatesConfiguration();
        const states = statesConfiguration.getStates().filter(state => !state.allowsEdition());

        const elementId = element.getId();

        if (statesConfiguration.hasRecordLock()) {
            this.setDisabledByRecordLock(elementId, this.metadata.isRecordLocked(), listIndices);
        }

        states.forEach(state => {
            const stateId = state.getId();

            this.setDisabledByRecordState(elementId, stateId, this.metadata.hasRecordState(stateId), listIndices);
        });

        if (element.isField()) {
            const formId = element.getForm().getId();

            if (statesConfiguration.hasFormLock()) {
                const isFormLocked = this.metadata.isFormLocked(formId, listIndices);

                this.setDisabledByFormLock(elementId, isFormLocked, listIndices);
            }

            const formStates = states.filter(state => state.hasFormApproval());
            formStates.forEach(state => {
                const stateId = state.getId();
                const isEnabled = this.metadata.hasFormState(stateId, formId, listIndices);

                this.setDisabledByFormState(elementId, stateId, isEnabled, listIndices);
            });
        }
    }

    /**
     * Actualiza el flag general de visibilidad de un elemento, a partir de los distintos sub-flags de visibilidad
     *
     * @param  {ElementFlags} elementFlags Flags de un elemento
     *
     * @return {boolean}    Valor del flag actualizado
     *
     * @private
     */
    _updateVisibleFlag(elementFlags) {
        // El elemento se considera visible si todos los flags que afectan a su visibilidad están puestos a TRUE
        // Si algún flag "visibleBy" no está establecido se entiende que no hay restricciones sobre la visibilidad
        elementFlags.visible = Object.keys(elementFlags).every(key => {
            return key.startsWith('visibleBy') ? !!elementFlags[key] : true;
        });

        if (elementFlags.required !== undefined) {
            // GARU-3738 El campo es obligatorio si no está deshabilitado por los propios datos del registro
            // Es decir, metadatos (estados) y permisos no intervienen en el cálculo
            // Si no fuera un campo, este valor simplemente se pondrá a falso
            const disabledByEntityData = this._checkDisabledByEntityDataFlag(elementFlags);

            elementFlags.required = !disabledByEntityData && elementFlags.visible;
            debug('update required flag. Enabled %j, visible %j => required %j',
                elementFlags.enabled, elementFlags.visible, elementFlags.required);
        }

        return elementFlags.visible;
    }

    /**
     * Comprueba la propiedad "visible" del elemento. Si no se ha inicializado el valor se considera que es visible
     *
     * @param  {string}      elementId   ID del elemento en el CRF
     * @param  {ListIndices} listIndices Información de items dentro de listas
     *
     * @return {boolean}     TRUE si el objeto es visible
     */
    isElementVisible(elementId, listIndices) {
        const elementFlags = this.getElementFlags(elementId, listIndices);

        return elementFlags.visible === undefined || !!elementFlags.visible;
    }

    /**
     * Establece las opciones disponibles para un campo de tipo select o radio.
     * La propiedad availableOptions se usa por ejemplo para el atributo ngOptions del modelo del campo
     *
     * @param  {Number}            fieldId     ID del campo en el CRF
     * @param  {SelectOptionsList} options     Lista de opciones del select
     * @param  {ListIndices}       listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}                  El objeto de flags del campo
     */
    setAvailableOptions(fieldId, options, listIndices) {
        return this._setElementFlag(fieldId, 'availableOptions', options, listIndices);
    }

    /**
     * Obtiene las opciones disponibles de un campo en un momento dado
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Información de items dentro de listas
     *
     * @return {integer[]}               IDs de las opciones disponibles
     */
    getAvailableOptions(fieldId, listIndices) {
        const object = this.getElementFlags(fieldId, listIndices);

        return get(object, 'availableOptions', []);
    }

    /**
     * Establece el flag de cumplimiento obligatorio de un campo
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor de rellenado
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setRequired(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'required', value, listIndices);
    }

    /**
     * Establece el flag de rellenado en un campo de cumplimiento obligatorio
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor de rellenado
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setCompleted(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'completed', value, listIndices);
    }

    /**
     * Establece el flag de campo deshabilitado por una restricción en la propia definición del campo
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByConstraint(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'disabledByConstraint', value, listIndices);
    }

    /**
     * Establece el flag de campo deshabilitado por estar el formulario que lo contiene bloqueado
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByFormLock(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'disabledByFormLock', value, listIndices);
    }

    /**
     * Establece el flag de elemento deshabilitado por estar bloqueado el registro completo
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByRecordLock(elementId, value, listIndices) {
        return this._setElementFlag(elementId, 'disabledByRecordLock', value, listIndices);
    }

    /**
     * Establece el flag de campo deshabilitado por estar el formulario en un estado de aprobación
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {string}       stateId     ID del estado de aprobación
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}              El objeto de flags del campo
     */
    setDisabledByFormState(fieldId, stateId, value, listIndices) {
        return this._setElementFlag(fieldId, `disabledByFormState-${stateId}`, value, listIndices);
    }

    /**
     * Establece el flag de elemento deshabilitado por estar habilitado un estado del registro completo
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {string}       stateId     ID del estado de aprobación
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByRecordState(elementId, stateId, value, listIndices) {
        return this._setElementFlag(elementId, `disabledByRecordState-${stateId}`, value, listIndices);
    }

    /**
     * Establece el flag de campo deshabilitado por permisos del usuario
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByPermission(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'disabledByPermission', value, listIndices);
    }

    /**
     * Establece el flag de campo deshabilitado por le ejecución de una regla
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del campo
     */
    setDisabledByRule(fieldId, value, listIndices) {
        return this._setElementFlag(fieldId, 'disabledByRule', value, listIndices);
    }

    /**
     * Establece el flag que controla la visibilidad de un mensaje de error del campo
     *
     * @param {string}       messageID   Identificador del mensaje
     * @param {Number}       fieldId     ID del campo en el CRF
     * @param {ElementFlags} value       Valor de la visibilidad del mensaje
     * @param {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}            El objeto de flags del campo
     */
    setErrorMessage(messageID, fieldId, value, listIndices) {
        const flagName = `errorMessage${messageID}`;

        return this._setElementFlag(fieldId, flagName, value, listIndices);
    }

    /**
     * Recalcula el valor de la visibilidad de los hijos del elemento especificado
     * Actualiza el flag "visibleByParent" a partir de la visibilidad del padre
     *
     * @param {Number}      parentID    ID del elemento padre en el CRF
     * @param {ListIndices} listIndices Información de items dentro de listas
     *
     * @private
     */
    _setChildrenVisibilityByParent(parentID, listIndices) {
        const parentFlags = this.getElementFlags(parentID, listIndices);
        const parentElement = this.crf.getElement(parentID);
        const childrenIds = parentElement.getChildren().map(child => child.getId());
        const childrenVisibility = !parentFlags.hideChildren && this.isElementVisible(parentID, listIndices);

        // Si el elemento padre es de tipo lista hay que recorrer sus elementos para actualizar los índices de los hijos
        if (parentElement.isList()) {
            const listItems = this.record.getListLength(parentID, listIndices);
            for (let index = 0; index < listItems; index++) {
                const localIndices = Object.assign({}, listIndices);
                localIndices[parentID] = index + 1;

                childrenIds.forEach(childId => {
                    this._setElementFlag(childId, 'visibleByParent', childrenVisibility, localIndices);
                    this._setChildrenVisibilityByParent(childId, localIndices);
                });
            }
        } else {
            childrenIds.forEach(childId => {
                this._setElementFlag(childId, 'visibleByParent', childrenVisibility, listIndices);
                this._setChildrenVisibilityByParent(childId, listIndices);
            });
        }
    }

    /**
     * A partir de un elemento del eCRD, establece el flag de visibilidad de sus hijos conforme al valor indicado, que
     * indicaría la visibilidad de ese elemento. Si es un campo, trabajará  sobre sus subvariables. Si es un formulario,
     * trabajará sobre los campos que contiene.
     *
     * La idea es propagar por los hijos de este elemento el mismo valor de visibilidad para que estén sincronizados.
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del elemento
     */
    setHideChildren(elementId, value, listIndices) {
        const elementFlags = this._setElementFlag(elementId, 'hideChildren', value, listIndices);
        this._setChildrenVisibilityByParent(elementId, listIndices);

        return elementFlags;
    }

    /**
     * Establece el flag que controla la visibilidad de un mensaje de ayuda del campo
     *
     * @param {string}       messageId   Identificador del mensaje
     * @param {Number}       fieldId     ID del campo en el CRF
     * @param {ElementFlags} value       Valor de la visibilidad del mensaje
     * @param {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags} Valor de la visibilidad del mensaje
     */
    setInfoMessage(messageId, fieldId, value, listIndices) {
        const flagName = `infoMessage${messageId}`;

        return this._setElementFlag(fieldId, flagName, value, listIndices);
    }

    /**
     * Establece el flag de visibilidad por ejecución de una regla. Por cada hijo del elemento, actualiza recursivamente
     * el flag de visibilidad según la visibilidad del padre
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del elemento
     */
    setVisibleByRule(elementId, value, listIndices) {
        const elementFlags = this._setElementFlag(elementId, 'visibleByRule', value, listIndices);

        this._setChildrenVisibilityByParent(elementId, listIndices);

        return elementFlags;
    }

    /**
     * Establece el flag de visibilidad por definición de CRF. Por cada hijo del elemento, actualiza
     * recursivamente el flag de visibilidad según la visibilidad del padre
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {boolean}      value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             El objeto de flags del elemento
     */
    setVisibleByDefinition(elementId, value, listIndices) {
        const elementFlags = this._setElementFlag(elementId, 'visibleByDefinition', value, listIndices);

        this._setChildrenVisibilityByParent(elementId, listIndices);

        return elementFlags;
    }

    /**
     * Devuelve una relación con los UID de los formularios que son visibles (tienen el flag de visible habilitado)
     *
     * Los keys en el objeto _elementsFlags interno son una clave cuyo primer componente es el ID del elemento
     * en el CRF. Después, separados por puntos, se encuentran opcionalmente los índices en cada una de las listas
     * (anidadas si hay más de uno) en las que está contenido el elemento.
     *
     * Al hacer el recorrido desde el objeto interno de glags estamos suponiendo que todos los elementos tienen el flag
     * de visible asociado
     *
     * @example
     * _formFieldFlags = {
     *     "4": {...}, // elemento que no está en ninguna lista
     *     "7.1": {...}, // elemento 7 está contenido en una lista según el CRF, y ocupa el primer lugar
     * }
     *
     * @return {Array<integer>}  Lista de UID de formularios visibles
     *
     * @todo Hacer que el recorrido parta de los formularios disponibles en los datos, tengan o no flags
     */
    getVisibleForms() {
        const visibleForms = [];

        for (const itemKey in this._elementsFlags) {
            const itemData = this._elementsFlags[itemKey];

            const keyParts = itemKey.split('.');
            const elementId = keyParts.pop();
            const form = this.crf.getForm(elementId);

            // Se comprueban solamente formularios visibles
            if (form && itemData.visible) {
                // Se calculan los índices del formulario actual
                const listIndices = {};

                // Si 'keyParts' tenía más de un componente significa que el formulario actual está contenido en una
                // lista
                if (keyParts.length > 0) {
                    // Cada dos componentes en 'keyParts' definen una lista y el ID del elemento en la lista. Se agrupan
                    // estos valores en 'listIndices' para obtener el UID del formulario en cada elemento
                    chunk(keyParts, 2).forEach(pair => {
                        listIndices[pair[0]] = pair[1];
                    });
                }

                // Se obtiene el UID del formulario a partir de su ID y la lista de índices obtenidos
                const uid = this.record.getElementUID(elementId, listIndices);
                // Si el formulario existe en el CRD se añade a la respuesta
                if (uid !== null) {
                    visibleForms.push(uid);
                }
            }
        }

        return visibleForms;
    }

    /**
     * Obtiene la lista de UID de los campos que están visibles dentro del elemento actual
     *
     * @param  {Number}      elementId   El ID del elemento raíz, null para el CRF completo
     * @param  {ListIndices} listIndices Lista de índices a los que pertenece el elemento raíz
     *
     * @return {string[]}                Lista de representaciones de UID como string separado por puntos
     */
    getVisibleFields(elementId, listIndices) {
        const visibleFieldUids = [];

        const element = elementId ? this.crf.getElement(elementId) : this.crf;
        if (!element) {
            return visibleFieldUids;
        }

        const parentUid = this.record.getElementUID(elementId, listIndices);
        const uidPrefix = parentUid ? parentUid.slice(0, -1) : [];

        let fieldsList = [];
        let lists = [];

        const isRoot = element.isRoot();
        const isList = element.isList();

        if (isRoot) {
            fieldsList = element.getRootFields();
            lists = element.getRootLists();
        } else if (isList) {
            fieldsList = element.getDirectFields();
            lists = element.getDirectLists();
        } else if (element.isSection() || element.isForm()) {
            fieldsList = element.getFields();
        } else if (element.isField()) {
            fieldsList = element.getDescendants();
        }

        const allFlags = this.getFlags();

        fieldsList.forEach(field => {
            const fieldId = field.getId();
            const fieldUid = uidPrefix.concat(fieldId).join('.');

            if (fieldUid in allFlags && allFlags[fieldUid].visible) {
                visibleFieldUids.push(fieldUid);
            }
        });

        lists.forEach(list => {
            const listId = list.getId();
            const listData = this.record.getList(listId, listIndices, false);
            listData.forEach((item, index) => {
                const localIndices = {
                    ...listIndices,
                    [listId]: index + 1,
                };

                Array.prototype.push.apply(visibleFieldUids, this.getVisibleFields(listId, localIndices));
            });
        });

        return visibleFieldUids;
    }

    /**
     * Obtiene el objeto de flags relacionados con el elemento especificado
     * Si al solicitarlo no existe en la estructura, se crea
     *
     * @param  {Number}  elementId  ID del elemento en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}         Objeto de flags, como referencia dentro del objeto global
     */
    getElementFlags(elementId, listIndices) {
        const uid = this.record.getElementUID(elementId, listIndices);
        if (uid === null) {
            debug(`El elemento ${elementId} ya no existe, indices ${JSON.stringify(listIndices)}`);

            return {};
        }

        return this.getElementFlagsByUid(uid);
    }

    /**
     * Get element flags by UID
     *
     * @param {Array|String} uid UID
     *
     * @returns {Object} Element flags
     */
    getElementFlagsByUid(uid) {
        const elementUid = Array.isArray(uid) ? uid.join('.') : uid;
        if (!elementUid) {
            return {};
        }

        if (!this._elementsFlags[elementUid]) {
            this._elementsFlags[elementUid] = this._getFlagsBoilerplate();
        }

        return this._elementsFlags[elementUid];
    }

    /**
     * Establece un flag cualquiera en las propiedades de un elemento: crea o actualiza el objeto del elemento en la
     * estructura general de flags
     *
     * @param  {Number}       elementId   ID del elemento en el CRF
     * @param  {string}       flag        Nombre del flag
     * @param  {mixed}        value       Valor del flag
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {ElementFlags}             Objeto de flags actualizado
     *
     * @private
     */
    _setElementFlag(elementId, flag, value, listIndices) {
        const elementFlags = this.getElementFlags(elementId, listIndices);
        const previousFlags = { ...elementFlags };
        elementFlags[flag] = value;

        if (flag.indexOf('disabledBy') === 0) {
            this._updateEnabledFlag(elementFlags);
        }

        if (flag.indexOf('visibleBy') === 0) {
            this._updateVisibleFlag(elementFlags);
        }

        // Todos los flags internos que hayan podido cambiar se notifican
        // Se hace aquí porque se hacen asignaciones directas al objeto de flags con lo que no hay información del
        // elemento sobre el que se actúa

        const changedFlags = Object.keys(previousFlags).concat(Object.keys(elementFlags)).reduce((acc, key) => {
            return acc.indexOf(key) === -1 && previousFlags[key] !== elementFlags[key] ? acc.concat(key) : acc;
        }, []);

        changedFlags.forEach(changedFlag => {
            this.events.emit('elementFlagChanged', {
                elementId,
                listIndices,
                flag: changedFlag,
                oldValue: previousFlags[changedFlag],
                newValue: elementFlags[changedFlag],
            });
        });

        return elementFlags;
    }

    /**
     * Actualiza el flag de enabled a partir de los distintos flags de deshabilitación: si no hay ninguno activo, el
     * campo estará habilitado
     *
     * @param {ElementFlags} fieldFlags Objeto de flags del campo
     *
     * @private
     */
    _updateEnabledFlag(fieldFlags) {
        const isDisabled = this._checkDisabledFlag(fieldFlags);
        fieldFlags.enabled = !isDisabled;

        if (fieldFlags.required !== undefined) {
            // GARU-3738 El campo es obligatorio si no está deshabilitado por los propios datos del registro
            // Es decir, metadatos (estados) y permisos no intervienen en el cálculo
            const disabledByEntityData = this._checkDisabledByEntityDataFlag(fieldFlags);

            fieldFlags.required = !disabledByEntityData && fieldFlags.visible !== false;
            debug('update required flag. Enabled %j, visible %j => required %j',
                fieldFlags.enabled, fieldFlags.visible, fieldFlags.required);
        }
    }

    /**
     * Comprueba los flags de readonly para determinar si el elemento referenciado se considera readonly
     * Basta con que uno de ellos esté activo
     *
     * @param  {ElementFlags} fieldFlags Objeto de flags del campo
     *
     * @return {boolean}                 TRUE si el flag de readonly está activado
     *
     * @private
     */
    _checkReadOnlyFlag(fieldFlags = {}) {
        const flags = Object.keys(fieldFlags);

        return flags.some(flagName => {
            return (['disabledByRecordLock', 'disabledByFormLock'].indexOf(flagName) > -1
                || flagName.indexOf('disabledByRecordState-') === 0
                || flagName.indexOf('disabledByFormState-') === 0
            ) && !!fieldFlags[flagName];
        });
    }

    /**
     * Comprueba los flags de deshabilitación para determinar si el elemento referenciado se considera deshabilitado
     * Basta con que uno de ellos esté activo
     *
     * @param  {ElementFlags} fieldFlags Objeto de flags del campo
     *
     * @return {boolean}                 TRUE si el flag de disabled está activado
     *
     * @private
     */
    _checkDisabledFlag(fieldFlags) {
        return Object.keys(fieldFlags).some(flagName => {
            return flagName.substring(0, 10) === 'disabledBy' && !!fieldFlags[flagName];
        });
    }

    /**
     * Comprueba los flags de deshabilitación para determinar si el elemento está deshabilitado por datos inherentes al
     * propio registro:
     * - Por la propia definición del campo
     * - O bien por reglas automáticas
     *
     * @param  {ElementFlags} fieldFlags Objeto de flags del campo
     *
     * @return {boolean}                 Resultado de la comprobación
     */
    _checkDisabledByEntityDataFlag(fieldFlags) {
        return fieldFlags.disabledByConstraint || fieldFlags.disabledByRule;
    }

    /**
     * ¿Está el campo deshabilitado por propios datos de la entidad? Reglas o restricción del campo
     * Es decir, no está deshabilitado por el estado del cuaderno ni del formulario
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo está deshabilitado
     */
    isFieldDisabledByEntityData(fieldId, listIndices) {
        const fieldFlags = this.getElementFlags(fieldId, listIndices);

        return this._checkDisabledByEntityDataFlag(fieldFlags);
    }

    /**
     * Comprueba si el elemento está en solo lectura a partir de metadata
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo es readonly
     */
    isFieldReadOnly(fieldId, listIndices) {
        const object = this.getElementFlags(fieldId, listIndices);

        return this._checkReadOnlyFlag(object);
    }

    /**
     * Comprueba si el elemento está deshabilitado a partir de los valores de deshabilitación por distintas fuentes
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo está deshabilitado
     */
    isFieldDisabled(fieldId, listIndices) {
        const object = this.getElementFlags(fieldId, listIndices);

        return this._checkDisabledFlag(object);
    }

    /**
     * Comprueba si el elemento es editable, es decir, no está deshabilitado ni es de solo lectura
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo es editable
     */
    isFieldEditable(fieldId, listIndices) {
        // read only es un subconjunto de flags de deshabilitación, lo que significa que basta con comprobar que el
        // campo no esté deshabilitado por ninguna razón para determinar si es editable
        return !this.isFieldDisabled(fieldId, listIndices);
    }

    /**
     * Determina si un campo está disponible en el cuaderno en el estado actual. La idea es determinar si el
     * usuario tiene capacidad para grabar ese campo, p.ej. para saber si procede tratarlo como campo obligatorio;
     * si no está disponible, no debería ser obligatorio.
     *
     * Comprueba una serie de condiciones para establecer que el campo no está disponible:
     * - El campo no es visible (a causa de su padre, una regla, o por definición)
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo está disponible
     *
     * @todo Revisar otras condiciones: deshabilitado por permisos, por constraint.
     */
    isFieldAvailable(fieldId, listIndices) {
        return this.isElementVisible(fieldId, listIndices) && !this.isFieldDisabledByEntityData(fieldId, listIndices);
    }

    /**
     * Determina si el campo tiene asociado algún error personalizado por las reglas. Lo calcula si entre todos los
     * flags del campo alguno comienza por el prefijo conocido "errorMessage"
     *
     * @param  {Number}       fieldId     ID del campo en el CRF
     * @param  {ListIndices}  listIndices Información de items dentro de listas
     *
     * @return {boolean}                  TRUE si el campo tiene algún error de este tipo
     */
    hasCustomError(fieldId, listIndices) {
        const customErrors = this.getCustomErrors(fieldId, listIndices);

        return customErrors.length > 0;
    }

    /**
     * Obtiene la lista de errores personalizados por reglas asociados al campo
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Información de items dentro de listas
     *
     * @return {string[]}                Lista de códigos de error de regla
     */
    getCustomErrors(fieldId, listIndices) {
        const fieldFlags = this.getElementFlags(fieldId, listIndices);

        return Object.keys(fieldFlags).filter(flagName => {
            return flagName.indexOf('errorMessage') === 0 && fieldFlags[flagName];
        });
    }

    /**
     * Aplica la función especificada de manera global a todos los campos del registro
     *
     * @param {string} method Nombre del método de esta misma clase
     * @param {mixed}  args   Argumentos de entrada al método especificado
     */
    allFields(method, ...args) {
        const allFields = this._getAllRecordFields();

        allFields.forEach(data => {
            this[method](data.field.getId(), ...args, data.listIndices);
        });
    }

    /**
     * Aplica la función especidicada a todos los campos del formulario indicado
     *
     * @param {Number}      formId      ID del formulario
     * @param {ListIndices} listIndices Índices a las que pertenece el formulario
     * @param {string}      method      Nombre del método de esta misma clase
     * @param {mixed}       args        Argumentos de entrada al método especificado
     */
    formFields(formId, listIndices, method, ...args) {
        if (!this.record.hasElement(formId, listIndices)) {
            return;
        }

        const form = this.crf.getForm(formId);
        if (!form) {
            return;
        }

        const formFields = form.getFields();
        formFields.forEach(field => {
            this[method](field.getId(), ...args, listIndices);
        });
    }
}

module.exports = FormFields;
