'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: Audit trail
 */
class AuditConfiguration extends ConfigurationModule {
    /**
     * Determina si la aplicación tiene definida la necesidad de especificar los cambios de valor de las variables,
     * cambios que se adjuntan a la línea de audit
     *
     * @return {boolean} Valor definido, false si en primer lugar no se ha definido el audit
     *
     * @example
     * // returns true
     * const configuration = new AuditConfiguration({enabled: true, reason: true});
     * configuration.hasReason();
     */
    hasReason() {
        return this.isEnabled() && !!this.definition.reason;
    }

    /**
     * Obtiene los valores preconfigurados de las razones de cambio del audit
     *
     * @return {string[]} Lista de mensajes predefinidos
     *
     * @example
     * // returns ['message1', 'message2']
     * const configuration = new AuditConfiguration({audit: true, reason: true, fixedReasons: ['message1', 'message2']})
     * configuration.getFixedReasons();
     */
    getFixedReasons() {
        if (!this.hasReason()) {
            return null;
        }

        if (!('fixedReasons' in this.definition)) {
            this.definition.fixedReasons = [];
        }

        return this.definition.fixedReasons;
    }
}

module.exports = AuditConfiguration;
