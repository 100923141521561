'use strict';

const _ = require('lodash');

const Container = require('./Container');

/**
 * Contenedor general cuyos hijos directos son todos de clase {@link Structure.Container}
 * @extends Container
 * @abstract
 * @memberOf Structure
 */
class SectionsContainer extends Container {
    /**
     * En el constructor se inicializa la propiedad "sections" si no está definida
     * @param  {object} jsonDefinition Definición del contenedor de secciones
     */
    constructor(jsonDefinition = {}) {
        _.merge(jsonDefinition, {
            sections: [],
        });

        super(jsonDefinition);
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['sections', 'accessProfiles', 'editionProfiles']);
    }

    /**
     * Check if given field exists in CRF structure
     * @param  {string}  fieldId Unique field ID
     * @return {boolean} Whether the requested field exists
     */
    hasField(fieldId) {
        const field = this.getField(fieldId);

        return field !== null;
    }

    /**
     * Get field by its unique ID
     * @param  {string} fieldId Unique field ID
     * @return {Field|null} Found field
     */
    getField(fieldId) {
        const element = this.getElement(fieldId);

        return element !== null && element.isField() ? element : null;
    }

    /**
     * Check if given form exists in CRF structure
     * @param  {string}  formId Unique form ID
     * @return {boolean} Whether the requested form exists
     */
    hasForm(formId) {
        let element = this.getElement(formId);

        return element !== null && element.isForm();
    }

    /**
     * Get form by its unique ID
     * @param  {string} formId Unique form ID
     * @return {Form|null} Found form
     */
    getForm(formId) {
        let element = this.getElement(formId);

        if (element !== null && element.isForm()) {
            return element;
        }

        return null;
    }

    /**
     * Check if given section exists in CRF structure
     * @param  {string}  sectionId Unique section ID
     * @return {boolean} Whether the requested section exists
     */
    hasSection(sectionId) {
        let element = this.getElement(sectionId);

        return element !== null && element.isSection();
    }

    /**
     * Get section by its unique ID
     * @param  {string} sectionId Unique section ID
     * @return {Section|null} Found section
     */
    getSection(sectionId) {
        let element = this.getElement(sectionId);

        if (element !== null && element.isSection()) {
            return element;
        }

        return null;
    }

    /**
     * Check if given list exists in CRF structure
     * @param  {string}  listId Unique list ID
     * @return {boolean} Whether the requested list exists
     */
    hasList(listId) {
        let element = this.getElement(listId);

        return element !== null && element.isList();
    }

    /**
     * Obtiene la lista especificada por su ID en el CRF
     *
     * @param  {Number}  listId  ID único de la lista
     *
     * @return {List}  Instancia de lista
     */
    getList(listId) {
        let element = this.getElement(listId);

        if (element !== null && element.isList()) {
            return element;
        }

        return null;
    }

    /**
     * Obtiene la lista especificada por su nombre en el CRF (que es único)
     *
     * @param  {string} listName  Nombre único de la lista
     *
     * @return {List}  Instancia de lista
     */
    getListByName(listName) {
        const list = this.getLists().find(candidate => candidate.getName() === listName);

        return list || null;
    }

    /**
     * Recoge la lista recursiva de contenedores de tipo sección, lista o formulario incluidos en la estructura del
     * elemento y las añade a una lista de primer nivel
     *
     * @return {Structure.Container[]} Lista de contenedores incluidos
     */
    flattenSections() {
        return _.reduce(this.getChildren(), (current, childElement) => {
            current.push(childElement);
            if (!childElement.isForm()) {
                current = current.concat(childElement.flattenSections());
            }

            return current;
        }, []);
    }

    /**
     * Devuelve la lista de secciones incluidas en el contenedor
     *
     * @return {Array<Structure.Section>} Lista de secciones
     */
    getSections() {
        return _.filter(this.flattenSections(), element => element.isSection());
    }

    /**
     * Devuelve la lista de formularios de este contenedor
     *
     * @return {Array<Structure.Form>} Lista de formularios
     */
    getForms() {
        return _.filter(this.flattenSections(), element => element.isForm());
    }

    /**
     * Devuelve la lista completa de campos de este contenedor
     *
     * @return {Array<Structure.Field>} Lista de campos
     */
    getFields() {
        return _.flatten(this.getForms().map(form => form.getFields()));
    }

    /**
     * Devuelve la lista completa de campos de cumplimentación obligatoria de este contenedor
     *
     * @return {Array<Structure.Field>} Lista de campos obligatorios
     */
    getRequiredFields() {
        return _.flatten(this.getForms().map(form => form.getRequiredFields()));
    }

    /**
     * Comprueba si el contenedor incluye algún campo
     *
     * @return {boolean} Verdadero si alguno de los formularios tiene campos
     */
    hasFields() {
        return _.some(this.getForms(), form => form.hasFields());
    }

    /**
     * Comprueba si el contenedor incluye alguna lista
     *
     * @return {boolean} Verdadero si existe alguna sección de tipo lista
     */
    hasLists() {
        return _.some(this.flattenSections(), element => element.isList());
    }

    /**
     * Devuelve la lista completa de listas de repetición del CRD
     *
     * @return {Array<Structure.List>} Lista de listas
     */
    getLists() {
        return _.filter(this.flattenSections(), element => element.isList());
    }

    /**
     * Elimina un campo perteneciente a alguno de los formularios del contenedor
     *
     * @param  {Number}  fieldId El ID único del campo
     *
     * @return {boolean}         Resultado de la operación
     */
    removeField(fieldId) {
        const field = this.getField(fieldId);
        if (!field) {
            return false;
        }

        // fieldParent instanceof FieldsContainer
        const fieldParent = field.getParent();
        const isRemoved = fieldParent.removeField(fieldId);

        if (isRemoved) {
            const fieldList = fieldParent.getParentList();
            if (fieldList !== null) {
                fieldList.removeListableField(fieldId);
            }
        }

        return isRemoved;
    }

    /**
     * @inheritDoc
     */
    addChild(container) {
        super.addChild(container);
        this.definition.sections.push(container.get());
    }
}

module.exports = SectionsContainer;
