'use strict';

const _ = require('lodash');

const FieldExpression = require('./Expression/Field');
const ValueExpression = require('./Expression/Value');
const ListLengthExpression = require('./Expression/ListLength');
const ListIndexExpression = require('./Expression/ListIndex');
const RecordFieldExpression = require('./Expression/RecordField');

/**
 * Tipos de expresión que se pueden instanciar, presentes en la propiedad type de los objetos de definición
 * @readonly
 * @enum {string}
 */
const ExpressionTypes = {
    FIELD: 'field',
    LIST: 'list',
    RECORD: 'record',
    VALUE: 'value',
};

/**
 * Tipos de acción sobre listas
 * @readonly
 * @enum {string}
 */
const ListActions = {
    FUNCTION: 'function',
    INDEX: 'index',
};

/**
 * Tipos de función sobre listas
 * @readonly
 * @enum {string}
 */
const ListFunctions = {
    COUNT: 'count',
};

/**
 * Constructor de expresiones de entrada en condiciones. A partir de un objeto de definición instancia un tipo de
 * expresión acorde
 *
 * @memberOf Criteria
 */
class ExpressionFactory {
    /**
     * Instancia un objeto de Expression según la configuración definida, aplicando modificadores de valor y añadiendo
     * índices de listas a la expresión si es pertinente
     *
     * @param  {object} config Definición de la expresión
     *
     * @return {Criteria.Expression} Objeto de expresión
     */
    static createFromConfig(config) {
        let expression = this.instantiateExpression(config);

        if (_.has(config, 'modifier')) {
            expression.modify(config.modifier.operation, this.value(config.modifier.amount));
        }

        _.each(config.lists, (index, listId) => {
            expression.list(_.toNumber(listId), index);
        });

        return expression;
    }

    /**
     * Instancia un objeto de Expression según la configuración definida
     *
     * @param  {object} config Definición de la expresión
     *
     * @return {Criteria.Expression} Objeto de expresión
     *
     * @throws {Error} si el tipo de expresión no se reconoce
     *
     * @private
     */
    static instantiateExpression(config) {
        if (config.type === ExpressionTypes.VALUE) {
            return this.value(config.value);
        }

        if (config.type === ExpressionTypes.FIELD) {
            return this.field(config.id);
        }

        if (config.type === ExpressionTypes.LIST) {
            if (config.action === ListActions.FUNCTION) {
                if (config.function === ListFunctions.COUNT) {
                    return this.count(config.id);
                }
                throw new Error(`Función de lista no reconocida: "${config.function}"`);
            }
            if (config.action === ListActions.INDEX) {
                return this.index(config.id);
            }
            throw new Error(`Acción sobre lista no reconocida: "${config.action}"`);
        }

        if (config.type === ExpressionTypes.RECORD) {
            return this.record(config.field);
        }

        throw new Error(`Tipo de expresión desconocido: "${config.type}"`);
    }

    /**
     * Instancia una expresión de tipo Valor de campo del CRD
     *
     * @param  {Number}                  fieldId ID del campo
     *
     * @return {Criteria.FieldExpression}        La expresión generada
     */
    static field(fieldId) {
        return new FieldExpression(fieldId);
    }

    /**
     * Instancia una expresión de tipo Valor constante
     *
     * @param  {mixed}                    value El valor constante
     *
     * @return {Criteria.ValueExpression}       La expresión generada
     */
    static value(value) {
        return new ValueExpression(value);
    }

    /**
     * Instancia una expresión de tipo Número de elementos de lista del CRD
     *
     * @param  {Number}                        listId ID de la lista
     *
     * @return {Criteria.ListLengthExpression}        La expresión generada
     */
    static count(listId) {
        return new ListLengthExpression(listId);
    }

    /**
     * Instancia una expresión de tipo Índice de la lista en el contexto de ejecución
     *
     * @param  {Number}                       listId ID de la lista
     *
     * @return {Criteria.ListIndexExpression}        La expresión generada
     */
    static index(listId) {
        return new ListIndexExpression(listId);
    }

    /**
     * Instancia una expresión de tipo Valor del registro de datos
     *
     * @param  {string}                         fieldName Identificador del campo
     *
     * @return {Criteria.RecordFieldExpression}           La expresión generada
     */
    static record(fieldName) {
        return new RecordFieldExpression(fieldName);
    }
}

module.exports = ExpressionFactory;
