'use strict';

const _ = require('lodash');
const FieldValidation = require('./Field');

const TIME_PATTERN = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

const { UNKNOWN_VALUE } = require('../../constants');

/**
 * Validador de campos de tipo lista de ficheros
 *
 * @memberOf Record
 */
class FieldTimeValidation extends FieldValidation {
    /**
     * @inheritDoc
     */
    _validateFieldType(fieldValue) {
        if (!this.isValidable(fieldValue) || (this.field.allowsUnknownValue() && fieldValue === UNKNOWN_VALUE)) {
            return true;
        }

        return _.isString(fieldValue) && TIME_PATTERN.test(fieldValue);
    }

    /**
     * @inheritDoc
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        if (!this._validateFieldType(fieldValue)) {
            return true;
        }

        // las validaciones sólo se tienen que evaluar sobre campos con valor validable, si el valor no se puede validar
        // no puede estar en estado inválido
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        if (constraintName === 'max') {
            return _.lte(fieldValue, constraintDefinition);
        }

        if (constraintName === 'min') {
            return _.gte(fieldValue, constraintDefinition);
        }

        // Clave desconocida
        return false;
    }
}

module.exports = FieldTimeValidation;
