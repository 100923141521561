'use strict';

const moment = require('moment');

const Field = require('../../../Structure/Field');
const FieldValidation = require('./Field');

/**
 * Validador de campos de tipo datetime
 *
 * @memberOf Record
 */
class FieldDateTimeValidation extends FieldValidation {
    /**
     * Valida que el dato sea una fecha + hora correcta especificada en el formato correcto
     *
     * @param  {string}  value Representación de la fecha + hora
     *
     * @return {boolean}       Si el valor representa una fecha + hora válida
     * @protected
     */
    static validateDateIsValid(value) {
        return value
            ? (moment(value, [
                Field.FieldFormats.DATETIME_SECONDS,
                Field.FieldFormats.DATETIME_MINUTES,
            ], true)).isValid()
            : false;
    }

    /**
     * Build moment object
     *
     * @param {String} value Datetime value with format 'YYYY-MM-DD HH:mm:[ss]'.
     * @param {String} accuracy Accuracy ['minutes', 'seconds']
     *
     * @returns {Moment}     Moment instance
     *
     * @static
     * @private
     */
    static buildMomentObject(value, accuracy) {
        const format = accuracy === 'seconds'
            ? Field.FieldFormats.DATETIME_SECONDS
            : Field.FieldFormats.DATETIME_MINUTES;

        return moment(value, format, true);
    }

    /**
     * Validate min datetime
     *
     * @param {String} value Field value
     * @param {String} limit Lower limit
     * @param {String} accuracy Accuracy ['minutes', 'seconds']
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintDateTimeMin(value, limit, accuracy) {
        const date = FieldDateTimeValidation.buildMomentObject(value, accuracy);
        const limitDate = FieldDateTimeValidation.buildMomentObject(limit, accuracy);

        return date.isValid() && limitDate.isValid() ? date.unix() >= limitDate.unix() : true;
    }
    /**
     * Validate max datetime
     *
     * @param {String} value Field value
     * @param {String} limit Lower limit
     * @param {String} accuracy Accuracy ['minutes', 'seconds']
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintDateTimeMax(value, limit, accuracy) {
        const date = FieldDateTimeValidation.buildMomentObject(value, accuracy);
        const limitDate = FieldDateTimeValidation.buildMomentObject(limit, accuracy);

        return date.isValid() && limitDate.isValid() ? date.unix() <= limitDate.unix() : true;
    }

    /**
     * Validate datetime
     *
     * @param {String} value Field value
     * @param {String} accuracy Accuracy ['minutes', 'seconds']
     *
     * @returns {Boolean}    TRUE if value is valid
     *
     * @static
     * @protected
     */
    static validateConstraintDateAccuracy(value, accuracy) {
        const datetime = FieldDateTimeValidation.buildMomentObject(value, accuracy);

        return datetime.isValid();
    }

    /**
     * @inheritDoc
     */
    _validateFieldType(fieldValue) {
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        return FieldDateTimeValidation.validateDateIsValid(fieldValue);
    }

    /**
     * @inheritDoc
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        const accuracy = this.field.getConstraint('accuracy');

        let valid = false;
        switch (true) {
            case !this._validateFieldType(fieldValue):
                valid = true;
                break;

            case constraintName === 'after':
                valid = FieldDateTimeValidation.validateConstraintDateTimeMin(fieldValue, constraintDefinition, accuracy);
                break;

            case constraintName === 'before':
                valid = FieldDateTimeValidation.validateConstraintDateTimeMax(fieldValue, constraintDefinition, accuracy);
                break;

            case constraintName === 'accuracy':
                valid = FieldDateTimeValidation.validateConstraintDateAccuracy(fieldValue, constraintDefinition);
                break;
        }

        return valid;
    }
}

module.exports = FieldDateTimeValidation;
