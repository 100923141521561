'use strict';

const FieldValidation = require('./Field');

/**
 * Validador de campos de tipo lista de ficheros
 *
 * @memberOf Record
 */
class FieldNumberValidation extends FieldValidation {
    /**
     * @inheritDoc
     */
    _validateFieldType(fieldValue) {
        return !this.isValidable(fieldValue) || (typeof fieldValue === 'number' && isFinite(fieldValue));
    }

    /**
     * @inheritDoc
     */
    _validateConstraint(constraintName, fieldValue, constraintDefinition) {
        if (!this._validateFieldType(fieldValue)) {
            return true;
        }

        // las validaciones sólo se tienen que evaluar sobre campos con valor validable, si el valor no se puede validar
        // no puede estar en estado inválido
        if (!this.isValidable(fieldValue)) {
            return true;
        }

        if (constraintName === 'max') {
            return fieldValue <= constraintDefinition;
        }

        if (constraintName === 'min') {
            return fieldValue >= constraintDefinition;
        }

        if (constraintName === 'no-decimals') {
            return Math.trunc(fieldValue) === fieldValue;
        }

        if (constraintName === 'decimals') {
            const [, decimalPart] = fieldValue.toString().split('.');

            return typeof decimalPart === 'undefined' || decimalPart.length <= constraintDefinition;
        }

        // Clave desconocida
        return true;
    }
}

module.exports = FieldNumberValidation;
