'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core');

/**
 * Clase que implementa una gestión básica de eventos personalizados
 */
class EventEmitter {
    /**
     * Inicializa el objeto de listeners de eventos
     */
    constructor() {
        this._eventListeners = {};
    }

    /**
     * Registra una función para ejecutar cuando ocurra el evento especificado
     *
     * @param  {string}   eventName Nombre del evento
     * @param  {function} callback  Función a ejecutar cuando se lanza el evento
     *
     * @return {function}           Función para des-registrar el evento
     */
    on(eventName, callback) {
        if (this._eventListeners[eventName] === undefined) {
            this._eventListeners[eventName] = {};
        }

        const listenerId = _.uniqueId('eventlistener');

        this._eventListeners[eventName][listenerId] = callback;

        return () => {
            delete this._eventListeners[eventName][listenerId];
        };
    }

    /**
     * Lanza un evento llamando a todos los listeners registrados para dicho evento
     *
     * @param  {RuleEvents} eventName Nombre del evento
     * @param  {Array<mixed>}    args      Lista variable de argumentos asociados al evento
     */
    emit(eventName, ...args) {
        if (!this._eventListeners[eventName]) {
            return;
        }

        debug('event: %s %o', eventName, Object.keys(this._eventListeners[eventName]));
        _.each(this._eventListeners[eventName], callback => {
            callback(...args);
        });
    }
}

module.exports = EventEmitter;
module.exports.instance = (...args) => new EventEmitter(...args);
