'use strict';

const _ = require('lodash');

const Side = require('./Side');

class ModifiableSide extends Side {
    hasModifier() {
        return _.has(this.definition, 'modifier');
    }

    getModifier() {
        return _.get(this.definition, 'modifier', null);
    }
}

module.exports = ModifiableSide;
