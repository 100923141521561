'use strict';

const _ = require('lodash');

const ConfigurationModule = require('./Module');

/**
 * Tipos de icono / identificador visual del estado
 *
 * @enum {string}
 *
 * @readOnly
 */
const Icons = {
    NONE: 'none',
    CHECK_CIRCLE_SOLID: 'check',
    CHECK_CIRCLE_LIGHT: 'check-light',
    CHECK_SQUARE_SOLID: 'check-square',
    CHECK_SQUARE_LIGHT: 'check-square-light',
    CERTIFICATE: 'certificate',
    FULL_STAR: 'star-full',
    HALF_STAR: 'star-half',
    FLAG: 'flag-solid',
    CHECKERED_FLAG: 'flag-checkered',
    PENCIL: 'pencil',
    PENCIL_SQUARE_SOLID: 'pencil-square',
    PENCIL_SQUARE_LIGHT: 'pencil-square-light',
    THUMBS_UP_SOLID: 'thumbs-up',
    THUMBS_UP_LIGHT: 'thumbs-up-light',
    THUMBS_DOWN_SOLID: 'thumbs-down',
    THUMBS_DOWN_LIGHT: 'thumbs-down-light',
};

/**
 * Criterios de cambio en campos para limpiar el estado
 *
 * @enum {string}
 *
 * @readOnly
 */
const CleanState = {
    NO: 'no', // nunca se cambia
    ANY: 'any', // en cualquier cambio de datos
    REQUIRED: 'required', // cuando cambia algún campo obligatorio
    CRITICAL: 'critical', // cuando cambia algún campo crítico
};

/**
 * Criterios para crear queries automáticas al establecer el estado
 *
 * @enum {string}
 *
 * @readOnly
 */
const CreateQueries = {
    NO: 'no',
    REQUIRED: 'required', // en los campos obligatorios que no estén rellenos
};

/**
 * Gestión de un estado de aprobación individual
 * Se comporta como un módulo de configuración dentro del módulo de estados de aprobación
 */
class State extends ConfigurationModule {
    /**
     * Inicialización de los datos internos
     *
     * @param {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super(definition);

        // Configuración inicial
        _.defaults(this.definition, {
            id: '',
            name: '',
            icon: Icons.NONE,
            color: '#000000',
            sign: false,
            signMessage: '',
            forms: false,
            signForms: false,
            clean: CleanState.NO,
            createQueries: CreateQueries.NO,
            queryText: '',
            allowEdition: true,
            notifications: {
                enabled: false,
                global: {
                    enable: [],
                    disable: [],
                },
                form: {
                    enable: [],
                    disable: [],
                },
            },
            certifiedCopy: false,
        });
    }

    /**
     * Constantes de tipo de icono
     */
    static get Icons() {
        return Icons;
    }

    /**
     * Constantes de criterios para limpiar el estado
     */
    static get CleanState() {
        return CleanState;
    }

    /**
     * Constantes de criterios para crear notas de discrepancia
     */
    static get CreateQueries() {
        return CreateQueries;
    }

    /**
     * Obtiene el identificador interno del estado. Tienen la forma "s{index}"
     *
     * @return {string} El ID del estado
     */
    getId() {
        return this.definition.id;
    }

    /**
     * Obtiene el nombre configurado del estado
     *
     * @return {string} El nombre del estado
     */
    getName() {
        return this.definition.name;
    }

    /**
     * Obtiene el icono configurado del estado
     *
     * @return {Icons} El nombre del estado
     */
    getIcon() {
        return this.definition.icon;
    }

    /**
     * Obtiene el color configurado del estado
     *
     * @return {string} Código hexadecimal del color
     */
    getColor() {
        return this.definition.color;
    }

    /**
     * Determina si se necesita firma digital para establecer el estado en el registro
     *
     * @return {boolean} La configuración de firma digital
     */
    requiresSignature() {
        return !!this.definition.sign;
    }

    /**
     * Obtiene el mensaje de declaración de firma electrónica
     *
     * @return {string} El mensaje configurado
     */
    getSignatureMessage() {
        return this.requiresSignature() ? this.definition.signMessage : '';
    }

    /**
     * Determina si tiene configuración de aprobación parcial para formularios
     *
     * @return {boolean} Si está configurada la aprobación parcial
     */
    hasFormApproval() {
        return !!this.definition.forms;
    }

    /**
     * Determina si se necesita firma digital para establecer el estado en formularios
     *
     * @return {boolean} La configuración de firma digital
     */
    requiresSignatureOnForms() {
        return this.hasFormApproval() && this.requiresSignature() && !!this.definition.signForms;
    }

    /**
     * Obtiene la configuración de los campos cuyas modificaciones suponen la limpieza del valor del estado
     *
     * @return {CleanState} Configuración del criterio de modificación
     */
    cleansOnUpdatedFields() {
        return this.definition.clean;
    }

    /**
     * Obtiene la configuración del criterio para crear queries automáticas en campos
     *
     * @return {CreateQueries} El valor configurado
     */
    createsQueries() {
        return this.definition.createQueries;
    }

    /**
     * Obtiene el texto configurado para añadir en las queries automáticas creadas al habilitar el estado
     *
     * @return {string} El texto del mensaje de la query
     */
    getQueryText() {
        return this.createsQueries() !== CreateQueries.NO ? this.definition.queryText : '';
    }

    /**
     * Determina si cuando el estado está habilitado se permite la edición de los datos afectados
     *
     * @return {boolean} Definición de la propiedad
     */
    allowsEdition() {
        return !!this.definition.allowEdition;
    }

    /**
     * Determina si las notificaciones al cambiar de estado están habilitadas
     *
     * @return {boolean} TRUE si están habilitadas
     */
    isNotificationEnabled() {
        return this.definition.notifications.enabled;
    }

    /**
     * Determina si se deben notificar un cambio de estado a nivel global de paciente, cuando se activa
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenGlobalActive() {
        return this.isNotificationEnabled()
            && Array.isArray(this.definition.notifications.global.enable)
            && this.definition.notifications.global.enable.length > 0;
    }

    /**
     * Determina si se deben notificar un cambio de estado a nivel global de paciente, cuando se deshabilita
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenGlobalInactive() {
        return this.isNotificationEnabled()
            && Array.isArray(this.definition.notifications.global.disable)
            && this.definition.notifications.global.disable.length > 0;
    }

    /**
     * Determina si se deben notificar un cambio de estado a nivel de formulario dentro de un paciente, cuando se activa
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenFormActive() {
        return this.isNotificationEnabled()
            && Array.isArray(this.definition.notifications.form.enable)
            && this.definition.notifications.form.enable.length > 0;
    }

    /**
     * Determina si se deben notificar un cambio de estado a nivel de formulario de paciente, cuando se deshabilita
     *
     * @return {boolean} TRUE si hay que notificar
     */
    notifyWhenFormInactive() {
        return this.isNotificationEnabled()
            && Array.isArray(this.definition.notifications.form.disable)
            && this.definition.notifications.form.disable.length > 0;
    }

    /**
     * Devuelve la lista de canales a notificar al establecer (o quitar) un estado de formulario
     *
     * @param {boolean} enabled Estado del formulario puesto?
     *
     * @return {Array} Lista de ID de canal
     */
    getNotificationFormChannels(enabled) {
        return this.definition.notifications.form[enabled ? 'enable' : 'disable'] || [];
    }

    /**
     * Devuelve la lista de canales a notificar al establecer (o quitar) un estado de paciente global
     *
     * @param {boolean} enabled Estado del paciente puesto?
     *
     * @return {Array} Lista de ID de canal
     */
    getNotificationGlobalChannels(enabled) {
        return this.definition.notifications.global[enabled ? 'enable' : 'disable'] || [];
    }

    /**
     * Determina si se debe generar un copia certificada contemporánea al momento de establecer este estado a true
     *
     * @return {boolean} TRUE si están habilitadas
     */
    requiresCertifiedCopy() {
        return this.definition.certifiedCopy;
    }
}

module.exports = State;
