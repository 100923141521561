'use strict';

/**
 * Identificadores de índice de listas
 *
 * @enum {string}
 *
 * @readOnly
 */
const ListIdentifiers = {
    CURRENT: 'current',
    FIRST: 'first',
    LAST: 'last',
    NEXT: 'next',
    PREVIOUS: 'previous',
};

module.exports = ListIdentifiers;
