'use strict';

const _ = require('lodash');

const ConfigurationModule = require('./Module');
const RevisionState = require('./RevisionState');

/**
 * Módulo de configuración: Gestión de estados
 *
 * @property {RevisionState[]} states Lista fija de estados configurables
 */
class StatesConfiguration extends ConfigurationModule {
    /**
     * Constructor de la clase, inicializa los estados configurables
     *
     * @param  {object} definition Objeto de definición
     */
    constructor(definition = {}) {
        super(definition);

        _.defaults(this.definition, {
            states: [],
        });

        this._states = this.definition.states.map(state => RevisionState.instance(state));
    }

    /**
     * Obtiene la lista de estados de aprobación
     *
     * @return {State[]} Lista de objetos de gestión de estado
     *
     */
    getStates() {
        return this._states;
    }

    /**
     * Obtiene la definición de un estado a partir de su ID
     *
     * @param  {string} stateId ID del estado
     *
     * @return {State}          El estado encontrado
     */
    getState(stateId) {
        return this.getStates().find(state => state.getId() === stateId) || null;
    }

    /**
     * Determina si el estado solicitado se encuentra en la configuración
     *
     * @param  {string}  stateId ID del estado
     *
     * @return {boolean}         Si existe en la lista de estados
     */
    hasState(stateId) {
        return this.getState(stateId) !== null;
    }
}

module.exports = StatesConfiguration;
module.exports.RevisionState = RevisionState;
