'use strict';

const Action = require('./Action');

/**
 * Regla de tipo Bloquear (no permitir) aleatorización (del registro)
 */
class BlockRandomizationAction extends Action {
    /**
     * @inheritDoc
     */
    constructor(definition) {
        super(definition);
        this.definition.type = Action.Types.BLOCK_RANDOMIZATION;
    }

    /**
     * Obtiene el destinatario de la acción, en este caso el objeto global de CRF
     *
     * @return {Structure.CRF} El objeto de CRF
     */
    getTarget() {
        return this.getCRF();
    }

    /**
     * Obtiene el texto del mensaje a mostrar cuando se quiera aleatorizar
     *
     * @return {string} El mensaje definido
     */
    getMessage() {
        return this.definition.message || '';
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @returns {ExportedAction} Objeto de exportación de la acción
     */
    export() {
        const action = super.export();
        action.func = 'blockRandomization';

        return action;
    }
}

module.exports = BlockRandomizationAction;

module.exports.instance = (...args) => new BlockRandomizationAction(...args);
