'use strict';

const Base = require('./Base');
const Data = require('./Data');
const Metadata = require('./Metadata');
const Completion = require('./Completion');
const FormFields = require('./FormFields');
const Validation = require('./Validation');
const Changes = require('./Changes');
const Rules = require('./Rules');
const Queries = require('./Queries');

/**
 * @namespace Record
 */
module.exports = {
    Base: Base,
    Data: Data,
    Metadata: Metadata,
    Completion: Completion,
    FormFields: FormFields,
    Validation: Validation,
    Changes: Changes,
    Rules: Rules,
    Queries: Queries,

    ...require('./constants'),
};
