'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core:validation');

const FieldValidationFactory = require('./Validation/Field/Factory');
const EventEmitter = require('../EventEmitter');

/**
 * Estado de validación de un elemento. Contiene tanto la información de validación propia, en la propiedad
 * __valid, como el resto de elementos hijo que tiene, cada uno indexado por el ID correspondiente
 *
 * @typedef {object} ValidationState
 *
 * @property {boolean} __valid   Elemento válido o no
 * @property {ValidationState} [elementID] Elementos hijos del actual, cada uno con su información propia
 *
 * @example
 * {
 *     __valid: true,
 *     8: {
 *         __valid: false,
 *         9: {
 *             __valid: false,
 *             11: {
 *                 __valid: true
 *             },
 *             12: {
 *                 __valid: false
 *             }
 *         }
 *     }
 * }
 */

/**
 * Objeto de gestión de la validación general de un registro
 * Lleva a cuenta la información de validación de formularios, secciones, listas, campos, etc.
 *
 * @memberOf Record
 */
class Validation {
    /**
     * @param {Record.Rules}  recordRules   Reglas de ejecución con referencias al registro de datos
     * @param {Configuration} configuration Instancia de configuración del cuaderno
     */
    constructor(recordRules, configuration) {
        this.rules = recordRules;
        this.record = recordRules.getRecord();
        this.crf = this.record.getCRF();
        this.formFields = recordRules.getFormFields();
        this.configuration = configuration;

        this.validation = {};
        Object.defineProperty(this.validation, '__valid', {
            enumerable: false,
            configurable: false,
            writable: true,
            value: true,  // valor por defecto
        });
        this.customValidators = [];

        this._fieldValidators = {};

        this.events = EventEmitter.instance();

        this.errorRulesActions = [];
        (this.rules.definitions || []).forEach(rule => {
            rule.actions.forEach(action => {
                if (action.definition.type === 'error') {
                    this.errorRulesActions.push(action);
                }
            });
        });
    }

    /**
     * Resetea la información de validación en este registro, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     */
    reset() {
        _.each(Object.keys(this.validation), key => {
            _.unset(this.validation, key);
        });
        this.validation.__valid = true;
    }

    /**
     * Genera la clave del elemento en el objeto de resultados de validación
     *
     * @param  {Number}      elementId   ID único del elemento en la estructura
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {integer[]}               Clave de inserción, coincide con el UID del elemento en los datos
     *
     * @private
     */
    _getValidationKey(elementId, listIndices) {
        return this.record.getElementUID(elementId, listIndices);
    }

    /**
     * Carga la información de validación con el record actualmente cargado, inicializando la validación completa del
     * registro.
     *
     * Este método siempre resetea la información, para que sea coherente con el recorddata que tiene referenciado
     *
     * @return {boolean} TRUE si se cargó la información con éxito
     */
    load() {
        this.reset();

        return this.validateRecord();
    }

    /**
     * Obtiene la estructura de validación de la entidad
     *
     * @return {ValidationState}  Estado de validación global del registro
     */
    getValidation() {
        return this.validation;
    }

    /**
     * Determina si el objeto completo de registro se ha validado correctamente
     *
     * @return {boolean} Si se considera válido
     */
    isRecordValid() {
        return this.getValidation().__valid;
    }

    /**
     * Obtiene la referencia al objeto de datos
     *
     * @return {Record.Data} El objeto de datos
     */
    getRecord() {
        return this.record;
    }

    /**
     * Obtiene la referencia del objeto de CRF usado en la validación
     *
     * @return {Structure.CRF} El CRF
     */
    getCRF() {
        return this.crf;
    }

    /**
     * Determina recursivamente si el objeto de validación resuelto es válido. Se recorre los hijos, y en cuando
     * encuentra alguno que no es válido, devuelve un FALSE
     *
     * @param  {ValidationState} validationState Objeto de estado de validación
     *
     * @return {boolean}         TRUE si representa un valor válido
     */
    _checkObjectValidation(validationState) {
        return _.every(validationState, (child, key) => {
            // Si la clave empieza por __ es interna, no es un hijo y por tanto no hay que comprobar su propio __valid
            return _.startsWith(key, '__') || child.__valid;
        });
    }
    /**
     * Comprueba la validación de la entidad o de un elemento de la entidad a partir de la validación de los
     * elementos hijos
     *
     * @param  {Container}   element     Instancia del elemento en el CRF, si es nulo se refiere a la entidad completa
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}                 El elemento se considera válido si todos los hijos son válidos
     *
     * @private
     */
    _checkValidation(element, listIndices = {}) {
        const validationState = element !== null ? this._getElementValidity(element, listIndices) : this.validation;

        return this._checkObjectValidation(validationState);
    }
    /**
     * Comprueba la validación de un elemento dentro de una lista
     *
     * @param  {List}        list        Instancia de la lista en el CRF
     * @param  {Number}      itemId      ID único del elemento en su lista
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}                 TRUE si es válido
     */
    _checkListItemValidation(list, itemId, listIndices) {
        const listValidation = this._getElementValidity(list, listIndices);
        const itemValidation = listValidation[itemId];

        return this._checkObjectValidation(itemValidation);
    }
    /**
     * Valida el tipo de campo. No toca la estructura de validación, solamente determina si el dato es válido o no
     * con respecto al tipo. Se usa externamente cuando no se quiere validar según la lógica de la aplicación.
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}     TRUE si el campo es válido
     */
    validateFieldType(fieldId, listIndices) {
        const field = this.crf.getField(fieldId);

        // Si no conocemos el campo no podemos invalidarlo
        if (!field) {
            return true;
        }

        const fieldValue = this.record.getFieldValue(fieldId, listIndices);
        const formControl = field.getFormControl();

        const fieldValidation = this.validateFieldWithErrors(fieldId, fieldValue, listIndices);

        return !_.includes(fieldValidation.errors, `type-${formControl}`);
    }

    /**
     * Valida el valor de un campo (usando el this.record que tiene asociado mediante el constructor),
     * actualizando la estructura de datos de validación
     *
     * @param  {Number}  fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}     TRUE si el campo es válido
     */
    validateField(fieldId, listIndices = {}) {
        // GARU-3815 para prevenir errores, si el campo no se encuentra en los datos no se sigue validando
        if (!this.record.getElementPath(fieldId, listIndices)) {
            return false;
        }

        const field = this.crf.getField(fieldId);
        if (!field.allowsValue()) {
            // Si el campo es de tipo raw, por ejemplo, que no tiene valor, no se debe validar nunca
            return true;
        }

        let isValid;

        const fieldValue = this.record.getFieldValue(fieldId, listIndices);
        const result = this.validateFieldWithErrors(fieldId, fieldValue, listIndices);
        isValid = result.valid;

        // Si hay validadores personales se ejecutan todos, porque pueden estar usando su propia lógica por dentro
        // Entre los validadores personales se encuentra por ejemplo la comprobación de razones de cambio de valor
        _.each(this.customValidators, validatorFunction => {
            isValid = validatorFunction(fieldId, listIndices) && isValid;
        });

        this._setElementValidity(field, isValid, listIndices);

        return isValid;
    }

    /**
     * Valida el valor de un campo con todas las opciones posibles. Idealmente sustituirá a validateField pero de
     * momento no actualiza el objeto global para nada
     *
     * @param  {Number}               fieldId     ID numérico del campo en el CRF
     * @param  {mixed}                value       El valor del campo
     * @param  {ListIndices}                      listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {ValidationWithErrors}             Resultado de la comprobación
     *
     * @todo Hacer que todas las validaciones de campo pasen por aquí
     */
    validateFieldWithErrors(fieldId, value, listIndices) {
        const validator = this.field(fieldId);
        const validation = validator.validate(value, listIndices);

        return validation;
    }

    /**
     * Obtiene el validador de un campo en concreto
     *
     * @param  {Number}                 fieldId ID del campo que se quiere validar
     *
     * @return {Record.FieldValidation}         Instnacia del validador
     */
    field(fieldId) {
        if (!_.has(this._fieldValidators, fieldId)) {
            const fieldValidator = FieldValidationFactory.instance(this.rules, fieldId, this.configuration, this.errorRulesActions);
            _.set(this._fieldValidators, fieldId, fieldValidator);
        }

        return _.get(this._fieldValidators, fieldId);
    }

    /**
     * Añade una función de validación de un campo
     *
     * Debe recibir dos argumentos, el fieldId y listIndices, y devolver un boolean
     *
     * @param {function} validatorFunction Función de validación
     */
    addCustomValidator(validatorFunction) {
        this.customValidators.push(validatorFunction);
    }

    /**
     * Determina si el elemento (lista, sección, form) es válido recorriendo sus hijos, y para cada uno
     * consultando a su vez si es válido. No sirve para validar un campo individual, para eso es necesario
     * usar la función this.validateField
     *
     * En el caso de los formularios, recorre todos los fields y los valida uno a uno, con el objetivo de
     * mantener la estructura interna de this.validation actualizada
     *
     * En el caso de las listas, hace lo mismo, valida siempre todos los items, de nuevo para mantener
     * actualizado this.validation
     *
     * @param {Container}    element     Instancia del elemento en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {boolean}     TRUE si el elemento es válido
     *
     * @throws {Error} Si el elemento es de algún tipo desconocido
     *
     * @private
     */
    _validateElement(element, listIndices) {
        if (element.isForm()) {
            return this.validateForm(element, listIndices);
        }

        if (element.isList()) {
            return this.validateList(element, listIndices);
        }

        if (element.isSection()) {
            return this.validateSection(element, listIndices);
        }

        if (element.isRoot()) {
            throw new Error('cannot validate element of CRF type, use validateRecord instead');
        }

        if (element.isField()) {
            throw new Error('cannot validate element of Field type, validate Form element instead');
        }

        throw new Error(`field type unknown for ${element.getName()}`);
    }

    /**
     * Valida el registro completo, actualizando la estructura interna de this.validation para todos los elementos que
     * lo componen
     *
     * @return {boolean} TRUE si el registro es válido
     */
    validateRecord() {
        let isValid = true;
        const children = this.crf.getChildren();
        for (const childIndex in children) {
            const child = children[childIndex];
            isValid = this._validateElement(child) && isValid;
        }

        const result = this._setElementValidity(this.crf, isValid);

        this.events.emit('validateRecord', result);

        return isValid;
    }

    /**
     * Valida un formulario completo, actualizando la estructura interna de this.validation para todos sus campos
     * y el propio formulario
     *
     * @param  {Structure.Form|integer} form        Si es un número es el ID del formulario en el CRF, si es un objeto
     *                                              es el propio formulario
     * @param  {ListIndices}            listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {boolean}                            TRUE si el formulario es válido
     */
    validateForm(form, listIndices) {
        if (_.isNumber(form)) {
            form = this.crf.getForm(form);
        }

        // el formulario será válido si todos y cada uno de sus campos lo son
        const fields = form.getFields();
        let isValid = true;
        for (const fieldIndex in fields) {
            const field = fields[fieldIndex];
            isValid = this.validateField(field.getId(), listIndices) && isValid;
        }

        const result = this._setElementValidity(form, isValid, listIndices);

        this.events.emit('validateForm', form.getId(), listIndices, result);

        return isValid;
    }

    /**
     * Valida una sección completa, actualizando la estructura interna de this.validation para todos sus hijos
     *
     * @param  {Structure.Section|integer} section     Si es un número es el ID de la sección en el CRF, si es un objeto
     *                                                 es la propia sección
     * @param  {ListIndices}               listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {boolean}                               TRUE si la sección es válida
     */
    validateSection(section, listIndices) {
        if (_.isNumber(section)) {
            section = this.crf.getSection(section);
        }

        const children = section.getChildren();
        let isValid = true;
        for (const childIndex in children) {
            const child = children[childIndex];
            isValid = this._validateElement(child, listIndices) && isValid;
        }

        this._setElementValidity(section, isValid, listIndices);

        return isValid;
    }

    /**
     * Valida una lista completa, actualizando la estructura interna de this.validation para todos sus items,
     * y a su vez dentro de cada uno todos los hijos que tenga (secciones, formularios, etc.)
     *
     * @param  {Structure.List|integer} list        Si es un número es el ID de la lista en el CRF, si es un objeto es
     *                                              la propia lista
     * @param  {ListIndices}            listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {boolean}                            TRUE si la lista es válida
     */
    validateList(list, listIndices) {
        if (_.isNumber(list)) {
            list = this.crf.getList(list);
        }

        const listLength = this.record.getListLength(list.getId(), listIndices);
        const itemIndices = _.range(1, listLength + 1);
        let isValid = true;
        for (const index in itemIndices) {
            const itemIndex = itemIndices[index];
            isValid = this.validateListItem(list, itemIndex, listIndices) && isValid;
        }

        this._setElementValidity(list, isValid, listIndices);

        return isValid;
    }

    /**
     * Validación de un elemento específico de la lista
     *
     * @param  {Structure.List|integer} list        El objeto de lista o bien su ID
     * @param  {Number}                 itemIndex   El índice del elemento comenzando por 1
     * @param  {ListIndices}            listIndices Índices de las listas a las que pertenece la lista
     *
     * @return {boolean}                            Si el elemento se considera válido
     *
     * @todo Tratar de tener una única interfaz, bien con el ID o bien con el objeto
     */
    validateListItem(list, itemIndex, listIndices = {}) {
        if (_.isNumber(list)) {
            list = this.crf.getList(list);
        }

        const listId = list.getId();
        const listItem = this.record.getListElement(listId, itemIndex, listIndices);
        if (!listItem) {
            return false;
        }

        // Para las llamadas internas se usa un objeto de listIndices actualizado con el índice del item en esta lista
        const localIndices = JSON.parse(JSON.stringify(listIndices));
        localIndices[listId] = itemIndex;

        const children = list.getChildren();
        let isValid = true;
        for (const childIndex in children) {
            const child = children[childIndex];
            isValid = this._validateElement(child, localIndices) && isValid;
        }

        this._setListItemValidity(list, localIndices, isValid);

        return isValid;
    }

    /**
     * Obtiene una plantilla para un objeto vacío de validación de un elemento
     *
     * @return {object} Nuevo objeto de validación
     *
     * @todo Lo suyo es que __valid sea no enumerable, pero entonces el debug lo dejamos de ver
     */
    _getValidationStateBoilerplate() {
        const validationState = {
            __valid: true,
        };
        // Object.defineProperty(validationState, '__valid', {
        //     enumerable: false,
        //     configurable: false,
        //     writable: true,
        // });
        // Object.defineProperty(validationState, '__label', {
        //     enumerable: false,
        //     configurable: false,
        //     writable: true,
        // });

        return validationState;
    }

    /**
     * Obtiene el objeto de validación de un elemento cualquiera. Si no existe en la estructura, se crea vacío
     *
     * @param  {Container}   element     Instancia del elemento en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {ValidationState}         Objeto de validación
     *
     * @private
     */
    _getElementValidity(element = null, listIndices) {
        if (element === null || element.isRoot()) {
            return this.validation;
        }

        const elementId = element.getId();
        const validationKey = this._getValidationKey(elementId, listIndices);

        // La jugada es que si ponemos directamente el valor true no se sigue ascendiendo en la estructura
        // porque no sería capaz de detectar el cambio
        if (!_.has(this.validation, validationKey)) {
            const fragments = validationKey.slice();
            let current = this.validation;

            const boilerplate = this._getValidationStateBoilerplate();

            do {
                const fragment = fragments.shift();

                if (typeof current[fragment] === 'undefined') {
                    current[fragment] = { ...boilerplate };
                }

                current = current[fragment];
            } while (fragments.length > 0);
        }

        const validationState = _.get(this.validation, validationKey);

        return validationState;
    }

    /**
     * Establece el valor de validación de un elemento de lista
     *
     * @param  {Structure.List} list        Objeto de lista que contiene el elemento
     * @param  {ListIndices}    listIndices Índices de las listas en las que se encuentra el elemento, incluyendo la
     *                                      lista inmediatamente superior
     * @param  {boolean}        isValid     Valor de validación
     *
     * @return {ValidationState}            Estado de validación del item
     *
     * @private
     */
    _setListItemValidity(list, listIndices, isValid) {
        const listId = list.getId();
        const itemId = this.record.getListElementId(listId, listIndices[listId], listIndices);

        const itemValidation = this.getListItemValidity(listId, listIndices, itemId);

        const previousValue = itemValidation.__valid;
        itemValidation.__valid = isValid;

        if (previousValue !== isValid) {
            this.validateList(list, _.omit(listIndices, listId));
        }

        return itemValidation;
    }

    /**
     * Actualiza el valor de la validación de un elemento cualquiera, y recorre el árbol para actualizar los
     * padres si es necesario
     *
     * @param  {Container}       element     Instancia del elemento en el CRF
     * @param  {boolean}         isValid     Valor de actualización
     * @param  {ListIndices}     listIndices Índices de las listas a las que pertenece el elemento
     *
     * @return {ValidationState}             Estado de validación completo
     *
     * @private
     */
    _setElementValidity(element, isValid, listIndices = {}) {
        const isListItem = element.isList() && _.has(listIndices, element.getId());

        if (isListItem) {
            return this._setListItemValidity(element, listIndices, isValid);
        }

        const validityState = this._getElementValidity(element, listIndices);
        if (_.isUndefined(isValid)) {
            isValid = this._checkValidation(element, listIndices);
        }

        // Si cambia la validación hay que volver a evaluar al elemento padre
        if (validityState.__valid !== isValid) {
            validityState.__valid = isValid;
            if (!element.isRoot()) {
                validityState.__label = element.getLabel();
            }

            let parent;
            if (element.isField()) {
                parent = element.getForm();
            } else {
                parent = element.getParent();
            }

            if (parent !== null) {
                if (parent.isRoot()) {
                    this.validateRecord();
                } else {
                    this._validateElement(parent, listIndices);
                }
            }
        }

        return validityState;
    }

    /**
     * Actualiza el estado de validación de un formulario
     *
     * @param  {Number}   formId      ID del formulario en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el elemento
     * @param  {boolean} isValid  Estado a actualizar
     *
     * @return {ValidationState} Estado de validación completo
     */
    setFormValidity(formId, listIndices, isValid) {
        return this._setElementValidity(this.crf.getElement(formId), listIndices, isValid);
    }

    /**
     * Obtiene el estado de validación de una sección
     *
     * @param  {Number}       sectionId   ID de la sección en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la sección
     *
     * @return {ValidationState}    Estado de validación de la sección
     */
    getSectionValidity(sectionId, listIndices) {
        const section = this.crf.getElement(sectionId);
        const validityState = this._getElementValidity(section, listIndices);

        if (_.isUndefined(validityState.__valid)) {
            validityState.__valid = this._validateElement(section, listIndices);
        }

        return validityState;
    }
    /**
     * Obtiene el estado de validación de una lista
     *
     * @param  {Number}      listId      ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     *
     * @return {ValidationState}    Estado de validación de la lista
     */
    getListValidity(listId, listIndices) {
        const list = this.crf.getElement(listId);
        const validityState = this._getElementValidity(list, listIndices);

        if (_.isUndefined(validityState.__valid)) {
            validityState.__valid = this._validateElement(list, listIndices);
        }

        return validityState;
    }
    /**
     * Obtiene el estado de validación de un formulario
     *
     * @param  {Number}      formId      ID del formulario en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el formulario
     *
     * @return {ValidationState}    Estado de validación de la lista
     */
    getFormValidity(formId, listIndices) {
        const form = this.crf.getElement(formId);
        const validityState = this._getElementValidity(form, listIndices);

        if (_.isUndefined(validityState.__valid)) {
            validityState.__valid = this._validateElement(form, listIndices);
        }

        return validityState;
    }
    /**
     * Obtiene el estado de validación de un elemento de una lista
     *
     * @param  {Number}      listId      ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el formulario
     * @param  {Number}      itemId      ID interno único para el item de la lista (propiedad __id en los datos)
     *
     * @return {ValidationState}    Estado de validación de la lista
     */
    getListItemValidity(listId, listIndices, itemId) {
        const listValidity = this.getListValidity(listId, listIndices);

        if (!_.has(listValidity, itemId)) {
            _.set(listValidity, itemId, this._getValidationStateBoilerplate());
        }

        return _.get(listValidity, itemId);
    }

    /**
     * Función auxiliar recursiva para devolver los UID de los campos inválidos
     *
     * @param {object} validationObject Objeto de validación
     * @param {Array} currentPath Path hasta el momento del UID
     *
     * @return {Array} Lista de UIDs de campos inválidos
     */
    findInvalidFields(validationObject, currentPath = []) {
        return _.reduce(validationObject, (invalidFieldsList, childValidation, key) => {
            // Solo tiene interés si es inválido
            if (_.get(childValidation, '__valid') === false) {
                if (this.crf.hasField(key)) {
                    // es un campo inválido
                    invalidFieldsList.push(currentPath.concat(key));
                } else if (this.crf.hasList(key)) {
                    // es una lista, hay que seguir recorriendo hasta encontrar el campo inválido
                    const localPath = currentPath.concat(key);
                    for (const itemId in childValidation) {
                        if (!itemId.startsWith('__')) {
                            invalidFieldsList = invalidFieldsList.concat(
                                this.findInvalidFields(childValidation[itemId], localPath.concat(itemId))
                            );
                        }
                    }
                }
            }

            return invalidFieldsList;
        }, []);
    }

    /**
     * Obtiene la lista de campos que están marcados como inválidos
     *
     * @return {array[]} Lista de UIDs de los campos inválidos
     */
    getInvalidFields() {
        return this.findInvalidFields(this.validation);
    }

    /**
     * Indica si el elemento indicado por su UID tiene un valor de validación correcto
     *
     * @param  {string|integer[]}  elementUid El UID del elemento
     *
     * @return {boolean}                      Si el elemento está validado correctamente
     */
    isElementValid(elementUid) {
        const elementValidation = _.get(this.validation, elementUid);

        return elementValidation && elementValidation.__valid;
    }
}

module.exports = Validation;
