'use strict';

const ConfigurationModule = require('./Module');

/**
 * Módulo de configuración: ePRO
 */
class EproConfiguration extends ConfigurationModule {
    /**
     * Constructor estático
     *
     * @param  {Object} params Argumentos de entrada
     */
    constructor(params) {
        const definition = {
            credentialsExplanation: {},
            defaultLanguage: '',
            texts: {},
            languages: [],
            mustChangePinCode: false,
            allowSavePatientEmail: false,
            multiStepForm: false,
            ...params,
        };
        super(definition);
    }

    /**
     * Obtiene la explicación del PDF de credenciales
     *
     * @return {Object} Texto de explicación configurado
     */
    getCredentialsExplanation() {
        return this.definition.credentialsExplanation;
    }


    /**
     * Obtiene el idioma por defecto de la aplicación sin sesión de usuario
     *
     * @return {String} Código de idioma
     */
    getDefaultLanguage() {
        return this.definition.defaultLanguage;
    }

    /**
     * Obtener textos personalizados
     *
     * @return {Object} Lista de textos personalizados
     */
    getTexts() {
        return this.definition.texts;
    }

    /**
     * Obtener un texto personalizado en específico
     *
     * @param  {String} key Clave del texto dentro del objeto
     *
     * @return {Object}     Definición del texto traducible
     */
    getText(key) {
        return this.getTexts()[key];
    }

    /**
     * Obtiene los códigos de idioma disponibles para la aplicación de ePRO
     *
     * @return {String[]} Lista de códigos de idioma
     */
    getLanguages() {
        return this.definition.languages;
    }

    /**
     * Indica si el paciente debe cambiar su PIN al entrar
     *
     * @return {String[]} Lista de códigos de idioma
     */
    mustChangePinCode() {
        return this.definition.mustChangePinCode;
    }

    /**
     * Indica si se permite guardar los datos de contacto del paciente
     *
     * @return {Boolean} Si permite o no guardar datos del paciente
     */
    allowSavePatientEmail() {
        return this.definition.allowSavePatientEmail;
    }

    /**
     * Indica si se incluye el estado de la cuenta ePRO en el listado de pacientes
     *
     * @return {Boolean} Si se incluye el estado de la cuenta ePRO en el listado de pacientes
     */
    getListableStatus() {
        return !!this.definition.listableStatus;
    }

    /**
     * Indica si está activado el formulario por pasos en la aplicación ePRO
     *
     * @returns {Boolean} Si está activado el formulario por pasos en la aplicación ePRO
     */
    getMultiStepForm() {
        return !!this.definition.multiStepForm;
    }
}

module.exports = EproConfiguration;
