'use strict';

const _ = require('lodash');
const moment = require('moment');

const Side = require('./Side');

/**
 * Lado de condición: valor constante
 *
 * @extends {Structure.ConditionSide}
 */
class ValueConditionSide extends Side {
    /**
     * Obtiene el valor definido
     *
     * @return {mixed} El valor definido
     */
    getValue() {
        return this.definition.value;
    }

    /**
     * @inheritDoc
     */
    getDataType() {
        const value = this.getValue();
        if (_.isNil(value)) {
            return null;
        }

        const lhs = this.getCondition().getLhs();
        const lhsDataType = lhs.getDataType();

        if (_.isNumber(value)) {
            // Puede ser el ID de la opción de un campo con grupo de opciones
            if (lhs && lhs.isField() && _.startsWith(lhsDataType, ValueConditionSide.DataTypes.OPTIONS_GROUP)) {
                return lhsDataType;
            }

            return ValueConditionSide.DataTypes.NUMBER;
        }

        if (_.isBoolean(value)) {
            return ValueConditionSide.DataTypes.BOOLEAN;
        }

        if (_.isString(value)) {
            // Aquí puede ser string o date, para saber si es date nos fijamos en el valor izquierdo
            if (!lhs || lhs === this || !lhs.isField() || lhsDataType !== ValueConditionSide.DataTypes.DATE) {
                return ValueConditionSide.DataTypes.STRING;
            }

            // Todas las fechas internas tienen este formato
            if (moment(value, 'YYYY-MM-DD').isValid()) {
                return ValueConditionSide.DataTypes.DATE;
            }

            return ValueConditionSide.DataTypes.STRING;
        }

        throw new Error(`Tipo de dato nativo no reconocido: "${typeof value}" (${value})`);
    }

    /**
     * Obtiene el valor legible del lado de la condición a modo informativo
     *
     * @return {mixed} El valor legible
     */
    getReadableValue() {
        const value = this.getValue();
        const lhs = this.getCondition().getLhs();

        if (lhs.isField()) {
            const field = lhs.getTarget();
            if (field) {
                return field.getReadableValue(value);
            }
        }

        if (lhs.isRecordField()) {
            const recordField = lhs.getField();

            if (recordField === 'randomization') {
                const configuration = this.getCRF().getEcrd().getConfiguration();

                if (configuration.hasRandomization()) {
                    const branch = _.find(configuration.getRandomization().getArms(), {
                        id: value,
                    });

                    if (branch && branch.masked) {
                        return branch.masked;
                    }
                }
            } else if (recordField === 'version' && value) {
                return `#${value}`;
            }
        }

        return value;
    }
}

module.exports = ValueConditionSide;
