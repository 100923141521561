'use strict';

const _ = require('lodash');

/**
 * @typedef {object} ActionDefinition
 */

/**
 * @typedef {object} ExportedAction
 *
 * @property {string} func Función en <Record.Rules> que se debe ejecutar
 * @property {object} definition Definición de la acción original
 * @property {Number}  ruleId ID de la regla correspondiente a esta acción
 * @property {Array<string>} scope Lista de ámbitos donde es aplicable la acción: 'create', 'update'
 * @property {boolean} hasTarget Indica si la acción tiene un objetivo (campo, formulario, etc.)
 * @property {Number}  targetForm Si el objetivo es un campo, este es el ID del formulario al que pertenece
 * @property {Array<string>} targetLists Si el objetivo pertenece a una lista, estos son los ID de todas las listas
 *                                       anidadas a las que pertenece
 * @property {boolean} undo TRUE si la acción es deshacer la acción (las condiciones no se cumplen)
 */

/**
 * Tipos de acción (propiedad "type") conocidos
 *
 * @readonly
 *
 * @enum {string}
 */
const Types = {
    BLOCK_RANDOMIZATION: 'blockRandomization',
    DISABLE: 'disable',
    ERROR: 'error',
    FILTER: 'filter',
    HIDE: 'hide',
    HIDE_CHILDREN: 'hideChildren',
    MESSAGE: 'message',
    NOTIFICATION: 'notification',
    QUERY: 'query',
    SET_VALUE: 'setValue',
    ENABLE_EPRO: 'enableEpro',
};

/**
 * Ámbitos de ejecución de la acción
 *
 * @readOnly
 *
 * @enum {string}
 */
const Scopes = {
    CREATE: 'create',
    UPDATE: 'update',
};

/**
 * Clase abstracta de gestión de una acción genérica de regla
 *
 * @abstract
 *
 * @memberOf Structure
 */
class Action {
    /**
     * Constructor de la clase: inicializa la propiedad de definición
     *
     * @param  {ActionDefinition} definition Definición de la acción
     */
    constructor(definition) {
        this.definition = definition || {};
        this.rule = null;
    }

    /**
     * Valores de la enumeración de tipos de acción
     */
    static get Types() {
        return Types;
    }

    /**
     * Valores de la enumeración de ámbitos
     */
    static get Scopes() {
        return Scopes;
    }

    /**
     * Obtiene el objeto editable de definición de la acción
     *
     * @return {ActionDefinition} La definición de la acción
     */
    get() {
        return this.definition;
    }

    /**
     * Establece la regla padre que contiene la acción
     *
     * @param  {Structure.Rule}   rule La definición de la regla
     *
     * @return {Structure.Action}      La acción
     */
    setRule(rule) {
        this.rule = rule;

        return this;
    }

    /**
     * Devuelve la regla padre que contiene la acción
     *
     * @return  {Structure.Rule}   Regla que contiene la acción
     */
    getRule() {
        return this.rule;
    }

    /**
     * Obtiene un identificador único de la acción
     *
     * @return {Number}  El valor del ID de la acción
     */
    getId() {
        return this.definition.id;
    }

    /**
     * Obtiene el identificador del tipo de acción
     *
     * @return {string} Tipo de acción
     */
    getType() {
        return this.definition.type;
    }

    /**
     * Determina si la acción es del tipo especificado
     *
     * @param  {string}  type Tipo de acción a comprobar
     *
     * @return {boolean}      Si coincide el tipo con la propiedad type de la acción
     */
    isType(type) {
        return this.getType() === type;
    }

    /**
     * Exportación de la acción en un objeto para la ejecución de las reglas
     *
     * @interface
     *
     * @todo Si es un form el target del action, ¿poner también la propiedad targetForm?
     */
    export() {
        let action = {
            definition: this.definition,
            ruleId: this.getRule().getId(),
            scope: this.getScope(),
            hasTarget: _.has(this.definition, 'target'),
        };
        if (action.hasTarget) {
            const target = this.getTarget();
            if (target) {
                if (target.isField()) {
                    // formulario al que pertenece el campo 'target'
                    action.targetForm = target.getForm().getId();
                }
                // listas a las que pertenece el campo 'target'
                action.targetLists = _.map(target.getParentLists(), _.method('getId'));
            }
        }

        return action;
    }

    /**
     * Determina si el destino de la acción es el elemento con el ID seleccionado
     *
     * @param  {Number}   targetId ID del elemento que se va a comprobar
     *
     * @return {boolean}           Resultado de la comprobación
     */
    hasTarget(targetId) {
        return _.get(this.definition, 'target', null) === targetId;
    }

    /**
     * Determina si alguno de los elementos con ID seleccionados es el destinatario de la acción
     *
     * @param  {integer[]}  targetIds IDs de los elementos
     *
     * @return {boolean}              Si alguno de ellos aparece como destino en la definición
     */
    hasAnyTarget(targetIds) {
        return _.castArray(targetIds).includes(this.definition.target);
    }

    /**
     * Obtiene el elemento de destino de la acción
     *
     * @return {Structure.Container} El destino de la acción, puede ser cualquier tipo de elemento desde un campo hasta
     *                               el propio CRD
     */
    getTarget() {
        return this.getCRF().getElement(_.get(this.definition, 'target'));
    }

    /**
     * Obtiene la referencia del objeto global del CRF
     *
     * @return {Structure.CRF} Definición del CRF
     */
    getCRF() {
        return this.rule ? this.rule.getCRF() : null;
    }

    /**
     * Obtiene los identificadores del ámbito de ejecución de la acción
     *
     * @return {Array<string>} Lista de identificadores del ámbito de ejecución
     *
     * @todo Refactorizar para evitar el 'always' existente
     */
    getScope() {
        let scope = _.get(this.definition, 'scope', 'always');
        if (scope === 'always') {
            scope = ['create', 'update'];
        }

        return _.castArray(scope);
    }

    /**
     * Determina si la acción está deshabilitada
     *
     * @return {boolean} Si está deshabilitada
     */
    isDisabled() {
        return _.get(this.definition, 'disabled');
    }

    /**
     * Determina si la acción está habilitada
     *
     * @return {boolean} Si está habilitada (no está deshabilitada)
     */
    isEnabled() {
        return !this.isDisabled();
    }

    /**
     * Marca el flag de deshabilitado
     *
     * @return {Action} El objeto de acción modificado
     */
    disable() {
        _.set(this.definition, 'disabled', true);

        return this;
    }

    /**
     * Desmarca el flag de deshabilitado
     *
     * @return {Action} El objeto de acción modificado
     */
    enable() {
        _.set(this.definition, 'disabled', false);

        return this;
    }
}

module.exports = Action;
