'use strict';

const _ = require('lodash');

/**
 * @typedef {@object} RelationalFieldDefinition
 *
 * Objeto de definición de un campo genérico del usuario
 *
 * @property {Number}  id   ID del elemento
 * @property {string}  name Valor de la propiedad name
 */

/**
 * @typedef {object} UserDefinition
 *
 * Objeto de definición del usuario que sirve de entrada a la clase para inicializar los valores
 *
 * @property {RelationalFieldDefinition[]} profiles       Datos de los perfiles del usuario
 * @property {RelationalFieldDefinition[]} sites          Datos de los sitios a los que pertenece el usuario
 * @property {RelationalFieldDefinition[]} groups         Datos de los grupos a los que pertenece el usuario
 * @property {object}                      currentProfile Perfil de acceso del usuario, uno de profiles
 */

/**
 * Clase de gestión de usuarios del estudio. Contiene los datos de consulta aptos para ser utilizados en condiciones de
 * reglas
 *
 * @property {RelationalFieldDefinition[]} profiles       Datos de los perfiles del usuario
 * @property {RelationalFieldDefinition[]} sites          Datos de los sitios a los que pertenece el usuario
 * @property {RelationalFieldDefinition[]} groups         Datos de los grupos a los que pertenece el usuario
 * @property {object}                      currentProfile Perfil de acceso del usuario, uno de profiles
 */
class User {
    /**
     * Constructor de la clase: inicializa las listas de perfiles, sitios y grupos
     *
     * @param  {UserDefinition} userData Datos del usuario
     */
    constructor(userData) {
        this.setProfiles(_.get(userData, 'profiles', []));
        this.setSites(_.get(userData, 'sites', []));
        this.setGroups(_.get(userData, 'groups', []));

        let currentProfile = userData.currentProfile;
        // Si solamente hay un posible perfil se trata implícitamente de currentProfile
        if (!currentProfile && _.size(userData.profiles) === 1) {
            currentProfile = _.pick(userData.profiles[0], 'id', 'name');
        }
        this.setCurrentProfile(currentProfile);
    }

    /**
     * Establece los perfiles del usuario
     *
     * @param {integer[]} profiles Lista de IDs de perfiles
     *
     * @return {User} El objeto de User modificado
     */
    setProfiles(profiles) {
        this.profiles = _.castArray(profiles);

        return this;
    }

    /**
     * Obtiene la lista de perfiles del usuario
     *
     * @return {integer[]} IDs de los perfiles del usuario
     */
    getProfiles() {
        return this.profiles;
    }

    /**
     * Determina si el usuario tiene el perfil especificado
     *
     * @param  {Number}  profile ID del perfil
     *
     * @return {boolean}         Si el ID se encuentra en la lista
     */
    hasProfile(profile) {
        return _.includes(this.profiles, profile);
    }

    /**
     * Establece los sitios del usuario
     *
     * @param {integer[]} sites Lista de IDs de sitios
     *
     * @return {User} El objeto de User modificado
     */
    setSites(sites) {
        this.sites = _.castArray(sites);

        return this;
    }

    /**
     * Obtiene la lista de sitios a los que pertenece el usuario
     *
     * @return {integer[]} IDs de los sitios del usuario
     */
    getSites() {
        return this.sites;
    }

    /**
     * Determina si el usuario pertenece al sitio especificado
     *
     * @param  {Number}  site ID del sitio
     *
     * @return {boolean}      Si el ID se encuentra en la lista
     */
    hasSite(site) {
        return _.includes(this.sites, site);
    }

    /**
     * Establece los grupos del usuario
     *
     * @param {integer[]} groups Lista de IDs de grupos
     *
     * @return {User} El objeto de User modificado
     */
    setGroups(groups) {
        this.groups = _.castArray(groups);

        return this;
    }

    /**
     * Obtiene la lista de grupos a los que pertenece el usuario
     *
     * @return {integer[]} IDs de los grupos del usuario
     */
    getGroups() {
        return this.groups;
    }

    /**
     * Determina si el usuario pertenece al grupo especificado
     *
     * @param  {Number}  group ID del grupo
     *
     * @return {boolean}       Si el ID se encuentra en la lista
     */
    hasGroup(group) {
        return _.includes(this.groups, group);
    }

    /**
     * Obtiene el valor comparable a partir de un nombre de campo
     * - Si el campo es único (profile) se devuelve el nombre definido del perfil
     * - Si el campo es múltiple (profiles, sites, groups) se devuelve la lista con los nombres de los elementos
     *
     * @param  {string}          fieldName Identificador del campo
     *
     * @return {string|string[]}           Nombre o lista de nombres (propiedad name) según índice
     */
    getValue(fieldName) {
        if (fieldName === 'profile') {
            return _.get(this.getCurrentProfile(), 'name', null);
        }

        // El resto de propiedades son arrays, no hay un uso claro para estos datos pero se mantienen para consulta
        let fieldArray;

        if (fieldName === 'profiles') {
            fieldArray = this.getProfiles();
        } else if (fieldName === 'sites') {
            fieldArray = this.getSites();
        } else if (fieldName === 'groups') {
            fieldArray = this.getGroups();
        }

        return fieldArray ? _.map(fieldArray, 'name') : null;
    }

    /**
     * Establece el perfil de acceso activo del usuario
     *
     * @param  {object} profile Datos del perfil {id, name}
     *
     * @return {User}           La instancia de User
     */
    setCurrentProfile(profile) {
        this.currentProfile = profile;

        return this;
    }

    /**
     * Obtiene el perfil de acceso activo del usuario
     *
     * @return {object} Datos del perfil {id, name}
     */
    getCurrentProfile() {
        return this.currentProfile;
    }
}

module.exports = User;
