'use strict';

const _ = require('lodash');
const moment = require('moment');
const debug = require('debug')('sharecrf:core');
const striptags = require('striptags');

const FieldsContainer = require('./FieldsContainer');
const FieldOptions = require('./FieldOptions');

const regexFloat = /^[-]?[0-9]*\.?([0-9]+)$/;
const regexInteger = /^[-]?[0-9]+$/;

/**
 * Tipos básicos de campo según el dato que pueden contener
 *
 * @readonly
 *
 * @enum {string}
 */
const fieldType = {
    ARRAY: 'array',
    BOOLEAN: 'boolean',
    DATE: 'date',
    DATETIME: 'datetime',
    NUMBER: 'number',
    STRING: 'string',
    TIME: 'time',
};

/**
 * Formatos de representación del campo
 *
 * @readonly
 *
 * @enum {string}
 */
const fieldFormat = {
    DATE_SHORT: 'DD/MM/YYYY',
    DATE_ISO8601: 'YYYY-MM-DD',
    DATETIME_MINUTES: 'YYYY-MM-DD HH:mm',
    DATETIME_SECONDS: 'YYYY-MM-DD HH:mm:ss',
};

/**
 * Tipos de control visual para introducir el dato en el campo
 *
 * @readonly
 *
 * @enum {string}
 */
const fieldFormControl = {
    CHECKBOX: 'checkbox',
    DATEPICKER: 'datepicker',
    DATECOMPONENTS: 'datecomponents',
    DATETIMEPICKER: 'datetimepicker',
    FILES: 'files',
    DICOM: 'dicom',
    INPUT: 'input',
    NUMBER: 'number',
    RADIO: 'radio',
    RAW: 'raw',
    SELECT: 'select',
    TEXTAREA: 'textarea',
    TIME: 'time',
    VISUAL_ANALOGUE_SCALE: 'vas',
    MULTIPLE_CHECKBOX: 'multiple_checkbox',
    GRID: 'grid',
};

const vasLayout = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

/**
 * Clase de manejo de campos del CRF. Es una especialización de FieldsContainer en tanto que cada campo puede tener una
 * lista de subvariables
 * @extends FieldsContainer
 * @property {object}  definition             Definición específica del campo
 * @property {boolean} definition.required    Campo obligatorio
 * @property {mixed}   definition.default     Valor por defecto del campo
 * @property {string}  definition.type        Tipo de control de formulario que maneja el campo
 * @property {object}  definition.constraints Lista de restricciones sobre el valor del campo
 * @property {object}  definition.properties  Propiedades de visualización del campo
 * @memberOf Structure
 */
class Field extends FieldsContainer {
    /**
     * Constructor de la clase. Instancia la lista de opciones de valor si está definida
     * @param  {object} definition Definición del campo
     */
    constructor(definition) {
        super(definition);

        if (this.needsOptions()) {
            this.options = new FieldOptions(definition.options || {});
        }

        if (this.getFormControl() === fieldFormControl.GRID) {
            this.cells = [];
        }

        this.grid = null;
    }

    /**
     * Constantes de tipo de campo
     */
    static get FieldType() {
        return fieldType;
    }

    /**
     * Constantes de tipo de control
     */
    static get FormControl() {
        return fieldFormControl;
    }

    /**
     * Constantes de formato de valores
     */
    static get FieldFormats() {
        return fieldFormat;
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat([
            'fieldType',
            'formControl',
            'required',
            'default',
            'constraints',
            'options',
            'format',
            'hidden',
            'disabled',
            'unknown',
            'exportable',
            'encrypted',
            'excludedFromEpro',
            'help',
            'template',
            'hideChildren',
            'hideChildrenIfEmpty',
            'hideEproLabel',
            'resetDescendants',
            'properties',
            'partialDateSettings',
            'rows',
            'columns',
            'cells',
            'anonymize',
            'lang',
            'ignoreDataCleaning',
        ]);
    }

    /**
     * Devuelve TRUE
     *
     * @return {boolean} Indica que es un contenedor de tipo Field
     */
    isField() {
        return true;
    }

    /**
     * Determina si el campo se considera de obligado cumplimiento
     * @return {boolean} Valor definido, si no hay definición se devuelve falso
     */
    isRequired() {
        return _.get(this, 'definition.required', false);
    }

    /**
     * Obtiene el formulario al que pertenece el campo. Si el campo actual es subvariable de otro campo se busca
     * el formulario del campo padre
     * @return {Form} Objeto de gestión del formulario
     */
    getForm() {
        if (this.parent instanceof Field) {
            return this.parent.getForm();
        }

        return this.parent;
    }

    /**
     * Obtiene el valor por defecto definido para el campo. Si no hay definición de valor por defecto devuelve undefined
     * @return {mixed} El valor por defecto o undefined si no hay
     */
    getDefaultValue() {
        return this.definition && this.definition.default;
    }

    /**
     * Obtiene el tipo de campo de formulario para representar el valor del campo
     *
     * @return {fieldFormControl} Tipo de control de formulario
     */
    getFormControl() {
        return this.definition && this.definition.formControl || 'input';
    }

    /**
     * Obtiene el tipo de dato del campo
     *
     * @return {fieldType} Tipo de dato
     */
    getFieldType() {
        return this.definition && this.definition.fieldType || 'string';
    }

    /**
     * Obtiene el formato de representación del campo
     *
     * @return {fieldFormat} Identificador del formato
     */
    getFormat() {
        return this.definition && this.definition.format || this.getDefaultFormat();
    }
    /**
     * Devuelve un formato por defecto en función del tipo de control del campo
     * De uso en campos de fecha y fecha+hora
     *
     * @return {String|null} Formato por defecto para el campo
     *
     * @private
     */
    getDefaultFormat() {
        const formControl = this.getFormControl();

        if ([fieldFormControl.DATEPICKER, fieldFormControl.DATECOMPONENTS].includes(formControl)) {
            return this.getCRF().getEcrd().getConfiguration().getDateFormat();
        }

        if (formControl === fieldFormControl.DATETIMEPICKER) {
            const dateFormat = this.getCRF().getEcrd().getConfiguration().getDateFormat();

            return this.getConstraint('accuracy') === 'seconds' ? `${dateFormat} HH:mm:ss` : `${dateFormat} HH:mm`;
        }

        return null;
    }

    /**
     * Obtiene la lista de restricciones del campo
     * @return {object} Objeto indexado por nombre de restricción con el valor definido
     */
    getConstraints() {
        const constraintsDefinition = this.definition && this.definition.constraints || {};

        // El valor vacío no es relevante para ninguna de las validaciones; al contrario, puede provocar problemas si no
        // se trata correctamente. Para anticiparnos a dichos problemas vamos a filtrar aquí los valores que no nos
        // dicen nada (que a fin de cuentas son equivalentes a no tener la restricción)
        const constraints = {};

        Object.keys(constraintsDefinition).forEach(key => {
            const value = constraintsDefinition[key];

            if (value !== null && typeof value !== 'undefined' && value !== '' && value !== false) {
                constraints[key] = value;
            }
        });

        return constraints;
    }

    /**
     * Obtiene una restricción concreta
     *
     * @param {string} constraint Nombre de la restricción
     *
     * @return {object} Objeto indexado por nombre de restricción con el valor definido
     */
    getConstraint(constraint) {
        const constraints = this.getConstraints();

        return _.get(constraints, constraint, null);
    }

    /**
     * Determina si el valor del campo se encuentra en una lista fija de valores
     *
     * @return {boolean} Si el tipo de representación es de radio o select, el valor está en una lista fija
     */
    needsOptions() {
        const fieldsWithOptions = [
            fieldFormControl.RADIO,
            fieldFormControl.SELECT,
            fieldFormControl.MULTIPLE_CHECKBOX,
        ];

        return _.includes(fieldsWithOptions, this.getFormControl());
    }

    /**
     * Obtiene la lista de opciones que puede tomar el valor del campo
     *
     * @return {FieldOptions}   Definición de las opciones, null si no tiene
     */
    getOptions() {
        return this.options || null;
    }

    /**
     * Determina si el campo está oculto
     *
     * @return {boolean} TRUE si está oculto
     */
    isHidden() {
        return this.definition && this.definition.hidden || false;
    }

    /**
     * Determina si el campo está deshabilitado
     *
     * @return {boolean} TRUE si está deshabilitado
     */
    isDisabled() {
        return this.definition && this.definition.disabled || false;
    }

    /**
     * Determina si el campo permite valores desconocidos
     *
     * @return {boolean} TRUE si permite valores desconocidos
     */
    allowsUnknownValue() {
        const allowedTypes = [
            fieldFormControl.DATEPICKER,
            fieldFormControl.DATETIMEPICKER,
            fieldFormControl.DATECOMPONENTS,
            fieldFormControl.INPUT,
            fieldFormControl.NUMBER,
            fieldFormControl.TIME,
            fieldFormControl.VISUAL_ANALOGUE_SCALE,
            fieldFormControl.TEXTAREA,
        ];

        return allowedTypes.indexOf(this.getFormControl()) > -1 && !!this.definition.unknown;
    }

    /**
     * Determina si el campo es exportable
     *
     * @return {boolean} TRUE si es exportable
     */
    isExportable() {
        return this.definition && typeof this.definition.exportable !== 'undefined' ? this.definition.exportable : true;
    }

    /**
     * Determina si el campo está encriptado
     *
     * @return {boolean} TRUE si está encriptado
     */
    isEncrypted() {
        return this.definition && this.definition.encrypted || false;
    }

    /**
     * Determina si el campo se excluye en el ePRO
     *
     * @return {boolean} TRUE si se excluye en el ePRO
     */
    isExcludedFromEpro() {
        return this.definition && this.definition.excludedFromEpro || false;
    }

    /**
     * Devuelve el texto de ayuda del campo
     *
     * @return {string} Texto de ayuda
     */
    getHelp() {
        return this.definition && this.definition.help || '';
    }

    /**
     * Devuelve el contenido de un campo RTF
     *
     * @return {string} Contenido del campo
     */
    getTemplate() {
        return this.definition && this.definition.template || '';
    }

    /**
     * Devuelve el contenido de un campo RTF en formato de texto plano corto
     *
     * @param {Number} limitLength Longitud referencia para el resumen
     *
     * @return {string} Contenido del campo
     */
    getSummary(limitLength = 20) {
        const contents = striptags(this.getTemplate())
            .split(' ')
            .filter(word => !!word);
        let text = '';
        while (contents.length && text.length < limitLength) {
            text += ` ${contents.shift()}`;
        }
        contents.length && (text += '...');

        return text;
    }

    /**
     * Devuelve la cadena completa de variables padre. Es recursivo, de modo que si el padre es a su vez subvariable
     * de otra, la añade al resultado
     *
     * @return {Array<Field>} Lista de variables padre
     */
    getParentFields() {
        return this.getParents().filter(parent => parent.isField && parent.isField());
    }

    /**
     * Obtiene los valores de un selectable para las que los hijos están ocultos
     *
     * @return {string[]} Lista de valores (incluyendo NULL) para los que debe ocultarse los hijos de este campo
     */
    getValuesToHideChildren() {
        return (this.definition && this.definition.hideChildren || []).sort();
    }

    /**
     * Obtiene el flag de configuración del campo que indica si se quieren ocultar los hijos en caso
     * de que el valor del campo esté vacío (undefined, null o cadena vacía)
     *
     *
     * @return {Boolean} [description]
     */
    getHideChildrenIfEmpty() {
        if (_.has(this.definition, 'hideChildrenIfEmpty')) {
            return this.definition.hideChildrenIfEmpty;
        }

        // Si es un checkbox y oculta los hijos con el valor "desmarcado" también quiere decir que se oculta en vacío
        if (this.getFormControl() === fieldFormControl.CHECKBOX) {
            return _.includes(this.getValuesToHideChildren(), false);
        }

        return false;
    }

    /**
     * Devuelve la configuración del campo acerca de si deben resetearse las subvariables al ocultarse
     *
     * @return {boolean}  TRUE si deben resetearse
     */
    getResetDescendants() {
        return this.definition && this.definition.resetDescendants || false;
    }

    /**
     * Devuelve el valor legible de una variable. Por ejemplo, en el caso de selects, transforma el número
     * en el label del option
     *
     * @param  {*} value      Valor del campo en la BD
     *
     * @return {string}           Valor legible
     */
    getReadableValue(value) {
        let readableValue = '';
        let momentObject, format;
        let option = null;

        const type = this.getFormControl();
        switch (type) {
            case fieldFormControl.RADIO:
            case fieldFormControl.SELECT:
                option = this.getOptions().getOptionByValue(value);

                if (option !== null) {
                    readableValue = option.label;
                }
                break;

            case fieldFormControl.DATEPICKER:
                if (_.isNil(value)) {
                    readableValue = '';
                } else {
                    format = this.getFormat();
                    momentObject = moment(value, fieldFormat.DATE_ISO8601);
                    readableValue = momentObject.isValid() ? momentObject.format(format) : '' + value;
                }
                break;

            case fieldFormControl.CHECKBOX:
                readableValue = value ? 'Marcado' : 'Desmarcado';
                break;

            case fieldFormControl.MULTIPLE_CHECKBOX:
                option = this.getOptions().getOptionByValue(Array.isArray(value) ? value : [value]) || [];
                readableValue = '' + option.map(item => item.label).join(', ');
                break;

            default:
                readableValue = typeof value === 'undefined' || value === null ? '' : '' + value;
        }
        debug('Readable Value for %d (%s) %O is %O', this.getId(), type, value, readableValue);

        return readableValue;
    }

    /**
     * Obtiene las propiedades configuradas para la visualización del campo
     *
     * @return {object} Definición de propiedades
     */
    getProperties() {
        return this.definition && this.definition.properties || {};
    }

    /**
     * Determina si el campo es de valor múltiple
     *
     * @return {boolean} Si el tipo de dato es array
     */
    isMultivalued() {
        return this.getFieldType() === fieldType.ARRAY;
    }

    /**
     * Determina dinámicamente el tipo de dato más adecuado para este campo
     * Actualmente, solo miramos en el caso de contar con opciones:
     * 1. Si todos son números enteros, devuelve 'number' y precision 0
     * 2. Si todos son números, y al menos uno es un float, devuelve 'number' y precision > 0
     * 3. Si alguno de ellos no es un número válido, devuelve 'string'
     * En todos los casos devuelve length como la longitud del valor más largo (representado como texto)
     *
     * @return {object} {type: , length: , precision: }
     */
    computeFieldType() {
        if (!this.needsOptions()) {
            return {
                type: this.getFieldType(),
            };
        }

        const types = [];
        let info;
        let length = 0, precision = 0;

        const options = this.getOptions().getOptionList();
        for (let i = 0; i < options.length; i++) { // eslint-disable-line id-length
            info = this._getOptionValueInfo(options[i].value);
            length = Math.max(length, info.length);
            precision = Math.max(precision, info.precision);
            types.push(info.type);
        }

        return {
            type: _.includes(types, 'string') ? fieldType.STRING : fieldType.NUMBER,
            length: length,
            precision: precision,
        };
    }

    /**
     * Devuelve información acerca del tipo de valor de una opción
     *
     * @param {string} value Valor de la opción como cadena de texto
     *
     * @return {object} { type: <'integer', 'float', 'string'>,
     *                    length: max_length como string,
     *                    precision: max_decimals en caso de float }
     */
    _getOptionValueInfo(value) {
        let match, precision = 0;
        let type = 'string';

        if (regexInteger.test(value)) {
            type = 'integer';
        } else if ((match = value.match(regexFloat))) {
            type = 'float';
            precision = match[1].length;
        }

        return {
            type: type,
            length: value.length,
            precision: precision,
        };
    }

    /**
     * @inheritDoc
     */
    getDataPathFromList() {
        let components = this.getPathFromList();
        if (this.isField()) {
            const parentFieldNames = this.getParentFields().map(item => item.getName());
            // quitamos los componentes padre que sean 'field'
            components = _.difference(components, parentFieldNames);
        }

        return components;
    }

    /**
     * Indica si el campo permite tener un valor (es variable), o por el contrario no puede tenerlo (es informativo)
     *
     * @return {boolean} Si el campo es de tipo variable
     */
    allowsValue() {
        return this.getFormControl() !== fieldFormControl.RAW &&
            this.getFormControl() !== fieldFormControl.GRID;
    }

    // Partial dates

    /**
     * Get partial date settings
     *
     * @returns {Object} Partial date settings
     */
    getPartialDateSettings() {
        return Object.assign({
            allowed: false,
            requiredDay: false,
            requiredMonth: false,
            requiredYear: false,
        }, this.definition.partialDateSettings || {});
    }

    /**
     * Is partial date allowed
     *
     * @returns {Boolean} TRUE if partial date is allowed
     */
    isPartialDateAllowed() {
        return this.getPartialDateSettings().allowed;
    }
    /**
     * Is day required in partial date
     *
     * @returns {Boolean} TRUE if day required in partial date
     */
    isDayRequiredInPartialDate() {
        return this.getPartialDateSettings().requiredDay;
    }
    /**
     * Is month required in partial date
     *
     * @returns {Boolean} TRUE if month required in partial date
     */
    isMonthRequiredInPartialDate() {
        return this.getPartialDateSettings().requiredMonth;
    }
    /**
     * Is year required in partial date
     *
     * @returns {Boolean} TRUE if year required in partial date
     */
    isYearRequiredInPartialDate() {
        return this.getPartialDateSettings().requiredYear;
    }

    // Grid

    /**
     * @inheritDoc
     *
     * Si el campo es de tipo grid recorre la matriz de subvariables
     */
    getChildren() {
        if (this.getFormControl() === fieldFormControl.GRID) {
            return this.getCells().reduce((acc, cellsRow) => {
                return acc.concat(cellsRow.filter(cellColumn => cellColumn !== null));
            }, []);
        }

        return super.getChildren();
    }

    /**
     * Establece el grid como contenedor que incluye al campo actual
     *
     * @param  {Structure.Field} grid Instancia del grid padre
     *
     * @return {Structure.Field}      Instancia del campo perteneciente al grid
     */
    setGrid(grid) {
        this.grid = grid;

        return this;
    }

    /**
     * Obtiene el grid que incluye al campo actual
     *
     * @return {Structure.Field} Instancia del grid padre
     */
    getGrid() {
        return this.grid;
    }

    /**
     * Obtiene la definición de filas del grid actual
     *
     * @return {string[]} Lista de cabeceras de fila
     */
    getRows() {
        return this.definition.rows || [];
    }

    /**
     * Obtiene la definición de columnas del grid actual
     *
     * @return {string[]} Lista de cabeceras de columna
     */
    getColumns() {
        return this.definition.columns || [];
    }

    /**
     * Establece la matriz de campos del grid
     *
     * @param  {Array<(Structure.Field|null)[]>} cells Lista de campos que pertenecen al grid
     *
     * @return {Structure.Field}                       Instancia del grid
     */
    setCells(cells) {
        this.cells = cells;

        return this;
    }

    /**
     * Obtiene la matriz de campos del grid
     *
     * @return {Array<(Structure.Field|null)[]>} Lista de campos que pertenecen al grid
     */
    getCells() {
        return this.cells || [];
    }

    /**
     * Si el campo pertenece a un grid, obtiene las coordenadas dentro de la cuadrícula donde se encuentra
     *
     * @return {integer[]} Coordenadas [fila, columna] del campo dentro del grid. Undefined si no pertenece a un grid
     */
    getGridPosition() {
        const grid = this.getGrid();
        if (!grid || grid.getFormControl() !== fieldFormControl.GRID) {
            return undefined;
        }

        const cells = grid.getCells();

        for (let rowIndex = 0; rowIndex < cells.length; rowIndex++) {
            for (let colIndex = 0; colIndex < cells[rowIndex].length; colIndex++) {
                if (cells[rowIndex][colIndex] === this) {
                    return [rowIndex, colIndex];
                }
            }
        }
    }

    /**
     * Si el campo pertenece a un grid, devuelve el título de la fila que lo contiene
     *
     * @return {string} Título de la fila donde se encuentra el campo
     */
    getGridRowName() {
        const position = this.getGridPosition();

        // Si position es un array sabemos que pertenece a un grid
        return Array.isArray(position) ? this.getGrid().getRows()[position[0]] : undefined;
    }

    /**
     * Si el campo pertenece a un grid, devuelve el título de la fila que lo contiene
     *
     * @return {string} Título de la fila donde se encuentra el campo
     */
    getGridColumnName() {
        const position = this.getGridPosition();

        // Si position es un array sabemos que pertenece a un grid
        return Array.isArray(position) ? this.getGrid().getColumns()[position[1]] : undefined;
    }

    // DICOM

    /**
     * Si el estudio DICOM adjunto se debe anonimizar antes de subir
     *
     * @returns {Boolean} Si el estudio DICOM adjunto se debe anonimizar antes de subir
     */
    getAnonymize() {
        return this.getFormControl() === 'dicom' && !!this.definition.anonymize;
    }

    // VAS

    /**
     * Get layout
     *
     * @returns {string} Layout del campo VAS
     */
    getLayout() {
        if (this.getFormControl() === fieldFormControl.VISUAL_ANALOGUE_SCALE) {
            return this.definition && this.definition.properties && this.definition.properties.layout || vasLayout.HORIZONTAL;
        }

        return null;
    }

    /**
     * Get show scale values
     *
     * @returns {boolean} Si se debe mostrar el valor del campo
     */
    getShowScaleValues() {
        if (this.getFormControl() === fieldFormControl.VISUAL_ANALOGUE_SCALE) {
            return this.definition && this.definition.properties && this.definition.properties.showScaleValues || false;
        }

        return false;
    }

    /**
     * Get show scale marks
     *
     * @returns {boolean} Si se deben mostrar las marcas de la escala
     */
    getShowScaleMarks() {
        if (this.getFormControl() === fieldFormControl.VISUAL_ANALOGUE_SCALE) {
            return this.definition && this.definition.properties && this.definition.properties.showScaleMarks || false;
        }

        return false;
    }

    /**
     * Get show value box
     *
     * @returns {boolean} Si se debe mostrar el cuadro con el valor del campo
     */
    getShowValueBox() {
        if (this.getFormControl() === fieldFormControl.VISUAL_ANALOGUE_SCALE) {
            return this.definition && this.definition.properties && this.definition.properties.showValueBox || false;
        }

        return false;
    }

    /**
     * Get value box text
     *
     * @returns {string} Texto mostradoe en el cuadro con el valor del campo
     */
    getTextValueBox() {
        if (this.getFormControl() === fieldFormControl.VISUAL_ANALOGUE_SCALE) {
            return this.definition && this.definition.properties && this.definition.properties.textValueBox || '';
        }

        return '';
    }

    // General

    /**
     * @inheritDoc
     *
     * Si el campo es de tipo grid recorre los elementos definidos en la cuadrícula
     */
    getAllElements() {
        if (this.getFormControl() === fieldFormControl.GRID) {
            return this.getCells().reduce((gridAcc, row) => {
                return gridAcc.concat(row.reduce((rowAcc, cell) => {
                    return cell ? rowAcc.concat(cell) : rowAcc;
                }, []));
            }, []);
        }

        return super.getAllElements();
    }

    /**
     * Si no se debe tener en cuenta el campo en un data-cleaning
     *
     * @returns {Boolean} Si no se debe tener en cuenta el campo en un data-cleaning
     */
    ignoresDataCleaning() {
        return !!this.definition.ignoreDataCleaning;
    }
}

module.exports = Field;
