'use strict';

const ConfigurationModule = require('./Module');

/**
 * Configuración de cohortes
 *
 * @typedef {object} CohortsConfiguration
 *
 * @property {boolean}   site   Si el centro del registro interviene en la cohorte
 * @property {integer[]} fields IDs de los campos del CRD que conforman la cohorte
 */

/**
 * Tipos de aleatorización
 *
 * @enum {string}
 *
 * @readOnly
 */
const RandomizationTypes = {
    SIMPLE: 'simple',
    BLOCK: 'block',
};

/**
 * Módulo de configuración: Aleatorización
 */
class RandomizationConfiguration extends ConfigurationModule {
    /**
     * Constantes de tipo de aleatorización
     */
    static get RandomizationTypes() {
        return RandomizationTypes;
    }

    /**
     * Determina si tiene habilitada la gestión de stock de aleatorización
     *
     * @return {boolean} Si tiene activada esta característica
     */
    hasStock() {
        return this.isEnabled() && !!this.definition.stockManagement;
    }

    /**
     * Obtiene la configuración de los brazos de aleatorización
     *
     * @return {object} Definición de los brazos
     */
    getArms() {
        return this.isEnabled() && this.definition.branches ? this.definition.branches : [];
    }

    /**
     * Obtiene el nombre del campo visible en el listado
     *
     * @return {string} Nombre de la propiedad de aleatorización
     */
    getListableField() {
        return this.isEnabled() && this.definition.listableField ? this.definition.listableField : null;
    }

    /**
     * Determina si tiene configurado el código individual de aleatorización
     *
     * @return {boolean} Valor definido
     */
    hasIndividualMasking() {
        return this.isEnabled() && !!this.definition.individualMasking;
    }

    /**
     * Obtiene el identificador del tipo o método de aleatorización
     *
     * @return {RandomizationTypes} El tipo definido
     */
    getType() {
        return this.isEnabled() ? this.definition.type : null;
    }

    /**
     * Obtiene la definición de cohortes o estratos
     *
     * @return {CohortsConfiguration} Definición de las cohortes
     */
    getCohorts() {
        return this.isEnabled() ? this.definition.cohorts : {};
    }

    /**
     * Obtiene los tamaños de bloques definidos
     *
     * @return {integer[]} Lista de tamaños
     */
    getBlockSizes() {
        return this.getType() === RandomizationTypes.BLOCK ? this.definition.blocks : [];
    }
}

module.exports = RandomizationConfiguration;
