'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core');

const Events = {
    ON_FIELD_CHANGE: 'onFieldChange',
    ON_UNDO_FIELD_CHANGE: 'onUndoFieldChange',
    ON_RESET: 'onReset',
    ON_SET_REASON: 'onSetReason',
    ON_AUTOMATIC_CHANGE: 'onAutomaticChange',
};

/**
 * Objeto de cambio de valor de un campo
 *
 * @typedef {object} FieldChange
 *
 * @property {mixed}  previous        Valor anterior al cambio
 * @property {mixed}  current         Valor posterior al cambio
 * @property {string} manualReason    Razón del cambio introducida manualmente
 * @property {string} fixedReason     Parte fija de la razón del cambio, configurable en el módulo de audit
 * @property {string} automaticReason Identificador de la razón del cambio cuando es automático
 * @property {string} reason          Razón calculada a partir de los datos anteriores
 */

/**
 * Comparación de valor, sin tener en cuenta el orden de los elementos en arrays: si tienen los mismos elementos se
 * consideran equivalentes
 *
 * @param  {mixed}   valueA Valor A
 * @param  {mixed}   valueB Valor B
 *
 * @return {boolean}        Si los valores son equivalentes
 */
const isEqualValue = (valueA, valueB) => {

    // Notar que en principio es suficiente con ordenar de esta manera, ya que los arrays de objetos (campos de fichero)
    // no pueden tener los mismos valores desordenados
    const comparableA = Array.isArray(valueA) ? valueA.sort() : valueA;
    const comparableB = Array.isArray(valueB) ? valueB.sort() : valueB;

    return _.isEqual(comparableA, comparableB);
};

/**
 * Objeto de gestión de los cambios de datos en un registro/paciente
 *
 * @memberOf Record
 */
class Changes {
    /**
     * @param  {Record}        record        Instancia de un registro
     * @param  {Configuration} configuration Configuración del cuaderno
     */
    constructor(record, configuration) {
        this.record = record;
        this._changes = {};  // indexado por strings, cada uno un pathLodash completo
        // this._changes = {
        //     // lleva a cuenta los cambios en las propias listas (nuevos elementos). Para identificar cada
        //     // lista se emplea el path único al elemento, es decir, los índices dentro de cada lista se sustituyen
        //     // por el __id interno
        //     lists: {},
        //     // lleva a cuenta los cambios en los datos. indexado por ID del campo en nivel raíz, y por ID de lista
        //     // (incluyendo anidamientos) para los datos que están dentro de una lista de repetición. Para cada lista,
        //     // se emplea como índice el __id interno del item
        //     data: {},
        // };
        this._originalRecord = {};

        this.configuration = configuration;

        this._eventListeners = {};
    }
    /**
     * Resetea la información de datos en este registro, así como los cambios, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     */
    reset() {
        if (this._originalRecord) {
            Object.keys(this._originalRecord).forEach(key => {
                _.unset(this._originalRecord, key);
            });
        }

        if (this._changes) {
            Object.keys(this._changes).forEach(key => {
                _.unset(this._changes, key);
            });
        }

        this.emitEvent(Events.ON_RESET);
    }
    /**
     * Carga la información de cambios con el record actualmente cargado, inicializando la
     * información del registro original (como copia para compararlo luego con posibles cambios)
     *
     * Este método siempre resetea la información, para que sea coherente con el recorddata que tiene referenciado
     *
     * @return {boolean} TRUE si se cargó la información con éxito
     */
    load() {
        this.reset();
        _.merge(this._originalRecord, this.record.export());

        return true;
    }

    /**
     * Obtiene el objeto completo de cambios. No debería ser necesario salvo con motivo de depuración
     *
     * @return {object}   Objeto de cambios
     */
    getChanges() {
        return this._changes;
    }

    /**
     * Constantes de nombres de evento
     */
    get Events() {
        return Events;
    }

    /**
     * Registra una función para ejecutar cuando ocurra el evento especificado
     *
     * @param {Events}   eventName Nombre del evento
     * @param {function} callback  Función a ejecutar cuando se lanza el evento
     *
     * @return {string} Identificador del listener del evento para poder eliminarlo
     */
    addEventListener(eventName, callback) {
        if (!_.has(this._eventListeners, eventName)) {
            this._eventListeners[eventName] = {};
        }

        const listenerId = _.uniqueId('changesListener');

        this._eventListeners[eventName][listenerId] = callback;

        return listenerId;
    }

    /**
     * Registra una función para ejecutar en un evento solamente cuando afecte al campo especificado
     * Acaba siendo una llamada a addEventListener con un filtro previo en el callback
     *
     * @param {Events}      eventName   Nombre del evento
     * @param {Number}      fieldId     ID único del campo
     * @param {ListIndices} listIndices Índices de las listas donde se incluye el campo
     * @param {function}    callback    Función a ejecutar cuando se lanza el evento
     *
     * @return {string} Identificador del handler del evento
     */
    addFieldEventListener(eventName, fieldId, listIndices, callback) {
        // Eventos cuyo prototipo incluye fieldId, listIndices, ...
        const fieldEvents = [
            Events.ON_FIELD_CHANGE,
            Events.ON_SET_REASON,
            Events.ON_AUTOMATIC_CHANGE,
            Events.ON_UNDO_FIELD_CHANGE,
        ];
        if (!_.includes(fieldEvents, eventName)) {
            return;
        }

        return this.addEventListener(eventName, (eventFieldId, eventListIndices, ...args) => {
            if (_.toNumber(fieldId) === _.toNumber(eventFieldId) && _.isEqual(listIndices, eventListIndices)) {
                return callback(eventFieldId, eventListIndices, ...args);
            }
        });
    }

    /**
     * Elimina un listener de evento previamente registrado
     *
     * @param  {string} listenerId Identificador del listener
     */
    removeEventListener(listenerId) {
        if (listenerId) {
            _.each(this._eventListeners, eventList => {
                delete eventList[listenerId];
            });
        }
    }

    /**
     * Lanza un evento llamando a todos los listeners registrados para dicho evento
     *
     * @param  {RuleEvents} eventName Nombre del evento
     * @param  {Array<mixed>}    args      Lista variable de argumentos asociados al evento
     */
    emitEvent(eventName, ...args) {
        if (!this._eventListeners[eventName]) {
            return;
        }

        debug('event: %s %o', eventName, Object.keys(this._eventListeners[eventName]));
        _.each(this._eventListeners[eventName], callback => {
            callback(...args);
        });
    }

    /**
     * Obtiene el UID de un campo del CRF, teniendo en cuenta las listas
     *
     * @param  {Number}  fieldId    ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {Array}      UID del elemento en forma de array de IDs
     *
     * @throws {Error} Si el fieldId no se corresponde con ningún campo
     *
     * @private
     */
    _getElementUid(fieldId, listIndices) {
        const uid = this.record.getElementUID(fieldId, listIndices);
        if (uid === null) {
            let indicesStr;
            try {
                indicesStr = JSON.stringify(listIndices);
            } catch (error) {
                indicesStr = '{}';
            }
            throw new Error(`El elemento ${fieldId} no existe en el CRF, indices ${indicesStr}`);
        }

        return uid;
    }

    /**
     * Obtiene el valor del dato original en el registro cargado
     *
     * @param  {Number}  fieldId    ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {mixed}              Valor del campo en el registro original
     */
    getOriginalValue(fieldId, listIndices) {
        const elementPath = this.record.getElementPath(fieldId, listIndices);

        const originalValue = _.get(this._originalRecord, elementPath);

        // El valor original es de consulta y no se debe modificar
        return Array.isArray(originalValue) ? originalValue.slice() : originalValue;
    }

    /**
     * Inicializa un objeto de cambios a partir de un valor inicial
     *
     * @param  {mixed}       originalValue El valor inicial
     *
     * @return {FieldChange}               El objeto configurado
     */
    _getChangeBoilerplate(originalValue) {
        const boilerplate = {
            // Si el valor del campo es de array (checkboxes, files) evitamos tener la referencia del valor original
            previous: Array.isArray(originalValue) ? originalValue.slice() : originalValue,
            current: originalValue,
            manualReason: '',
            fixedReason: '',
            automaticReason: '',
        };

        Object.defineProperty(boilerplate, 'reason', {
            enumerable: true,
            get() {
                if (this.automaticReason) {
                    return this.automaticReason;
                }

                return _.compact([this.fixedReason, this.manualReason]).join(' - ');
            },
        });

        return boilerplate;
    }

    /**
     * Obtiene el detalle de cambio de un campo en concreto, a partir de su clave en el objeto global. Si no existe al
     * solicitarlo se inicializa con el valor guardado en la entidad original
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {FieldChange}             Objeto con los valores {valor original, nuevo valor, razón del cambio}
     *
     * @throws {Error} Si el fieldId no se corresponde con ningún campo
     */
    getFieldChange(fieldId, listIndices) {
        const fieldKey = this._getElementUid(fieldId, listIndices).join('.');

        if (_.isUndefined(this._changes[fieldKey])) {
            const originalValue = this.getOriginalValue(fieldId, listIndices);
            this._changes[fieldKey] = this._getChangeBoilerplate(originalValue);
        }

        return this._changes[fieldKey];
    }

    /**
     * Obtiene la clave en el objeto de cambios asociada a un ítem de lista
     *
     * @param  {Number}      listId      ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     * @param  {Number}      itemIndex   Índice que ocupa el ítem en la lista (empezando por 1)
     *
     * @return {string} Clave compuesta por los componentes separados por punto
     */
    getListItemKey(listId, listIndices, itemIndex) {
        const listItemUniquePath = this._getElementUid(listId, listIndices);
        const itemId = this.record.getListElementId(listId, itemIndex, listIndices);
        listItemUniquePath.push(itemId);

        return listItemUniquePath.join('.');
    }

    /**
     * Obtiene el detalle de cambio en los items de una lista (añadir nuevo item, eliminar item)
     *
     * @param  {Number}      listId      ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     * @param  {Number}      itemIndex   Índice que ocupa el item que se elimina o añade en la lista (empezando por 1)
     *
     * @return {object}                  Objeto con los valores {existe originalmente, existe ahora, razón del cambio}
     */
    getListItemChange(listId, listIndices, itemIndex) {
        const itemId = this.record.getListElementId(listId, itemIndex, listIndices);
        const listKey = this.getListItemKey(listId, listIndices, itemIndex);

        if (_.isUndefined(this._changes[listKey])) {
            const originalList = this.getOriginalValue(listId, listIndices);

            const originalItem = _.find(originalList, { __id: itemId }); // undefined si no se encuentra
            const itemExists = !!originalItem;

            this._changes[listKey] = this._getChangeBoilerplate(itemExists);
        }

        return this._changes[listKey];
    }

    /**
     * Determina si el item que ocupa el índice *itemIndex* en la lista *listId* existe en los datos originales
     * del registro/paciente
     *
     * @param  {Number}  listId      ID de la lista en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     * @param  {Number}  itemIndex   Índice que ocupa el item en la lista (empezando por 1)
     *
     * @return {boolean}             El item ya existía en los datos originales
     */
    isListItemInOriginalData(listId, listIndices, itemIndex) {
        const listItemChange = this.getListItemChange(listId, listIndices, itemIndex);

        return listItemChange.previous === true;
    }

    /**
     * Añade un cambio al valor de un campo
     *
     * @param  {Number}  fieldId      ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     * @param  {mixed}  currentValue Nuevo valor del campo
     *
     * @return {FieldChange}              Registro de cambio del campo
     */
    addChange(fieldId, listIndices, currentValue) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.current = currentValue;
        debug('addChange %s %o %O', fieldId, listIndices, fieldChange);

        this.emitEvent(Events.ON_FIELD_CHANGE, fieldId, listIndices, fieldChange);

        return fieldChange;
    }

    /**
     *
     *
     * @param {Number}      fieldId     Field ID
     * @param {ListIndices} listIndices List indices
     * @param {mixed}       newValue    New field value
     *
     * @returns {Boolean} TRUE if field needs a reason for a change in its value
     */
    needsManualReason(fieldId, listIndices, newValue) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);

        // Initial value is not empty
        // and new value is different than initial value
        // and reason is not valid
        return !this._isEmpty(fieldChange.previous)
            && !isEqualValue(fieldChange.previous, newValue)
            && !fieldChange.fixedReason && !fieldChange.manualReason;
    }

    /**
     * Añade un cambio automático, la diferencia con los cambios manuales es que se crea una razón del cambio y esta no
     * es editable
     *
     * @param  {Number}      fieldId      ID del campo en el CRF
     * @param  {ListIndices} listIndices  Índices de las listas a las que pertenece el campo
     * @param  {mixed}       currentValue El nuevo calor del campo
     * @param  {string}      reason       Identificador de la razón automática
     *
     * @return {FieldChange}              Registro de cambio del campo
     */
    addAutomaticChange(fieldId, listIndices, currentValue, reason) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.current = currentValue;
        fieldChange.fixedReason = '';
        fieldChange.manualReason = '';
        fieldChange.automaticReason = reason;
        debug('addAutomaticChange %s %o %O', fieldId, listIndices, fieldChange);

        this.emitEvent(Events.ON_AUTOMATIC_CHANGE, fieldId, listIndices, fieldChange);

        return fieldChange;
    }

    /**
     * Elimina el valor automático del cambio, quitando la razón automática que exista en ese momento
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {FieldChange}             Registro de cambio del campo
     */
    removeAutomaticChange(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.automaticReason = '';

        return fieldChange;
    }

    /**
     * Determina si el campo tiene asociado un cambio automático, a través de una razón automatica registrada
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}                 Si hay razón automática para el cambio de valor del campo
     */
    hasAutomaticChange(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);

        return !!fieldChange.automaticReason;
    }

    /**
     * Elimina un registro de cambio de un campo del registro global
     * Nota: Si hay razones automáticas, estas se conservan
     *
     * @param {Number}      fieldId     ID del campo en el CRF
     * @param {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     */
    removeFieldChange(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.fixedReason = '';
        fieldChange.manualReason = '';
        fieldChange.current = _.clone(fieldChange.previous);

        this.emitEvent(Events.ON_UNDO_FIELD_CHANGE, fieldId, listIndices, fieldChange);
    }

    /**
     * Establece la razón del cambio de valor de un campo. Es la parte manual editable
     *
     * @param  {Number}  fieldId      ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     * @param  {string} reason      Razón del cambio
     *
     * @return {FieldChange}             Registro de cambio del campo actualizado
     */
    setFieldReason(fieldId, listIndices, reason) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.manualReason = reason;
        fieldChange.automaticReason = '';

        this.emitEvent(Events.ON_SET_REASON, fieldId, listIndices, fieldChange.reason);

        return fieldChange;
    }

    /**
     * Establece la parte fija de la razón del cambio de valor de un campo
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     * @param  {string}      reason      Razón del cambio
     *
     * @return {FieldChange}                  Registro de cambio del campo actualizado
     */
    setFixedReason(fieldId, listIndices, reason) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        fieldChange.fixedReason = reason;
        fieldChange.automaticReason = '';

        this.emitEvent(Events.ON_SET_REASON, fieldId, listIndices, fieldChange.reason);

        return fieldChange;
    }

    /**
     * Obtiene la razón del cambio de valor de un campo
     *
     * @param  {Number}  fieldId      ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {string}             Razón anteriormente guardada
     */
    getFieldReason(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);

        return fieldChange.reason;
    }

    /**
     * Establece la razón de la eliminación de un elemento de lista
     *
     * @param  {string}      listId      ID de la lista en el CRF que contiene al ítem
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     * @param  {Number}      listIndex   Índice del ítem en la lista (comenzando en 1)
     * @param  {boolean}     newValue    Valor actual: dice si el elemento existe o no
     *
     * @return {FieldChange}             Registro de cambio del elemento actualizado
     */
    addListItemChange(listId, listIndices, listIndex, newValue) {
        const listChange = this.getListItemChange(listId, listIndices, listIndex);
        listChange.current = newValue;

        // GARU-4929 Hay que descartar todos los valores asociados al elemento que se ha borrado
        const itemKey = this.getListItemKey(listId, listIndices, listIndex);
        // Todos los elementos cuya clave comience por el elemento...
        const prefix = `${itemKey}.`;

        const fieldChanges = this.getChanges();
        Object.keys(fieldChanges).forEach(key => {
            if (key.indexOf(prefix) === 0) {
                delete fieldChanges[key];
            }
        });

        // También desaparecerán del objeto inicial de datos, ya que cualquier operación que se haga sobre el nuevo
        // elemento va a contar como un nuevo valor

        let itemObject = this._originalRecord;
        // La clave tiene una forma [lista1, itemId1, lista2, itemId2...], nos quedamos con los valores de ID de lista
        const listIds = itemKey.split('.').filter((item, index) => index % 2 === 0);
        let currentId;
        do {
            currentId = listIds.shift();

            itemObject = itemObject[currentId];
            if (itemObject) {
                // Para indicar el índice por el que recorrer en los datos, nos fijamos en la lista actual. Si es una
                // lista antecesora el índice estará en listIndices, y si es la lista final coincide con listIndex
                if (currentId === listId.toString()) {
                    itemObject = itemObject[listIndex - 1];
                } else {
                    itemObject = itemObject[listIndices[currentId] - 1];
                }
            }
        } while (itemObject !== undefined && listIds.length);

        // Si hemos encontrado la raíz del árbol de datos originales podemos eliminar todo su contenido
        if (itemObject) {
            Object.keys(itemObject).forEach(key => {
                delete itemObject[key];
            });
        }

        return listChange;
    }

    /**
     * Obtiene todas las razones que se han definido. Útil por ejemplo para obtener un dump de las razones
     * de cambio del registro/paciente y guardarlo en BD
     *
     * @return {object} Objeto indexado por ID del campo con el texto de la razón
     */
    getReasons() {
        const reasons = {};

        _.each(this._changes, (fieldChanges, fieldKey) => {
            if (!_.isEmpty(fieldChanges.reason)) {
                reasons[fieldKey] = fieldChanges.reason;
            }
        });

        return reasons;
    }

    /**
     * Obtiene el valor original (antes de los cambios) de un campo
     *
     * @param  {string} fieldId      ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     *
     * @return {mixed}              El valor previo del campo
     */
    // EntityChanges.getPreviousValue = function getPreviousValue(fieldName, stateParams) {
    //     var fieldChange = getFieldChange(fieldName, stateParams);
    //     return fieldChange.previous;
    // };

    /**
     * Comprueba un cambio de valor que requiere especificar la razón: debe haber un valor previo y ser distinto al
     * valor actual
     *
     * @param  {FieldChange} fieldChange Registro de cambio del campo
     *
     * @return {boolean}   TRUE si el campo ha cambiado respecto al original
     *
     * @private
     */
    _checkChangedValue(fieldChange) {
        // Si no había un valor previo no ha podido cambiar
        if (this._isEmpty(fieldChange.previous) || fieldChange.previous === false) {
            return false;
        }

        return !_.isEqual(fieldChange.previous, fieldChange.current);
    }

    /**
     * Comprueba un cambio de valor que requiere especificar la razón: debe haber un valor previo y ser distinto al
     * valor actual
     *
     * @param  {Number}  fieldId         ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}   TRUE si el cambio requiere especificar una razón
     */
    hasChangedValue(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);
        const changed = this._checkChangedValue(fieldChange);
        if (changed) {
            debug('Field has changed value', fieldId, listIndices, fieldChange);
        }

        return changed;
    }

    /**
     * Comprueba que la razón del cambio es válida según la configuración
     *
     * @param  {FieldChange} change Registro de cambio de valor
     *
     * @return {boolean}            Si tiene todos los datos necesarios
     *
     * @private
     */
    _checkReason(change) {
        // Si no hay una razón, se necesita la razón
        if (_.isEmpty(change.reason)) {
            return false;
        }

        // Interpretaremos que todas las razones automáticas son correctas
        if (!_.isEmpty(change.automaticReason)) {
            return true;
        }

        // Si en la configuración hay razones fijas entonces es obligatorio especificar una
        const fixedReasons = this.configuration.getAudit().getFixedReasons();
        if (fixedReasons.length === 0) {
            return true;
        }

        // En el objeto de cambio solo hay un string, y aunque no lo hubiera no nos íbamos a fiar de lo que tuviera...
        const reasonConfig = _.find(fixedReasons, { message: change.fixedReason });
        if (!reasonConfig) {
            return false;
        }

        const emptyManualReason = _.isEmpty(change.manualReason);

        // Criterio: prohibir especificar razón manual
        if (reasonConfig.manual === 'forbid') {
            return emptyManualReason;
        }

        // Criterio: requerir especificar razón manual
        if (reasonConfig.manual === 'require') {
            return !emptyManualReason;
        }

        // Criterio: se puede especificar o no razón manual
        return true;
    }

    /**
     * Valida el registro de cambios de un campo: si no hay modificaciones es válido, y si las hay es necesario que
     * exista una razón de las mismas
     * No se llama hasValidFieldChange por compatibilidad con el código que existe
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {boolean}                 TRUE si el cambio sobre un campo es válido
     */
    hasValidChange(fieldId, listIndices) {
        const fieldChange = this.getFieldChange(fieldId, listIndices);

        // Si el valor no ha cambiado está correcto
        if (!this._checkChangedValue(fieldChange)) {
            return true;
        }

        return this._checkReason(fieldChange);
    }


    /**
     * Valida el registro de cambios de un ítem de lista, como consecuencia de haber eliminado el elemento
     *
     * @param  {Number}      listId      ID de la lista que contiene el elemento
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece la lista
     * @param  {Number}      itemIndex   Índice del ítem a eliminar dentro de la lista
     *
     * @return {boolean}                 TRUE si el cambio sobre el ítem es válido
     */
    hasValidListItemChange(listId, listIndices, itemIndex) {
        const itemChange = this.getListItemChange(listId, listIndices, itemIndex);

        return this._checkReason(itemChange);
    }

    /**
     * Establece la misma razón de cambio a todos los campos susceptibles de tener una razón (los que tienen cambios)
     *
     * @param  {string} reason La razón general
     */
    setGlobalReason(reason) {
        _.each(this._changes, fieldChange => {
            if (this._checkChangedValue(fieldChange)) {
                fieldChange.reason = reason;
            }
        });
    }

    /**
     * Establece la razón de cambio a todos los campos que la requieren y no tienen una razón definida
     *
     * @param  {string} reason La razón general
     */
    fillMissingReasons(reason) {
        _.each(this._changes, fieldChange => {
            if (this._checkChangedValue(fieldChange) && _.isEmpty(fieldChange.reason)) {
                fieldChange.reason = reason;
            }
        });
    }

    /**
     * Determina si el valor que se le pasa se considera vacío
     *
     * GARU-4689 La implementación se copia de sharecrf-back-lib/packages/audit-log/diff/utils.js@isEmptyValue para
     * tomar como "vacío" el valor "false" de un campo de tipo checkbox desmarcado.
     *
     * @param  {mixed} value Valor
     *
     * @return {boolean}       TRUE si es vacío
     */
    _isEmpty(value) {
        return _.isNil(value) ||
            value === '' ||
            value === false ||
            _.isNaN(value) ||
            (_.isArray(value) && _.isEmpty(value)) ||
            (_.isPlainObject(value) && Object.keys(value).length === 0);
    }
}

module.exports = Changes;
