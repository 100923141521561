'use strict';

/**
 * Lista de eventos que lanza la clase Rules
 * @readonly
 * @enum {string}
 */
const RuleEvents = {
    // Evento global previo a la ejecución de las reglas. func(ruleDefinitions, scope)
    ON_PRE_EXECUTE: 'onPreExecute',
    // Evento global posterior a la ejecución de las reglas. func(ruleDefinitions, scope, executionResult)
    ON_POST_EXECUTE: 'onPostExecute',
    // Al añadir una query. func(sectionId, queryData, listIndices)
    ON_CREATE_QUERY: 'onCreateQuery',
    // Al deshabilitar un campo. func(fieldId, listIndices)
    ON_DISABLE_FIELD: 'onDisableField',
    // Al habilitar un campo. func(fieldId, listIndices)
    ON_ENABLE_FIELD: 'onEnableField',
    // Al ocultar un campo. func(fieldId, listIndices)
    ON_HIDE_FIELD: 'onHideField',
    // Al quitar un error en un campo. func(fieldId, messageId, listIndices)
    ON_HIDE_FIELD_ERROR: 'onHideFieldError',
    // Al ocultar un mensaje de advertencia en un campo. func(fieldId, messageId, listIndices)
    ON_HIDE_FIELD_MESSAGE: 'onHideFieldMessage',
    // Al ocultar una sección/formulario. func(sectionId, listIndices)
    ON_HIDE_SECTION: 'onHideSection',
    // Al retirar una query. func(sectionId, queryData, listIndices)
    ON_REMOVE_QUERY: 'onRemoveQuery',
    // Al establecer el valor de un campo. func(fieldId, value, listIndices)
    ON_SET_VALUE: 'onSetValue',
    // Adicional al anterior, al modificar el valor del camp. func(fieldId, value, listIndices)
    ON_SET_VALUE_CHANGED: 'onSetValueChanged',
    // Al mostrar un campo. func(fieldId, listIndices)
    ON_SHOW_FIELD: 'onShowField',
    // Al asociar un error a un campo. func(fieldId, messageId, listIndices)
    ON_SHOW_FIELD_ERROR: 'onShowFieldError',
    // Al mostrar un mensaje de advertencia en un campo. func(fieldId, messageId, listIndices)
    ON_SHOW_FIELD_MESSAGE: 'onShowFieldMessage',
    // Al mostrar una sección/formulario. func(sectionId, listIndices)
    ON_SHOW_SECTION: 'onShowSection',
    // Al ocultar hijos de un campo. func(fieldId, listIndices)
    ON_HIDE_CHILDREN: 'onHideChildren',
    // Al mostrar hijos de un campo. func(fieldId, listIndices)
    ON_SHOW_CHILDREN: 'onShowChildren',
    // Al restringir las opciones disponibles de un campo. func(fieldId, listIndices, availableOptionIds)
    ON_FILTER_OPTIONS: 'onFilterOptions',
    // Al suprimir la restricción de opciones disponibles de un campo. func(fieldId, listIndices, availableOptionIds)
    ON_UNFILTER_OPTIONS: 'onUnfilterOptions',
    // Al bloquear la posibilidad de aleatorizar el paciente. func(exportedAction, listIndices)
    ON_BLOCK_RANDOMIZATION: 'onBlockRandomization',
    // Al desbloquear la posibilidad de aleatorizar el paciente. func(exportedAction, listIndices)
    ON_UNLOCK_RANDOMIZATION: 'onUnlockRandomization',
    // Al enviar una notificación. func(exportedAction, listIndices)
    ON_SEND_NOTIFICATION: 'onSendNotification',
    // Al cancelar una notificación. func(exportedAction, listIndices)
    ON_CANCEL_NOTIFICATION: 'onCancelNotification',
    // Al activar un elemento de ePRO. func(exportedAction, listIndices)
    ON_ENABLE_EPRO_ELEMENT: 'onEnableEproElement',
    // Al desactivar un elemento de ePRO. func(exportedAction, listIndices)
    ON_DISABLE_EPRO_ELEMENT: 'onDisableEproElement',
    // Al establecer el valor de un campo. func(exportedAction, listIndices)
    ON_DO_ACTION: 'onDoAction',
};

module.exports = RuleEvents;
