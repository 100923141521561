'use strict';

const THEMES = {
    SHARECRF: 'p1',
    ELEGANT: 'p2',
    PARTY: 'p3',
    OCEAN: 'p5',
    SAND: 'p6',
    SMOKE: 'p8',
    NATURE: 'p9',
};
const CUSTOM_THEME = 'custom';

const COLORS = {
    HEADER: 'color_header',
    SUBHEADER: 'color_subheader',
    MENU: 'color_menu',
    SUBMENU: 'color_submenu',
    MENU_ACTIVE: 'color_menu_active',
    CONTENT_HEADER: 'color_content_header',
    CONTENT: 'color_content',
    HIGHLIGHT: 'color_highlight',
    TABLE_HEAD: 'color_table_head',
    TABLE_ZEBRA: 'color_table_zebra',
    FOOTER: 'color_footer',
};

const PALETTES = {
    [THEMES.SHARECRF]: {
        [COLORS.HEADER]: '#455b73',
        [COLORS.SUBHEADER]: '#8fd7d4',
        [COLORS.MENU]: '#6d87a2',
        [COLORS.SUBMENU]: '#384a5e',
        [COLORS.MENU_ACTIVE]: '#8fd7d4',
        [COLORS.CONTENT_HEADER]: '#dadde2',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#e67259',
        [COLORS.TABLE_HEAD]: '#6d87a2',
        [COLORS.TABLE_ZEBRA]: '#f0f3f8',
        [COLORS.FOOTER]: '#f0f3f8',
    },
    [THEMES.ELEGANT]: {
        [COLORS.HEADER]: '#33373a',
        [COLORS.SUBHEADER]: '#cacac2',
        [COLORS.MENU]: '#33373a',
        [COLORS.SUBMENU]: '#2a2e31',
        [COLORS.MENU_ACTIVE]: '#cacac2',
        [COLORS.CONTENT_HEADER]: '#e4e4da',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#ff2c33',
        [COLORS.TABLE_HEAD]: '#545b61',
        [COLORS.TABLE_ZEBRA]: '#f0efea',
        [COLORS.FOOTER]: '#f0efea',
    },
    [THEMES.PARTY]: {
        [COLORS.HEADER]: '#9791ad',
        [COLORS.SUBHEADER]: '#f9faea',
        [COLORS.MENU]: '#9791ad',
        [COLORS.SUBMENU]: '#7f7a91',
        [COLORS.MENU_ACTIVE]: '#f9faea',
        [COLORS.CONTENT_HEADER]: '#e8f3ed',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#e59175',
        [COLORS.TABLE_HEAD]: '#b5aecd',
        [COLORS.TABLE_ZEBRA]: '#e8f3ed',
        [COLORS.FOOTER]: '#e8f3ed',
    },
    [THEMES.OCEAN]: {
        [COLORS.HEADER]: '#172d66',
        [COLORS.SUBHEADER]: '#b6e3c6',
        [COLORS.MENU]: '#172d66',
        [COLORS.SUBMENU]: '#0e2046',
        [COLORS.MENU_ACTIVE]: '#b6e3c6',
        [COLORS.CONTENT_HEADER]: '#f7f3d0',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#ff8e52',
        [COLORS.TABLE_HEAD]: '#1e3e89',
        [COLORS.TABLE_ZEBRA]: '#fefae1',
        [COLORS.FOOTER]: '#fefae1',
    },
    [THEMES.SAND]: {
        [COLORS.HEADER]: '#847079',
        [COLORS.SUBHEADER]: '#ddbea1',
        [COLORS.MENU]: '#847079',
        [COLORS.SUBMENU]: '#5d4e55',
        [COLORS.MENU_ACTIVE]: '#ddbea1',
        [COLORS.CONTENT_HEADER]: '#eef3ec',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#cf7552',
        [COLORS.TABLE_HEAD]: '#9a808b',
        [COLORS.TABLE_ZEBRA]: '#eef3ec',
        [COLORS.FOOTER]: '#eef3ec',
    },
    [THEMES.SMOKE]: {
        [COLORS.HEADER]: '#5c5c64',
        [COLORS.SUBHEADER]: '#c3cbc0',
        [COLORS.MENU]: '#5c5c64',
        [COLORS.SUBMENU]: '#47474f',
        [COLORS.MENU_ACTIVE]: '#c3cbc0',
        [COLORS.CONTENT_HEADER]: '#e9e9e1',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#d3a598',
        [COLORS.TABLE_HEAD]: '#7b7b87',
        [COLORS.TABLE_ZEBRA]: '#f6f6ec',
        [COLORS.FOOTER]: '#f6f6ec',
    },
    [THEMES.NATURE]: {
        [COLORS.HEADER]: '#5d836a',
        [COLORS.SUBHEADER]: '#c9e3b3',
        [COLORS.MENU]: '#5d836a',
        [COLORS.SUBMENU]: '#425951',
        [COLORS.MENU_ACTIVE]: '#c9e3b3',
        [COLORS.CONTENT_HEADER]: '#f3f4e6',
        [COLORS.CONTENT]: '#ffffff',
        [COLORS.HIGHLIGHT]: '#c79153',
        [COLORS.TABLE_HEAD]: '#6c997c',
        [COLORS.TABLE_ZEBRA]: '#f3f4e6',
        [COLORS.FOOTER]: '#f3f4e6',
    },
};

const FONT_ORIGINS = {
    DEFAULT: 'default',
    GOOGLE: 'google',
};

const DEFAULT_FONT = {
    fontFamily: 'Open Sans',
    fontCss: 'https://fonts.googleapis.com/css?family=Open+Sans&subset=latin,latin-ext',
};

const GOOGLE_FONTS = {
    'anonymous-pro': {
        fontFamily: 'Anonymous Pro',
        fontCss: 'https://fonts.googleapis.com/css?family=Anonymous+Pro',
    },
    'open-sans': DEFAULT_FONT,
    lato: {
        fontFamily: 'Lato',
        fontCss: 'https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext',
    },
    'pt-sans': {
        fontFamily: 'PT Sans',
        fontCss: 'https://fonts.googleapis.com/css?family=PT+Sans&subset=latin,latin-ext',
    },
    roboto: {
        fontFamily: 'Roboto',
        fontCss: 'https://fonts.googleapis.com/css?family=Roboto&subset=latin,latin-ext',
    },
};

/**
 * Objeto de personalización de la aplicación: fuentes, colores, textos propios de uso en tiempo de generación de la
 * aplicación
 */
class Customization {
    /**
     * Obtiene el listado de paletas de colores con la definición de cada una
     *
     * @return {Object} Diccionario indexado por el ID de tema. Los valores son diccionarios con los colores de cada uno
     */
    static getPalettes() {
        return JSON.parse(JSON.stringify(PALETTES));
    }

    /**
     * Obtiene el listado de orígenes de fuentes permitidos
     *
     * @return {Object} Listado de origenes
     */
    static getFontOrigins() {
        return JSON.parse(JSON.stringify(FONT_ORIGINS));
    }

    /**
     * Obtiene el listado de las fuentes de Google disponibles
     *
     * @return {Object} Diccionario indexado por el ID de la fuente con la definición de cada una
     */
    static getGoogleFonts() {
        return JSON.parse(JSON.stringify(GOOGLE_FONTS));
    }

    /**
     * Obtiene la descripción de una fuente de Google
     *
     * @param {String} fontId Identificador de la fuente
     *
     * @return {Object|null} Propiedades de la fuente
     */
    static getGoogleFont(fontId) {
        return GOOGLE_FONTS[fontId] ? JSON.parse(JSON.stringify(GOOGLE_FONTS[fontId])) : null;
    }

    /**
     * Obtiene la configuración de la fuente usada
     *
     * @param  {object} fontDefinition Definición de la fuente
     *
     * @return {objec}                 Objeto de configuración de la fuente según las constantes definidas
     */
    static getFont(fontDefinition) {
        const fontOrigin = fontDefinition.fontOrigin || FONT_ORIGINS.DEFAULT;
        const fontId = fontDefinition.font || null;

        let font;
        if (fontOrigin === FONT_ORIGINS.GOOGLE) {
            font = fontId && GOOGLE_FONTS[fontId] ? GOOGLE_FONTS[fontId] : DEFAULT_FONT;
        } else {
            font = DEFAULT_FONT;
        }

        return font;
    }

    /**
     * Constructor de la clase: proporciona la definición de la personalización para estar disponible al consultar
     *
     * @param  {object} definition Definición de la personalización tal y como se guarda en la base de datos
     */
    constructor(definition) {
        const theme = definition.palette || THEMES.SHARECRF;
        this.palette = PALETTES[theme] ? theme : CUSTOM_THEME;
        this.colors = definition.colors && this.palette == CUSTOM_THEME ? definition.colors : {};

        const defaultOrigin = FONT_ORIGINS.DEFAULT;
        const googleOrigin = FONT_ORIGINS.GOOGLE;

        this.font = definition.fontOrigin == googleOrigin && GOOGLE_FONTS[definition.font] ? definition.font : null;
        this.fontOrigin = this.font && definition.fontOrigin == googleOrigin ? googleOrigin : defaultOrigin;

        this.headerText = definition.headerText || '';
        this.headerImage = definition.headerImage || '';

        this.frontpage = definition.frontpage || '';
        this.frontpageOnEnter = !!definition.frontpageOnEnter;

        this.footer = definition.footer || '';
    }

    /**
     * Obtiene el objeto resuelto
     *
     * @return {object} Objeto con la definición
     */
    get() {
        return {
            palette: PALETTES[this.palette] ? this.palette : CUSTOM_THEME,
            colors: PALETTES[this.palette] ? {} : this.colors,

            fontOrigin: this.fontOrigin,
            font: this.fontOrigin === FONT_ORIGINS.GOOGLE && GOOGLE_FONTS[this.font] ? this.font : null,

            headerText: this.headerText,
            headerImage: this.headerImage,

            frontpage: this.frontpage || '',
            frontpageOnEnter: this.frontpageOnEnter,

            footer: this.footer || '',
        };
    }

    /**
     * Obtiene la lista de colores definidos por la paleta o por el usuario editor
     * @return {object} Pares clave-valor de definición de colores
     * @example
     * // returns {
     *     color_header': '#5d836a',
     *     color_subheader: '#c9e3b3',
     *     color_menu: '#5d836a',
     *     color_submenu: '#425951',
     *     color_menu_active: '#c9e3b3',
     *     color_content_header: '#f3f4e6',
     *     color_content: '#ffffff',
     *     color_highlight: '#c79153',
     *     color_table_head: '#6c997c',
     *     color_table_zebra: '#f3f4e6',
     *     color_footer: '#f3f4e6',
     * }
     * let customization = new Customization({colors: {
     *     color_header': '#5d836a',
     *     color_subheader: '#c9e3b3',
     *     color_menu: '#5d836a',
     *     color_submenu: '#425951',
     *     color_menu_active: '#c9e3b3',
     *     color_content_header: '#f3f4e6',
     *     color_content: '#ffffff',
     *     color_highlight: '#c79153',
     *     color_table_head: '#6c997c',
     *     color_table_zebra: '#f3f4e6',
     *     color_footer: '#f3f4e6',
     * }});
     * customization.getColors();
     */
    getColors() {
        return this.colors;
    }

    /**
     * Obtiene el origen de la fuente personalizada, puede ser uno de los valores proporcionados u otra fuente de Google
     * @return {string|null} Valor configurado: "default" o "google"
     * @example
     * // returns 'google'
     * let customization = new Customization({fontOrigin: 'google'});
     * customization.getFontOrigin();
     */
    getFontOrigin() {
        return this.fontOrigin;
    }

    /**
     * Obtiene el identificador de la paleta de colores usada por la aplicación
     * @return {string} Identificador: alguno entre "p1" (por defecto), "p2", "p3", "p5", "p6", "p8", "p9" o 'custom"
     * @example
     * // returns 'p5';
     * let customization = new Customization(palette: 'p5');
     * customization.getPalette();
     */
    getPalette() {
        return this.palette;
    }

    /**
     * Get header image path
     *
     * @return {String} Image path
     */
    getHeaderImagePath() {
        return this.headerImage;
    }
    /**
     * Get header text
     *
     * @return {String} Text
     */
    getHeaderText() {
        return this.headerText;
    }
}

module.exports = Customization;
