'use strict';

/**
 * @namespace Structure
 */
const CRF = require('./CRF');
const Field = require('./Field');
const Form = require('./Form');
const FieldOptions = require('./FieldOptions');
const Rules = require('./Rules/Rules');
const ConditionsList = require('./Rules/Conditions/List');
const Action = require('./Rules/Actions/Action');

module.exports = {
    CRF: CRF,
    FieldOptions: FieldOptions,
    Field: Field,
    Form: Form,
    Rules: Rules,
    ConditionsList: ConditionsList,
    Action,
};
