'use strict';

const _ = require('lodash');

const SideFactory = require('./SideFactory');

const CrfElementSide = require('./Side/CrfElement');

/**
 * Posibles valores de comparación entre los lados de la condición
 *
 * @readonly
 *
 * @enum {string}
 */
const Comparison = {
    CONTAINS: 'contains',
    EMPTY: 'empty',
    ENDS_WITH: 'endsWith',
    EQUAL: 'eq',
    GREATER_TAH_OR_EQUAL: 'gte',
    GREATER_THAN: 'gt',
    LESS_THAN: 'lt',
    LESS_THAN_OR_EQUAL: 'lte',
    NOT_CONTAINS: 'notContains',
    NOT_EMPTY: 'notEmpty',
    NOT_EQUAL: 'neq',
    STARTS_WITH: 'startsWith',
    IS_COMPLETE_DATE: 'isCompleteDate',
    IS_PARTIAL_DATE: 'isPartialDate',
    UNKNOWN: 'unknown',
    NOT_UNKNOWN: 'notUnknown',
};

/**
 * Objeto de definición de la condición
 * @typedef {object} ConditionDefinition
 *
 * @property {ConditionSideDefinition} lhs        Definición del lado izquierdo
 * @property {Comparison}              comparison Identificador de la comparación entre los lados
 * @property {ConditionSideDefinition} rhs        Definición del lado derecho
 */

/**
 * Objeto de exportación para aplicar las reglas
 * @typedef {ConditionDefinition} ExportedCondition
 */

/**
 * Clase de gestión de una condición individual en una regla
 *
 * @property {ConditionDefinition}      definition Definición de la condición almacenada en la base de datos
 * @property {object}                   sides      Instancias de los lados de la condición
 * @property {Structure.ConditionSide}  sides.lhs  Lado izquierdo de la condición
 * @property {Structure.ConditionSide}  sides.rhs  Lado derecho de la condición
 * @property {Structure.ConditionsList} list       Lista de condiciones a la que la condición pertenece
 *
 * @memberOf Structure
 */
class Condition {
    /**
     * Constructor de la clase, instancia los lados definidos en la condición
     *
     * @param  {ConditionDefinition} definition Objeto de definición de la condición
     */
    constructor(definition) {
        this.definition = definition;

        this.sides = {};

        ['lhs', 'rhs'].forEach(sideKey => {
            if (!_.isNil(definition[sideKey])) {
                this.sides[sideKey] = SideFactory.create(definition[sideKey], this);
            }
        });

        // Lista de condiciones que contiene esta condición
        this.list = null;
    }

    /**
     * Lista de comparaciones disponibles
     */
    static get Comparison() {
        return Comparison;
    }

    /**
     * Instancia un lado de la condición
     *
     * @param  {SideDefinition} definition Objeto de definición
     *
     * @return {Structure.Side}            La instancia creada
     */
    instantiateSide(definition) {
        return SideFactory.create(definition, this);
    }

    /**
     * Obtiene la representación del objeto en la base de datos
     *
     * @return {ConditionDefinition} El objeto de definición
     */
    get() {
        return this.definition;
    }

    /**
     * Establece la lista que contiene la condición
     *
     * @param {Structure.ConditionsList} list Lista de condiciones
     */
    setList(list) {
        this.list = list;
    }

    /**
     * Obtiene la lista que contiene esta condición
     *
     * @return {Structure.ConditionsList} Lista de condiciones
     */
    getList() {
        return this.list;
    }

    /**
     * Obtiene los lados de la condición
     *
     * @return {object} Instancias de los lados bajo las claves "lhs" y "rhs"
     */
    getSides() {
        return this.sides;
    }

    /**
     * Obtiene el operando izquierdo de la condición
     *
     * @return {Structure.ConditionSide} El objeto de expresión izquierdo
     */
    getLhs() {
        return this.sides.lhs;
    }

    /**
     * Determina si se requiere un lado derecho en la condición, según el tipo de comparación
     *
     * @return {boolean} Si hace falta un lado derecho
     */
    needsRhs() {
        // Si no existiera comparador tampoco se obliga a tener un lado derecho
        return !_.includes([
            Comparison.EMPTY,
            Comparison.NOT_EMPTY,
            Comparison.IS_COMPLETE_DATE,
            Comparison.IS_PARTIAL_DATE,
            Comparison.UNKNOWN,
            Comparison.NOT_UNKNOWN,
        ], this.getComparison());
    }

    /**
     * Obtiene el operando derecho de la condición
     *
     * @return {Structure.ConditionSide} El objeto de expresión derecho
     */
    getRhs() {
        return _.get(this.sides, 'rhs', null);
    }

    /**
     * Obtiene la referencia al objeto global de CRD
     *
     * @return {Structure.CRF} La instancia del CRD
     */
    getCRF() {
        return this.list.getCRF();
    }

    /**
     * Obtiene el objeto de regla que contiene esta condición
     *
     * @return {Structure.Rule} La instancia de Rule
     */
    getRule() {
        return this.list.getRule();
    }

    /**
     * Obtiene el identificador del comparador
     *
     * @return {Comparison} Identificador del comparador
     */
    getComparison() {
        return _.get(this.definition, 'comparison', null);
    }

    /**
     * Obtiene los campos que intervienen en la condición
     *
     * @return {Structure.Field[]} Lista de campos
     */
    getFields() {
        return _.compact(_.map(this.sides, side => {
            if (side.isField()) {
                return side.getTarget();
            }
        }));
    }

    /**
     * Obtiene la definición de las listas que intervienen en la condición
     *
     * @return {Structure.List[]} Lista de listas
     */
    getLists() {
        return _.compact(_.map(this.sides, side => {
            if (side.isList()) {
                return side.getTarget();
            }
        }));
    }

    /**
     * Añade una expresión al lado derecho de la condición
     *
     * @return {Structure.ValueConditionSide} La instancia creada
     */
    addRhs() {
        const defaultSide = {
            type: 'value',
            value: null,
        };

        this.sides.rhs = SideFactory.create(defaultSide, this);

        return this.getRhs();
    }

    /**
     * Determina si la condición incluye al elemento del CRF seleccionado
     *
     * @param  {Number}  elementId ID único del elemento
     *
     * @return {boolean}           Si el elemento se incluye en la condición
     */
    hasElement(elementId) {
        return _.some(this.sides, side => {
            return side && (side.isField() || side.isList()) && side.getId() === _.toNumber(elementId);
        });
    }

    /**
     * Determina si la condición incluye alguno de los elementos del CRF seleccionados
     *
     * @param  {integer[]} elementIds IDs únicos de los elementos
     *
     * @return {boolean}              Si algún elemento se incluye en la condición
     */
    hasAnyElement(elementIds) {
        const idsAsNumber = _.map(elementIds, _.toNumber);

        return _.some(this.sides, side => {
            if (!(side instanceof CrfElementSide)) {
                return;
            }

            return idsAsNumber.includes(side.getId());
        });
    }

    /**
     * Determina si los lados que componen la condición comparten el mismo tipo de dato, lo que hace que sean
     * comparables entre sí.
     *
     * @return {boolean} Si todos los lados presentes tienen el mismo tipo de dato. Esto implica que si hay solamente un
     *                   lado izquierdo, o incluso ninguno, el resultado es verdadero.
     */
    matchesSideDataTypes() {
        return _(this.sides).map(_.method('getDataType')).uniqBy().value().length <= 1;
    }

    /**
     * Obtiene la ruta relativa dentro de la estructura de reglas teniendo en cuenta listas antecesoras
     *
     * @return {integer[]} Array de posiciones relativas
     */
    getPath() {
        const positionInList = _.findIndex(this.list.getConditions(), this);

        return (this.list.getPath() || []).concat(positionInList);
    }

    /**
     * Determina si en la condición se compara con el valor de aleatorización del registro
     *
     * @return {boolean} Si en el lado izquierdo se usa un valor de aleatorización
     */
    hasRandomization() {
        const lhs = this.getLhs();

        return lhs && lhs.isRecordField() && lhs.getField() === 'randomization';
    }
}

module.exports = Condition;
