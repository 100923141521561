'use strict';

const {
    castArray,
    round,
} = require('lodash');

const babylon = require('babylon');

const AdvancedMath = require('../AdvancedMath');
const debug = require('debug')('sharecrf:rules:formula');

const Formula = require('./Formula');
const Functions = Formula.Functions;
const Operators = Formula.Operators;
const UnaryOperators = Operators.UNARY;
const BinaryOperators = Operators.BINARY;
const ListIdentifiers = Formula.ListIdentifiers;

const { UNKNOWN_VALUE } = require('../Record/constants');

/**
 * Clase encargada de resolver el valor de una fórmula a partir de la definición
 *
 * @property {Record.Data} record           Objeto de referencia para resolver campos y listas
 * @property {ListIndices} executionContext El contexto de ejecución de listas actual
 *
 * @memberOf Formula
 */
class FormulaExecutor {
    /**
     * Constructor de la clase: guarda la referencia al objeto de Record
     *
     * @param {Record.Data} record  El objeto de record
     * @param {ListIndices} context El contexto de ejecución (índices de las listas actuales) de la regla
     */
    constructor(record, context) {
        this.record = record;
        this.executionContext = context;
    }

    /**
     * Ejecución de una fórmula: se interpreta un objeto descriptivo con formato conocido para resolver el valor que
     * devuelve según el estado del Record asociado
     *
     * 2018-12-27: GARU-3820 Si la fórmula está vacía se devuelve un NULL. El valor es independiente del tipo de campo,
     * de manera similar a como se hace en Record/Rules.js@undoSetFormula
     *
     * @param  {string} definition Definición de la fórmula
     *
     * @return {mixed}             Valor resuelto
     */
    execute(definition) {
        let value = null;

        if (definition) {
            try {
                const node = babylon.parseExpression(definition);
                value = this.executeNode(node);
            } catch (error) {
                // Para protegernos de posibles errores, cuando algo vaya mal devolvemos el string genérico
                // La próxima implementación será transformar el error a un tipo específico según GARU-4108
                debug(`Error en la ejecución de la fórmula "${definition}": ${error.message}`);
                value = 'N/A';
            }
        }

        if (typeof value === 'number' && isNaN(value)) {
            // GARU-3947 Si el resultado de una fórmula es NaN se anota como "N/A" para asegurar el error en el campo
            // Notar que es una solución temporal hasta que haya una implementación plena de los errores asociados
            value = 'N/A';
        }

        debug(`Fórmula ejecutada: ${definition} Resultado: ${value}`);

        return value;
    }

    /**
     * Resuelve el valor de un nodo de la fórmula
     *
     * @param  {Node}  node Nodo de Babylon
     *
     * @return {mixed}      Valor resuelto
     *
     * @private
     */
    executeNode(node) {
        switch (node.type) {
            case 'BinaryExpression':
                return this.executeBinaryExpression(node);
            case 'CallExpression':
                return this.executeCallExpression(node);
            case 'NumericLiteral':
            case 'StringLiteral':
                return node.value;
            case 'ObjectExpression':
                return this.executeObjectExpression(node);
            case 'UnaryExpression':
                return this.executeUnaryExpression(node);
        }

        throw new Error(`Tipo de nodo de fórmula desconocido: ${node.type}`);
    }

    /**
     * Conversión segura a número, comprobando previamente que el valor original es válido para convertir. Esto excluye
     * nulos y vacíos que se interpretan erróneamente como el valor 0
     *
     * @param  {mixed} value Valor que se va a transformar
     *
     * @return {number}      Valor numérico
     *
     * @private
     */
    castNumber(value) {
        if (typeof value === 'number') {
            return value;
        }

        if (typeof value === 'string' && value !== '') {
            return Number(value);
        }

        // Cualquier cosa que no sea número ni cadena representando a un número es NaN
        return NaN;
    }

    /**
     * Resuelve una expresión binaria, compuesta por un operador y dos operandos que a su vez son nodos
     *
     * @param  {Node}  node Nodo de tipo BinaryExpression
     *
     * @return {mixed}      Valor resuelto
     *
     * @private
     */
    executeBinaryExpression(node) {
        // Todos los operadores definidos en BinaryOperators actúan sobre números
        const leftValue = this.castNumber(this.executeNode(node.left));
        const rightValue = this.castNumber(this.executeNode(node.right));

        let result;

        // Operadores conocidos
        if (node.operator === BinaryOperators.ADD) {
            result = leftValue + rightValue;
        } else if (node.operator === BinaryOperators.SUBTRACT) {
            result = leftValue - rightValue;
        } else if (node.operator === BinaryOperators.MULTIPLY) {
            result = leftValue * rightValue;
        } else if (node.operator === BinaryOperators.DIVIDE) {
            result = leftValue / rightValue;
        } else if (node.operator === BinaryOperators.MODULO) {
            result = leftValue % rightValue;
            // TODO: Probar el Infinity
        } else {
            // Operador desconocido
            throw new Error(`Operador desconocido: ${node.operator}`);
        }

        debug('binary expression: %s %s %s => %s', leftValue, node.operator, rightValue, result);

        return result;
    }

    /**
     * Resuelve una expresión unaria, compuesta por un operador y un nodo operando
     *
     * @param  {Node}  node Nodo de tipo UnaryExpression
     *
     * @return {mixed}      Valor resuelto
     *
     * @private
     */
    executeUnaryExpression(node) {
        // Los operadores definidos en UnaryOperators actúan sobre números
        const value = this.castNumber(this.executeNode(node.argument));

        let result;

        // Operadores conocidos
        if (node.operator === UnaryOperators.PLUS) {
            result = value;
        } else if (node.operator === UnaryOperators.MINUS) {
            result = 0 - value;
        } else {
            // Operador desconocido
            throw new Error(`Operador desconocido: ${node.operator}`);
        }

        debug('unary expression: %s%s => %s', node.operator, value, result);

        return result;
    }

    /**
     * Interpreta una llamada a una función, compuesta por el identificador de la función y una lista de n nodos como
     * argumentos
     *
     * @param  {Node}  node Nodo de tipo CallExpression
     *
     * @return {mixed}      Valor resuelto
     *
     * @private
     */
    executeCallExpression(node) {
        const funcName = node.callee.name;
        const validExpression = Functions.get(funcName) !== null;
        if (!validExpression) {
            throw new Error(`Función desconocida: ${funcName}`);
        }

        const args = this.parseFunctionArguments(funcName, node.arguments);

        // Funciones de Lodash
        if (funcName === Functions.ROUND) {
            return round(...args);
        }

        // Funciones de Math
        if ([Functions.ABSOLUTE_VALUE, Functions.ARCCOSINE, Functions.ARCSINE, Functions.ARCTANGENT, Functions.CEILING,
            Functions.COSINE, Functions.FLOOR, Functions.HYPERBOLIC_ARCCOSINE, Functions.HYPERBOLIC_ARCSINE,
            Functions.HYPERBOLIC_ARCTANGENT, Functions.HYPERBOLIC_COSINE, Functions.HYPERBOLIC_SINE,
            Functions.HYPERBOLIC_TANGENT, Functions.MAXIMUM, Functions.MINIMUM, Functions.POWER, Functions.SINE,
            Functions.SQUARE_ROOT, Functions.TANGENT,
        ].indexOf(funcName) > -1) {
            return Math[funcName](...args);
        }
        if (funcName === Functions.NATURAL_LOGARITHM) {
            return Math.log(...args);
        }
        if (funcName === Functions.DECIMAL_LOGARITHM) {
            const value = args[0];

            // Nota: Math.log10 no tiene soporte para IE, lo mete el polyfill de Webpack
            return Math.log10(value);
        }

        // Funciones de texto
        if (funcName === Functions.CONCATENATE) {
            return args.reduce((result, chunk) => {
                if (!chunk && chunk !== 0) {
                    return result;
                }

                return result + chunk.toString();
            }, '');
        }

        // Funciones de ShareCRF
        switch (funcName) {
            case Functions.DATE_DIFF_DAYS:
                return AdvancedMath.dateDiffDays(...args);
            case Functions.FIELD_VALUE:
                return this.getFieldValue(...args);
            case Functions.TIME_DIFF_HOURS:
                return AdvancedMath.timeDiffHours(...args);
            case Functions.TIME_DIFF_MINUTES:
                return AdvancedMath.timeDiffMinutes(...args);
            case Functions.DATE_ADD_DAYS:
                return AdvancedMath.dateAddDays(...args);
            case Functions.DATE_SUB_DAYS:
                return AdvancedMath.dateSubDays(...args);
            case Functions.DATE_AGE:
                return AdvancedMath.dateAge(...args);
            case Functions.DATETIME_DIFF_DAYS:
                return AdvancedMath.datetimeDiffDays(...args);
            case Functions.DATETIME_DIFF_HOURS:
                return AdvancedMath.datetimeDiffHours(...args);
            case Functions.DATETIME_DIFF_MINUTES:
                return AdvancedMath.datetimeDiffMinutes(...args);
            case Functions.DATETIME_DIFF_SECONDS:
                return AdvancedMath.datetimeDiffSeconds(...args);
            case Functions.DATETIME:
                return AdvancedMath.datetime(...args);
            case Functions.GET_DATE:
                return AdvancedMath.getDate(...args);
            case Functions.GET_TIME:
                return AdvancedMath.getTime(...args);
            case Functions.COUNT:
                // Siempre es una lista, si se aplica sobre una primitiva el resultado de count() será 1
                return castArray(args[0]).length;
            case Functions.SELECTED:
                // Si el valor está en el array, se obtiene "valor cuando incluye" (por defecto 1)
                // En caso contrario se obtiene "valor cuando no incluye" (por defecto 0)
                return castArray(args[0]).indexOf(args[1]) > -1 ? args[2] || 1 : args[3] || 0;
        }

        debug('Función de fórmula no implementada: %s', funcName);
    }

    /**
     * Obtiene el valor de los argumentos de entrada a una función según la definición y el estado actual del registro
     *
     * @param  {string} functionName Nombre de la función
     * @param  {Array}  functionArgs Lista de definiciones de argumentos
     *
     * @return {Array}               Lista de valores de argumentos
     *
     * @private
     */
    parseFunctionArguments(functionName, functionArgs = []) {
        // Una excepción: si la función es para obtener el valor de un campo se normalizan primero los índices de las
        // listas a las que puede pertenecer, según el contexto actual
        if (functionName === Functions.FIELD_VALUE) {
            return [functionArgs[0].value, this.parseListIndices(functionArgs[1])];
        }

        return functionArgs.map(argumentNode => {
            return this.executeNode(argumentNode);
        });
    }

    /**
     * Normaliza el valor de un nodo que representa índices de lista
     *
     * @param  {object} listIndicesNode Nodo de tipo ObjectExpression
     *
     * @return {ListIndices}            Índices normalizados
     */
    parseListIndices(listIndicesNode) {
        const listIndices = {};

        if (!listIndicesNode) {
            return listIndices;
        }

        listIndicesNode.properties.forEach(propertyNode => {
            const key = this.executeNode(propertyNode.key);

            const valueNode = propertyNode.value;
            let value;

            if (valueNode.type === 'Identifier') {
                // ¡es una cadena conocida!
                value = valueNode.name;
            } else {
                // cualquier otro caso...
                value = this.executeNode(valueNode);
            }

            listIndices[key] = value;
        });

        return listIndices;
    }

    /**
     * Obtiene el valor actual de un campo
     *
     * @param  {Number}      fieldId     ID del campo
     * @param  {ListIndices} listIndices Índices de las listas, con las cadenas por resolver (current, next...)
     *
     * @return {mixed}                   El valor del campo
     */
    getFieldValue(fieldId, listIndices = {}) {
        const resolvedIndices = {};

        for (const listId in listIndices) {
            const indexValue = this.parseListIndex(listId, listIndices[listId], Object.assign({}, resolvedIndices));

            // Algún índice no se ha podido resolver => el campo no existe en los datos
            // Aquí el valor 0 se evalúa a falso ya que listIndices comienza por 1 siempre
            if (!indexValue) {
                return undefined;
            }

            resolvedIndices[listId] = indexValue;
        }

        debug('Índices normalizados. Contexto %o, definición %o, resultado %o',
            this.executionContext,
            listIndices,
            resolvedIndices
        );

        const value = this.record.getFieldValue(fieldId, resolvedIndices);
        if (value === UNKNOWN_VALUE) {
            // GARU-4864 Si el campo no es conocido el valor en la fórmula se toma como vacío
            return undefined;
        }

        return value;
    }

    /**
     * Obtiene el valor numérico normalizado de índice de lista
     *
     * @param  {Number}         listId        ID de la lista
     * @param  {integer|string} indexValue    Valor del índice: número o identificador
     * @param  {ListIndices}    parentIndices Índices de los padres de la lista
     *
     * @return {Number}                       Valor numérico de índice
     */
    parseListIndex(listId, indexValue, parentIndices) {
        const listLength = this.record.getListLength(listId, parentIndices);
        const currentIndex = this.executionContext[listId];

        if (indexValue === ListIdentifiers.FIRST) {
            return listLength > 0 ? 1 : null;
        }

        if (indexValue === ListIdentifiers.PREVIOUS) {
            return currentIndex > 1 ? currentIndex - 1 : null;
        }

        if (indexValue === ListIdentifiers.CURRENT) {
            return currentIndex !== undefined ? currentIndex : null;
        }

        if (indexValue === ListIdentifiers.NEXT) {
            return currentIndex < listLength ? currentIndex + 1 : null;
        }

        if (indexValue === ListIdentifiers.LAST) {
            return listLength > 0 ? listLength : null;
        }

        // Y si no es ninguno de esos valores será un número

        return indexValue;
    }

    /**
     * Resuelve una expresión representada como un objeto de expresiones
     *
     * @param  {Node}  node Nodo de tipo ObjectExpression
     *
     * @return {mixed}      Valor resuelto
     *
     * @private
     */
    executeObjectExpression(node) {
        const object = {};

        node.properties.forEach(property => {
            const key = this.executeNode(property.key);
            const value = this.executeNode(property.value);
            object[key] = value;
        });

        return object;
    }
}

module.exports = FormulaExecutor;
