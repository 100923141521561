'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core');

const ProfilesSelector = require('./ProfilesSelector');

/**
 * Tipos básicos de contenedor
 *
 * @readonly
 *
 * @enum {string}
 */
const containerType = {
    FIELD: 'field',
    FORM: 'form',
    LIST: 'list',
    SECTION: 'section',
};

/**
 * Clase general de objeto contenedor. Se caracteriza porque tiene una lista de elementos hijos que a su vez son
 * contenedores.
 *
 * No se debería instanciar nunca directamente, sino que hay que utilizar alguna de las clases que extienden Container
 *
 * @property {object}           definition      Objeto de definición del contenedor
 * @property {string|integer}   definition.id   ID único del objeto
 * @property {string}           definition.name Identificador visible de usuario
 * @property {Container}        parent          El contenedor que es padre directo del objeto
 * @property {Array<Container>} children        Lista de elementos hijos
 * @property {object}           elementsById    Caché de elementos obtenidos indexados por su ID
 * @property {object}           elementsByName  Caché de elementos obtenidos indexados por su nombre de exportación
 * @property {ProfilesSelector} accessProfiles  Perfiles para los que el elemento es accesible
 * @property {ProfilesSelector} editionProfiles Perfiles para los que el elemento es editable
 *
 * @abstract
 *
 * @memberOf Structure
 */
class Container {
    /**
     * Constructor de la clase
     * @param  {object} definition Definición específica del contenedor
     */
    constructor(definition = {}) {
        this.definition = this._cleanDefinition(definition);

        this.parent = null;
        this.children = [];

        this.accessProfiles = ProfilesSelector.instance(_.get(this.definition, 'accessProfiles', {}));
        this.editionProfiles = ProfilesSelector.instance(_.get(this.definition, 'editionProfiles', {}));

        // Tablas de lookup para agilizar las búsquedas de elementos
        this.elementsById = {};
        this.elementsByName = {};
    }

    /**
     * Constantes de tipo de contenedor
     */
    static get ContainerTypes() {
        return containerType;
    }

    /**
     * Obtiene el objeto de definición del contenedor con propiedades editables
     *
     * @return {object} Objeto con la definición
     */
    get() {
        return this.definition;
    }

    /**
     * Obtiene los nombres de las posibles propiedades de definición
     *
     * @return {string[]} Lista de propiedades
     *
     * @protected
     */
    _getAvailableProperties() {
        return ['id', 'type', 'name', 'label', 'lang'];
    }

    /**
     * Limpia un objeto de definición suprimiendo las propiedades no admisibles o desconocidas
     *
     * @param  {object} definition Definición del contenedor
     *
     * @return {object}            Objeto transformado
     *
     * @private
     */
    _cleanDefinition(definition) {
        const unknownProperties = _.difference(Object.keys(definition), this._getAvailableProperties());

        if (unknownProperties.length > 0) {
            debug('Unknown definition properties %s %o', this.constructor.name, unknownProperties);
            unknownProperties.forEach(propertyName => {
                _.unset(definition, propertyName);
            });
        }

        return definition;
    }

    /**
     * Determina si el contenedor es el raíz del CRF
     *
     * @return {boolean} Falso por defecto, a menos que se sobreescriba en una clase extendida
     */
    isRoot() {
        return false;
    }

    /**
     * Determina si el contenedor es de tipo formulario
     *
     * @return {boolean} Falso por defecto, a menos que se sobreescriba en una clase extendida
     */
    isForm() {
        return false;
    }

    /**
     * Determina si el contenedor es de tipo sección
     *
     * @return {boolean}  TRUE si este contenedor es una sección
     */
    isSection() {
        return false;
    }

    /**
     * Determina si el contenedor es de tipo lista
     *
     * @return {boolean}  TRUE si este contenedor es una lista
     */
    isList() {
        return false;
    }

    /**
     * Determina si el contenedor es de tipo campo
     *
     * @return {boolean}  TRUE si este contenedor es un campo
     */
    isField() {
        return false;
    }

    /**
     * Determina si el contenedor es de tipo visita
     *
     * @return {boolean}  TRUE si este contenedor es una visita
     */
    isScheduledVisit() {
        return typeof this.definition.scheduledVisit !== 'undefined' && !!this.definition.scheduledVisit.enabled;
    }

    /**
     * Obtiene una representación en texto del tipo de contenedor
     *
     * @return {containerType} El identificador de tipo (o null)
     */
    getType() {
        if (this.isForm()) {
            return containerType.FORM;
        }
        if (this.isSection()) {
            return containerType.SECTION;
        }
        if (this.isList()) {
            return containerType.LIST;
        }
        if (this.isField()) {
            return containerType.FIELD;
        }

        return null;
    }

    /**
     * Obtiene el ID del contenedor
     *
     * @return {string|integer} El valor original del ID de la definición, o NULL si no se proporcionó
     */
    getId() {
        return this.definition && this.definition.id || null;
    }

    /**
     * Obtiene el nombre definido por el usuario del contenedor
     *
     * @return {string} El valor original del nombre de la definición, o NULL si no se proporcionó
     */
    getName() {
        return  this.definition && this.definition.name || null;
    }

    /**
     * Obtiene el texto de la etiqueta definido por el usuario
     *
     * @return {string} El valor original de la etiqueta, o NULL si no se encuentra
     */
    getLabel() {
        return  this.definition && this.definition.label || null;
    }

    /**
     * Establece el contenedor padre del actual
     *
     * @param {Container} parent El contenedor que actúa como padre
     *
     * @throws {Error} Si el padre no es un objeto Container
     */
    setParent(parent) {
        if (!(parent instanceof Container)) {
            throw new Error('El elemento padre no es un contenedor válido');
        }
        this.parent = parent;
    }

    /**
     * Obtiene el contenedor padre
     *
     * @return {Container} El objeto Container padre, o NULL si no tiene
     */
    getParent() {
        return this.parent;
    }

    /**
     * Establece la lista de hijos del contenedor
     *
     * @param {Array<Container>} children Lista de objetos Container que actúan como hijos
     *
     * @throws {Error} Si alguno de los hijos no es un objeto Container
     */
    setChildren(children) {
        this.children = children;

        _.each(this.children, childNode => {
            if (!(childNode instanceof Container)) {
                throw new Error('El elemento hijo no es un contenedor válido');
            }
            childNode.setParent(this);
        });
    }

    /**
     * Determina si el contenedor tiene elementos descendiendo directamente
     *
     * @return {boolean} Si tiene al menos un elemento hijo
     */
    hasChildren() {
        return this.getChildren().length > 0;
    }

    /**
     * Obtiene la lista de hijos del contenedor
     *
     * @return {Array<Container>} Lista de objetos Container
     */
    getChildren() {
        return this.children;
    }

    /**
     * Determina si existe en la estructura del contenedor el elemento con el ID especificado
     *
     * @param  {Number}  elementId ID del elemento a buscar. Si es un string, lo convertirá a número.
     *
     * @return {boolean}                TRUE si el elemento existe en el CRF
     */
    hasElement(elementId) {
        // Usamos getElement() para aprovechar la opción de caché
        const element = this.getElement(elementId);

        return element !== null;
    }

    /**
     * Obtiene de la estructura del contenedor el elemento con el ID especificado
     *
     * @param  {number} elementId    ID del elemento a obtener. Si es un string, lo convertirá a número.
     *
     * @return {Container}          El objeto encontrado, o null si no se encuentra en la estructura
     */
    getElement(elementId) {
        if (elementId === null || typeof elementId === 'undefined' || elementId === '') {
            return null;
        }

        const hasCache = this.getCRF().getOption('cache');

        // Primero se busca en la tabla de lookup
        if (hasCache && _.has(this.elementsById, elementId)) {
            return this.elementsById[elementId];
        }

        // A partir de ahora se busca en la estructura
        // El ID de un elemento siempre tiene que ser numérico, si viene como texto lo convertimos
        if (typeof elementId === 'string') {
            elementId = parseInt(elementId, 10);
        }

        let foundElement = null;

        _.some(this.getChildren(), function getRequestedElement(childNode) {
            if (childNode.getId() === elementId) {
                foundElement = childNode;
            } else {
                foundElement = childNode.getElement(elementId);
            }

            return foundElement !== null;
        });

        // Por último se actualiza la caché aunque no se haya encontrado el elemento
        if (hasCache) {
            this.elementsById[elementId] = foundElement;
        }

        return foundElement;
    }

    /**
     * Obtiene de la estructura del contenedor el elemento con el nombre único especificado
     *
     * @param  {string} elementName   Nombre único del elemento a obtener
     *
     * @return {Container}            El objeto encontrado, o null si no se encuentra en la estructura
     */
    getElementByName(elementName) {
        if (elementName === null || typeof elementName === 'undefined' || elementName === '') {
            return null;
        }

        const hasCache = this.getCRF().getOption('cache');

        // Primero se busca en la tabla de lookup
        if (hasCache && _.has(this.elementsByName, elementName)) {
            return this.elementsByName[elementName];
        }

        let foundElement = null;

        _.some(this.getChildren(), function getRequestedElement(childNode) {
            if (childNode.getName() === elementName) {
                foundElement = childNode;
            } else {
                foundElement = childNode.getElementByName(elementName);
            }

            return foundElement !== null;
        });

        // Por último se actualiza la caché aunque no se haya encontrado el elemento
        if (hasCache) {
            this.elementsByName[elementName] = foundElement;
        }

        return foundElement;
    }

    /**
     * Obtiene los elementos con el nombre especificado, sin tener en cuenta mayúsculas ni minúsculas
     * En este caso, "FIELD_NAME" sería igual que "field_name"
     * No hace uso de la caché interna de elementos
     * Se usa exclusivamente para detectar elementos duplicados
     *
     * @param  {string}    elementName El nombre del elemento a obtener
     *
     * @return {Container}             El objeto encontrado, o NULL
     */
    getElementsByNameCaseInsensitive(elementName) {
        let foundElements = [];

        if (_.isString(elementName) && elementName.length) {
            const lowerCaseName = elementName.toLowerCase();

            this.getChildren().map(childNode => {
                const childName = childNode.getName();
                if (typeof childName === 'string' && childName.toLowerCase() === lowerCaseName) {
                    foundElements.push(childNode);
                }

                foundElements = [].concat(foundElements, childNode.getElementsByNameCaseInsensitive(elementName));
            });
        }

        return foundElements;
    }

    /**
     * Obtiene una representación de la ruta del objeto dentro de la estructura global del CRF
     *
     * @return {Array<string>} Lista con la propiedad name de cada elemento que compone la ruta
     */
    getPath() {
        if (this.parent !== null) {
            return this.parent.getPath().concat(this.definition.name);
        }

        return [this.definition.name];
    }

    /**
     * Obtiene la etiqueta completa compuesta por las etiquetas de todos los elementos pertenecientes a la ruta
     *
     * @return {Array<string>} Lista de etiquetas (propiedad label)
     *
     * @example
     * // returns ['Sección', 'Lista', 'Formulario', 'Variable', 'Subvariable']
     * let subvar = new Container({label: 'Subvariable'});
     * subvar.getFullLabel();
     */
    getFullLabel() {
        if (this.parent !== null) {
            return this.parent.getFullLabel().concat(this.getLabel());
        }

        return [this.getLabel()];
    }

    /**
     * Obtiene todas las secciones que intervienen en la ruta del elemento, salvo este, en orden descendiente desde la
     * raíz de la estructura
     *
     * @return {Array<Structure.Container>} Lista de contenedores padres del actual (vacía si este es de primer nivel)
     */
    getParents() {
        if (!this.__parents) {
            const parents = [];
            let parent;
            let current = this;
            do {
                parent = current.getParent();
                if (parent) {
                    parents.unshift(parent);
                }
                current = parent;
            } while (current);
            this.__parents = parents;
        }

        return this.__parents;
    }

    /**
     * Obtiene la definición de las listas en las que está incluido el elemento
     *
     * @return {Array<Structure.List>} Lista de secciones de tipo lista (vacía si no hay ninguna lista padre)
     */
    getParentLists() {
        return this.getParents().filter(parent => parent.isList && parent.isList());
    }

    /**
     * Obtiene la lista más inmediata que contiene a este elemento
     *
     * @return {Structure.List} Lista contenedora del elemento, NULL si no está en una lista
     */
    getParentList() {
        const lists = this.getParentLists();

        return lists.length > 0 ? lists.slice(-1)[0] : null;
    }

    /**
     * Obtiene el ámbito de listas del elemento
     *
     * Llamamos ámbito a la lista de nivel más interior que contiene al elemento; a muchos efectos las listas se pueden
     * tratar como entidades dentro de la entidad
     *
     * @return {Number}  El ID de la lista que lo contiene, o NULL si no pertenece a listas
     */
    getListScope() {
        const parentList = this.getParentList();

        return parentList ? parentList.getId() : null;
    }

    /**
     * Obtiene la ruta relativa del elemento contando desde la lista más cercana. Si no se incluye en listas el
     * resultado es equivalente a getPath()
     *
     * @return {Array<string>} Lista con la propiedad name de cada elemento que compone la ruta
     */
    getPathFromList() {
        const parent = this.getParent();
        const name = this.getName();

        if (parent === null || parent.isList()) {
            return [name];
        }

        return parent.getPathFromList().concat(name);
    }

    /**
     * Obtiene la ruta relativa del elemento contando desde la lista más cercana. La estructura que mantiene
     * es la de los datos, no la de la estructura.
     *
     * En este caso, cuando encuentra una subvariable, no anida la estructura en la variable padre. El path es
     * equivalente al de la variable padre, es decir, ambos estarán directamente bajo el formulario que
     * le corresponde
     *
     * @return {Array<string>} Lista con la propiedad name de cada elemento que compone la ruta
     */
    getDataPathFromList() {
        return this.getPathFromList();
    }

    /**
     * Obtiene la etiqueta completa compuesta por las etiquetas de todos los elementos pertenecientes a la ruta,
     * desde la última lista que lo contiene (no incluida)
     *
     * @return {Array<string>} Lista de etiquetas (propiedad label)
     *
     * @example
     * // returns ['Formulario', 'Variable', 'Subvariable']
     * let subvar = new Container({label: 'Subvariable'});
     * subvar.getFullLabelFromList();
     */
    getFullLabelFromList() {
        const parent = this.getParent();
        const label = this.getLabel();

        if (parent === null || parent.isList()) {
            return label ? [label] : [];
        }

        return parent.getFullLabelFromList().concat(label);
    }

    /**
     * Obtiene la referencia al objeto global de definición donde está incluido el contenedor
     * Recorre hacia arriba los padres hasta llegar al objeto de CRF que sobrecarga el método para devolverse a sí mismo
     *
     * @return {Structure.CRF} El objeto de CRF
     */
    getCRF() {
        const parent = this.getParent();

        if (parent === null) {
            return null;
        }

        return parent.getCRF();
    }

    /**
     * Añade un elemento a la lista de hijos
     *
     * @param {Structure.Container} container Instancia del elemento a añadir
     */
    addChild(container) {
        this.children.push(container);
        container.setParent(this);
    }

    /**
     * Determina si el perfil con el ID seleccionado tiene acceso de lectura sobre el elemento
     *
     * @param  {Number}   profileId ID del perfil a consultar
     *
     * @return {boolean}            Si está permitido el acceso al elemento
     *
     * @example
     * // returns true
     * const element = new Container({accessProfiles: [1, 6]});
     * element.isAvailableToProfile(6);
     */
    isAvailableToProfile(profileId) {
        const parent = this.getParent();
        if (parent && !parent.isAvailableToProfile(profileId)) {
            return false;
        }

        return this.accessProfiles.allows(profileId);
    }

    /**
     * Determina si el perfil con el ID seleccionado tiene acceso de edición sobre el elemento
     *
     * @param  {Number}   profileId ID del perfil a consultar
     *
     * @return {boolean}            Si está permitida la edición del elemento
     *
     * @example
     * // returns true
     * const element = new Container({editionProfiles: [1, 6]});
     * element.isEditableToProfile(6);
     */
    isEditableToProfile(profileId) {
        if (!this.isAvailableToProfile(profileId)) {
            return false;
        }

        const parent = this.getParent();
        if (parent && !parent.isEditableToProfile(profileId)) {
            return false;
        }

        return this.editionProfiles.allows(profileId);
    }

    /**
     * Obtiene la lista de todos los elementos que son descendientes del actual pero al mismo nivel, sin tener en cuenta
     * la jerarquía
     *
     * @return {Structure.Container[]} Lista de elementos
     */
    getAllElements() {
        let allElements = [];

        this.getChildren().forEach(child => {
            allElements.push(child);
            allElements = allElements.concat(child.getAllElements());
        });

        return allElements;
    }

    /**
     * Obtiene la lista de elementos descendientes del actual que tienen configurado el acceso por perfil para el perfil
     * seleccionado
     *
     * @param  {Number}                profileId ID del perfil
     *
     * @return {Structure.Container[]}           Lista de elementos
     */
    getAvailableElementsToProfile(profileId) {
        // TODO: Intentar cachear estas respuestas
        const elements = this.getAllElements();

        return elements.filter(element => element.isAvailableToProfile(profileId));
    }

    /**
     * Obtiene la lista de elementos descendientes del actual que tienen configurada la edición por perfil para el
     * perfil seleccionado
     *
     * @param  {Number}                profileId ID del perfil
     *
     * @return {Structure.Container[]}           Lista de elementos
     */
    getEditableElementsToProfile(profileId) {
        const elements = this.getAllElements();

        return elements.filter(element => element.isEditableToProfile(profileId));
    }

    /**
     * Get rules with this element as target
     *
     * @return {Rule[]} List of rules
     *
     * @todo cache results in this.rules
     */
    getRules() {
        return this.getCRF().getEcrd().getRules().rules.filter(rule => rule.hasActionTarget(this.getId()));
    }
}

module.exports = Container;
