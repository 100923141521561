'use strict';

const Side = require('./Side');

/**
 * Nombres de los campos admisibles
 * @enum {string}
 * @readOnly
 */
const RecordFields = {
    RANDOMIZATION: 'randomization',
    SITE_CODE: 'siteCode',
    SITE_ID: 'siteId',
    VERSION: 'version',
};

/**
 * Tipo de condición: dato del registro
 *
 * @extends {Structure.ConditionSide}
 */
class RecordConditionSide extends Side {
    /**
     * Constantes de nombre de campo
     */
    static get RecordFields() {
        return RecordFields;
    }

    /**
     * Obtiene el nombre del campo definido
     *
     * @return {RecordFields} Nombre del campo
     */
    getField() {
        return this.definition.field;
    }

    /**
     * @inheritDoc
     */
    getDataType() {
        const field = this.getField();

        return {
            [RecordFields.SITE_CODE]: Side.DataTypes.STRING,
            [RecordFields.RANDOMIZATION]: Side.DataTypes.RECORD_RANDOMIZATION,
            [RecordFields.SITE_ID]: Side.DataTypes.RECORD_SITE,
            [RecordFields.VERSION]: Side.DataTypes.RECORD_VERSION,
        }[field];
    }
}

module.exports = RecordConditionSide;
