'use strict';

const _ = require('lodash');
const debug = require('debug')('sharecrf:core');

const Rule = require('./Rule');
const ConditionsList = require('./Conditions/List');
const RecordData = require('../../Record/Data');

/**
 * Objeto de definición de las reglas, es una lista de definiciones de regla individual
 * @typedef {RuleDefinition[]} RulesDefinition
 */

/**
 * Formato de exportación de las reglas para ser aplicadas
 * @typedef {ExportedRule[]} ExportedRules
 */

/**
 * Objeto que contiene la estructura de las reglas que se aplican sobre el CRF
 *
 * @property {RulesDefinition}  definition El objeto original almacenado en la base de datos
 * @property {Structure.Rule[]} rules      La lista de reglas instanciadas
 * @property {Structure.CRF}    crf        Referencia al objeto global de CRF
 * @property {Number}           idCounter  Contador interno de IDs de reglas, se debe definir si se van a crear nuevas
 *
 * @memberOf Structure
 */
class Rules {
    /**
     * Constructor de la clase, instancia la lista de reglas definidas
     *
     * @param {Structure.CRF}   crf        El objeto al que se asocian las reglas
     * @param {RulesDefinition} definition Definición de las reglas
     */
    constructor(crf, definition = []) {
        this.definition = definition;

        this.crf = crf;

        this.rules = [];

        _.each(this.definition, ruleDefinition => {
            this.instantiateRule(ruleDefinition);
        });

        this.idCounter = null;
    }

    /**
     * Obtiene el objeto de definición de la clase con propiedades editables
     *
     * @return {object} Objeto con la definición
     */
    get() {
        return this.definition;
    }

    /**
     * Inicializa el contador interno de IDs para las siguientes operaciones
     *
     * @param  {Number}  initialValue Valor del cual partir
     */
    initIdCounter(initialValue) {
        this.idCounter = initialValue;
    }

    /**
     * Añade una regla a la lista a partir de su configuración
     *
     * @param {RuleDefinition} ruleDefinition Objeto de definición de la regla
     *
     * @return {Structure.Rule} La instancia de Rule creada
     */
    instantiateRule(ruleDefinition) {
        const rule = new Rule(ruleDefinition);
        rule.setParent(this);

        this.rules.push(rule);

        return rule;
    }

    /**
     * Crea una nueva regla, y la añade a la lista global
     *
     * @param {RuleDefinition} ruleDefinition Objeto de definición de la regla
     *
     * @return {Structure.Rule} La instancia de {@link Structure.Rule} creada
     */
    addRule(ruleDefinition) {
        if (this.idCounter === null) {
            throw new Error('Para añadir una regla se debe inicializar el contador de IDs');
        }

        ruleDefinition.id = ++this.idCounter;

        this.definition.push(ruleDefinition);

        return this.instantiateRule(ruleDefinition);
    }

    /**
     * Obtiene la referencia al objeto global de gestión del CRF
     *
     * @return {Structure.CRF} Objeto de CRF
     */
    getCRF() {
        return this.crf;
    }

    /**
     * Obtiene una definición de las reglas con un formato conocido para su aplicación
     *
     * @return {ExportedRules} El objeto generado
     */
    export() {
        return _.reduce(this.getRules(), (exportedList, rule) => {
            if (rule.isEnabled()) {
                const exportedRule = rule.export();

                // Y si al exportar las acciones no queda ninguna, tampoco hay que exportar la regla
                if (!_.isEmpty(exportedRule.actions)) {
                    exportedList.push(exportedRule);
                }
            }

            return exportedList;
        }, []);
    }

    /**
     * Obtiene las reglas en las que interviene el elemento como destino de alguna acción
     *
     * @param  {Number}  targetId ID único del elemento en el CRF
     *
     * @return {RuleDefinition[]} Lista filtrada de reglas
     */
    getByActionTarget(targetId) {
        return _.filter(this.rules, _.method('hasActionTarget', targetId));
    }

    /**
     * Obtiene la lista de reglas en cuyas acciones interviene alguno de los ID de elemento especificados
     *
     * @param  {integer[]}        targetIds IDs de elementos a buscar
     *
     * @return {Structure.Rule[]}           Lista de reglas filtradas
     *
     * @private
     */
    _getByActionTargets(targetIds) {
        return _.filter(this.rules, _.method('hasAnyActionTarget', targetIds));
    }

    /**
     * Obtiene las reglas en las que está implicado un elemento del CRF, bien directamente o a través de alguno de los
     * elementos contenidos
     *
     * @param  {Number}           elementId ID único del elemento
     * @param  {object}           where     Configuración de búsqueda:
     *                                      - actions: Buscar en acciones
     *                                      - conditions: Buscar en condiciones
     *                                      - formulas: Buscar en fórmulas
     *
     * @return {Structure.Rule[]}           Lista de reglas filtrada
     */
    getByElement(elementId, where = {}) {
        _.defaults(where, {
            actions: true,
            conditions: true,
            formulas: true,
        });

        let rulesList = [];

        const element = this.crf.getElement(elementId);
        if (!element) {
            return rulesList;
        }

        if (where.actions) {
            rulesList = rulesList.concat(this.getByActionTarget(elementId));
        }

        if (where.conditions) {
            rulesList = rulesList.concat(_.filter(this.rules, _.method('hasElementInConditions', elementId)));
        }

        if (where.formulas) {
            rulesList = rulesList.concat(_.filter(this.rules, _.method('hasElementInFormulas', elementId)));
        }

        const rulesByChildren = element.getChildren().map(child => {
            return this.getByElement(child.getId(), where);
        });

        rulesList = rulesList.concat(_.flatten(rulesByChildren));

        return _.uniqBy(rulesList);
    }

    /**
     * Obtiene la lista de reglas
     *
     * @return {Structure.Rule[]} Lista de objetos de tipo regla
     */
    getRules() {
        return this.rules;
    }

    /**
     * Obtiene una regla individual
     *
     * @param  {Number}         ruleId ID de la regla
     *
     * @return {Structure.Rule}        Objeto de gestión de la regla
     */
    getRule(ruleId) {
        return _.find(this.rules, rule => {
            return rule.getId() === ruleId;
        });
    }

    /**
     * Obtiene la lista de los campos que están incluidos en las condiciones de las reglas
     *
     * @return {Structure.Field[]} Lista de campos
     */
    getFieldsInConditions() {
        return _.chain(this.rules)
            .map(_.method('getFieldsInConditions'))
            .flatten()
            .uniqBy(_.method('getId'))
            .value();
    }

    /**
     * Obtiene la lista de las listas que están incluidas en las condiciones de las reglas
     *
     * @return {Structure.List[]} Lista de listas
     */
    getListsInConditions() {
        return _.chain(this.rules)
            .map(_.method('getListsInConditions'))
            .flatten()
            .uniqBy(_.method('getId'))
            .value();
    }

    /**
     * Obtiene la lista de las listas que están implicadas en las acciones de las reglas, p.ej. que contengan
     * algún campo de una acción
     *
     * @return {Structure.List[]} Lista de listas
     */
    getListsInActions() {
        return _.chain(this.rules)
            .map(_.method('getListsInActions'))
            .flatten()
            .uniqBy(_.method('getId'))
            .value();
    }

    /**
     * Extiende el conjunto de reglas añadiendo unas nuevas generadas que se corresponden con la funcionalidad
     * de los campos que, en función de los valores que toman, ocultan a sus hijos (subvariables)
     *
     * Lo que hacemos es leer el CRF, detectar qué campos son susceptibles de contar con una regla de este tipo,
     * y añadir esa nueva regla al conjunto actual.
     *
     * @todo El id de la acción debe ser único
     * @todo Colocar este método en mejor sitio (resolver?)
     * @todo Llamar siempre en el constructor?
     */
    extendRules() {
        this.crf.getFields().forEach(field => {
            const fieldId = field.getId();

            // Hide children
            if (field.getChildren()) {
                let comparisonList = [];

                // 1. Si hay que ocultar los hijos cuando está vacío
                if (field.getHideChildrenIfEmpty()) {
                    debug('Hide field %d children if empty', fieldId);
                    comparisonList.push({
                        comparison: 'empty',
                        lhs: {
                            type: 'field',
                            id: fieldId,
                        },
                    });
                }
                // 2. Si hay que ocultar los hijos cuando toma ciertos valores
                const valuesForHidden = field.getValuesToHideChildren();
                if (!_.isEmpty(valuesForHidden)) {
                    comparisonList = comparisonList.concat(_.map(valuesForHidden, value => {
                        debug('Hide field %d children for value %o', fieldId, value);

                        return {
                            comparison: 'eq',
                            lhs: {
                                type: 'field',
                                id: fieldId,
                            },
                            rhs: {
                                type: 'value',
                                value: value,
                            },
                        };
                    }));
                }

                // 3. Finalmente, si hay algo que comparar
                if (!_.isEmpty(comparisonList)) {
                    const parentList = field.getParentList();

                    const ruleDefinition = {
                        id: `hidechildren-${fieldId}`,
                        context: parentList !== null ? parentList.getId() : null,
                        conditions: {
                            operator: 'or',
                            list: comparisonList,
                        },
                        actions: [
                            {
                                id: `hidechildren-${fieldId}`,
                                type: 'hideChildren',
                                scope: 'always',
                                target: fieldId,
                                // indica si deben resetearse los valores de los hijos que se ocultan
                                resetDescendants: field.getResetDescendants(),
                            },
                        ],
                    };
                    debug('Generate rule hideChildren for %o %O', field.getName(), ruleDefinition);
                    this.instantiateRule(ruleDefinition);
                }
            }
        });

        return this;
    }

    /**
     * Elimina una acción en una regla
     *
     * @param  {Structure.Action} action Instancia de la acción
     *
     * @return {boolean}                 Resultado de la operación
     */
    removeAction(action) {
        return action.getRule().removeAction(action);
    }

    /**
     * Elimina una lista de condiciones
     *
     * @param  {ConditionsList} conditionsList La lista a eliminar
     *
     * @return {boolean}                       Si se ha eliminado correctamente
     */
    removeConditionsList(conditionsList) {
        const parent = conditionsList.getParent();

        // Si es la lista de condiciones principal de la regla (sólo hay una), se puede borrar la regla al perder todas
        // las condiciones (GARU-3716)
        if (parent instanceof Rule) {
            return this.removeRule(parent.getId());
        }

        return this._removeChildFromConditionsList(parent, conditionsList);
    }

    /**
     * Elimina un elemento que pertenece a una lista de condiciones: bien una condición o una lista anidada
     * Adicionalmente, según el estado de la lista tras eliminar, se pueden ejecutar acciones sobre la propia lista:
     * - Si se queda vacía, se elimina
     * - Si se queda con un único elemento, se añade el elemento a la lista superior (si la hubiera)
     *
     * @param  {ConditionsList}           list  La lista que incluye el elemento
     * @param  {Condition|ConditionsList} child El elemento a eliminar
     *
     * @return {boolean}                        Si se ha eliminado correctamente
     *
     * @private
     */
    _removeChildFromConditionsList(list, child) {
        const result = list.removeChild(child);
        if (!result) {
            return result;
        }

        const remainingConditions = list.getConditions().length;

        // Si el grupo se queda vacío, eliminar el grupo
        if (remainingConditions === 0) {
            this.removeConditionsList(list);
        } else if (remainingConditions === 1) {
            // Si quedase un único elemento moveremos la condición a la lista de nivel superior
            this.mergeConditionsList(list);
        }

        // Y si queda más de un elemento, la lista continúa en su sitio

        return result;
    }

    /**
     * Incluye todos los elementos de una lista de condiciones en la lista que la contiene, eliminando posteriormete la
     * lista original
     *
     * @param  {ConditionsList} conditionsList La lista a gestionar
     *
     * @return {boolean}                       Si se ha modificado la estructura
     *
     * @private
     */
    mergeConditionsList(conditionsList) {
        const parent = conditionsList.getParent();

        if (parent instanceof Rule) {
            return false;
        }

        let positionInParent = parent.getConditions().indexOf(conditionsList);

        conditionsList.getConditions().forEach(child => {
            if (child instanceof ConditionsList) {
                parent.addList(child, positionInParent);
                child.setParent(parent);
            } else { // child instanceof Condition
                parent.addCondition(child, positionInParent);
                child.setList(parent);
            }

            positionInParent++;
        });

        parent.removeChild(conditionsList);

        return true;
    }

    /**
     * Elimina una regla
     *
     * @param  {Number}  ruleId ID de la regla
     *
     * @return {boolean}        Resultado de la operación
     */
    removeRule(ruleId) {
        const isRemoved = _.remove(this.rules, rule => {
            return rule.getId() === ruleId;
        }).length > 0;

        _.remove(this.definition, {id: ruleId});

        return isRemoved;
    }

    /**
     * Agrupa la lista de reglas de acuerdo a un criterio determinado
     *
     * @param  {function} callback Función que se aplica a cada regla individual para obtener las claves de agrupación
     *
     * @return {object}            Lista de instancias de regla indexadas por las claves devueltas por el callback
     *
     * @private
     */
    _groupByRuleData(callback) {
        const indexed = {};

        const chunks = [this.getRules()];

        do {
            const currentChunk = chunks.shift();
            _.each(currentChunk, rule => {
                const keys = _.castArray(callback(rule));
                _.each(keys, key => {
                    if (_.isUndefined(indexed[key])) {
                        indexed[key] = [];
                    }
                    indexed[key].push(rule);
                });
            });
        } while (chunks.length > 0);

        return indexed;
    }

    /**
     * Agrupa la lista de reglas por el tipo de acción que ejecutan. Si una regla tiene más de un tipo de acción
     * aparecerá en tantos grupos como tipos distintos haya
     *
     * @return {object} Lista de instancias de regla indexadas por identificador del tipo de acción
     */
    groupByActionType() {
        return this._groupByRuleData(rule => {
            return _.uniqBy(rule.getActions().map(_.method('getType')));
        });
    }

    /**
     * Agrupa la lista de reglas por el objeto de destino de las acciones que ejecutan. Si una regla tiene más de un
     * destinatario aparecerá en tantos grupos como destinatarios distintos haya
     *
     * @return {object} Lista de instancias de regla indexadas por ID del destino de la acción
     */
    groupByActionTarget() {
        return this._groupByRuleData(rule => {
            return rule.getActionTargets().map(_.method('getId'));
        });
    }

    /**
     * Agrupa la lista de reglas por el ámbito de ejecución de las acciones que ejecutan. Si una regla tiene más de un
     * ámbito de ejecución aparecerá en tantos grupos como ámbitos distintos haya
     *
     * @return {object} Lista de instancias de regla indexadas por identificador de ámbito
     */
    groupByActionScope() {
        return this._groupByRuleData(rule => {
            return _(rule.getActions()).map(_.method('getScope')).flatten().uniqBy().value();
        });
    }

    /**
     * Agrupa la lista de reglas por el formulario en el que se ejecutan. Esto incluye las reglas cuyo destino es el
     * propio formulario y las que actúan sobre alguno de sus campos
     *
     * @return {object} Lista de instancias de regla indexadas por ID del formulario
     */
    groupByTargetForm() {
        const indexed = {};
        const indexedByAction = this.groupByActionTarget();

        const crfForms = this.getCRF().getForms();
        _.each(crfForms, form => {
            const idsToFind = [form].concat(form.getFields()).map(_.method('getId'));

            indexed[idsToFind[0]] = _(indexedByAction).pick(idsToFind).values().flatten().uniqBy().value();
        });

        return indexed;
    }

    /**
     * Obtiene una lista de reglas que pueden desencadenar mediante llamadas sucesivas la regla seleccionada. Para ello
     * se detectan las reglas cuyo destino es un campo que entra en la condición de la siguiente, hasta finalmente
     * llegar a la regla de destino
     *
     * @param  {Structure.Rule}   targetRule Regla final de la cadena
     * @param  {Structure.Rule[]} rulesList  Lista de reglas de donde se buscan las cadenas, si se deja vacío es el
     *                                       conjunto de todas las reglas con acciones "encadenables"
     *
     * @return {Structure.Rule[]}            Instancias de regla
     */
    getChain(targetRule, rulesList = null) {
        // Si la regla no comprueba variables del CRD entonces no hay que mirar más: las únicas cadenas de reglas son
        // las que se originan por un cambio de valor en variables
        const fields = targetRule.getFieldsInConditions();
        if (_.isEmpty(fields)) {
            return [];
        }

        // El primer subconjunto de reglas es la lista de todos los candidatos
        if (rulesList === null) {
            rulesList = this.getRules().filter(otherRule => {
                return targetRule !== otherRule && otherRule.isChainable();
            });
        }

        const [inChain, notInChain] = _.partition(rulesList, candidate => {
            const targetFields = _(candidate.getActions()).map(action => {
                const actionType = action.getType();
                const actionTarget = action.getTarget();

                if (actionType === 'setValue') {
                    return actionTarget;
                }

                if (actionType === 'hidechildren') {
                    return [actionTarget].concat(actionTarget.getDescendants());
                }
            }).flatten().filter().value();

            return _.intersection(fields, targetFields).length > 0;
        });

        const chain = inChain.map(chainRule => {
            return this.getChain(chainRule, notInChain).concat(chainRule);
        });

        return _(chain).flatten().uniqBy().value();
    }

    /**
     * Obtiene los identificadores de campos del registro que intervienen en las condiciones de las reglas
     *
     * @return {RecordFields[]} Lista de identificadores de campos
     */
    getRecordFieldsInConditions() {
        return _(this.getRules())
            .map(_.method('getRecordFieldsInConditions'))
            .flatten()
            .uniqBy()
            .value();
    }

    /**
     * Obtiene la lista de todas las acciones definidas en las reglas
     *
     * @return {Action[]} Lista de objetos de acción
     */
    getActions() {
        return _.flatten(this.getRules().map(rule => rule.getActions()));
    }

    /**
     * Obtiene la lista de todas las condiciones definidas en las reglas
     *
     * @return {Condition[]} Lista de condiciones individuales
     */
    getSingleConditions() {
        return _.flatten(this.getRules().map(rule => rule.getConditions().flattenConditions()));
    }

    /**
     * Elimina de las reglas las referencias a los elementos del CRF especificados bajo las siguientes condiciones:
     * - Si el destinatario de alguna acción está en la lista de IDs, se elimina la acción
     * - Si alguno de los elementos interviene en una condición, se elimina la condición
     * - Las reglas que queden sin acciones o condiciones se eliminan
     *
     * @param  {integer[]} elementIds IDs de elemento donde buscar referencias
     */
    removeReferencesToElements(elementIds) {
        const actions = this.getActions().filter(action => action.hasAnyTarget(elementIds));
        actions.forEach(action => {
            const rule = action.getRule();
            rule.removeAction(action);

            // Si no quedan más acciones en la regla, se puede eliminar porque ya no hace nada
            if (rule.getActions().length === 0) {
                this.removeRule(rule.getId());
            }
        });

        const conditions = this.getSingleConditions().filter(condition => condition.hasAnyElement(elementIds));
        conditions.forEach(condition => {
            const list = condition.getList();

            this._removeChildFromConditionsList(list, condition);
        });
    }
}

module.exports = Rules;
