'use strict';

const Configuration = require('./Configuration');
const Customization = require('./Customization');
const Ecrd = require('./Ecrd');
const Record = require('./Record');
const Structure = require('./Structure');
const User = require('./User');
const Formula = require('./Formula/Formula');

module.exports = {
    Ecrd: Ecrd,
    CRF: Structure.CRF,
    CRFField: Structure.Field,
    CRFForm: Structure.Form,
    Rules: Structure.Rules,
    FieldOptions: Structure.FieldOptions,
    RulesConditionsList: Structure.ConditionsList,
    RulesAction: Structure.Action,
    Configuration: Configuration,
    Customization: Customization,
    RecordBase: Record.Base,
    RecordData: Record.Data,
    RecordMetadata: Record.Metadata,
    RecordRules: Record.Rules,
    RecordValidation: Record.Validation,
    RecordChanges: Record.Changes,
    RecordQueries: Record.Queries,
    FieldsCompletion: Record.Completion,
    FormFields: Record.FormFields,
    User: User,
    Formula: Formula,
    FormulaExecutor: require('./Formula/Executor'),
};
