'use strict';

/**
 * Tipos de operación
 *
 * @enum {string}
 * @readOnly
 */
module.exports = {
    CONTAINS: 'contains',
    EMPTY: 'empty',
    ENDS_WITH: 'endswith',
    EQUALS: 'eq',
    GREATER_THAN: 'gt',
    GREATER_THAN_OR_EQUALS: 'gte',
    LESS_THAN: 'lt',
    LESS_THAN_OR_EQUALS: 'lte',
    NOT_CONTAINS: 'notcontains',
    NOT_EMPTY: 'notempty',
    NOT_EQUALS: 'neq',
    STARTS_WITH: 'startswith',
    IS_COMPLETE_DATE: 'iscompletedate',
    IS_PARTIAL_DATE: 'ispartialdate',
    UNKNOWN: 'unknown',
    NOT_UNKNOWN: 'notunknown',
    // Tipo automático cuando se resuelve una condición con un campo inactivo
    ALWAYS_FALSE: 'false',
};
