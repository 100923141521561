'use strict';

const _ = require('lodash');

const Container = require('./Container');

/**
 * Clase contenedora de campos. Es una especialización de {@link Structure.Container} en la que todos sus hijos son
 * instancias de {@link Structure.Field}
 * @extends Container
 * @abstract
 * @memberOf Structure
 */
class FieldsContainer extends Container {
    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['fields', 'accessProfiles', 'editionProfiles']);
    }

    /**
     * Determina si existe el campo especificado. Dado que todos los hijos de la clase son de tipo
     * {@link Structure.Field} la función es equivalente a {@link Structure.Container#hasElement}
     * @param  {string}  fieldId ID del campo a buscar
     * @return {Boolean}         Si existe el campo en la estructura del contenedor
     */
    hasField(fieldId) {
        return this.hasElement(fieldId);
    }

    /**
     * Determina si el contenedor contiene algún campo
     *
     * @return {boolean} Verdadero si hay algún campo definido en la estructura dentro del objeto contenedor
     */
    hasFields() {
        return this.getChildren().length > 0;
    }

    /**
     * Obtiene el campo especificado por su ID. Dado que todos los hijos de la clase son de tipo {@link Structure.Field}
     * la función es equivalente a {@link Structure.Container#getElement}
     * @param  {string}     fieldId ID del campo a obtener
     * @return {Field|null}         Campo encontrado, o null si no se encuentra en la estructura
     */
    getField(fieldId) {
        return this.getElement(fieldId);
    }

    /**
     * Obtiene todos los campos contenidos en el objeto, incluyendo aquellos que son subvariables
     * @return {Field[]} Lista de objetos de campo
     */
    getDescendants() {
        return this.getAllElements();
    }

    /**
     * Elimina un campo de la lista
     *
     * @param  {Number}  fieldId El ID único del campo
     *
     * @return {boolean}         Resultado de la operación
     */
    removeField(fieldId) {
        const field = this.getField(fieldId);
        if (!field) {
            return false;
        }

        if (this.getCRF().getOption('cache')) {
            _.unset(this.elementsById, fieldId);
        }

        // fieldParent instanceof FieldsContainer
        const fieldParent = field.getParent();
        if (field.getParent() === this) {
            _.remove(this.children, field => {
                return field.getId() === fieldId;
            });
            _.remove(this.definition.fields, {id: fieldId});

            return true;
        }

        return fieldParent.removeField(fieldId);
    }

    /**
     * @inheritDoc
     */
    addChild(container) {
        super.addChild(container);
        this.definition.fields.push(container.get());
    }
}

module.exports = FieldsContainer;
