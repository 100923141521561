'use strict';

const moment = require('moment');

/**
 * Funciones personalizadas para fórmulas, que no están en Math ni Lodash
 */
class AdvancedMath {
    /**
     * Devuelve la diferencia en días de dos fechas
     * @param  {Date|string} finalDate     Fecha final
     * @param  {Date|string} initialDate   Fecha inicial
     * @return {Number}                    Diferencia en días
     */
    static dateDiffDays(finalDate, initialDate) {
        let initialMoment, finalMoment;
        if (initialDate instanceof Date || typeof initialDate === 'string') {
            initialMoment = moment(initialDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return NaN;
        }

        if (finalDate instanceof Date || typeof finalDate === 'string') {
            finalMoment = moment(finalDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return NaN;
        }

        if (initialMoment.isValid() && finalMoment.isValid()) {
            return finalMoment.diff(initialMoment, 'days');
        }

        return NaN;
    }

    /**
     * Devuelve la diferencia entre dos horas expresada en una unidad de medida
     *
     * @param  {string}  unit        Unidad de medida: hours o minutes
     * @param  {string}  finalTime   Representación de la hora final
     * @param  {string}  initialTime Representación de la hora inicial
     *
     * @return {number}              Diferencia en horas
     *
     * @private
     */
    static _timeDiff(unit, finalTime, initialTime) {
        if (['hours', 'minutes'].indexOf(unit) === -1) {
            return NaN;
        }

        const initialMoment = moment(initialTime, 'HH:mm', true);
        const finalMoment = moment(finalTime, 'HH:mm', true);

        if (initialMoment.isValid() && finalMoment.isValid()) {
            return finalMoment.diff(initialMoment, unit, true);
        }

        return NaN;
    }

    /**
     * Devuelve la diferencia en horas entre dos horas
     *
     * @param  {string}  finalTime   Representación de la hora final
     * @param  {string}  initialTime Representación de la hora inicial
     *
     * @return {Number}              Diferencia en horas
     */
    static timeDiffHours(finalTime, initialTime) {
        return this._timeDiff('hours', finalTime, initialTime);
    }

    /**
     * Devuelve la diferencia en minutos entre dos horas
     *
     * @param  {string}  finalTime   Representación de la hora final
     * @param  {string}  initialTime Representación de la hora inicial
     *
     * @return {Number}              Diferencia en minutos
     */
    static timeDiffMinutes(finalTime, initialTime) {
        return this._timeDiff('minutes', finalTime, initialTime);
    }

    /**
     * Añade un número de días a una fecha
     *
     * @param  {Date|string} date    Fecha origen
     * @param  {Number}      days    Días a añadir
     * @return {string}              Nueva fecha
     */
    static dateAddDays(date, days) {
        let dateMoment;
        if (date instanceof Date || typeof date === 'string') {
            dateMoment = moment(date, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return '';
        }

        if (dateMoment.isValid()) {
            return dateMoment.add(days, 'days').format('YYYY-MM-DD');
        }

        return '';
    }

    /**
     * Resta un número de días a una fecha
     *
     * @param  {Date|string} date    Fecha origen
     * @param  {Number}      days    Días a añadir
     * @return {string}              Nueva fecha
     */
    static dateSubDays(date, days) {
        let dateMoment;
        if (date instanceof Date || typeof date === 'string') {
            dateMoment = moment(date, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return '';
        }

        if (dateMoment.isValid()) {
            return dateMoment.subtract(days, 'days').format('YYYY-MM-DD');
        }

        return '';
    }

    /**
     * Calcula la edad desde la fecha de nacimiento hasta otra fecha
     *
     * @param  {Date|string}  birthDate    Fecha de nacimiento
     * @param  {Date|dstring} finalDate    Fecha final
     * @return {Number}                     Edad en años
     */
    static dateAge(birthDate, finalDate) {
        let birthMoment, finalMoment;
        if (birthDate instanceof Date || typeof birthDate === 'string') {
            birthMoment = moment(birthDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return NaN;
        }
        if (finalDate instanceof Date || typeof finalDate === 'string') {
            finalMoment = moment(finalDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601], true);
        } else {
            return NaN;
        }

        if (birthMoment.isValid() && finalMoment.isValid()) {
            return finalMoment.diff(birthMoment, 'years');
        }

        return NaN;
    }

    /**
     * Difference between two datetimes
     *
     * @param {String} unit            Unit of measure: years, months, days, hours, minutes, seconds
     * @param {String} finalDatetime   Final datetime
     * @param {String} initialDatetime Initial datetime
     *
     * @returns {Number} Difference in the specified unit
     *
     * @private
     */
    static datetimeDiff(unit, finalDatetime, initialDatetime) {
        const formats = ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm'];

        const initialMoment = moment(initialDatetime, formats, true);
        const finalMoment = moment(finalDatetime, formats, true);

        return initialMoment.isValid() && finalMoment.isValid() ? finalMoment.diff(initialMoment, unit) : NaN;
    }

    /**
     * Difference in days between two datetimes
     *
     * @param {String} finalDatetime   Final datetime
     * @param {String} initialDatetime Initial datetime
     *
     * @returns {Number} Difference in days
     */
    static datetimeDiffDays(finalDatetime, initialDatetime) {
        return this.datetimeDiff('days', finalDatetime, initialDatetime);
    }

    /**
     * Difference in hours between two datetimes
     *
     * @param {String} finalDatetime   Final datetime
     * @param {String} initialDatetime Initial datetime
     *
     * @returns {Number} Difference in hours
     */
    static datetimeDiffHours(finalDatetime, initialDatetime) {
        return this.datetimeDiff('hours', finalDatetime, initialDatetime);
    }

    /**
     * Difference in minutes between two datetimes
     *
     * @param {String} finalDatetime   Final datetime
     * @param {String} initialDatetime Initial datetime
     *
     * @returns {Number} Difference in minutes
     */
    static datetimeDiffMinutes(finalDatetime, initialDatetime) {
        return this.datetimeDiff('minutes', finalDatetime, initialDatetime);
    }

    /**
     * Difference in seconds between two datetimes
     *
     * @param {String} finalDatetime   Final datetime
     * @param {String} initialDatetime Initial datetime
     *
     * @returns {Number} Difference in seconds
     */
    static datetimeDiffSeconds(finalDatetime, initialDatetime) {
        return this.datetimeDiff('seconds', finalDatetime, initialDatetime);
    }

    /**
     * Creates a datetime value from a date and a time
     *
     * @param {String} date Date value
     * @param {String} time Time value
     *
     * @returns {String} Datetime value
     */
    static datetime(date, time) {
        if (typeof date !== 'string' || typeof time !== 'string') {
            return '';
        }

        const dateMoment = moment(date, 'YYYY-MM-DD', true);
        const timeMoment = moment(time, ['HH:mm:ss', 'HH:mm'], true);

        return dateMoment.isValid() && timeMoment.isValid() ? `${date} ${timeMoment.format('HH:mm:ss')}` : '';
    }

    /**
     * Gets the date component of a datetime value
     *
     * @param {String} datetime Datetime value
     *
     * @returns {String} Date value
     */
    static getDate(datetime) {
        const momentObject = moment(datetime, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm'], true);

        return momentObject.isValid() ? momentObject.startOf('day').format('YYYY-MM-DD') : '';
    }

    /**
     * Gets the time component of a datetime value
     *
     * @param {String} datetime Datetime value
     *
     * @returns {String} Time value
     */
    static getTime(datetime) {
        const momentObject = moment(datetime, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm'], true);

        return momentObject.isValid() ? momentObject.format('HH:mm:ss') : '';
    }
}

module.exports = AdvancedMath;
