'use strict';

const _ = require('lodash');

const SectionsContainer = require('./SectionsContainer');

/**
 * @typedef {object} ListDefinition
 */

/**
 * Clase de gestión de una lista del CRF
 * @extends SectionsContainer
 * @memberOf Structure
 */
class List extends SectionsContainer {
    /**
     * Constructor de la clase a partir de un objeto de definición
     *
     * @param {ListDefinition} definition Definición de la lista
     */
    constructor(definition) {
        super(definition);

        this.listFields = null;
    }

    /**
     * @inheritDoc
     */
    _getAvailableProperties() {
        return super._getAvailableProperties().concat(['header', 'listFields', 'epro', 'eproReminders']);
    }

    /**
     * Determina si el objeto es de tipo lista
     * @return {boolean} Verdadero siempre
     */
    isList() {
        return true;
    }

    /**
     * Is ePRO
     *
     * @return {Boolean} Indica si se corresponde con un evento de ePRO
     */
    isEpro() {
        return !!this.definition.epro;
    }

    /**
     * Obtiene el contenido HTML de cabecera en la vista de resumen de la lista
     * @return {string} Código HTML
     */
    getHeader() {
        return _.get(this.definition, 'header', '');
    }

    /**
     * Obtiene la lista de campos que se incluyen en el listado de elementos de la lista
     *
     * @param {boolean} reload Flag para recargar la lista, si no se habilita se usan los datos ya calculados
     *
     * @return {Structure.Field[]} Instancias de campo
     */
    getListableFields(reload = false) {
        if (reload || this.listFields === null) {
            const fieldIds = _.get(this.definition, 'listFields', []);

            this.listFields = _.reduce(fieldIds, (currentList, fieldId) => {
                const field = this.getField(fieldId);
                if (field && field.getParentList() === this) {
                    currentList.push(field);
                }

                return currentList;
            }, []);
        }

        return this.listFields;
    }

    /**
     * Devuelve la lista de campos descendientes directos de esta lista, es decir, que no estén a su vez
     * dentro de otra lista hija de ésta
     *
     * @return {Structure.Field[]} Lista de objetos de campo
     */
    getDirectFields() {
        const listForms = this.getDirectForms();

        return _.flatten(listForms.map(_.method('getFields')));
    }

    /**
     * Devuelve la lista de formularios descendientes directos de esta lista, es decir, que no estén a su vez
     * dentro de otra lista hija de ésta
     *
     * @return {Structure.Form[]} Lista de objetos de formulario
     */
    getDirectForms() {
        return this.getForms().filter(form => {
            return form.getParentList() === this;
        });
    }

    /**
     * Obtiene la lista de secciones descendientes directas de esta lista, es decir, que no estén a su vez dentro de
     * otra lista hija de ésta
     *
     * @return {Structure.Section[]} Lista de objetos de sección
     */
    getDirectSections() {
        return this.getSections().filter(section => {
            return section.getParentList() === this;
        });
    }

    /**
     * Obtiene la lista de listas descendientes directas de esta lista, es decir, que no estén a su vez dentro de otra
     * lista hija de ésta
     *
     * @return {Structure.List[]} Lista de objetos de lista
     */
    getDirectLists() {
        return this.getLists().filter(list => {
            return list.getParentList() === this;
        });
    }

    /**
     * Elimina un campo de la lista de campos que aparecen en la tabla principal
     *
     * @param  {Number}  fieldId ID numérico del campo
     */
    removeListableField(fieldId) {
        _.remove(this.definition.listFields, listableId => {
            return listableId === fieldId;
        });

        // Recarga de la lista
        this.getListableFields(true);
    }

    /**
     * Obtiene los objetos de definición de recordatorio
     *
     * @returns {Object[]} Definición de recordatorios ePRO
     */
    getEproReminders() {
        return this.isEpro() ? this.definition.eproReminders : [];
    }
}

module.exports = List;
