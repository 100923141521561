'use strict';

const FormulaFunction = require('./Function');

const definitions = {
    // Numeric
    ABSOLUTE_VALUE: {
        name: 'abs',
        category: 'numeric',
        arity: 1,
    },
    CEILING: {
        name: 'ceil',
        category: 'numeric',
        arity: 1,
    },
    FLOOR: {
        name: 'floor',
        category: 'numeric',
        arity: 1,
    },
    MAXIMUM: {
        name: 'max',
        category: 'numeric',
        minArity: 1,
    },
    MINIMUM: {
        name: 'min',
        category: 'numeric',
        minArity: 1,
    },
    NATURAL_LOGARITHM: {
        name: 'ln',
        category: 'numeric',
        arity: 1,
    },
    DECIMAL_LOGARITHM: {
        name: 'log10',
        category: 'numeric',
        arity: 1,
    },
    POWER: {
        name: 'pow',
        category: 'numeric',
        arity: 2,
    },
    ROUND: {
        name: 'round',
        category: 'numeric',
        minArity: 1,
        maxArity: 2,
    },
    SQUARE_ROOT: {
        name: 'sqrt',
        category: 'numeric',
        arity: 1,
    },
    // Geometric
    ARCCOSINE: {
        name: 'acos',
        category: 'geometric',
        arity: 1,
    },
    ARCSINE: {
        name: 'asin',
        category: 'geometric',
        arity: 1,
    },
    ARCTANGENT: {
        name: 'atan',
        category: 'geometric',
        arity: 1,
    },
    COSINE: {
        name: 'cos',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_ARCCOSINE: {
        name: 'acosh',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_ARCSINE: {
        name: 'asinh',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_ARCTANGENT: {
        name: 'atanh',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_COSINE: {
        name: 'cosh',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_SINE: {
        name: 'sinh',
        category: 'geometric',
        arity: 1,
    },
    HYPERBOLIC_TANGENT: {
        name: 'tanh',
        category: 'geometric',
        arity: 1,
    },
    SINE: {
        name: 'sin',
        category: 'geometric',
        arity: 1,
    },
    TANGENT: {
        name: 'tan',
        category: 'geometric',
        arity: 1,
    },
    // Dates / Times
    DATE_DIFF_DAYS: {
        name: 'date_diff_days',
        category: 'datetime',
        arity: 2,
        arguments: ['date', 'date'],
        returns: 'number',
    },
    TIME_DIFF_HOURS: {
        name: 'time_diff_hours',
        category: 'datetime',
        arity: 2,
        arguments: ['time', 'time'],
        returns: 'number',
    },
    TIME_DIFF_MINUTES: {
        name: 'time_diff_minutes',
        category: 'datetime',
        arity: 2,
        arguments: ['time', 'time'],
        returns: 'number',
    },
    DATE_ADD_DAYS: {
        name: 'date_add_days',
        category: 'datetime',
        arity: 2,
        arguments: ['date', 'number'],
        returns: 'date',
    },
    DATE_SUB_DAYS: {
        name: 'date_sub_days',
        category: 'datetime',
        arity: 2,
        arguments: ['date', 'number'],
        returns: 'date',
    },
    DATE_AGE: {
        name: 'date_age',
        category: 'datetime',
        arity: 2,
        arguments: ['date', 'date'],
        returns: 'number',
    },
    DATETIME_DIFF_DAYS: {
        name: 'datetime_diff_days',
        category: 'datetime',
        arity: 2,
        arguments: ['datetime', 'datetime'],
        returns: 'number',
    },
    DATETIME_DIFF_HOURS: {
        name: 'datetime_diff_hours',
        category: 'datetime',
        arity: 2,
        arguments: ['datetime', 'datetime'],
        returns: 'number',
    },
    DATETIME_DIFF_MINUTES: {
        name: 'datetime_diff_minutes',
        category: 'datetime',
        arity: 2,
        arguments: ['datetime', 'datetime'],
        returns: 'number',
    },
    DATETIME_DIFF_SECONDS: {
        name: 'datetime_diff_seconds',
        category: 'datetime',
        arity: 2,
        arguments: ['datetime', 'datetime'],
        returns: 'number',
    },
    DATETIME: {
        name: 'datetime',
        category: 'datetime',
        arity: 2,
        arguments: ['date', 'time'],
        returns: 'datetime',
    },
    GET_DATE: {
        name: 'get_date',
        category: 'datetime',
        arity: 1,
        arguments: ['string'],
        returns: 'date',
    },
    GET_TIME: {
        name: 'get_time',
        category: 'datetime',
        arity: 1,
        arguments: ['string'],
        returns: 'time',
    },
    // Texts
    CONCATENATE: {
        name: 'concat',
        category: 'text',
        minArity: 1,
    },
    // Sets
    COUNT: {
        name: 'count',
        category: 'array',
        arity: 1,
        arguments: ['array'],
        returns: 'number',
    },
    SELECTED: {
        name: 'selected',
        category: 'array',
        minArity: 2,
        maxArity: 4,
        arguments: ['array', 'string', 'number', 'number'],
        returns: 'number',
    },
    // Field value
    FIELD_VALUE: {
        name: 'field',
        category: 'field',
        minArity: 1,
        maxArity: 2,
        arguments: ['field', 'listIndices'],
        returns: 'any',
    },
    // Answers set
    ANSWER: {
        name: 'answer',
        category: 'answer',
        arity: 2,
        arguments: ['answerSet', 'answer'],
        returns: 'string',
    },
};

// @enum {string}
module.exports = Object.keys(definitions).reduce((acc, key) => {
    acc[key] = definitions[key].name;

    return acc;
}, {});

module.exports.get = name => {
    for (const key in definitions) {
        const definition = definitions[key];

        if (definition.name === name) {
            return new FormulaFunction(definition);
        }
    }

    return null;
};

module.exports.all = () => {
    return Object.keys(definitions).map(key => new FormulaFunction(definitions[key]));
};
