'use strict';

const Expression = require('./Expression');

/**
 * Clase de gestión de una expresión para obtener un valor constante
 *
 * @extends Criteria.Expression
 *
 * @property {mixed} value El valor fijo definido
 *
 * @memberOf Criteria
 */
class ValueExpression extends Expression {
    /**
     * Constructor de la clase: añade la propiedad "value" al objeto
     *
     * @param  {mixed} value Definición del valor
     */
    constructor(value) {
        super();

        this.value = value;
    }

    /**
     * Obtiene el identificador del tipo de expresión
     *
     * @return {string} Tipo de expresión: "value"
     */
    getType() {
        return 'value';
    }

    /**
     * Genera una representación de la expresión apta para exportar o depurar
     *
     * @return {string} Información de los datos de la expresión en una cadena
     *
     * @example
     * // returns 'value:17'
     * const expr = new ValueExpression({value: 17});
     * expr.toString();
     *
     * @example
     * // returns '"string"'
     * const expr = new ValueExpression({value: 'string'});
     * expr.toString();
     */
    toString() {
        return `value:${JSON.stringify(this.value)}`;
    }

    /**
     * Obtiene el valor constante definido
     *
     * @return {mixed} El valor
     *
     * @example
     * // returns 17
     * const expr = new ValueExpression({value: 17});
     * expr.getValue();
     */
    getValue() {
        return this.value;
    }
}

module.exports = ValueExpression;
