'use strict';

const CoreBase = require('./CoreBase');

/**
 * Caché genérica de elementos
 */
class ElementsCache extends CoreBase {
    /**
     * Constructor estático
     *
     * @param  {...any}        args Argumentos originales del constructor
     *
     * @return {ElementsCache}      Instancia creada
     */
    static instance(...args) {
        return new this(...args);
    }

    /**
     * @param {object}        definition Objeto plano de definición de elementos accesibles por clave
     * @param {Structure.CRF} crf        Instancia del objeto de estructura
     */
    constructor(definition, crf) {
        super(definition);

        this.crf = crf;

        // Lista de elementos indexados por ID
        this.elements = {};
    }

    /**
     * Obtiene la lista de elementos que se encuentran agrupados bajo una misma clave. La clave debe estar contenida en
     * el objeto de definición y albergar una lista de IDs de elemento
     *
     * @param  {string}                key Clave bajo la que se encuentran los elementos
     *
     * @return {Structure.Container[]}     Lista de elementos obtenidos
     */
    getItemsByGroup(key) {
        const idsByKey = this.definition[key];
        if (!Array.isArray(idsByKey)) {
            return [];
        }

        return idsByKey.reduce((acc, elementId) => {
            const element = this.getElement(elementId);

            return element ? acc.concat(element) : acc;
        }, []);
    }

    /**
     * Obtiene un elemento cacheado a partir de su ID. Si no se encuentra en la caché interna, se añade
     *
     * @param  {Number}                     elementId ID de elemento a buscar
     *
     * @return {Structure.Container | null}           El elemento encontrado, o NULL si no pertenece a la estructura
     */
    getElement(elementId) {
        if (!(elementId in this.elements)) {
            // El resultado se guarda en el objeto aunque el elemento no se encuentre en el CRF
            this.elements[elementId] = this.crf.getElement(elementId);
        }

        return this.elements[elementId];
    }
}

module.exports = ElementsCache;