'use strict';

const CrfElementConditionSide = require('./CrfElement');

/**
 * Tipo de expresión: valor de un campo del CRF
 *
 * @property {Structure.Field} target Definición del campo seleccionado
 *
 * @extends Structure.CrfElementSide
 */
class FieldConditionSide extends CrfElementConditionSide {
    /**
     * @inheritDoc
     *
     * @throws {Error} Si no se reconoce el tipo del campo de destino. Se trata más bien de una medida de control para
     *                 desarrollo
     */
    getDataType() {
        const field = this.getTarget();
        if (!field) {
            return null;
        }

        const fieldType = field.getFieldType();
        const optionsGroup = field.getOptions();

        let dataType;
        switch (fieldType) {
            case 'boolean':
                dataType = FieldConditionSide.DataTypes.BOOLEAN;
                break;
            case 'number':
                dataType = FieldConditionSide.DataTypes.NUMBER;
                break;
            case 'string':
                if (field.getFormControl() === 'datepicker') {
                    dataType = FieldConditionSide.DataTypes.DATE;
                } else if (field.getFormControl() === 'datetimepicker') {
                    dataType = FieldConditionSide.DataTypes.DATETIME;
                } else if (optionsGroup) {
                    dataType = FieldConditionSide.DataTypes.OPTIONS_GROUP + optionsGroup.getId();
                } else {
                    dataType = FieldConditionSide.DataTypes.STRING;
                }
                break;
            case 'array':
                dataType = FieldConditionSide.DataTypes.ARRAY;
                break;
        }

        if (!dataType) {
            throw new Error(`Tipo de dato desconocido para asociar: "${fieldType}"`);
        }

        return dataType;
    }

    /**
     * Determina si el lado de la condición admite modificadores de valor
     *
     * @return {boolean} Verdadero si el destinatario es un campo de tipo numérico o fecha
     *
     * @override
     */
    canModifyValue() {
        const modifiableTypes = [FieldConditionSide.DataTypes.NUMBER, FieldConditionSide.DataTypes.DATE];

        return modifiableTypes.indexOf(this.getDataType()) > -1;
    }
}

module.exports = FieldConditionSide;
