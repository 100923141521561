'use strict';

const debug = require('debug')('sharecrf:core');
const _ = require('lodash');

const Data = require('./Data');

/**
 * Contiene información acerca del estado general de las notas de discrepancia. Lleva a cuenta cosas como nº de notas
 * abiertas, contestadas, cerradas, etc.
 *
 * @typedef {QueriesMetaInfo}
 *
 * @property {Number}  new                  Nº de queries nuevas
 * @property {Number}  updated              Nº de queries actualizadas
 * @property {Number}  resolutionProposed   Nº de queries con solución propuesta
 * @property {Number}  closed               Nº de queries cerrads
 * @property {boolean} exists               Indica si el campo aún existe en el CRF
 * @property {Array}   label                Lista de componentes como strings con la etiqueta completa del campo
 */

/**
 * Obtiene una copia nueva de un objeto tipo para metainformación de queries
 *
 * @return {object} Objeto inicializado
 */
function getMetainfoBoilerplate() {
    const metaInfoBoilerplate = {
        total: 0,
        new: 0,
        updated: 0,
        resolutionProposed: 0,
        closed: 0,
        exists: true,
        label: [],
    };

    return metaInfoBoilerplate;
}

/**
 * Objeto de gestión de notas de discrepancia
 *
 * El objeto interno está indexado por el UID del campo
 *
 * @memberOf Record
 */
class Queries {
    /**
     * @param  {Record.Data} recordData  Instancia del registro/paciente asociado a estas notas
     * @param  {Record.Metadata} recordMetadata  Instancia de metadatos
     * @param  {Structure.Rules} rules  Definición de reglas según el CRF
     * @param  {Configuration} configuration   Instancia del configuración del eCRD
     */
    constructor(recordData, recordMetadata, rules, configuration) {
        this.record = recordData;
        this.recordMetadata = recordMetadata;
        this.rules = rules;
        this.configuration = configuration;
        this.crf = recordData.getCRF();
        this.queries = {};
        this.metaInfo = {};

        Object.defineProperty(this.metaInfo, 'global', {
            enumerable: false,
            configurable: false,
            writable: false,
            value: {
                total: 0,
                new: 0,
                updated: 0,
                resolutionProposed: 0,
                closed: 0,
            },
        });
    }
    /**
     * Carga las notas de discrepancia de un registro/paciente en BD
     *
     * @param  {Array} queriesData  Lista de notas de discrepancia obtenidas de BD
     *
     * @return {Queries}            Instancia de gestor de queries
     */
    load(queriesData = {}) {
        // Miramos primero los fieldUId que obtenemos de la lista de queries a cargar
        // De este modo, haremos una limpia de los que han desaparecido selectivamente. Esto nos permitirá
        const fieldUIdToKeep = _.uniq(_.map(queriesData, 'fieldUId'));
        const formUIdToKeep = _.compact(_.map(fieldUIdToKeep, fieldUId => {
            const field = this.crf.getElementByUID(fieldUId);
            if (field !== null) {
                const listIndices = this.record.getListIndicesByUID(fieldUId);
                const formId = field.getForm().getId();

                // TODO renombrar las funciones como getFieldKey para que no lleven a confusión, es getElementKey
                return this.getFieldKey(formId, listIndices);
            }
        }));
        this._clean(fieldUIdToKeep.concat(formUIdToKeep));
        _.each(queriesData, query => {
            if (_.isUndefined(this.queries[query.fieldUId])) {
                this.queries[query.fieldUId] = [];
            }
            this.queries[query.fieldUId].push(query);
        });
        this._calculateQueriesMetainfo();

        return this;
    }
    /**
     * Resetea la información de datos de notas de discrepancia, sin perder la referencia en memoria
     * del objeto que las almacena
     *
     * Útil para manejar esta instancia como un singleton, por ejemplo, en un servicio de AngularJS
     */
    reset() {
        this._clean();
    }
    /**
     * Limpia la información almacenada en esta instancia
     *
     * Opcionalmente se le puede pasar una lista de UID de campos a que no se deben borrar,
     * útil para no eliminar todas las referencias en memoria de golpe, solo las que no se están usando
     *
     * @param {Array<string>} [UIdToKeep] Lista de UID de campos a conservar
     */
    _clean(UIdToKeep) {
        if (Array.isArray(UIdToKeep)) {
            // borrado selectivo de claves
            _.each(Object.keys(this.queries), fieldUId => {
                if (_.includes(UIdToKeep, fieldUId)) {
                    // borramos los elementos, pero conservamos la referencia al array original
                    _.remove(this.queries[fieldUId]);
                } else {
                    _.unset(this.queries, fieldUId);
                }
            });
            _.each(Object.keys(this.metaInfo), elementUId => {
                if (_.includes(UIdToKeep, elementUId)) {
                    // solo reseteamos los valores
                    Object.assign(this.metaInfo[elementUId], getMetainfoBoilerplate());
                } else {
                    _.unset(this.metaInfo, elementUId);
                }
            });
        } else {
            // borrado completo de claves
            _.each(Object.keys(this.queries), elementUId => _.unset(this.queries, elementUId));
            _.each(Object.keys(this.metaInfo), elementUId => _.unset(this.metaInfo, elementUId));
        }

        // Reseteo de datos globales (suma total)
        Object.assign(this.metaInfo.global, getMetainfoBoilerplate());
    }

    /**
     * Devuelve las queries asociadas a este registro
     *
     * @return {Array<object>} Lista de notas de discrepancia
     */
    getQueries() {
        return this.queries;
    }
    /**
     * Devuelve la metainformación completa asociada a todas las notas de discrepancia de este registro
     *
     * Es un objeto indexado por el UID (tal y como lo devuelve Record.Data.getElementUID) de cada campo
     *
     * @return {object}  Metainformación completa indexada por UID del campo
     */
    getMetaInfo() {
        return this.metaInfo;
    }

    /**
     * Construye una clave única para cada campo en los datos del eCRD, útil para indexar el objeto interno de
     * datos de queries *this.queries* o *this.metaInfo*
     *
     * Por ejemplo, si un campo está dentro de una lista, lo tiene en cuenta para distinguir los campos distintos
     * dentro de cada uno de los ítems.
     *
     * @param  {Number}  fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {string}              Clave única para el campo, NULL si no existe
     */
    getFieldKey(fieldId, listIndices) {
        // El elementUId nos identifica unívocamente un campo en los datos. Para campos dentro de una lista
        // por ejemplo contiene también la información del ítem que lo contiene
        const elementUId = this.record.getElementUID(fieldId, listIndices);

        // como es un array, y lo queremos para obtener la clave en los datos de queries,
        return Array.isArray(elementUId) ? elementUId.join('.') : elementUId;
    }

    /**
     * Calcula una serie de datos adicionales relacionados con las notas de discrepancia
     *
     * Mantiene un objeto con la suma de notas por estado por cada UID de campo y UID de formulario
     *
     * @private
     */
    _calculateQueriesMetainfo() {
        _.each(this.queries, (queryList, fieldUId) => {
            const field = this.crf.getElementByUID(fieldUId);
            const fieldKey = fieldUId;
            const fieldExists = field !== null;

            let formId, formKey, listIndices;
            if (field !== null) {
                listIndices = this.record.getListIndicesByUID(fieldUId);
                formId = field.getForm().getId();
                formKey = this.getFieldKey(formId, listIndices);
            }

            // Llevamos a cuenta meta-información por campo y por formulario (si el campo existe)
            if (_.isUndefined(this.metaInfo[fieldKey])) {
                this.metaInfo[fieldKey] = getMetainfoBoilerplate();
            }
            this.metaInfo[fieldKey].exists = fieldExists;
            if (fieldExists) {
                this.metaInfo[fieldKey].label = this.record.getElementFullLabeledPathByUID(fieldUId);
                this.metaInfo[fieldKey].name = field.getName();
                this.metaInfo[fieldKey].id = field.getId();
            } else {
                this.metaInfo[fieldKey].label = _.get(queryList, [0 , 'fieldLabel'], fieldUId);
                this.metaInfo[fieldKey].name = _.get(queryList, [0 , 'fieldName'], '');
                this.metaInfo[fieldKey].id = this.crf.getElementIdByUID(fieldUId);
            }

            if (formKey) {
                if (_.isUndefined(this.metaInfo[formKey])) {
                    this.metaInfo[formKey] = getMetainfoBoilerplate();
                }
                _.remove(this.metaInfo[formKey].label);
                _.merge(this.metaInfo[formKey].label, this.record.getElementFullLabeledPathByUID(formKey));
            }

            _.each(queryList, query => {
                this.metaInfo.global.total++;
                this.metaInfo[fieldKey].total++;
                if (formKey) {
                    this.metaInfo[formKey].total++;
                }
                const lastText = query.lastText ? query.lastText : query.texts[query.texts.length - 1];
                switch (lastText.status) {
                    case 'new':
                        this.metaInfo.global.new++;
                        this.metaInfo[fieldKey].new++;
                        if (formKey) {
                            this.metaInfo[formKey].new++;
                        }
                        break;
                    case 'updated':
                        this.metaInfo.global.updated++;
                        this.metaInfo[fieldKey].updated++;
                        if (formKey) {
                            this.metaInfo[formKey].updated++;
                        }
                        break;
                    case 'resolution_proposed':
                        this.metaInfo.global.resolutionProposed++;
                        this.metaInfo[fieldKey].resolutionProposed++;
                        if (formKey) {
                            this.metaInfo[formKey].resolutionProposed++;
                        }
                        break;
                    case 'closed':
                        this.metaInfo.global.closed++;
                        this.metaInfo[fieldKey].closed++;
                        if (formKey) {
                            this.metaInfo[formKey].closed++;
                        }
                        break;
                }
            });
        });
        debug('Queries loaded %O', this.metaInfo.global);
    }

    /**
     * Devuelve la metainformación asociada a un campo del registro/paciente. Si el campo no tiene nada asociado
     * (porque no tendrá notas de discrepancia), lo inicializa con un objeto inicial
     *
     * @param  {Number}  fieldId    ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {QueriesMetaInfo}    Metainfo de notas sobre ese campo
     */
    getFieldMetaInfo(fieldId, listIndices) {
        const fieldKey = this.getFieldKey(fieldId, listIndices);
        if (!fieldKey) {
            return {};
        }
        if (_.isUndefined(this.metaInfo[fieldKey])) {
            this.metaInfo[fieldKey] = getMetainfoBoilerplate();
        }

        return this.metaInfo[fieldKey];
    }

    /**
     * Devuelve la metainformación asociada a un formulario del registro. Si el formulario no tuviera metainformación
     * asociada se inicia con un objeto inicial
     *
     * @param  {Number}          formId      ID del formulario en el CRF
     * @param  {ListIndices}     listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {QueriesMetaInfo}             Metainfo de notas sobre el formulario
     */
    getFormMetaInfo(formId, listIndices) {
        const formKey = this.getFieldKey(formId, listIndices);
        if (!formKey) {
            return {};
        }
        if (_.isUndefined(this.metaInfo[formKey])) {
            this.metaInfo[formKey] = getMetainfoBoilerplate();
        }
        if (_.isEmpty(this.metaInfo[formKey].label)) {
            _.remove(this.metaInfo[formKey].label);
            _.merge(this.metaInfo[formKey].label, this.record.getElementFullLabeledPathByUID(formKey));
        }

        return this.metaInfo[formKey];
    }

    /**
     * Devuelve la lista de queries sobre un campo individual
     *
     * @param  {Number}  fieldId    ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {Array}             Lista de queries de ese campo concreto
     */
    getFieldQueries(fieldId, listIndices) {
        const fieldKey = this.getFieldKey(fieldId, listIndices);
        if (!fieldKey) {
            return [];
        }
        if (_.isUndefined(this.queries[fieldKey])) {
            this.queries[fieldKey] = [];
        }

        return this.queries[fieldKey];
    }

    /**
     * Devuelve la lista de queries de un campo provenientes de una regla concreta identificada por su ID
     *
     * @param  {Number}  fieldId  ID del campo en el CRF
     * @param  {Number}  ruleId   ID de la regla en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     *
     * @return {Array}            Lista de queries
     */
    getFieldQueriesByRule(fieldId, ruleId, listIndices) {
        return _.filter(this.getFieldQueries(fieldId, listIndices), query => {
            return query.firstText.ruleId && query.firstText.ruleId === ruleId;
        });
    }

    /**
     * Determina si la query que se le pasa, que debe ser automática, ya se considera que está creada y por tanto
     * no se debería volver a crear
     * Considera que ya está creada si se cumplen de estas condiciones:
     * 1. Hay una query existente sobre el mismo campo, de tipo "rule" (automática) y con la misma regla (ruleId)
     * 2. Esa query no está cerrada de forma manual
     * 3. Esa query está cerrada automáticamente, pero el valor del campo sigue siendo el mismo
     *
     * @param  {object}  queryToCreate  Nueva query que queremos comprobar
     *
     * @return {boolean}       TRUE si se considera que esa query ya existe sobre el campo
     */
    isAlreadyCreatedByRules(queryToCreate) {
        const fieldId = this.record.getElementId(queryToCreate.fieldUId);
        const listIndices = this.record.getListIndicesByUID(queryToCreate.fieldUId);
        const ruleId = queryToCreate.ruleId;

        const fieldQueries = this.getFieldQueriesByRule(fieldId, ruleId, listIndices);

        return _.some(fieldQueries, query => {
            // GARU-4819 El fieldValue de una query es un objeto compuesto
            const oldFieldValue = _.get(query.lastText.fieldValue, 'value');
            const newFieldValue = _.get(queryToCreate.fieldValue, 'value');

            return query.lastText.status !== 'closed' // ya hay una query sin cerrar debida a esta regla
                || (
                    query.lastText.type == 'manual'    // o el cierre es manual
                    && Queries.isSameValue(oldFieldValue, newFieldValue) // y el valor no ha cambiado
                );
        });
    }

    /**
     * Is same value
     *
     * @param {*} valueA Value to compare
     * @param {*} valueB Value to compare
     *
     * @return {boolean} TRUE if both values are equal
     *
     * @private
     */
    static isSameValue(valueA, valueB) {
        return Array.isArray(valueA) && Array.isArray(valueB) && valueA.length === valueB.length
            // 1. Valores múltiples
            ? valueA.every(item => {
                if ((Object(item) === item)) {
                    // 1.1 se trata de un array de objetos: campo de tipo fichero o dicom (con "id") o campo de opciones (con "value")
                    return (typeof item.id !== 'undefined' && valueB.find(value => value.id === item.id))
                        || (typeof item.value !== 'undefined' && valueB.find(value => value.value === item.value));
                }

                // 1.2 array de valores simples
                return valueB.includes(item);
            })
            // 2. Valores simples
            : valueA === valueB;
    }

    /**
     * Devuelve la lista de queries de un campo provenientes de guardar el paciente sin rellenar un campo obligatorio,
     * si la configuración así lo establece el sistema crea una query automática
     *
     * @param  {Number}      fieldId     ID del campo en el CRF
     * @param  {ListIndices} listIndices Índices de las listas a las que pertenece el campo
     * @param  {string}      stateId     ID del estado, si lo hay, por el cual filtrar la lista de notas
     *
     * @return {Array}                   Lista de queries
     *
     * @private
     */
    getFieldQueriesByRequired(fieldId, listIndices, stateId) {
        return this.getFieldQueries(fieldId, listIndices).filter(query => {
            return query.firstText.type === 'field_required' && (!stateId || query.firstText.stateId === stateId);
        });
    }

    /**
     * Determina si la query que se le pasa, que debe ser automática, ya se considera que está creada y por tanto
     * no se debería volver a crear
     * Considera que ya está creada si existe alguna query sobre ese campo para la que se se cumplen estas condiciones:
     * 1. Hay una query existente sobre el mismo campo, de tipo 'field_required' y asociada al mismo estado
     * Y
     * 2.1. Esa query no está cerrada
     * O
     * 2.2. Esa query está cerrada manualmente, y el valor en la última respuesta es vacío
     *
     * @param  {object}  queryToCreate  Nueva query que queremos comprobar
     *
     * @return {boolean}       TRUE si se considera que esa query ya existe sobre el campo
     */
    isAlreadyCreatedByFieldRequired(queryToCreate) {
        const fieldId = this.record.getElementId(queryToCreate.fieldUId);
        const listIndices = this.record.getListIndicesByUID(queryToCreate.fieldUId);

        const field = this.crf.getField(fieldId);
        const fieldType = field.getFieldType();

        const fieldQueries = this.getFieldQueriesByRequired(fieldId, listIndices, queryToCreate.stateId);

        return _.some(fieldQueries, query => {
            // GARU-4819 El fieldValue de una query es un objeto compuesto
            const queryFieldValue = _.get(query.lastText.fieldValue, 'value');

            return query.lastText.status !== 'closed' // ya hay una query sin cerrar sobre este mismo campo
                || (
                    query.lastText.type == 'manual'              // o el cierre es manual
                    && Data.isValueEmpty(queryFieldValue, fieldType)  // y se cerró con valor vacío
                );
        });
    }

    /**
     * Determina a través de los datos de una query que se le pasan (p.ej. provenientes de una acción de una regla)
     * si hay una query susceptible de ser cerrada automáticamente
     *
     * Considera que se puede responder y cerrar si se cumplen de estas condiciones:
     * 1. Hay una query existente sobre el mismo campo, automática y con la misma regla (ruleId)
     * 2. Esa query no está ya cerrada
     *
     * @param  {object}  queryToClose  Datos de la query que queremos cerrar
     *
     * @return {object}  Datos de la query a cerrar, undefined si no hay ninguna adecuada
     *
     * @todo  No contempla el caso de que haya más de una query abierta por causa de la misma regla, es que eso
     *        no debería pasar, ¿lo deberíamos contemplar por si acaso?
     */
    getOpenQueryByRules(queryToClose) {
        const fieldId = this.record.getElementId(queryToClose.fieldUId);
        const listIndices = this.record.getListIndicesByUID(queryToClose.fieldUId);
        const ruleId = queryToClose.ruleId;

        const fieldQueries = this.getFieldQueriesByRule(fieldId, ruleId, listIndices);

        // devuelve si hay una query sin cerrar debida a esta regla
        return _.find(fieldQueries, query => query.lastText.status !== 'closed');
    }

    /**
     * Determina a partir del UID de un campo si tiene queries abiertas debido a la configuración de estados de
     * aprobación y al estado de obligatorio del propio campo que puedan ser cerradas automáticamente
     *
     * Útil por ejemplo para determinar las que podemos cerrar automáticamente porque ya venga un valor en el paciente,
     * o porque haya dejado de ser requerido (por estar oculto o deshabilitado)
     *
     * @param  {string}   fieldUId  UID del campo
     * @param  {string}   stateId   El ID del estado de aprobación asociado que generó la nota automática
     *
     * @return {object[]}           Datos de las queries a cerrar
     */
    getOpenQueriesByFieldRequired(fieldUId, stateId = null) {
        const fieldId = this.record.getElementId(fieldUId);
        const listIndices = this.record.getListIndicesByUID(fieldUId);

        const fieldQueries = this.getFieldQueriesByRequired(fieldId, listIndices, stateId);

        return fieldQueries.filter(query => query.lastText.status !== 'closed');
    }
}

module.exports = Queries;
